import React, { useState } from 'react';

import ItemContent from './ItemContent';
import styles_field from '../field.module.css';
import styles_content from './content.module.css';

var update = require('immutability-helper');

function Content(props) {

  const [values, updateValues] = useState(props.field.value)
  const [formValues, updateFormValues] = useState([])

  function onChange({ value, image, index, field }){
    let newValue = [...values]
    let newIndex = newValue.findIndex(x => x.key === index) 

    if(newIndex !== -1){
      newValue[newIndex] = {...newValue[newIndex]}
      newValue[newIndex].value = {...newValue[newIndex].value}
      newValue[newIndex].value = {type:field, value}
      updateValues(newValue)
    }

    let newFormValues = [...formValues]
    let newIndexFormValues = newFormValues.findIndex(x => x.index === index) 
    if(newIndexFormValues !== -1){
      newFormValues[newIndexFormValues] = {index:index, type:field, value:value && value.id ? value.id : value}
    }else{
      newFormValues.push({index:index, type:field, value:value && value.id ? value.id : value, item: value})
    }
    updateFormValues(newFormValues)
    props.onChange({
      value:newFormValues, 
      image:image && {image, value},
      key:props.field.key,
    })
  }

  return(
    <div className={`${styles_field.container_field} ${styles_content.content}`} key={props.key}>
      {props.renderLabelField()}
      <div className={`${styles_content.inner_content}`}>
        {values.map((item, index) => {
          return(
            <ItemContent
              item={item}
              dataFiles={props.dataFiles}
              onChange={(data) => onChange(data, index)}
              id_cab={props.id_cab}
              key={item.key}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Content;
