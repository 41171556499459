import React, { useState, useEffect, Stylesheet } from 'react';
import ReactHtmlParser from 'react-html-parser';
import AddCV from '@user/addcv/AddCV.js';

import styles from '../apply.module.css';

function ApplyCV({ 
  cv,
  deleteCV,
  getCV,
}){
  
  return(
    <div className={`${styles.content_offre} ${styles.content_cv}`}>
      <div>
        <img src={require('@user/listofferjunior/modaloffre/icon/apply_cv.svg').default}/>
      </div>
      <div className={styles.label_apply}>
        On y est presque ! Ajoutez votre CV et postulez à l’offre !
      </div>
      <AddCV 
        cv={cv}
        deleteCV={deleteCV}
        getCV={getCV}
        label={cv ? 'Mon CV' : 'Ajouter mon CV'}
      />
    </div>
  )
}

export default ApplyCV