import React, { useState, useEffect, Stylesheet } from 'react';
import ReactHtmlParser from 'react-html-parser';

import Modal from '@utils/modal/Modal';
import Form from '@utils/form/Form';

import styles from './stopapplication.module.css';

var update = require('immutability-helper');

const StopApplication = ({ 
  id_apply,
  closeModal,
  onMoveOffer,
  endpoint,
  admin
}) => {

  const fields = [
    {label:'J\'ai accepté une offre d\'une autre entreprise', value:0},
    {label:'Le cabinet n\'a pas donné suite à ma candidature', value:1},
    {label:'Autre', value:2, hiddenDetails:{placeholder:'Expliquez-nous en quelques mots pourquoi vous souhaitez mettre fin à votre candidature, cela nous permettra de vous proposer des offres plus adaptées !'}}
  ]

  if(admin) fields.push({ label:'Candidature périmée', value:3 })

  return(
    <Modal
      closeModal={closeModal}
      header='Arrêt du processus'
      modalShrink={true}
    >
      <Form 
        fields={[
          {type:'checkbox', key:'stop_explanation', options:fields}
        ]}
        endpoint={endpoint}
        autoHeight={true}
        buttons={{
          action:{label:'Arrêter le processus', type:'delete', field:[{key:'status', value:2}, {key:'id_apply', value:id_apply}]},
          sub_action:{label:'Annuler', type:'cancel', onPress:closeModal},
        }}
        success={onMoveOffer}
      />
    </Modal>
  )
}

export default StopApplication