import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import HomePage from './HomePage';

import Header from '@utils/header/Header';
import Carrousel from './carrousel/Carrousel';
import TypeTranslate from './typetranslate/TypeTranslate';
import BannerScreening from './bannerscreening/BannerScreening';
import ButtonBuddyScan from './buttonbuddyscan/ButtonBuddyScan';
import SimulateurPreview from './simulateurpreview/SimulateurPreview';
import ListOfferJunior from '@user/listofferjunior/ListOfferJunior';
import List from '@utils/list/List';

import MinCab from '@site/cabs/mincab/MinCab';
import MinArticle from '@site/articles/minarticle/MinArticle';

import { Scrollbars } from 'react-custom-scrollbars';

import customFetch from '@utils/function/customFetch';
import useListReducer from '@utils/function/useListReducer';

import styles from './home.module.css';

const Home = ({
  openBuddyScan,
  user,
  fetchUser,
  showConnect,
}) => {

  const [isLoading, updateLoading] = useState(true)
  const [numberCabs, updateNumberCabs] = useState()
  const [carrousel, updateCarrousel] = useState()
  const [cabs, updateCabs] = useState([])
  const [articles, updateArticles] = useState([])
  const [cv, updateCV] = useState();

  useEffect(() => {
    customFetch({ endpoint: 'home_page', onSuccess, type:'GET'}) 
    customFetch({ endpoint: 'home_cabs', onSuccess:({response}) => updateCabs(response), type:'GET'}) 
    customFetch({ endpoint: 'home_articles', onSuccess:({response}) => updateArticles(response), type:'GET'}) 
    customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 3, type : 0 } , type:'POST'}) 
  }, [])


  useEffect(() => {
    if(user){
      customFetch({ endpoint: 'user/profile', onSuccess: onSuccessProfileUser, type:'GET'})
    }
  }, [user])

  function onSuccessProfileUser({ response }){
    updateCV(response?.cv)
  }

  function onSuccess({ response }){
    updateNumberCabs(response.numberCabs)
    updateCarrousel(response.carrousel)
    updateLoading(false)
  }

  if(isLoading) return null
 
  return(
    <div className={styles.container_home} >
      <div className={styles.intro_main_page}>
        <div className={styles.card_main_page}>
          <div className={styles.background}></div>
          <div className={styles.content_card}>
            <div className={styles.container_logo}>
              <img src={require('@icon/consultingbuddies.svg').default} alt="Consulting Buddies"/>
            </div>
            <div className={styles.container_presentation}>
              <div className={styles.item_presentation}>
                <img src={require('@icon/arrow.svg').default} alt=""/>
                <div className={styles.label_presentation}>Comprendre le conseil</div>
              </div>
              <div className={styles.item_presentation}>
                <img src={require('@icon/arrow.svg').default} alt=""/>
                <div className={styles.label_presentation}>
                  <BrowserView>
                    Découvrir les cabinets en
                    <TypeTranslate />
                  </BrowserView>
                  <MobileView>
                    Découvrir des cabinets
                  </MobileView>
                </div>
              </div>
              <div className={styles.item_presentation}>
                <img src={require('@icon/arrow.svg').default} alt=""/>
                <div className={styles.label_presentation}>
                  {isMobile ? 'Se faire recruter' : 'Choisir ceux qui vous correspondent'}
                </div>
              </div>
            </div>

            <BrowserView>
              <ButtonBuddyScan
                onClick={openBuddyScan}
                label={isMobile ? "Trouvez le cabinet idéal" : "Trouvez le cabinet qui vous correspond"}
                styleLabel={{fontSize:'2.8vh', marginLeft:'1vw'}}
                style={isMobile ? {alignSelf:'stretch', marginTop:'5vh', borderRadius:'4vh'} : {marginTop:'5vh', borderRadius:'4vh'}}
                styleIcon={{height:'3vh', width:'3vh', padding:'1.5vh'}}
              />
            </BrowserView>
            
            <BrowserView>
              <div className={styles.container_infos}>
                <div className={styles.item_info}>
                  <img src={require('@icon/timer_color.png').default} alt=""/>
                  <div className={styles.title_info}>3 minutes</div>
                  <div className={styles.description_info}>Le temps pour créer votre profil</div>
                </div>
                <div className={styles.item_info}>
                  <img src={require('@icon/cabs_home.png').default} alt=""/>
                  <div className={styles.title_info}>{numberCabs} cabinets</div>
                  <div className={styles.description_info}>Référencés et présentés en détail</div>
                </div>
              </div>
            </BrowserView>
          </div>
        </div>
        
        <Carrousel 
          carrousel={carrousel}
        />
      </div>

      <MobileView style={{zIndex:2, alignItems:'center', justifyContent:'center', right:0, transform:'translateY(-50%)', position:'sticky', top:window.innerHeight * 0.8 + 'px'}}>
        <ButtonBuddyScan
          onClick={openBuddyScan}
          label={"Trouvez le cabinet idéal"}
          styleLabel={{fontSize:'2.2vh', marginLeft:'1vw'}}
          style={{alignSelf:'stretch', borderRadius:'4vh'}}
          styleIcon={{height:'3vh', width:'3vh', padding:'1vh'}}
        />
      </MobileView>


      <div className={styles.section}>
        <div className={styles.title_section}>
        <div className={styles.label_title_section}>Nous vous aidons à trouver le cabinet idéal</div>
        </div>
        <div className={styles.content_section}>
          <BannerScreening
            onClick={openBuddyScan}
          />
        </div>
      </div>

      <div className={`${styles.section} ${styles.section_offres}`}>
        <div className={styles.title_section}>
          <div className={styles.label_title_section}>Découvrez les cabinets de conseil référencés</div>
          <Link to={'/cabinets'} className={`${styles.see_more} hover_color`}>
            <span>Voir plus</span>
            <img src={require('@icon/arrow_color_right.svg').default}/>
          </Link>
        </div>
        <div className={`${styles.content_section} ${styles.content_section_list}`}>
          {cabs.map((item, index) => (
            <MinCab 
              cab={item} 
              style={
                isMobile ? {width:'100%'} : 
                  index%3 === 2 ? {width:'32%'} : {width:'32%', marginRight: '2%'}
              }
            />
          ))}
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.title_section}>
          <div className={styles.label_title_section}>Informez-vous sur les spécificités du recrutement en conseil</div>
          <Link to={'/articles'} className={`${styles.see_more} hover_color`}>
            <span>Voir plus</span>
            <img src={require('@icon/arrow_color_right.svg').default}/>
          </Link>
        </div>
        <div className={`${styles.content_section} ${styles.content_section_list}`}>
          {articles.map((item, index) => (
            <MinArticle
              article={item} 
              style={isMobile ? {width:'100%', marginBottom:'3vh'} : 
                index%3 === 2 ? {width:'32%', margin:'0 0 3vh 0'} : {width:'32%', margin:'0 2% 3vh 0'}
              }
            />
          ))}
        </div>
      </div>

      <div className={`${styles.section} ${styles.section_offres}`}>
        <div className={styles.title_section}>
          <div className={styles.label_title_section}>Trouvez votre prochain emploi/stage parmi les offres du moment</div>
          <Link to={'/offres'} className={`${styles.see_more} hover_color`}>
            <span>Voir plus</span>
            <img src={require('@icon/arrow_color_right.svg').default}/>
          </Link>
        </div>
        <div className={`${styles.content_section} ${styles.content_section_list}`}>
          <ListOfferJunior 
            noScroll={true}
            endpoint={'user/get_offres'}
            endpointDetails={'user/offre'}
            user={user}
            fetchUser={fetchUser}
            home={true}
            hideHeader={true}
            extraData={{home:true}}
            style={isMobile ? {} : {padding:'0 6vw'}}
            profileUser={{cv}}
            showConnect={showConnect}
          />
        </div>
      </div>
      
      <BrowserView>
        <SimulateurPreview/>
      </BrowserView>

    </div>
  )
}

export default Home;
