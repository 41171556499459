import React, { useState, useEffect, Stylesheet } from 'react';

import HomeUser from '@user/HomeUser'
import Header from '@utils/header/Header'

import customFetch from '@utils/function/customFetch';

import './mainuser.css'

const MainUser = ({
  location,
}) => {

  const [user, updateUser] = useState()
  const [profileUser, updateProfileUser] = useState()
  const [initialMatch, updateInitialMatch] = useState()
  const [initialOffre, updateInitialOffre] = useState()
  const [initialSpontaneous, updateInitialSpontaneous] = useState()
  const [isLoading, updateIsLoading] = useState(true)
  const [modal, updateModal] = useState(null)

  const searchParams = []

  useEffect(() => {
    if(location.search){
      const params = location.search.replace('?', '').split('&')
      for(let i=0; i<params.length; i++){
        const data = params[i].split('=')
        searchParams.push({key:data[0], value:data[1]})
      }
    }

    /* if(searchParams.length>0 && searchParams[0].key === 'success_subscribe'){
      let params = { token : localStorage.getItem('token'), id_activity: 7 }
      if(searchParams.length === 2){
        if(searchParams[1].key === 'id_offre') params.id_offre = searchParams[1].value
        if(searchParams[1].key === 'id_spontaneous') params.id_cab = searchParams[1].value
      }
      customFetch({ endpoint: 'activity/activity', data: params , type:'POST'}) 
    } 
 */
    if(searchParams.length>0 && searchParams[0].key == 'id_offre') updateInitialOffre(searchParams[0].value)
    if(searchParams.length>0 && searchParams[0].key == 'id_spontaneous') updateInitialSpontaneous(searchParams[0].value)

    if(searchParams.length>1 && searchParams[1].key == 'id_offre') updateInitialOffre(searchParams[1].value)
    if(searchParams.length>1 && searchParams[1].key == 'id_spontaneous') updateInitialSpontaneous(searchParams[1].value)

    if(searchParams.length>0 && searchParams[0].key === 'key'){
      customFetch({ endpoint: 'user/verifAccount', onSuccess:fetchUser, type:'POST', data:{key:searchParams[0].value}})
    }else if(searchParams.length>0 && searchParams[0].key === 'id_match'){
      customFetch({ endpoint: 'user/email_match', onSuccess:onSuccessMatch, type:'POST', data:{id_match:searchParams[0].value, answer:searchParams[1].value}})
    }else{
      customFetch({ endpoint: 'user/login_user', onSuccess, onError, type:'GET'})
    }
  }, [])

  function onSuccess(data){
    updateUser(data.response)
    updateIsLoading(false)
  }

  function fetchUser(){
    customFetch({ endpoint: 'user/login_user', onSuccess, onError, type:'GET'})
  }

  function onError(error){
    window.location.href = '/'
  }

  function logOut(){
    customFetch({ endpoint: 'user/logout', onSuccess:onSuccesLogOut, type:'GET'})
  }

  function onSuccesLogOut(){
   // updateUser(null)
    window.location.href = '/'
  }

  function onSuccessMatch({ response }){
    if(response.error){
      window.location.href = '/'
    }else{
      updateInitialMatch({ id_match: response.id_match, answer: response.answer})
      updateUser(response.user)
      updateIsLoading(false)
    }
  }

  function onSuccessCV({ response }){
    let updatedProfile = {...profileUser}
    updatedProfile.cv = response.cv
    updateProfileUser(updatedProfile)
  }

  function onSuccessAvailability({ response }){
    let updatedProfile = {...profileUser}
    updatedProfile.available = response.available
    updateProfileUser(updatedProfile)
  }

  if(isLoading) return null
  
  return(
    <div id="container_candidat" className="container">
      <Header
        logOut={logOut}
        user={user}
        color
        profileUser={profileUser}
        updateModal={updateModal}
        onSuccessCV={onSuccessCV}
        onSuccessAvailability={onSuccessAvailability}
        updateUser={updateUser}
      />
      {user && (
        <HomeUser 
          user={user}
          //fetchUser={fetchUser}
          initialMatch={initialMatch}
          initialOffre={initialOffre}
          initialSpontaneous={initialSpontaneous}
          updateProfileUser={updateProfileUser}
          profileUser={profileUser}
          updateModal={updateModal}
          modal={modal}
          onSuccessCV={onSuccessCV}
          onSuccessAvailability={onSuccessAvailability}
          updateUser={updateUser}
          fetchUser={fetchUser}
        />
      )}

    </div>
  )
}

export default MainUser

 