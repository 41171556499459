import React from 'react';

import Menu from '@admin/menu/Menu';

import Cab from './cab/Cab';
import Article from './article/Article';
import HomePage from './homepage/HomePage';
import Administratif from './administratif/Administratif';

import Tab from '@utils/tab/Tab';

import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";

var update = require('immutability-helper');


class Site extends React.Component {


  constructor(props) {
    super(props);
    this.state = {

    };

  }
  
  render(){
      return(
          <Router>
              <Tab 
                style={{marginTop:'-1vh', borderRadius:'0 0 1vh 1vh', borderTop:'1px solid var(--border)'}}
                style_item_tab={{borderRight:'none'}}
                tabs = {[
                  {"pathname":"/admin/cms/site/accueil", name:"Page d'accueil", routes:["/admin/cms", "/admin/cms/site","/admin/cms/site/accueil"]},
                  {"pathname":"/admin/cms/site/articles", name:"Articles"},
                  {"pathname":"/admin/cms/site/cabinets", name:"Cabinets"},
                  {"pathname":"/admin/cms/site/administratif", name:"Administratif"}
                ]}
              />
              <Switch>
                <Route 
                  path={["/admin/cms", "/admin/cms/site","/admin/cms/site/accueil"]}
                  exact
                  render={(props) => <HomePage {...props} />}
                />

                <Route 
                  path="/admin/cms/site/cabinets" 
                  render={(props) => <Cab {...props} token={this.props.token} />}
                />
                <Route 
                  path="/admin/cms/site/articles" 
                  render={(props) => <Article {...props} token={this.props.token} />} 
                />

                <Route 
                  path="/admin/cms/site/administratif" 
                  render={(props) => <Administratif {...props} />} 
                />



              </Switch>
          </Router>
      )
    
  }
}

export default Site;
