import React from 'react';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './texteditor.css';
import Modal from '@utils/modal/Modal'
import Button from '@utils/button/Button'
import styles_field from '../field.module.css';
import customFetch from '@utils/function/customFetch';

var SocketIOFileUpload = require('socketio-file-upload');

var update = require('immutability-helper');

const BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create(value);
    const div = document.createElement('DIV');
    div.textContent = value.alt;
    const img = document.createElement('IMG');
    img.src = value.url;
    node.appendChild(img);
    node.appendChild(div);
    node.setAttribute('alt', value.alt);
    node.setAttribute('src', value.url);
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'div';
ImageBlot.className = 'inline-img';
Quill.register(ImageBlot);


let Inline = Quill.import('blots/inline');
class BoldBlot extends Inline { }
BoldBlot.blotName = 'highlight';
BoldBlot.tagName = 'highlight';
Quill.register('formats/highlight', BoldBlot);

var icons = Quill.import('ui/icons');
icons['highlight'] = '<svg class="" viewBox="0 0 18 18">'
icons['highlight'] += '<line class="ql-stroke" x1="2" x2="2" y1="3" y2="15" stroke-width="3"></line>'
icons['highlight'] += '<line class="ql-stroke" x1="6" x2="10" y1="15" y2="3"></line>'
icons['highlight'] += '<line class="ql-stroke" x1="10" x2="14" y1="3" y2="15"></line>'
icons['highlight'] += '<line class="ql-stroke" x1="8" x2="12" y1="12" y2="12"></line>'
icons['highlight'] += ' </svg>';

class TextEditor extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      selected:null
    };

    this.imageHandler = this.imageHandler.bind(this)
    this.validateImage = this.validateImage.bind(this)
    this.onSuccess = this.onSuccess.bind(this)

    this.formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image',
      'highlight'
    ]

    this.modules = props.field.disabled || props.field.extraData && props.field.extraData.min ? {
      toolbar:false,
      clipboard: {
        matchVisual: false,
      }
    } : {
      toolbar: {
        container:
          [
          [{size: []}],
          ['bold', 'italic', 'underline'],
          ['blockquote', 'highlight'],
          [{'list': 'ordered'}, {'list': 'bullet'}, 
           {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ],
        handlers: {
           'image': this.imageHandler
        }
      },
      clipboard: {
        matchVisual: false,
      }
    }

    this.field = [
      {id:0, placeholder:"Titre de l'article", required:true, value:null, unique:true, image:'logo', version:"version_logo"},
      {id:0, placeholder:"Photo d'illustration de l'article", type:"photo", required:true, value:[], unique:true, image:'article', version:"version"},
    ]

  }

  imageHandler(data, callback){
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*')
    input.click();


    input.onchange = (evt) => {
      customFetch({ endpoint: 'admin/image', files:input.files, onSuccess: this.onSuccess})
      
      var range = this.quillRef.getEditor().getSelection();
      
      this.setState({
        range:range.index,
      })

      this.props.onChange({
        image:input.files[0]
      })

    };
  }

  onSuccess({ response }){
    this.setState({
      file:response
    })
  }

  changeCaption(value){
    this.setState({
      caption:value
    })
  }

  validateImage(){
    this.quillRef.getEditor().insertEmbed(this.state.range, 'image', {url:this.state.file, alt:this.state.caption}, "user");

    this.setState({
      file:null,
      caption:null,
      range:null
    })
  }

  renderFooter(){
    return(
      <Button 
        type="full"
        label="Valider"
        onClick={this.validateImage}
      />
    )
  }

  render(){
      return(
        <div className={`
            ${styles_field.container_field} 
            ${this.props.field.extraData?.singleLine && 'singleLine'}  
            ${this.props.display && 'display'}
            ${this.props.showMissing && !this.props.complete && 'item_missing'}
          `} 
          style={this.props.field.style} key={this.props.key}
        >
          {this.props.renderLabelField && this.props.renderLabelField()}
          <ReactQuill 
            defaultValue={this.props.field.value || ''}
            onChange={(value) => {
              this.props.onChange && this.props.onChange({
                value:value, 
                key:this.props.field.key, 
                complete:value.length>0,
                addlist:this.props.field.addlist
              })} 
            }
            formats={this.formats}
            modules={this.modules}
            ref={(el) => this.quillRef = el}
            readOnly={this.props.field.disabled || this.props.disabled}
            placeholder={this.props.field.placeholder || 'Ecrire ici'}
            style={this.props.field.quillStyle}
          />
          {this.state.range || this.state.range === 0 ? (
            <Modal
              header='Ajouter une légende'
              modalShrink
              footer={{style:{justifyContent:'flex-end'}, content:this.renderFooter()}}
            >
              <div id="pop_up_texteditor" className="content_pop_up">
                {this.state.file ? (
                  <img className="preview_image_article" src={this.state.file}/>
                ):(
                  <img className="loading_image_article" src={require('@icon/loading.gif').default}/>
                )}
                <input 
                  onChange={(evt) => this.changeCaption(evt.target.value)}
                  placeholder="Ajouter une légende à l'image sélectionnée"
                />
              </div>
            </Modal>
          ):(null)}
        </div>
      )
    
  }
}

export default TextEditor;
