import React from 'react';
import {Link} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

import styles from './minarticle.module.css';

var update = require('immutability-helper');
var date = require('@utils/function/date');

const MinArticle = ({
  article,
  style
}) => {
  return(
    <Link 
      to={{pathname:"/article/" + article.pathname, state:{id:article ? article.id : null}}} 
      key={article.id.toString()} 
      className={`${styles.min_article} card`}
      style={style}
    >
      <div className={styles.illustration_min_article}>
        <img alt={article.alt} src={article.image} className="image_cover"/>
      </div>
      <div className={styles.details_min_article}>
        <div className={styles.title_min_article}>
          <span>{article.title}</span>
        </div>
        <div className={styles.container_abstract_min_article}>
          <div className={styles.abstract_min_article}>
            <span>{ReactHtmlParser(article.abstract)}</span>
          </div>
        </div>
        <div className={styles.date_min_article}>
          {article.author ? (
            <span className="name_author">{article.author.name}</span>
          ):(null)}
          <span>Mis à jour le {date(article.last_modif)}</span>
        </div>
      </div>
    </Link>
  )
}

export default MinArticle;
