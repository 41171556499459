import React, { useState, useEffect, Stylesheet } from 'react';
import Switch from '@utils/form/field/switch/Switch';
import customFetch from '@utils/function/customFetch';

import styles from '../offer.module.css';

const OfferCab = ({
  clickOffer,
  offer,
  onSuccess,
  previewCV
}) => {

  function toggleFavori(e){
    e.stopPropagation()
    customFetch({ endpoint: 'recruteur/modify_offer', data:{id_cab:offer.id_cab, id:offer.id, favori:offer.favori ? 0 : 1}, onSuccess, type:'POST'})
  }
  
  function renderCandidates(){
    if(!offer.candidates || offer.candidates.length === 0) return
    return(
      <div className={styles.container_candidates}>
        <div className={styles.images_candidates}>
          <div className={styles.image_candidate}>
            <img src={offer.candidates[0].image || require('@icon/placeholder_user.svg').default} className="image_cover"/>
          </div>
          {offer.candidates.length>1 && (
            <div className={styles.image_candidate}>
              <img src={offer.candidates[1].image || require('@icon/placeholder_user.svg').default} className="image_cover"/>
            </div>
          )}
          {offer.candidates.length>2 && (
            <div className={`${styles.image_candidate} ${styles.more_candidate}`}>
              +{offer.candidates.length-2}
            </div>
          )}
        </div>
        <div className={`${styles.label_candidate}`}>{offer.candidates.length} candidat{offer.candidates.length>1 && 's' }</div>
      </div>
    )
  }


  return(
    <div className={`card ${styles.container_offer} ${previewCV ? styles.previewCV : ' hover_card'}`} onClick={() => clickOffer && clickOffer(offer)}>
      <div className={styles.container_switch} onClick={e => e.stopPropagation()}>
        <Switch 
          field={{value:offer.published ? true : false}}
          onChange={({value}) => customFetch({ endpoint: 'recruteur/modify_offer', data:{id:offer.id, published:value, id_cab:offer.id_cab}, onSuccess, type:'POST'})}
          style={{marginBottom:0}}
          options={['On', 'Off']}
        />
      </div>
      <div className={styles.container_description}>
        <div className={styles.container_title}>{offer.title}</div>
        <div className={styles.container_details}>{offer.contract?.label} | {offer.place?.label} | {offer.date_creation}</div>
      </div>
      <div className={styles.container_candidates}>
        {renderCandidates()}
      </div>
      <div className={styles.container_views}>
        {(offer.views || offer.views === 0) && 
          (
            <div className={styles.views}>{offer.views} vue{offer.views>1 && 's' }</div>
          )
        }
      </div>
      <div className={`${styles.container_favori}`} onClick={(e) => toggleFavori(e)}>
        <img src={!offer.favori ? require('@icon/star.svg').default : require('@icon/star_full.svg').default}/>
      </div>
      {!offer.ats && offer.new_candidates ? <div className={styles.new_candidate}></div> : null}
    </div>
  )

}

export default OfferCab