import React, { useState, useEffect } from 'react';

import ListOfferJunior from '@user/listofferjunior/ListOfferJunior';

import styles from './matching.module.css';

const Matching = ({
  user
}) => {

  return(
    <ListOfferJunior
      admin={true}
      extraData={{id_user:user.id}}
      user={user}
      senior={user.senior}
      endpoint={user.senior ? 'admin/get_matchs' : 'admin/get_offres'}
      endpointDetails={user.senior ? 'admin/get_user_match' : 'admin/offre'}
    />
  )

}

export default Matching;
