import React from 'react';

import './socialnetwork.css';

var update = require('immutability-helper');

const IMAGES = [
  {type:0, img:require('@icon/social_network/linkedin_logo.svg').default, order:0},
  {type:1, img:require('@icon/social_network/twitter_logo.svg').default, order:1},
  {type:2, img:require('@icon/social_network/glassdoor_logo.svg').default, order:3},
  {type:5, img:require('@icon/social_network/instagram_logo.svg').default, order:2},
  //{type:3, img:require('@icon/social_network/greatplacetowork.svg').default, order:4}
]

class SocialNetwork extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      grade:null
    };

    

  }

  componentDidMount(){
    if(this.props.item.type === 2){
      fetch(global.endpoint + '/glassdoor', {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({
          url:this.props.item.link
        })
      })
      .then((response) => response.json())
      .then((responseData) => {
        this.setState({
          grade:responseData
        })
      })
      .catch((error) => {

           
       });
    }

  /*  const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = `
    window.mentreprisesAsyncInit = function() {
    Mentreprises.init({
      scores: { containerClassName: 'mentreprises-scores-container' }
    });
  };
  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id; js.charset = 'utf-8';
     js.src = 'https://choosemycompany.com/ext/api.js';
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'mentreprises-js'));;
   `
    document.body.appendChild(s);*/

  }

  shouldComponentUpdate(nextProps, nextState){
    return true
  }

  renderStar(index){
    let progress = Math.min(1, Math.max(this.state.grade - index, 0))
    return(
      <div className="container_star">
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1000 1000">
        <defs>
                      <linearGradient id={"grad_" + index}>
                          <stop offset={progress} stopColor="#26AA2B"/>
                          <stop offset={progress} stopColor="grey" stopOpacity="1" />
                      </linearGradient>
                  </defs>
        <polygon className="st0" points="500,24.4 653.3,335 996.1,384.8 748.1,626.6 806.6,968 500,806.8 193.5,968 252,626.6 4,384.8 
346.7,335 " fill={"url(#grad_" + index + ")"}/>
        </svg>
      </div>
    )

  }

  render(){
    if(this.props.item.type === 6 && this.props.item.link){
      return(
        <div className={"social_network sn_" + this.props.item.type} style={{order:10}}>
          <a href={this.props.item.link} target="_blank">
            <div class="mentreprises-scores-container" data-company="Carrefour" data-type="employees"></div>
          </a>
        </div>
      )
    }else if(this.props.item.link){
      let index = IMAGES.findIndex(x => x.type === this.props.item.type)
      return(
        <div className={"social_network sn_" + this.props.item.type} style={{order:IMAGES[index].order}}>
          <a href={this.props.item.link} target="_blank">
            <img src={IMAGES[index].img}/>
            {this.state.grade ? (
              <div className="container_grade">
                <div className="grade">
                  <span>{this.state.grade}</span>
                </div>
                <div className="container_stars">
                  {this.renderStar(0)}
                  {this.renderStar(1)}
                  {this.renderStar(2)}
                  {this.renderStar(3)}
                  {this.renderStar(4)}
                </div>
              </div>
            ):(null)}
          </a>
        </div>
      )
    }else{
      return null
    }
  }
}

export default SocialNetwork;
