import clsx from "clsx";
import { useState, useRef, useEffect, useCallback } from "react";
import styles from "./styles.module.css";

function useIsMounted() {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
}

const ListSchools = ({ schools, color, label, value }) => {
  const [showSchools, setShowSchools] = useState(false);
  const [height, setHeight] = useState();
  const ref = useRef();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (ref && ref.current && !height) {
      setHeight(ref.current.clientHeight);
    }
  }, [isMounted]);

  useEffect(() => {
    if (showSchools && height) setHeight();
  }, [showSchools]);

  if (!schools) return;

  return (
    <div
      className={styles.list_school}
      ref={ref}
      style={height ? { maxHeight: height } : {}}
    >
      <div className={styles.header_school}>
        <div
          className={styles.round_color}
          style={{ backgroundColor: color }}
        ></div>
        <div className={styles.title_list_school}>
          <span className={styles.label_list_school}>{label}</span>
          <span className={styles.figure_list_school}>{value}%</span>
        </div>
      </div>
      <div className={styles.logo_schools}>
        {schools.map((item, index) => {
          return (
            <div
              className={clsx(styles.item_school, {
                [styles.hidden]: !showSchools && index >= 7,
              })}
              key={item.id.toString()}
            >
              <div className={styles.logo_school}>
                <img
                  src={item.image}
                  alt={item.name}
                  className="image_contain"
                />
              </div>
            </div>
          );
        })}
        {schools.length > 7 && !showSchools && (
          <div
            className={clsx(styles.item_school, styles.view_more)}
            onClick={() => setShowSchools(schools)}
          >
            +{schools.length - 7}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListSchools;
