import React, {
  useState,
  useRef,
  useEffect,
  useReducer,
  useImperativeHandle,
} from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import List from "@utils/list/List";
import useListReducer from "@utils/function/useListReducer";
import TextEditor from "../texteditor/TextEditor";
import Delete from "@utils/delete/Delete";
import Modal from "@utils/modal/Modal";
import Button from "@utils/button/Button";
import Searchbar from "@utils/searchbar/Searchbar";
import useOnClickOutside from "@utils/function/useOnClickOutside";

import { ReactComponent as IconNext } from "@utils/icon/next.svg";
import { ReactComponent as IconPrev } from "@utils/icon/prev.svg";

import styles_field from "../field.module.css";
import styles_select from "./select.module.css";

const Select = React.forwardRef((props, refParent) => {
  const [state, updateData] = useListReducer();
  const [selectState, setSelectState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      search: "",
      selected: props.field.value || (props.field.multiple && []),
      showSearch: false,
    }
  );
  useEffect(() => {
    if (props.listener) {
      select(props.field.value);
    }
  }, [props.field.value]);

  const ref = useRef();
  useOnClickOutside(
    ref,
    () => !isMobile && setSelectState({ showSearch: false })
  );
  useImperativeHandle(refParent, () => ({ reset }));

  function select(item, event) {
    if (props.field.disabled) return;

    if (event) {
      event.stopPropagation();
    }

    if (props.field.multiple) {
      let data = [...selectState.selected];

      let index = data.findIndex((x) => x.id === item.id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(item);
      }

      setSelectState({
        selected: data,
      });

      props.onChange({
        value: data,
        key: props.field.key,
        section: props.section,
        addlist: props.field.addlist,
      });
    } else {
      setSelectState({
        showSearch: props.multiple ? selectState.showSearch : false,
        selected: props.multiple ? null : item,
        search: "",
      });

      props.onChange({
        value: item,
        key: props.field.key,
        section: props.section,
        addlist: props.field.addlist,
        complete: item ? true : false,
      });
    }
  }

  function reset() {
    setSelectState({
      search: "",
      selected: null || (props.field.multiple && []),
      showSearch: false,
    });
  }

  function changeOrder(index, direction) {
    if (
      (index === 0 && direction < 0) ||
      (index === selectState.selected.length - 1 && direction > 0)
    ) {
      return;
    }

    let data = [...selectState.selected];
    data.splice(index + direction, 0, data.splice(index, 1)[0]);
    setSelectState({
      selected: data,
    });

    props.onChange({
      value: data,
      key: props.field.key,
      section: props.section,
    });
  }

  function onChangeDescription({ value, key }) {
    let data = [...selectState.selected];
    let index = data.findIndex((x) => x.id === key);
    if (index !== -1) {
      data[index].text = value;
      setSelectState({
        selected: data,
      });

      props.onChange({
        value: data,
        key: props.field.key,
        section: props.section,
      });
    }
  }

  function deleteItem(index) {
    let data = [...selectState.selected];
    data.splice(index, 1);
    setSelectState({
      selected: data,
    });

    props.onChange({
      value: data,
      key: props.field.key,
      section: props.section,
      addlist: props.field.addlist,
    });
  }

  function renderLoading() {
    return (
      <div className="item_list_select skeleton">
        <img src={require("@f_icon/select/loading.svg").default} />
      </div>
    );
  }

  function ListHeaderComponent() {
    if (props.field.extraData && props.field.extraData.create) {
      return (
        <div
          className={`${styles_select.item_list_select} ${styles_select.item_create}`}
          onClick={(evt) =>
            select(
              {
                id: null,
                label: props.field.extraData.create,
                image: require("@icon/add_color.svg").default,
              },
              evt
            )
          }
        >
          <div className={styles_select.container_item_list_select_img}>
            <img
              className={styles_select.item_list_select_img}
              src={require("@icon/add_color.svg").default}
            />
          </div>
          <div className="name_item_select">{props.field.extraData.create}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  function renderSearch() {
    if (selectState.showSearch) {
      if (isMobile) {
        return (
          <Modal
            closeModal={() => setSelectState({ showSearch: false })}
            header={props.field.placeholder}
          >
            <Searchbar
              onSearch={(value) => setSelectState({ search: value })}
              search={state.search}
            />
            <List
              endpoint={props.field.socket ? props.field.socket : "list_select"}
              data={state.data}
              renderItem={renderItem}
              //autoHeight={true}
              ListHeaderComponent={ListHeaderComponent}
              data_received={(_data, refresh) =>
                updateData(
                  refresh
                    ? { type: "refresh", data: _data }
                    : { type: "next_batch", data: _data }
                )
              }
              isLoading={state.isLoading}
              //renderEmpty={renderEmpty}
              //onError={onError}
              extraData={props.field.extraData}
              socketKeys={props.field.id || props.socketKeys}
              search={selectState.search}
            />
            {props.filter && (
              <div className={styles_select.bannerButtons}>
                <Button
                  label="Appliquer"
                  type="full"
                  onClick={() => setSelectState({ showSearch: false })}
                />
              </div>
            )}
          </Modal>
        );
      } else {
        return (
          <div className={styles_select.container_search_select}>
            <List
              endpoint={props.field.socket ? props.field.socket : "list_select"}
              data={state.data}
              renderItem={renderItem}
              autoHeight={true}
              ListHeaderComponent={ListHeaderComponent}
              data_received={(_data, refresh) =>
                updateData(
                  refresh
                    ? { type: "refresh", data: _data }
                    : { type: "next_batch", data: _data }
                )
              }
              isLoading={state.isLoading}
              //renderEmpty={renderEmpty}
              //onError={onError}
              extraData={props.field.extraData}
              socketKeys={props.field.id || props.socketKeys}
              search={selectState.search}
              inner_list_style={
                props.filter
                  ? {
                      marginTop: "3vh",
                      border: "1px solid var(--color)",
                      boxSizing: "border-box",
                    }
                  : { marginTop: "3vh" }
              }
            />
          </div>
        );
      }
    }
  }

  function renderItem(item, index, selected) {
    let classe = `${styles_select.item_list_select}`;
    classe +=
      props.field.multiple &&
      selectState.selected.findIndex((x) => x.id === item.id) !== -1
        ? ` ${styles_select.item_selected}`
        : "";
    classe += !selected ? " hover_color_font" : "";
    return (
      <div
        className={classe}
        onClick={!selected ? (evt) => select(item, evt) : undefined}
        key={item.id && item.id.toString()}
      >
        {item.image ? (
          <div className={styles_select.container_item_list_select_img}>
            <img
              className={styles_select.item_list_select_img}
              src={item.image}
            />
          </div>
        ) : null}
        <div className={styles_select.name_item_select}>
          {item.label || item.value || item.name}
        </div>
        {item.status && (
          <div className={styles_select.status_item_select}>
            {`(${item.status})`}
          </div>
        )}
        {props.field.multiple && (
          <div className={`${styles_select.multiple_item_select}`}></div>
        )}
      </div>
    );
  }

  function renderContent() {
    if (!props.field.multiple && selectState.selected) {
      let classe = "container_field_select";
      // classe += selectState.selected.disabled ? " disabled" : ""
      return (
        <div
          className={`${styles_field.container_field_input} ${styles_select.container_selected}`}
          onClick={() => setSelectState({ showSearch: true })}
        >
          {renderItem(selectState.selected, null, true)}
          <Delete onDelete={() => select(null)} />
        </div>
      );
    } else {
      return (
        <div
          className={`${styles_field.container_field_input} ${styles_select.container_input_select}`}
          onClick={() => setSelectState({ showSearch: true })}
        >
          <input
            placeholder={
              props.field.placeholder ? props.field.placeholder : "Sélectionner"
            }
            onChange={(evt) => setSelectState({ search: evt.target.value })}
            size="1"
          />
          {!props.filter || selectState.selected?.length === 0 ? (
            <img
              onClick={() =>
                setSelectState({ showSearch: !selectState.showSearch })
              }
              className={styles_select.down_arrow}
              src={require("@utils/icon/down_arrow.svg").default}
            />
          ) : (
            <div className={styles_select.counter}>
              {selectState.selected?.length}
            </div>
          )}
        </div>
      );
    }
  }

  let classe = `${styles_field.container_input}`;
  classe += selectState.showSearch ? ` ${styles_select.show_search}` : "";
  classe += selectState.selected ? ` ${styles_select.selected}` : "";
  classe += props.field.disabled ? ` ${styles_select.disabled}` : "";
  classe +=
    props.showMissing && !props.complete
      ? ` ${styles_select.item_missing}`
      : ``;

  return (
    <div
      className={`${styles_field.container_field} ${
        props.filter && styles_select.filter
      }`}
      style={props.field.style}
      ref={refParent}
    >
      {props.renderLabelField()}
      <div className={classe} ref={ref}>
        {renderContent()}
        {renderSearch()}
      </div>
      {props.field.multiple && !props.filter && (
        <div
          className={styles_select.container_multiple_selected}
          style={props.field.styleSelected || {}}
        >
          {selectState.selected.map((item, index) => {
            return (
              <div
                className={`${styles_select.multiple_selected} ${
                  props.field.description
                    ? styles_select.multiple_selected_description
                    : ""
                }`}
                key={item.id}
              >
                <div className={styles_select.inner_selected}>
                  <div className={styles_select.label_multiple_selected}>
                    <span>{item.label || item.name}</span>
                  </div>
                  <div className={styles_select.container_order}>
                    <IconPrev
                      onClick={() => changeOrder(index, -1)}
                      className="hover_color"
                    />
                    <IconNext
                      onClick={() => changeOrder(index, 1)}
                      className="hover_color"
                    />
                  </div>
                  <Delete onDelete={() => deleteItem(index)} absolute />
                </div>
                {props.field.description ? (
                  <TextEditor
                    field={{
                      key: item.id,
                      value: item.text,
                      required: true,
                      placeholder: "Décrivez la mission sélectionnée",
                    }}
                    onChange={(data) => onChangeDescription(data)}
                    renderLabelField={() => {
                      return null;
                    }}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});

export default Select;
