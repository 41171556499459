import React, { useState, useEffect, Stylesheet } from 'react';

import Form from '@utils/form/Form';
import customFetch from '@utils/function/customFetch';

const HomePage = ({
    
}) => {

  const [loading, updateLoading] = useState(true)
  const [sections, updateSections] = useState([])

  useEffect(() => {
    customFetch({ endpoint: 'admin/home_page', onSuccess, type:'GET'})
  }, [])

  function onSuccess(data){
    updateSections(data.response)
    updateLoading(false)
  }

  if(loading){
    return null
  }else{
    return(
      <Form
        endpoint={"admin/update_home_page"}
        sections={sections}
        type="formadmin"
      />
    )
  }
}

export default HomePage

 
