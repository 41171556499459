import React from 'react';

import Header from '@admin/header/Header';

import CMS from '@admin/cms/CMS';
import Matching from '@admin/matching/Matching';
import Dashboard from '@admin/dashboard/Dashboard';

import { BrowserRouter as Router, Route, Link, Redirect, Switch, NavLink } from "react-router-dom";

import './homeadmin.css'

var update = require('immutability-helper');


class Admin extends React.Component {


  constructor(props) {
    super(props);
    this.state = {

    };

  }
  
  render(){
      return(
        <div id="container_admin" className="container">
          <Header {...this.props}/>
          <div className="container shrinked_container">
            <>
              <Switch>

                <Route 
                  path={['/admin', '/admin/dashboard', '/admin/dashboard/*']}
                  render={(props) => <Dashboard {...props} />}
                  exact
                />


                <Route 
                  path={["/admin/cms/site", "/admin/cms", "/admin/cms/site/administratif", "/admin/cms/site/accueil", "/admin/cms/site/articles", "/admin/cms/site/cabinets"]}
                  render={(props) => <CMS {...props} />}
                  exact
                />

                <Route 
                  path={["/admin/matching", "/admin/matching/*"]}
                  render={(props) => <Matching {...props} />}
                  exact
                />
               
              </Switch>
            </>
          </div>
        </div>
      )
    
  }
}

export default Admin;
