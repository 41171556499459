import React, { useState } from 'react';

import Field from '../Field.js'
import ReactHtmlParser from 'react-html-parser';

import styles_field from '../field.module.css';
import styles from './checkbox.module.css';

const Checkbox = (
  props
) => {

  const [value, updateValue] = useState(props.field.value);

  function selectOption(val){
    props.onChange({
      value:val === value ? null : val, 
      key:props.field.key, 
    })
    updateValue(val === value ? null : val)
  }

  return(
    <div className={`${styles_field.container_field} ${styles.container_field}`} style={props.field.style}>
      {props.renderLabelField()}
      <div className={styles.containerOptions}>
        {props.field.options.map((item) => (
          <div className={`${styles.option} ${value === item.value && styles.selectedOption}`}>
            <div className={`${styles.item_option}`} onClick={() => selectOption(item.value)}>
              <div className={`${styles.checkbox} hover_color`}></div>
              <div className={styles.label}>{ReactHtmlParser(item.label)}</div>
            </div>
            {item.hiddenDetails && value === item.value && (
              <Field 
                field={{type:'texteditor', key:`details_${props.field.key}`, extraData:{min:true}, placeholder:item.hiddenDetails.placeholder}}
                onChange={props.onChange}
              />
            )}
          </div>
        ))}
      </div>  
    </div>
  )
}

export default Checkbox;