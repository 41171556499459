import React, { useEffect, useState, useRef } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  withRouter,
} from "react-router-dom";

import Header from "@utils/header/Header";

import Lexique from "@site/lexique/Lexique";
import Footer from "@site/footer/Footer";

import ModalConnect from "@site/connect/ModalConnect";
import ModalForget from "@site/forget/ModalForget";
import ModalReset from "@site/reset/ModalReset";

import Home from "@site/home/Home";
import Article from "@site/articles/article/Article";
import DiscoverArticles from "@site/articles/discoverArticles/DiscoverArticles";
import Cab from "@site/cabs/cab/Cab";
import DiscoverCabs from "@site/cabs/discoverCabs/DiscoverCabs";
import Administratif from "@site/administratif/Administratif";
import Offers from "@site/offers/Offers";
import Simulator from "@site/simulator/Simulator";

import Scrollbars from "@utils/scrollbars/Scrollbars";

import Forgot from "@site/forgot/Forgot";

import BuddyScan from "@site/buddyScan/BuddyScan";

import customFetch from "@utils/function/customFetch";

const MainSite = ({ onConnect, logOut, location }) => {
  const history = useHistory();
  const searchParams = [];
  const refScrollbar = useRef();

  const [user, updateUser] = useState();
  const [popUp, updatePopUp] = useState();

  useEffect(() => {
    if (location.search) {
      const params = location.search.replace("?", "").split("&");
      for (let i = 0; i < params.length; i++) {
        const data = params[i].split("=");
        searchParams.push({ key: data[0], value: data[1] });
      }
    }
    if (searchParams.length > 0 && searchParams[0].key === "forgot") {
      customFetch({
        endpoint: "user/check_forgot",
        onSuccess: onResetPassword,
        type: "POST",
        data: { key: searchParams[0].value },
      });
    }
    fetchUser();

    let token = localStorage.getItem("token");
    if (!token) {
      token = Date.now().toString();
      localStorage.setItem("token", token);
    }

    customFetch({
      endpoint: "activity/activity",
      type: "POST",
      data: { token, id_activity: 0 },
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user"))
      customFetch({
        endpoint: "user/reconnect",
        onSuccess: onSuccessReconnect,
        onError,
        type: "POST",
        data: { token: localStorage.getItem("user") },
      });
  }, []);

  const onSuccessReconnect = ({ response }) => {
    localStorage.removeItem("user");
    fetchUser();
  };

  function fetchUser() {
    customFetch({
      endpoint: "user/login_user",
      onSuccess,
      onError,
      type: "GET",
    });
  }

  function onSuccess({ response, endpoint }) {
    updateUser(response);
    updatePopUp(null);
    if (endpoint === "login_user") {
      window.location.href = "/candidat";
    }
  }

  function onResetPassword({ response }) {
    if (response) updatePopUp({ type: "reset", key: response });
  }

  function onError(error) {}

  function logOut() {
    customFetch({
      endpoint: "user/logout",
      onSuccess: onSuccesLogOut,
      type: "GET",
    });
  }

  function onSuccesLogOut() {
    window.location.href = "/";
  }

  function onSuccessSubscribe({ response }) {
    if (response.successBuddyScan) {
      updateUser(response.user || user);
      updatePopUp(null);
      history.push("/candidat");
    }
  }

  function openBuddyScan() {
    if (user && user.type === "user") {
      window.location.href = "/candidat";
    } else {
      updatePopUp("screening");
    }
  }

  function scrollToTop() {
    if (refScrollbar.current) refScrollbar.current.scrollToTop();
  }

  return (
    <div className="container container_main_site">
      <Header
        showSubscribe={() => updatePopUp("screening")}
        showConnect={() => updatePopUp("connect")}
        logOut={logOut}
        user={user}
      />
      <Scrollbars
        autoHide
        renderView={(props) => (
          <div {...props} className="view" style={{ flexShrink: 0 }} />
        )}
        id="main_scrollbar"
        ref={refScrollbar}
      >
        <Switch>
          <Route
            path="/"
            exact
            render={() => (
              <Home
                openBuddyScan={openBuddyScan}
                user={user}
                fetchUser={fetchUser}
                showConnect={() => updatePopUp("connect")}
              />
            )}
          />
          <Route
            path={["/articles", "/articles/*"]}
            exact
            render={() => <DiscoverArticles />}
          />
          <Route
            path="/article/:id"
            exact
            render={(props) => <Article {...props} shrinked={true} />}
          />
          <Route
            path={["/cabinets", "/cabinets/*"]}
            exact
            render={(props) => <DiscoverCabs {...props} />}
          />
          <Route
            path="/cabinet/:id"
            exact
            render={(props) => (
              <Cab {...props} user={user} openBuddyScan={openBuddyScan} />
            )}
          />
          <Route
            path="/offres"
            exact
            render={(props) => (
              <Offers
                {...props}
                openBuddyScan={openBuddyScan}
                user={user}
                fetchUser={fetchUser}
                showConnect={() => updatePopUp("connect")}
                scrollToTop={scrollToTop}
              />
            )}
          />
          <Route
            path="/cgu"
            exact
            render={() => (
              <Administratif
                title="Conditions générales d'utilisation"
                data={{ type: "cgu" }}
                key="0"
              />
            )}
          />
          <Route
            path="/politique_confidentialite"
            exact
            render={() => (
              <Administratif
                title="Politique de confidentialité"
                data={{ type: "confidentialite" }}
                key="1"
              />
            )}
          />
          <Route
            path="/qui_sommes_nous"
            exact
            render={() => (
              <Administratif
                title="Qui sommes-nous ?"
                data={{ type: "presentation" }}
                key="2"
              />
            )}
          />

          <Route path="/lexique" exact render={() => <Lexique />} />

          <Route path="/forgot" exact render={() => <Forgot />} />

          <Route
            path="/simulateur-salaire"
            exact
            render={() => <Simulator openBuddyScan={openBuddyScan} />}
          />
        </Switch>

        {popUp === "screening" && (
          <BuddyScan
            onClose={() => updatePopUp()}
            onSuccessSubscribe={onSuccessSubscribe}
          />
        )}

        {popUp === "connect" && (
          <ModalConnect
            onClose={() => updatePopUp()}
            onSuccess={onSuccess}
            endpoint="login_user"
            showForgetPassword={() => updatePopUp("forget")}
          />
        )}

        {popUp === "forget" && <ModalForget onClose={() => updatePopUp()} />}

        {popUp?.type === "reset" && (
          <ModalReset onClose={() => updatePopUp()} resetKey={popUp.key} />
        )}

        <Footer />
      </Scrollbars>
    </div>
  );
};

export default withRouter(MainSite);
