import React, { useState, useEffect, Stylesheet } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Button from '@utils/button/Button';
import Modal from '@utils/modal/Modal';
import Checkbox from '@utils/form/field/checkbox/Checkbox';

import customFetch from '@utils/function/customFetch';
import styles from './offersenior.module.css';

const OfferSenior = ({
  offer,
  senior,
  toggleModalOffre,
  over,
  onSuccessApply,
  onDeclineOffer,
  initialMatch,
  admin
}) => {

  const [showPopUp, onShowPopUp] = useState()
  const [reasonDecline, updateReasonDecline] = useState()

  useEffect(() => {
    if(offer.id === initialMatch?.id_match){
      if(initialMatch.answer === 2){
        onShowPopUp('accept')
      }else{
        onShowPopUp('decline')
      }
    }
  }, [])

  function onChangeDecline({key, value}){
    updateReasonDecline(value)
  }

  const fields = [
    {label:'J\'ai déjà commencé un processus de recrutement avec ce cabinet', value:1},
    {label:'Je ne suis pas intéressé par cette proposition', value:0},
  ]

  if(admin) fields.push({label:'Candidature périmée', value:3})

  return(
    <div 
      className={`${styles.offer} card`} 
      key={offer.id}
    >
      <div className={styles.content_offer}>
        <div className={styles.header_offer}>
          <div className={styles.container_icon}>
            <img className='image_contain' src={offer.cab.logo}/>
          </div>
          <div className={styles.container_name}>
            <div className={styles.name}>{offer.cab.name}</div>
            <div className={styles.sub_title}>Offre d'entretien</div>
          </div>
          <div className={styles.date}>{offer.date}</div>
        </div>
    
        <div className={styles.presentation}>
          {offer.cab.introduction}
        </div>

        <div className="container_info_min_cab">
          <img src={require('@icon/min_cab/tags.svg').default}/>
            <div>
              {offer.cab.tags ? (
                offer.cab.tags.map((item, index) => {
                return(index === 0 ? item.label : ', ' + item.label)
              })
              ):(null)}
            </div>
        </div>

        <div className="container_info_min_cab">
          <img src={require('@icon/min_cab/staff.svg').default}/>
            <div>{offer.cab.size} employés</div>
        </div>

        <div className="container_info_min_cab">
          <img src={require('@icon/min_cab/address.svg').default}/>
            <div>{offer.cab.address}</div>
        </div>

      </div>
      
      <div className={styles.banner_button}>
        <Button 
          label='Voir la fiche cabinet'
          style={{marginRight:'1vw'}}
          onClick={() => window.open(offer.cab.link, '_blank').focus()}
        />
        <Button 
          label='Refuser'
          type='delete'
          style={{marginRight:'1vw'}}
          onClick={() => onShowPopUp('decline')}
        />
        <Button 
          label='Accepter'
          type='validate'
          onClick={() => onShowPopUp('accept')}
        />
      </div>

      {showPopUp === 'accept' && (
        <Modal
          header={`Accepter une proposition d'entretien : ${offer.cab.name}`}
          closeModal={() => onShowPopUp()}
          modalShrink={true}
          style={isMobile ? {maxHeight:'100%'} : {}}
        >
          <div className={styles.content}>
            <div className={styles.containerIllustration}>
              <img src={require('@icon/illustration_accept.svg').default} className='image_contain' />
            </div>
            <div>Nous allons transmettre vos coordonées à {offer.cab.name} et une personne de l’équipe RH va vous contacter (par mail ou téléphone) dans les prochains jours afin de planifier un premier échange.</div>
            <div>N’hésitez pas à lui indiquer vos contraintes d’agenda pour les entretiens : les cabinets sauront être flexibles.</div>
          </div>
          <div className={styles.banner_button}>
            <Button 
              label='Annuler'
              type='cancel'
              style={{marginRight:'1vw'}}
              onClick={() => onShowPopUp()}
            />
            <Button 
              label='Accepter'
              type='full'
              onClick={() => {
                onShowPopUp()
                customFetch({ endpoint: 'user/accept_match', onSuccess:onSuccessApply, data:{id:offer.id}, type:'POST'})
              }}
            />
          </div>

        </Modal>
      )}

      {showPopUp === 'decline' && (
        <Modal
          header={`Refuser une proposition d'entretien : ${offer.cab.name}`}
          closeModal={() => onShowPopUp()}
          modalShrink={true}
          style={isMobile ? {maxHeight:'100%'} : {}}
        >
          <div className={styles.content}>
            <div className={styles.title_popup}>En refusant cette proposition d’entretien, la proposition sera supprimée de votre espace et ne pourra plus être acceptée.</div>
            <Checkbox 
              field={
                {type:"checkbox", key:"answer", options:fields}
              }
              onChange={onChangeDecline}
              renderLabelField={() => (<div className={styles.subtitle_popup}>Sélectionnez ci-dessous la cause du refus de la proposition d'entretien</div>)}
            />
          </div>
          <div className={styles.banner_button}>
            <Button 
              label='Annuler'
              type='cancel'
              style={{marginRight:'1vw'}}
              onClick={() => onShowPopUp()}
            />
            <Button 
              label='Refuser'
              type='delete'
              onClick={() => customFetch({ endpoint: 'user/decline_match', onSuccess:onDeclineOffer, data:{id:offer.id, answer:reasonDecline}, type:'POST'})}
            />
          </div>
        </Modal>
      )}

    </div>
  )
}

export default OfferSenior