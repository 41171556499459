import React, { useState } from 'react';

import styles_delete from './delete.module.css';

function Delete({
  onDelete,
  style,
  absolute
}) {
  return(
    <div className={`${styles_delete.container_delete} ${absolute && styles_delete.container_delete_absolute} hover_background_color`} style={style}>
      <img src={require('@utils/icon/close.svg').default} onClick={onDelete}/>
    </div>
  )
}

export default Delete;