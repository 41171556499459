import React, { useState, useEffect, Stylesheet } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  useParams,
} from "react-router-dom";

import Header from "../header/Header";

import SeniorsRecruitment from "@recruteur/recrutement/seniors/SeniorsRecruitment";
import JuniorsRecruitment from "@recruteur/recrutement/juniors/JuniorsRecruitment";
import EditCab from "@recruteur/recrutement/cab/EditCab";
import Tab from "@utils/tab/Tab";
import Modal from "@utils/modal/Modal";
import Form from "@utils/form/Form";

import { ReactComponent as IconStar } from "@recruteur/icon/icon_tab_star.svg";
import { ReactComponent as IconBell } from "@recruteur/icon/icon_tab_bell.svg";
import { ReactComponent as IconFile } from "@recruteur/icon/icon_tab_file.svg";

import customFetch from "@utils/function/customFetch";

import styles from "./home_recruteur.module.css";

var update = require("immutability-helper");

const HomeRecruteur = ({ logOut, user }) => {
  const [profileUser, setProfileUser] = useState(null);
  const [showSettings, toggleSettings] = useState();

  useEffect(() => {
    customFetch({ endpoint: "recruteur/profile", onSuccess, type: "GET" });
  }, []);

  function onSuccess({ response }) {
    setProfileUser(response);
  }

  function updateUser(data) {
    let newProfileUser = { ...profileUser };
    if (data.response) {
      newProfileUser = {
        ...newProfileUser,
        ...{ settings: data.response.settings },
      };
    } else {
      newProfileUser = { ...newProfileUser, ...data };
    }
    setProfileUser(newProfileUser);
    toggleSettings();
  }

  function updateCab(data) {
    let newProfileUser = { ...profileUser };
    let newCab = { ...newProfileUser.cab, ...data };
    newProfileUser = { ...newProfileUser, ...{ cab: newCab } };
    setProfileUser(newProfileUser);
  }

  function onChangeEmailContact({ response }) {
    let newUser = { ...user };
    newUser.cab = response;
    setProfileUser(newUser);
  }

  function onSuccessPreferences({ response }) {
    let updatedProfile = { ...profileUser };
    updatedProfile.preferences = [...updatedProfile.preferences];
    updatedProfile.preferences.push(response);
    setProfileUser(updatedProfile);
  }

  if (!profileUser) return null;

  return (
    <div className={`${styles.container_recruteur} container`}>
      <Header
        user={profileUser}
        logOut={logOut}
        color={true}
        toggleSettings={toggleSettings}
        recruteur={true}
      />
      <Router>
        <div className="container">
          <Tab
            tabs={[
              {
                pathname: "/recruteur",
                name: "Mes suggestions (matching)",
                icon: <IconStar />,
                routes: ["/recruteur/"],
              },
              {
                pathname: "/recruteur/juniors",
                name: "Mes candidatures",
                icon: <IconBell />,
              },
              {
                pathname: "/recruteur/cabinet",
                name: "Ma fiche cabinet",
                icon: <IconFile />,
              },
            ]}
          />

          <Switch>
            <Route
              path="/recruteur"
              exact
              render={(props) => (
                <SeniorsRecruitment
                  user={profileUser}
                  updateUser={updateUser}
                  onSuccessPreferences={onSuccessPreferences}
                />
              )}
            />
            <Route
              path="/recruteur/juniors"
              exact
              render={(props) => (
                <JuniorsRecruitment
                  cab={profileUser.cab}
                  user={profileUser}
                  onChangeEmailContact={onChangeEmailContact}
                  onSuccessPreferences={onSuccessPreferences}
                />
              )}
            />
            <Route
              path="/recruteur/cabinet"
              exact
              render={(props) => (
                <EditCab
                  cab={profileUser.cab}
                  user={profileUser}
                  updateCab={updateCab}
                  onSuccessPreferences={onSuccessPreferences}
                />
              )}
            />
          </Switch>
        </div>
      </Router>

      {showSettings && (
        <Modal closeModal={() => toggleSettings()} header="Mes préférences">
          <Form
            sections={[
              {
                name: "Avertissements de confirmation",
                fields: [
                  {
                    type: "switch",
                    key: { value: "settings", type: 0 },
                    title:
                      "Demander confirmation pour valider une suggestion candidat",
                    extraData: { row: true },
                    value: profileUser.settings.findIndex((x) => x === 0) < 0,
                  },
                  {
                    type: "switch",
                    key: { value: "settings", type: 1 },
                    title:
                      "Demander confirmation pour rejeter une suggestion candidat",
                    extraData: { row: true },
                    value: !profileUser.settings.find((x) => x === 1),
                  },
                  {
                    type: "switch",
                    key: { value: "settings", type: 2 },
                    title:
                      "Demander confirmation pour rétablir une candidature rejetée",
                    extraData: { row: true },
                    value: !profileUser.settings.find((x) => x === 2),
                  },
                ],
              },
              {
                name: "Notifications par e-mail",
                fields: [
                  {
                    type: "switch",
                    key: { value: "settings", type: 3 },
                    title:
                      "Recevoir un email à chaque nouvelle suggestion candidat",
                    extraData: { row: true },
                    value: !profileUser.settings.find((x) => x === 3),
                  },
                  {
                    type: "switch",
                    key: { value: "settings", type: 4 },
                    title: "Recevoir un email à chaque nouveau match candidat",
                    extraData: { row: true },
                    value: !profileUser.settings.find((x) => x === 4),
                  },
                ],
              },
            ]}
            buttons={{
              action: { label: "Enregistrer", type: "full" },
              sub_action: {
                label: "Annuler",
                type: "cancel",
                onPress: () => toggleSettings(),
              },
            }}
            success={updateUser}
            endpoint={"recruteur/update_settings"}
            noNumber={true}
          />
        </Modal>
      )}
    </div>
  );
};

export default HomeRecruteur;
