import React, { useState, useEffect } from 'react';

import useListReducer from '@utils/function/useListReducer';

import List from '@utils/list/List';

import styles from './matching.module.css';


const Matching = ({
  id_cab
}) => {

  const [state, updateData] = useListReducer()

  function renderItem(item){
    return(
      <div className={`${styles.container_user} card`}>
      
      <div className={styles.infos}>
          <div className={styles.image}>
            <img className='image_cover' src={item.user.image || require('@icon/profile/empty_image_profile.svg').default}/>
          </div>
        <div className={styles.name}>
          <div className={styles.main_info}>{item.user.name}</div>
        </div>
      </div>

      <div className={styles.info}>
        {item.date}
      </div>

      <div className={styles.info}>
        {item.status}
      </div>

    </div>
    )
  }

  return(
    <List
      extraData={{id_cab}}
      data={state.data}
      endpoint={'admin/cab_matchs'}
      renderItem={renderItem}
      data_received={(_data, refresh) => updateData(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
      isLoading={state.isLoading}
    />
  )
}

export default Matching;
