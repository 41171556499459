import React, { useState, useEffect, Stylesheet } from "react";

import Form from "@utils/form/Form";
import Field from "@utils/form/field/Field";
import BannerContext from "@utils/bannerContext/BannerContext";

import customFetch from "@utils/function/customFetch";

import moment from "moment";

const EditCab = ({ cab, updateCab, onSuccessPreferences, user }) => {
  const [loading, updateLoading] = useState(true);
  const [sections, updateSections] = useState(null);

  useEffect(() => {
    customFetch({
      endpoint: "recruteur/get_form_cab",
      data: { id: cab.id },
      onSuccess,
      type: "POST",
    });
  }, []);

  function onSuccess({ response }) {
    updateSections(response);
    updateLoading(false);
  }

  function onPreview(data) {}

  function success(data) {
    updateCab({ lastModif: moment().toISOString() });
  }

  return (
    <div className="container">
      <BannerContext
        image={require("@icon/bannercontext/edit_cab.svg").default}
        subtitle={[
          `Ici vous pouvez modifier l’ensemble des informations présentes sur votre fiche cabinet. Lorsque vous effectuez une modification, cliquez sur Aperçu modif pour prévisualiser les changements. Si vous êtes satisfait, cliquez ensuite sur Sauvegarder pour mettre à jour votre fiche.`,
        ]}
        id_context={38}
        onSuccess={onSuccessPreferences}
        preferences={user.preferences}
        endpoint="recruteur/update_preferences"
        style={{ padding: "2vh 2vw", margin: "1vh", marginTop: "2vh" }}
      />
      {sections && !loading && (
        <Form
          endpoint={"recruteur/update_cab"}
          preview_endpoint={"preview_cab"}
          sections={sections}
          type="formadmin"
          id={cab.id}
          lastModif={cab.lastModif}
          success={success}
          preview={true}
          label_button="Sauvegarder"
        />
      )}
    </div>
  );
};

export default EditCab;
