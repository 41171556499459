import React, { useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import Field from '@utils/form/field/Field';

import styles_field from '../field.module.css';
import styles_number from './number.module.css';

function Number(props) {

  const [value, updateValue] = useState(props.field.value || props.field.value === 0 ? props.field.value : '');
  const [error, updateError] = useState(null)

  function onChange(data){
    props.onChange({
      value:data, 
      key:props.field.key, 
    })
  }

  return(
    <div className={`${styles_field.container_field} ${styles_number.container_field}`} style={props.field.style}>
        <div className={`${styles_field.label_field} ${styles_number.label_field}`}>{props.field.title}</div>
        <Field
          onChange={(data) => onChange(data.value)}
          field={{type:"input", value:value, placeholder:props.field.placeholder, style:{width:'5vw', padding:0}}}
        />
    </div>
  )
}

export default Number;