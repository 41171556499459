import React, { useState, useEffect } from 'react';

import ButtonBuddyScan from '@site/home/buttonbuddyscan/ButtonBuddyScan';

import styles from'./bannerscreening.module.css';


const BannerScreening = ({
  onClick,
  style,
  recruteur,
  user
}) => {

  let steps = []
  let label = ''

  if (recruteur){
    label = 'Trouvez le candidat idéal'
    steps = [
      {
        image:require('@icon/bannerscreening/image_recruteur_1.svg').default,
        label:'Entretenez-vous avec un de nos coachs Consulting Buddies afin de comprendre vos besoins.',
      },
      {
        image:require('@icon/bannerscreening/image_recruteur_2.svg').default,
        label:'Recevez des propositions de candidats expérimentés ou postez des offres depuis votre espace recruteur.',
      },
      {
        image:require('@icon/bannerscreening/image_recruteur_3.svg').default,
        label:'Gérez  vos recrutements et le contenu de votre page cabinet depuis votre espace recruteur ou votre ATS.',
      }
    ]
  }else{
    label = user ? 'Accéder à mon espace' : 'Trouvez le cabinet qui vous correspond'
    steps = [
      {
        image:require('@icon/bannerscreening/image_1.svg').default,
        label:'Je crée mon profil en 5 minutes afin de définir mon score de matching avec chaque cabinet.',
      },
      {
        image:require('@icon/bannerscreening/image_2.svg').default,
        label:'Je reçois des propositions d’entretiens ou je postule à des offres sur mon espace personnel.',
      },
      {
        image:require('@icon/bannerscreening/image_3.svg').default,
        label:'Je benéficie des conseils des coachs et je passe des entretiens.',
      }
    ]
  }

  function renderStep(item, index){
    return(
      <div className={styles.container_step}>
        <div className={styles.container_image}>
          <img src={item.image}/>
        </div>
        <div className={styles.container_description}>
          <div className={styles.container_index}>{index+1}</div>
          <div className={styles.container_label}>{item.label}</div>
        </div>
      </div>
    )
  }

  return(
    <div className={`${styles.container_banner}`} style={style}>
      <div className={styles.steps}>
        {steps.map((item, index) => renderStep(item, index))}
      </div>
      <ButtonBuddyScan
        onClick={onClick}
        label={label}
      />
    </div>
  )

}

export default BannerScreening;
