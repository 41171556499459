import React, { useState, useEffect, Stylesheet } from 'react';

import Modal from '@utils/modal/Modal';
import Button from '@utils/button/Button';

import styles from './modalconfirm.module.css';

const ModalConfirm = ({
  match,
  content,
  updateMatch,
  closeModal,
  isChecked,
  toggleCheck
}) => {

  return(
    <Modal
      closeModal={closeModal}
      header={content.title}
      modalShrink={true}
    >
      <div className={styles.content}>
        <div className={styles.title}>{content.label_title}</div>
        {content.text.map((item) => {
          return(
            <div className={styles.sub_title}>{item}</div>
          )
        })}
      </div>

      <div className={styles.banner_buttons}>
        <div className={styles.confirm}>
          <div className={`${styles.checkbox} ${isChecked && styles.isChecked}`} onClick={toggleCheck}/>
          <div className={styles.label_checkbox}>Ne plus me demander confirmation</div>
        </div>
        <div className={styles.actions}>
          <Button 
            label='Annuler'
            onClick={closeModal}
            type='cancel'
            style={{marginRight:'1vw'}}
          />  
          <Button 
            label={content.label_button}
            onClick={() => updateMatch(match)}
            style={content.style}
          />  
        </div>
      </div>

    </Modal>
  )
}

export default ModalConfirm

 