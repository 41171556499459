var _ = require('lodash')

async function customFetch ({ endpoint, data, onSuccess, onError, type, files} ){
  let options = {
    method: type || 'POST',
    body: dataToSend,
    credentials: 'include',
    redirect: 'follow',
  }
  
  var dataToSend = type === 'GET' ? null : JSON.stringify(data)
  if(!_.isEmpty(files)){
    const formData = new FormData();
    for (var key in data) {
      formData.append(key, _.isObject(data[key]) ? JSON.stringify(data[key]) : data[key]);
    }
    for (var key in files) {
      formData.append(key, files[key]);
    }
    dataToSend = formData
  }else{
    options = {...options, ...{headers: {'Content-Type': 'application/json'}} }
  }

  options = {...options, ...{body: dataToSend} }
  let res
  try{
   res = await fetch(process.env.REACT_APP_ENDPOINT + '/' + process.env.REACT_APP_VERSION + '/' + endpoint, options)

   const json = await res.json();
   if(res.status === 400){
      if(onError){
        onError(json || 'Une erreur inattendue est survenue, merci de réessayer plus tard.')
      } 
    }else{
      onSuccess({response:json, input:data, endpoint:endpoint})
    }
  }catch(e){
    if(onError){
      onError('Une erreur inattendue est survenue, merci de réessayer plus tard.')
    } 
  }

}

export default customFetch;
