import React, { useState } from 'react';

import {ReactComponent as IconRefresh} from '@icon/admin/refresh.svg';
import {ReactComponent as IconScheduled} from '@icon/admin/scheduled.svg';
import {ReactComponent as IconCalled} from '@icon/admin/called.svg';
import {ReactComponent as IconInactive} from '@icon/admin/inactive.svg';
import {ReactComponent as IconBookmarked} from '@icon/admin/bookmarked.svg';

import customFetch from '@utils/function/customFetch';

import styles from './actionsuser.module.css';

const ActionsUser = ({
  user,
  onUpdateUser,
}) => {

  function action(evt, type){
    evt.stopPropagation();
    customFetch({ endpoint: 'admin/update_user', onSuccess:onUpdateUser, data:{[type]:1-user[type], id: user.id},  type:'POST'})
  }
  return(
    <div className={styles.actions}>
      <div className={`${styles.action} ${user.bookmarked && styles.active}`} onClick={(evt) => action(evt, "bookmarked")}>
        <IconBookmarked className='image_contain hover_color'/>
      </div>
      <div className={`${styles.action} ${user.refresh && styles.active}`} onClick={(evt) => action(evt, "refresh")}>
        <IconRefresh className='image_contain hover_color'/>
      </div>
      <div className={`${styles.action} ${user.scheduled && styles.active}`} onClick={(evt) => action(evt, "scheduled")}>
        <IconScheduled className='image_contain hover_color'/>
      </div>
      <div className={`${styles.action} ${user.called && styles.active}`} onClick={(evt) => action(evt, "called")}>
        <IconCalled className='image_contain hover_color'/>
      </div>
      <div className={`${styles.action} ${user.inactive && styles.active}`} onClick={(evt) => action(evt, "inactive")}>
        <IconInactive className='image_contain hover_color'/>
      </div>
    </div>
  )
}

export default ActionsUser;
