import React, { useEffect, useState } from 'react';

import Modal from '@utils/modal/Modal';

import styles_field from '../field.module.css';
import styles from './calendar.module.css';

import moment from 'moment'

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar as Cal } from 'react-modern-calendar-datepicker';
import styles_select from '@utils/form/field/select/select.module.css';

const Calendar = ({
  showCalendar,
  updateShowCalendar,
  onSelect,
}) => {

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });

  useEffect(() => {
    if(selectedDayRange.from && selectedDayRange.to){
      updateShowCalendar(false)
      onSelect({dates:selectedDayRange, label:getLabel()})
    }
  }, [selectedDayRange])

  function getLabel(){
    return moment(selectedDayRange.from.year + ' ' + selectedDayRange.from.month + ' ' + selectedDayRange.from.day).format("DD/MM/YYYY")
    + ' - '
    + moment(selectedDayRange.to.year + ' ' + selectedDayRange.to.month + ' ' + selectedDayRange.to.day).format("DD/MM/YYYY") 
  }

  return(
    <>
      <div className={`${styles_select.item_list_select} hover_color_font`} style={{flex:1}}  onClick={() => updateShowCalendar(true)}>

        <div className={`${styles_select.name_item_select} ${styles.name_item_select}`}>
          {`${selectedDayRange.from && selectedDayRange.to ? 
            getLabel()
            : 
            'Choisir une période personnalisée'
          }`}
        </div>
      </div>

      {showCalendar && (
        <Modal
          closeModal={() => updateShowCalendar(false)}
          modalShrink={true}
          style={{width:'auto'}}
        >
          <Cal 
            value={selectedDayRange}
            onChange={setSelectedDayRange}
            colorPrimary="#8278F9"
            colorPrimaryLight="rgba(130, 120, 249, 0.25)"
          />
        </Modal>
      )}
    </>
  )
}

export default Calendar;