import React, { useEffect, useState, useRef } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


import ItemContent from './itemContent/ItemContent';

import styles from './content.module.css'

const Content = ({
  content,
  type,
  style
}) => {

  if(!content) return null

  function setStyle(index){
    if(isMobile) return {width:'100%', minHeight:'35vh'}
  
    if(type===0){
      if(index === 0){
        return {width:'100%', height:'45vh'}
      }else if(index === 2 || index === 4 || index === 6){
        return {width:'50%', height:'25vh', transform:'translateY(20vh)'}
      }else{
        return {width:'50%', height:'25vh'}
      }
    }else if(type === 3){
      if(index === 0){
        return {width:'100%', height:'45vh'}
      }else if(index === 1 || index === 4 || index === 5){
        return {width:'66%', height:'35vh'}
      }else{
        return {width:'34%', height:'35vh'}
      }
    }else if(type===1){
      return {width:'50%', height:'45vh'}
    }else if(type === 4){
      return {width:'100%', height:'45vh'}
    }else if(type===5 || type === 6){
      return {width:'50%'}
    }else if(type===7){
      return {width:'50%', height:'45vh'}
    }
  }

  return(
    <div className={styles.content} style={style}>
      {content.value.map((item, index) => {
        return(
          <ItemContent 
            content={item}
            style={setStyle(index)}
          />
        )
      })}
    </div>
  )

};



export default Content;
