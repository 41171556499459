import React, { useEffect, useState, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import customFetch from '@utils/function/customFetch';

import styles from './list.module.css';

function List(props) {

  const [page, updatePage] = useState(0)
  const [isFetching, updateIsFetching] = useState(true)
  const [noMore, updateNoMore] = useState(false)

  const ref = useRef();

  useEffect(() => fetchData({page, search:props.search, filters:props.filters, extraData:props.extraData, refresh:true}), [])
  useEffect(() => {
    updatePage(0)
    updateNoMore(false)
    if(ref && ref.current) ref.current.scrollToTop()
    fetchData({page: 0, search:props.search, filters:props.filters, extraData:props.extraData, refresh:true})
  }, [props.search, props.endpoint, JSON.stringify(props.filters), JSON.stringify(props.extraData)])
  useEffect(() => fetchData({page, search:props.search, filters:props.filters, extraData:props.extraData, refresh:false}), [page])

  function fetchData( { page, search, extraData, refresh, filters, socketKeys }){
    updateNoMore(false)
    customFetch({endpoint:props.endpoint, data:{
        page: page,
        refresh:refresh,
        extraData:extraData, 
        search:search,
        socketKeys:props.socketKeys,
        filters:props.filters,
      }, onSuccess, onError})
  }

  function onSuccess({ response, input }){
    if(props.socketKeys !== input.socketKeys){
      return
    }
    updateIsFetching(false)
    if(response.length === 0) updateNoMore(true)
    props.data_received(response, input.refresh)
  }

  function onError(error){
    props.onError(error)
  }

  function onScroll(evt){
    if(isFetching || noMore) return

    if(evt.top > 0.9){
      updatePage(page+1)
      updateIsFetching(true)
    } 
  }

  function renderContent(){
    if(props.isLoading){
      return(
        <div 
          className={[styles.content_list, styles.loading_list]}
          style={props.contentListStyle} 
        ></div>
      )
    }else{
      return(
        <div className={styles.content_list} style={props.contentListStyle}>
          {props.data.length > 0 ? (
            <>
              {props.ListHeaderComponent ? props.ListHeaderComponent() : null}
              {props.data.map((item, index) => props.renderItem(item, index))}
            </>
          ):(props.renderEmpty ? props.renderEmpty() : null)}
          {props.ListFooterComponent ? props.ListFooterComponent() : null}
        </div>
      )
    }
  }

  if(props.noScroll){
    return (
      <div 
        className={`${styles.list} ${styles.noScroll}`} 
        style={props.style}
      > 
        {props.renderHeader && props.renderHeader()}
        <div style={props.inner_list_style}>
          {renderContent()}
        </div>
      </div>
    );
  }

  return (
      <div 
        className={styles.list} 
        style={props.style}
      > 
        {props.renderHeader && props.renderHeader()}
        <Scrollbars 
          onScrollFrame={onScroll} 
          autoHide
          autoHeight={props.autoHeight}
          renderView={props => <div {...props} className={styles.view}/>}
          renderTrackHorizontal={props => <div {...props} className={styles.horizontal_track}/>}
          renderThumbHorizontal={props => <div {...props} className={styles.horizontal_thumb}/>}
          className={styles.inner_list}
          style={props.inner_list_style}
          ref={ref}
        >
          {renderContent()}
        </Scrollbars>
      </div>
    );
}

export default List;