import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Scrollbars as SB } from 'react-custom-scrollbars';

import styles from './scrollbars.module.css';

const Scrollbars = React.forwardRef(({
  children,
  innerStyle,
  noScroll,
  id,
  style = {}
}, refParent) => {

  const scrollbar = useRef()
  useImperativeHandle(refParent, () => ({ scrollToTop }));

  function scrollToTop(){
    scrollbar.current.scrollTop(0)
  }

  if(noScroll){
    return(
      <div>
        {children}
      </div>
    )
  }

  return(
      <SB 
        autoHide
        renderView={props => <div {...props}  id={id} className={styles.view} style={{...props.style, ...innerStyle, ...{flexShrink:0}}}/>}
        renderTrackHorizontal={props => <div {...props} className={styles.horizontal_track}/>}
        renderThumbHorizontal={props => <div {...props} className={styles.horizontal_thumb}/>}
        className={styles.inner_list}
        ref={scrollbar}
        style={style}
      >
        {children}
      </SB>
  )
});

export default Scrollbars

 


 
