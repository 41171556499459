import React, { useEffect, useState } from 'react';
import {
  useHistory
} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

import customFetch from '@utils/function/customFetch';
import Modal from '@utils/modal/Modal';

import {ReactComponent as IconBack} from '@icon/back.svg';


import styles from './article.module.css';

var date = require('@utils/function/date');

const Article = ({
  location,
  id_article,
  shrinked,
  onGoBack
}) => {

  const history = useHistory();
  const [article, updateArticle] = useState();
  const [showImage, updateShowImage] = useState();

  useEffect(() => {

    const params = {
      id: id_article || location?.state?.id,
      pathname:location?.pathname,
      preview:location?.search?.replace("?", "").split("=")[1]
    }
  
    /*
     let preview
    if(this.props.location.search){
      let search = this.props.location.search.replace("?", "").split("=")
      if(search.length>1 && search[0] === "preview") preview = search[1]
    }*/

    customFetch({ endpoint: '/article', onSuccess, type:'POST', data:params})
  }, [])

  function onSuccess({ response }){
    updateArticle(response)
    customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 3, type: 5, id_article: response.id } , type:'POST'}) 
  }

  useEffect(() => {
    const images = document.getElementsByClassName('inline-img')

    for (let image of images) {
      image.onclick = () => {
        updateShowImage(image.getElementsByTagName('img')[0].src)
      }
    }
  }, [article])

  function goBack(){
    if(onGoBack){
      onGoBack()
    }else{
      //history.push("/article")
      history.goBack()
    }
  }

  if(!article) return null

  return(
    <div className={`${styles.container_article} ${shrinked && styles.shrinked_article} card`}>
      <div className={styles.container_header}>
        <div className={`${styles.container_back} hover_color`} onClick={goBack}>
          <IconBack/>
        </div>
        <div className={styles.header}>
          <div className={styles.title}>{article.title}</div>
          <div className={styles.lastModif}>Mis à jour le {date(article.lastModif)}</div>
          <div className={styles.time}>
            <img src={require('@icon/timer.svg').default}/>
            <span>{`Lecture ${article.time} minute${article.time > 1 ? 's' : ''}`}</span>
          </div>
        </div>
      </div>
      <div className={styles.content_article}>
        <div className={styles.abstract}>{ReactHtmlParser(article.abstract)}</div>
        <div className={styles.image}>
          <img src={article.image} alt={article.alt}/>
        </div>
        <div className={styles.text}>{ReactHtmlParser(article.text)}</div>
      </div>
      {showImage && (
        <Modal
          closeModal={() => updateShowImage(false)}
          header={true}
          style={{width:'80%'}}
        >
          <div className={styles.container_image}>
            <img className='image_contain' src={showImage}/>
          </div>
        </Modal>
      )}
    </div>  
  )

}

export default Article;
