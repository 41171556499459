import React from 'react';
import {Link} from "react-router-dom";
import './footer.css';

var update = require('immutability-helper');


class Footer extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      email:'',
      error:null,
      confirm:false
    };

    this.onChange = this.onChange.bind(this)
    this.register = this.register.bind(this)

  }

  shouldComponentUpdate(nextProps, nextState){
    return this.state.email !== nextState.email || this.state.confirm !== nextState.confirm || this.state.error !== nextState.error
  }

  onChange(evt){
    let value = evt.target.value

    var pattern = /^\S+@\S+\.\S+$/

    if(pattern && !pattern.test(value)){
      this.setState({
        error:true
      })
      return
    }

    this.setState({
      email:value,
      error:null
    })
  }

  register(){
    fetch(global.endpoint + '/register_email', {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: this.state.email
      })
    })
    .then((response) => response.json())
    .then((responseData) => {
      this.setState({
        confirm:true
      })
    })
    .catch((error) => {
 
         
    });
  }



  render(){
      return(
        <footer>
          <div className="column_footer_image">
            <img id="image_footer" src={require('@icon/logo_min.svg').default}/>
          </div>
          <div className="column_footer">
            <div className="title_footer">
              CONTACTS
            </div>
            <Link to={"/recruteur"} className="item_footer">
               <span>Recruteurs</span>
            </Link>
            <div className="item_footer">
              <a href="mailto:contact@consulting-buddies.com ">
                <span>Contactez-nous</span>
              </a>
            </div>
          </div>

          <div className="column_footer">
            <div className="title_footer">
              À PROPOS
            </div>
            <Link to={"/qui_sommes_nous"} className="item_footer">
               <span>Qui sommes-nous ?</span>
            </Link>
            <Link to={"/cgu"} className="item_footer">
               <span>Conditions générales d'utilisation</span>
            </Link>
             <Link to={"/politique_confidentialite"} className="item_footer">
               <span>Politique de confidentialité</span>
            </Link>
             <Link to={"/lexique"} className="item_footer">
               <span>Lexique</span>
            </Link>
          </div>

        </footer>
      )
    
  }
}

export default Footer;
