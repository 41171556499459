import React, {useState} from 'react';

import List from '@utils/list/List';
import Searchbar from '@utils/searchbar/Searchbar';
import DrawerCab from './drawerCab/DrawerCab';

import ItemCab from './itemCab/ItemCab';

import useListReducer from '@utils/function/useListReducer';

import styles from './cabs.module.css';

const Cabs = ({
  
}) => {

  const [listState, updateList] = useListReducer()
  const [search, onSearch] = useState()
  const [selectedCab, selectCab] = useState()

  function onUpdateCab({ response }){
    updateList({ type: 'update_item', item:response})
    if(selectedCab) selectCab(response)
  }
  
  function renderHeader(){
    return(
      <Searchbar 
        onSearch={onSearch}
        placeholder="Rechercher un cabinet"
        search={search}
      />
    )
  }

  function renderItem(item, index){
    return(
      <ItemCab
        cab={item}
        onUpdateCab={onUpdateCab}
        onClick={() => selectCab(item)}
      />
    )
  }

  return(
    <div className={styles.container_list_cabs}>
       <List 
        endpoint={"admin/cabs"}
        data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
        isLoading={listState.isLoading}
        data={listState.data}
        renderItem={renderItem}
        renderHeader={renderHeader}
        search={search}
        onError={() => {}}
      />
      {selectedCab && (
        <DrawerCab
          closeDrawer={() => selectCab()}
          cab={selectedCab}
          onUpdateCab={onUpdateCab}
        />
      )}
    </div>
  )
    
}

export default Cabs;
