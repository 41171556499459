import React, { useState, useEffect } from 'react';
import SocketIOClient from 'socket.io-client';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";

import LogIn from '@admin/login/LogIn';
import HomeAdmin from '@admin/homeadmin/HomeAdmin';

import customFetch from '@utils/function/customFetch';


global.version = "1.0.0"
global.build = "1"

var update = require('immutability-helper');


const MainRecruteur =  (
  props
) => {

  const [isLoading, setLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    customFetch({ endpoint: 'admin/login', onSuccess, onError, type:'GET'})
  }, [])

  function onSuccess(data){
    setUser(data.response)
    setLoading(false)
  }

  function onError(){
    setLoading(false)
  }

  function forgot(){
    
  }

  function logOut(){
    customFetch({ endpoint: 'admin/logout', onSuccess:onLogOut, type:'GET'})
  }

  function onLogOut(){
    setUser(null)
  }

  if(isLoading){
    return null
  }else if(user){
    return(
      <HomeAdmin
        user={user}
        logOut={logOut}
      />
    )
  }else{
    return(
      <LogIn 
        onSuccess={onSuccess}
      />
    )
  }

}

export default MainRecruteur;
