import React, { useState, useEffect, Stylesheet } from "react";

import Form from "@utils/form/Form";

import List from "@utils/list/List";
import Searchbar from "@utils/searchbar/Searchbar";

import customFetch from "@utils/function/customFetch";
import useListReducer from "@utils/function/useListReducer";

import { ReactComponent as IconBack } from "@admin/icon/go_back.svg";

import styles from "@admin/cms/listes/listes.module.css";
import styles_select_form from "./selectForm.module.css";

const SelectForm = ({
  endpointList,
  endpointForm,
  endpointUpdate,
  endpointPreview,
  labelCreate,
  renderContent,
  onSelect,
  keyLastModif = "lastModif",
}) => {
  const [loading, updateLoading] = useState(false);
  const [sections, updateSections] = useState(null);
  const [selectedItem, updateSelectedItem] = useState(null);
  const [state, updateData] = useListReducer();
  const [search, onSearch] = useState(null);

  useEffect(() => {
    if (!renderContent) {
      selectedItem &&
        customFetch({
          endpoint: endpointForm,
          data: { id: selectedItem.id },
          onSuccess,
        });
      selectedItem && updateLoading(true);
    }
  }, [selectedItem?.id]);

  function onSuccess(data) {
    updateSections(data.response);
    updateLoading(false);
  }

  function selectItem(item, selected) {
    if (selected) return;
    if (onSelect) onSelect(item);
    updateSelectedItem(item);
  }

  function renderItem(item, index, selected) {
    return (
      <div
        className={`${styles.item} ${!selected && "hover_card"}`}
        key={`${selected && "s_"}${item.id.toString()}`}
        onClick={() => selectItem(item, selected)}
      >
        {selected && (
          <div
            className={styles_select_form.goBack}
            onClick={() => {
              onSearch(null);
              updateSelectedItem(null);
            }}
          >
            <IconBack className="hover_color image_contain" />
          </div>
        )}
        {item.image && (
          <div className={styles_select_form.container_image}>
            <img src={item.image} />
          </div>
        )}
        <div className={styles.label_item}>{item.label}</div>
        {item.status && (
          <div className={styles.status_item_select}>{`(${item.status})`}</div>
        )}
      </div>
    );
  }

  function renderHeader() {
    return (
      <Searchbar
        button={
          labelCreate && {
            label: labelCreate,
            onClick: () => updateSelectedItem({ id: 0 }),
          }
        }
        onSearch={onSearch}
        search={search}
      />
    );
  }

  if (!selectedItem) {
    return (
      <div className="container">
        <List
          endpoint={endpointList}
          data={state.data}
          renderItem={renderItem}
          renderHeader={renderHeader}
          data_received={(_data, refresh) =>
            updateData(
              refresh
                ? { type: "refresh", data: _data }
                : { type: "next_batch", data: _data }
            )
          }
          isLoading={state.isLoading}
          socketKeys={"get_questions"}
          search={search}
          extraData={{ sort: "last_modif DESC" }}
        />
      </div>
    );
  }

  return (
    <div className="container">
      {renderItem(selectedItem, null, true)}

      {renderContent && renderContent()}

      {sections && !loading && !renderContent && (
        <Form
          endpoint={endpointUpdate}
          preview_endpoint={endpointPreview}
          sections={sections}
          type="formadmin"
          id={selectedItem?.id}
          lastModif={selectedItem?.[keyLastModif]}
          status={selectedItem?.status}
          key={selectedItem?.id}
          success={(data) => {
            updateSelectedItem(data.response);
            customFetch({
              endpoint: endpointForm,
              data: { id: selectedItem.id },
              onSuccess,
            });
          }}
          //not usefull, check preview_endpoint
          preview={endpointPreview}
        />
      )}
    </div>
  );
};

export default SelectForm;
