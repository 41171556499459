import React, { useState, useEffect } from 'react';
import { Link as RouterLink, NavLink } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import ReactHtmlParser from 'react-html-parser';

import FormHandler from '@utils/form/formhandler/FormHandler';
import Button from '@utils/button/Button';

import styles from '@utils/list/list.module.css';
import styles_form from './formadmin.module.css';

import _, { last } from 'lodash'
import moment from 'moment'

var update = require('immutability-helper');

const FormAdmin = ({
  sections,
  fields,
  onChange,
  onValidate,
  status,
  lastModif,
  preview,
  disablePreview,
  dataForm,
  dataFiles,
  required,
  showMissing,
  label_button
}) => {

  const [activeSection, updateActiveSection] = useState(0)

  function renderMissing(index){
    let missing = required.filter((item) => item.section === index)
    if(!missing) return null
    let numberMissing = missing.filter((item) => !item.complete)
    if(numberMissing.length>0){
      return(
        <div className={`${styles_form.index} ${styles_form.missing}`}>{numberMissing.length}</div>
      )
    }
  }

  return(
    <div className={styles_form.container_form_view}>
      <div className={styles_form.side_menu}>
        <div className={`${styles_form.container_infos_modif} card`}>
          <div className={styles_form.container_info_admin}>
            <img src={require('@icon/admin/status.svg').default}/>
            <span>Statut : </span>
            <span>{status || "En ligne"}</span>
          </div>
          <div className={styles_form.container_info_admin}>
            <img src={require('@icon/admin/lastModif.svg').default}/>
            <span>MAJ : </span>
            <span>{lastModif && moment.utc(lastModif).local().format('DD/MM/YYYY [à] HH:mm')}</span>
          </div>
          <div className={styles_form.container_buttons}>
            {preview && (
              <Button 
                label="Aperçu modif."
                onClick={() => onValidate([{key:'preview', value:1}])}
                style={{padding:'1vh', margin:'0 0.5vh'}}
              />
            )}
            <Button 
              label={label_button || "Mettre à jour"}
              type="full"
              onClick={() => onValidate()}
              fill
              style={{padding:'1vh', margin:'0 0.5vh'}}
            />
          </div>
        </div>
        <div className={`${styles_form.container_menu_modif} card`}>
          <Scrollbars 
            autoHide
            autoHeight
            autoHeightMax={'100%'}
            autoHeightMin={'100%'}
            renderView={props => <div {...props} className={styles.view} />}
            renderTrackHorizontal={props => <div {...props} className={styles.horizontal_track}/>}
            renderThumbHorizontal={props => <div {...props} className={styles.horizontal_thumb}/>}
            className={`${styles.inner_list}`}
            
          >
            {sections.map((item, index) => {
              /* return(
                <Link activeClass={styles_form.active} key={item.key} to={"section_" + item.key} className={styles_form.item_menu} containerId="form_admin" smooth={true} spy={true}>
                  <div className={styles_form.index}>{index+1}</div>
                  <div className={styles_form.name}>{item.name}</div>
                </Link>
              ) */
              return(
                <div key={item.key} className={`${styles_form.item_menu} ${activeSection === index && styles_form.active}`} onClick={() => updateActiveSection(index)}>
                  <div className={styles_form.index}>
                    {item.icon ? (
                      <div className={styles_form.icon}>{ReactHtmlParser(item.icon)}</div>
                    ) : (
                      <>{index+1}</>
                    )}
                  </div>
                  <div className={styles_form.name}>{item.name}</div>
                  {showMissing && renderMissing(index)}
                </div>
              )
            })}
          </Scrollbars>
          </div>
      </div>
      <div className={`card container`}>
        <FormHandler 
          id="form_admin"
          fields={fields}
          icon={sections[activeSection].icon}
          //sections={sections}
          activeSection={activeSection}
          sections={[ {
            name: sections[activeSection].name,
            key: sections[activeSection].key,
            fields: sections[activeSection].fields.map((item) => {
              if(item.key.value){
                if(item.key.value === 'content'){
                  for(let i=0; i<item.value.length; i++){
                    item.value[i].value = dataForm[item.key.value] && dataForm[item.key.value][item.key.type] && dataForm[item.key.value][item.key.type].find(x => x.index === item.value[i].key) ? 
                      {
                        type: dataForm[item.key.value][item.key.type].find(x => x.index === item.value[i].key).type,
                        value: dataForm[item.key.value][item.key.type].find(x => x.index === item.value[i].key).item,
                      }
                      : item.value[i].value
                  }
                  return item
                }else{
                  return dataForm[item.key.value] && dataForm[item.key.value][item.key.type] ? {...item, ...{ value: dataForm[item.key.value][item.key.type] } } : item
                }
              }else if(dataForm[item.key]){

                return {...item, ...{ value: dataFiles && dataFiles[dataForm[item.key]] ? dataFiles[dataForm[item.key]] : dataForm[item.key] } }
              }else{
                return item 
              }
            }) 
          }]}
          onChange={onChange}
          autoHeight={false}
          dataForm={dataForm}
          dataFiles={dataFiles}
          required={required}
          showMissing={showMissing}
        />
      </div>

    </div>
  )
}

export default FormAdmin;