import React from 'react';

import Header from '@admin/header/Header';

import Site from '@admin/cms/site/Site';
import Recruit from '@admin/cms/recruit/Recruit';
import Listes from '@admin/cms/listes/Listes';
import BuddyScan from '@admin/cms/buddyscan/BuddyScan';

import Tab from '@utils/tab/Tab';

import { BrowserRouter as Router, Route, Link, Redirect, Switch, NavLink } from "react-router-dom";

import {ReactComponent as IconWorld} from '@admin/icon/icon_tab_world.svg';
import {ReactComponent as IconBuddyScan} from '@admin/icon/icon_buddy_scan.svg';
import {ReactComponent as IconListes} from '@admin/icon/icon_listes.svg';
import {ReactComponent as IconRecruit} from '@admin/icon/icon_recruit.svg';

//import './homeadmin.css'

var update = require('immutability-helper');


class CMS extends React.Component {


  constructor(props) {
    super(props);
    this.state = {

    };

  }
  
  render(){
      return(
        <div id="container_admin" className="container">
            <Router>
            
              <Tab 
                style={{borderRadius:'1vh 1vh 0 0'}}
                tabs = {[
                  {"pathname":"/admin/cms/site", name:"Site vitrine", icon:<IconWorld/>, routes:["/admin/cms/site", "/admin/cms", "/admin/cms/site/administratif", "/admin/cms/site/accueil", "/admin/cms/site/articles", "/admin/cms/site/cabinets"]},
                  {"pathname":"/admin/cms/buddyscan", name:"Buddy Scan", icon:<IconBuddyScan/>},
                  {"pathname":"/admin/cms/listes", name:"Listes", icon:<IconListes/>},
                  {"pathname":"/admin/cms/recruit", name:"Recrutement", icon:<IconRecruit/>},
                ]}
              />
              <Switch>
                <Route 
                  path={["/admin/cms/site", "/admin/cms", "/admin/cms/site/administratif", "/admin/cms/site/accueil", "/admin/cms/site/articles", "/admin/cms/site/cabinets"]}
                  render={(props) => <Site {...props} />}
                  exact
                />
                <Route 
                  path="/admin/cms/listes" 
                  render={(props) => <Listes {...props} />}
                  exact
                />
                <Route 
                  path="/admin/cms/recruit" 
                  render={(props) => <Recruit {...props} />}
                  exact
                />
                 <Route 
                  path="/admin/cms/buddyscan" 
                  render={(props) => <BuddyScan {...props} />}
                  exact
                />
              </Switch>
            </Router>
        </div>
      )
    
  }
}

export default CMS;
