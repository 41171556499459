import React, { useState, useEffect, Stylesheet } from 'react';

import {Link} from "react-router-dom";
import Article from '@site/articles/article/Article';

import Scrollbars from '@utils/scrollbars/Scrollbars';

import styles from './articlecoaching.module.css';

var update = require('immutability-helper');

function ArticleCoaching(props){

  return(
    <div className={styles.outer_article}>
      <Scrollbars>
        <Article 
          {...props}
        />
      </Scrollbars>
    </div>
  )
}

export default ArticleCoaching