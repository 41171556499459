import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Modal from '@utils/modal/Modal';
import Button from '@utils/button/Button';
import BannerContext from '@utils/bannerContext/BannerContext';

import Scrollbars from '@utils/scrollbars/Scrollbars';

import styles from './editbuddyscan.module.css';

const MenuQuestion = ({
  question,
  index,
  currentQuestion,
  onClick
}) => {
  return(
    <div className={`${styles.menu_question} ${currentQuestion === index && styles.active_menu_question} hover_color`} onClick={onClick}>
      <div className={styles.index_menu_question}>{index+1}</div>
      <div className={styles.label_menu_question}>{question.title}</div>
    </div>
  )
}

const EditBuddyScan = ({
  onClose,
  onValidate,
  renderContent,
  onGoBack,
  answers,
  questions,
  currentQuestion,
  updateCurrentQuestion,
  isFirst,
  isLast
}) => {

  const [userAnswers, updateUserAnswers] = useState(answers)
  const [isLoading, updateIsLoading] = useState(false)

   useEffect(() => {
    updateIsLoading(false)
  }, [currentQuestion])

  function changeQuestion(index){
    onValidate(userAnswers)
    updateIsLoading(true)
    updateCurrentQuestion(index)
  }

  return(
    <Modal
      closeModal={onClose}
      style={{width:isMobile ? '100vw' : '70vw'}}
    >
      
      <BannerContext
        subtitle={['Vous avez la possibilité ici de modifier vos réponses au questionnaire afin de mettre à jour votre profil et ainsi définir vos nouveaux scores de matching avec les cabinets de conseil']}
        image={require('@icon/bannercontext/newBuddyScan.svg').default}
        style={{margin:0, padding:'0 2vw'}}
      />

      <div className={`${styles.container_edit_buddy_scan}`}>

        <div className={styles.container_menu}>
          <Scrollbars>
            {questions.map((item, index) => (
              <MenuQuestion
                question={item}
                index={index}
                currentQuestion={currentQuestion}
                onClick={() => changeQuestion(index)}
              />
            ))}
          </Scrollbars>
        </div>

        <div className={styles.container_question}>
          {renderContent({ userAnswers, updateUserAnswers, isLoading, updateIsLoading })}
            <div className={styles.banner_button}>
              <div className={`${styles.navigate} ${!isMobile && 'hover_background_grey'} ${isFirst && styles.disabled}`} onClick={() => changeQuestion(currentQuestion-1)}>
                <img src={require('@icon/back_grey.svg').default}/>
              </div>
              <div className={`${styles.navigate} ${!isMobile && 'hover_background_grey'} ${isLast && styles.disabled}`} onClick={() => changeQuestion(currentQuestion+1)}>
                <img src={require('@icon/forward_grey.svg').default}/>
              </div>
              <Button
                label="Annuler"
                type="cancel"
                onClick={onClose}
                style={{marginRight:'1vw'}}
              />
              <Button
                label="Mettre à jour"
                type="full"
                onClick={() => {
                  onValidate(userAnswers, true)
                }}
              />
            </div>
        </div>
      </div>
    </Modal>
  )
};

export default EditBuddyScan

 
