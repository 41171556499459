import React, { useEffect, useState } from "react";

import Delete from "@utils/delete/Delete";

import styles_field from "../field.module.css";
import styles_photo from "./photo.module.css";

const { v4: uuidv4 } = require("uuid");
var update = require("immutability-helper");

function Photo(props) {
  const [value, updateValue] = useState(props.field.value);
  const [loading, updateLoading] = useState(false);
  const [error, setError] = useState();
  const [id, updateId] = useState();

  useEffect(() => {
    if (props.field.value instanceof File) {
      updateLoading(true);
      readImage(props.field.value);
    }
  }, []);

  function readImage(img) {
    let reader = new FileReader();
    reader.onload = function (ev) {
      updateValue(ev.target.result);
      updateLoading(false);
    };
    reader.readAsDataURL(img);
  }

  function getImage(e) {
    if (e.target.files && e.target.files[0]) {
      if (
        props.field.extraData?.maxSize &&
        e.target.files[0].size > props.field.extraData.maxSize
      ) {
        setError(
          `Votre photo de profil ne doit pas dépasser ${
            props.field.extraData.maxSize / 1000
          } Ko`
        );
        return;
      } else {
        setError();
      }

      updateLoading(true);
      readImage(e.target.files[0]);

      const id = uuidv4();
      updateId(id);
      props.onChange({
        value: id,
        image: e.target.files[0],
        key: props.field.key,
        section: props.section,
      });
    }
  }

  function deleteImage() {
    props.onChange({
      value: id,
      image: null,
      key: props.field.key,
      section: props.section,
    });
    updateValue(null);
  }

  return (
    <div className={`${styles_field.container_field}`}>
      {props.renderLabelField()}
      <div
        className={`
          ${styles_field.container_field_input} 
          ${styles_photo.container_photo} 
          ${props.min && !value && styles_photo.container_min_photo} 
          ${props.field.extraData?.type === "cover" && styles_photo.cover} 
          ${props.field.extraData?.type === "banner" && styles_photo.banner}
          ${props.field.extraData?.type === "round" && styles_photo.round}
          ${props.showMissing && !props.complete && styles_photo.item_missing}
        `}
        style={props.field.style}
      >
        {!value ? (
          <div className={styles_photo.inner_photo}>
            {loading && <div></div>}

            {!loading && props.min && (
              <div className={styles_photo.photo}>
                <input
                  className={styles_photo.input_file}
                  type="file"
                  onChange={getImage}
                  accept={"image/*"}
                />
                <div className={`${styles_photo.min_photo}`}>
                  <img
                    src={require("@utils/icon/add_circle_outlined.svg").default}
                  />
                  <span>Ajouter photo</span>
                </div>
              </div>
            )}

            {!loading && !props.min && (
              <div className={styles_photo.photo}>
                <input
                  className={styles_photo.input_file}
                  type="file"
                  onChange={getImage}
                  accept={"image/*"}
                />
                <div
                  className={`${styles_photo.photo} ${styles_photo.add_picture}`}
                >
                  <img src={require("@icon/picture.png").default} />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={styles_photo.inner_photo}>
            <div className={`${styles_photo.photo}`}>
              <img src={value} />
            </div>
            <Delete
              onDelete={deleteImage}
              absolute
              style={
                props.field.extraData?.type === "round"
                  ? { right: "0.5vh", top: "0" }
                  : {}
              }
            />
          </div>
        )}
      </div>
      {error && <span className={styles_photo.error}>{error}</span>}
    </div>
  );
}

export default Photo;
