import React, { useState, useEffect, Stylesheet, useRef } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, Switch, useParams } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import HeaderUser from '@user/header/HeaderUser'
import Tab from '@utils/tab/Tab'

import UserBuddyScan from '@site/buddyScan/UserBuddyScan';
import SubscribeBuddyScan from '@site/buddyScan/subscribeBuddyScan/SubscribeBuddyScan';

import ListOfferJunior from '@user/listofferjunior/ListOfferJunior'
import ListMatching from '@user/matching/ListMatching'
import Coaching from '@user/coaching/Coaching'
import Subscribe from '@user/subscribe/Subscribe';
import Modal from '@utils/modal/Modal';
import Button from '@utils/button/Button';
import ArticleCoaching from '@user/articleCoaching/ArticleCoaching';
import Scrollbars from '@utils/scrollbars/Scrollbars';

import {ReactComponent as IconCheck} from '@user/icon/icon_tab_check.svg';
import {ReactComponent as IconBell} from '@user/icon/icon_tab_bell.svg';
import {ReactComponent as IconGroup} from '@user/icon/icon_tab_group.svg';

import ListBuddyScore from './buddyscore/ListBuddyScore';
import customFetch from '@utils/function/customFetch';

import styles_home from './homeuser.module.css'

const HomeUser = ({
    user,
    fetchUser,
    initialMatch,
    initialOffre,
    initialSpontaneous,
    profileUser,
    updateProfileUser,
    modal,
    updateModal,
    onSuccessCV,
    onSuccessAvailability,
    updateUser
}) => {

  const [confirmEmailSent, onConfirmEmailSent] = useState(false)
  const [modalOffreBlockOpened, onModalOffreBlockOpen] = useState(false)

  const ref = useRef()

  useEffect(() => {
    customFetch({ endpoint: 'user/profile', onSuccess, onError, type:'GET'})
  }, [user])

  useEffect(() => {
    if(confirmEmailSent){
      setTimeout(function(){
        onConfirmEmailSent(false)
      }, 3000)
    }
  }, [confirmEmailSent])

  function onSuccess({ response }){
    updateProfileUser(response)
  }

  function onError(){

  }

  function onSuccessPreferences({ response }){
    let updatedProfile = {...profileUser}
    updatedProfile.preferences = [...updatedProfile.preferences]
    updatedProfile.preferences.push(response)
    updateProfileUser(updatedProfile)
  }

  function onSuccessCalledScheduled({ response }){
    let updatedProfile = {...profileUser}
    updatedProfile.scheduled = 1
    updateProfileUser(updatedProfile)
  }

  function sendVerifAgain(){
    if(confirmEmailSent) return
    onConfirmEmailSent(true)
    customFetch({ endpoint: 'user/sendVerifAgain', onSuccess:onSuccesVerifAgain, type:'POST'})
  }

  function onSuccesVerifAgain(){

  }

  if(!profileUser){
    return null
  }

  function renderContentSubscribe(){
    return(
      <Subscribe 
        onSuccessSubscribe={fetchUser}
        text='Inscrivez vous en quelques secondes pour découvrir vos scores de matching avec chaque cabinet de conseil référencé sur Consulting Buddies '
      />
    )
  }

  function renderContentVerif(){
    if(user.verif === 1){
      if(modalOffreBlockOpened){
        return(
          <Modal
            modalShrink={true}
            header='Vérifiez votre e-mail pour finaliser la candidature'
          >
            <div className={`${styles_home.container_modal} container`}>
              <div className={styles_home.image_modal}>
                <img src={require('@user/icon/waiting_confirm.svg').default} className="image_contain" />
              </div>
              <div className={styles_home.label_modal}>
                <div>Un lien de vérification a été envoyé sur votre adresse email {profileUser.email}.</div> 
                <div>Veuillez cliquer sur le lien pour vérifier votre email et finaliser votre candidature.</div>
              </div>
              <Button 
                label={confirmEmailSent ? 'Email envoyé' : 'Envoyer à nouveau l\'email'}
                onClick={sendVerifAgain}
                disabled={confirmEmailSent}
              />
            </div>
          </Modal>
        )
      }else if(profileUser.isBlocked){
        return(
          <Modal
            modalShrink={true}
          >
            <div className={`${styles_home.container_modal} container`}>
              <div className={styles_home.image_modal}>
                <img src={require('@user/icon/waiting_confirm.svg').default} className="image_contain" />
              </div>
              <div className={styles_home.label_modal}>
                <div>Un lien de vérification a été envoyé sur votre adresse email {profileUser.email}.</div> 
                <div>Veuillez cliquer sur le lien pour vérifier votre email et terminer votre inscription.</div>
              </div>
              <Button 
                label={confirmEmailSent ? 'Email envoyé' : 'Envoyer à nouveau l\'email'}
                onClick={sendVerifAgain}
                disabled={confirmEmailSent}
              />
            </div>
          </Modal>
        )
      }else{
        return(
          <div className={styles_home.banner_verif}>
            <div className={styles_home.icon_banner_verif}>
              <img src={require('@icon/white_email.svg').default} className="image_contain"/>
            </div>
            <div className={styles_home.label_banner_verif}>{isMobile ? 'Vérifiez votre email' : 'Un lien de vérification a été envoyé sur votre adresse email. Veuillez cliquer sur le lien pour vérifier votre email et terminer votre inscription.'}</div>
            <div className={styles_home.resend_banner_verif} onClick={sendVerifAgain}>
              {confirmEmailSent ? 'Email envoyé' : 'Envoyer à nouveau l\'email'}
            </div>
          </div>
        )
      }
    }
  }

  function scrollToTop(){
    ref.current.scrollToTop()
  }

  return(
    <div className={`${styles_home.homeuser} container`}>
      <Scrollbars
        innerStyle={isMobile ? {} : {padding:'0 20vw'}}
        ref={ref}
      >
        <BrowserView style={{width:'100%'}}>
          <HeaderUser 
            user={user}
            profileUser={profileUser}
            showTest={() => updateModal('buddyScan')}
            onSuccessCV={onSuccessCV}
            onSuccessAvailability={onSuccessAvailability}
            updateUser={updateUser}
          />
        </BrowserView>
        <Tab 
          tabs = {[
            {"pathname":"/candidat", name:"Mes scores", icon:<IconBell/>},
            {"pathname":"/candidat/candidatures", name:"Mes offres", icon:<IconCheck/>},
            {"pathname":"/candidat/coaching", name:"Coaching", icon:<IconGroup/>}
          ]}
          isSticky={true}
        />
        <Switch>
          <Route 
            path="/candidat" 
            exact
            render={(props) =>  <ListBuddyScore user={profileUser} onSuccessPreferences={onSuccessPreferences} showTest={() => updateModal('buddyScan')}/> }
          />
          <Route 
            path="/candidat/candidatures" 
            exact
            render={(props) =>  (
              <ListMatching 
                profileUser={profileUser} 
                user={user} 
                initialMatch={initialMatch} 
                initialOffre={initialOffre} 
                initialSpontaneous={initialSpontaneous}
                onSuccessPreferences={onSuccessPreferences}
                showTest={() => updateModal('buddyScan')}
                scrollToTop={scrollToTop}
                onModalOffreBlockOpen={onModalOffreBlockOpen}
              />
            )}
          />
          <Route 
            path="/candidat/coaching" 
            exact
            render={(props) =>  <Coaching user={user} profileUser={profileUser} onSuccessCalledScheduled={onSuccessCalledScheduled}/>}
          />
          <Route 
            path="/candidat/coaching/:article" 
            exact
            render={(props) =>  <ArticleCoaching user={user} {...props}/>}
          />
        </Switch>
      </Scrollbars>

      {!user?.verif && (
        <SubscribeBuddyScan
          renderContent={renderContentSubscribe}
          progress={100}
          text="Inscrivez vous en quelques secondes pour découvrir vos scores de matching avec chaque cabinet de conseil référencé sur Consulting Buddies."
        />
      )}

      {modal === 'buddyScan' && (
        <UserBuddyScan
          onClose={() => updateModal(null)}
          senior={user.senior}
        />
      )}

      {renderContentVerif()}

    </div>
  )
}

export default HomeUser

 