import React, { useState, useEffect, Stylesheet } from 'react';

import Button from '@utils/button/Button'

import customFetch from '@utils/function/customFetch';

import styles from './footeraction.module.css';

var date = require('@utils/function/date');

const FooterAction = ({
    candidate,
    activeTab,
    onSuccess,
    onValidate,
    showResend,
    type
}) => {

  if(!candidate) return null

  function updateCandidate(endpoint){
    customFetch({ endpoint: `${type}/${endpoint}`, data:{id:candidate.id_candidate}, onSuccess})
  }

  function renderActions(){
    if(candidate.status?.style === 'refused'){
      return(
          <Button 
            label='Restaurer la candidature'
            type='warn'
            style={{marginRight:'1vw'}}
            onClick={() => updateCandidate('restore_candidate')}
          />
      )
    }

    if(candidate.statusCab === 0 || candidate.statusCab === 1){
      return(
        <>
          <Button 
            label='Refuser'
            type='delete'
            style={{marginRight:'1vw'}}
            onClick={() => updateCandidate('refuse_candidate')}
          />
          <Button 
            label='Valider'
            type='validate'
            onClick={() => updateCandidate('validate_candidate')}
          />
        </>
      )
    }

    if(activeTab === 1 && (candidate.statusCab === 2 || showResend)){
      return(
        <Button 
          label='Envoyer'
          type='validate'
          style={{marginRight:'1vw'}}
          onClick={onValidate}
        />
      )
    }
    
  }

  return(
    <div className={`${styles.content_footer}`}>
      <div className={`${styles.candidate}`}>
        <div className={`${styles.name}`}>{candidate.name}</div>
        <div className={`${styles.date}`}>Candidature {candidate.date}</div>
      </div>
      <div className={`${styles.content_buttons}`}>
        {renderActions()}
      </div>
    </div>
  )
}

export default FooterAction

 