import React, { useState, useEffect } from 'react';

import Button from '@utils/button/Button';
import Modal from '@utils/modal/Modal';
import List from '@utils/list/List';
import Form from '@utils/form/Form';
import ModalConfirm from '@utils/modalConfirm/ModalConfirm';

import useListReducer from '@utils/function/useListReducer';
import customFetch from '@utils/function/customFetch';

import styles from './access.module.css';

const Access = ({
  id_cab,
}) => {

  const [listState, updateList] = useListReducer()
  const [showCreate, onShowCreate] = useState()
  const [showDelete, onShowDelete] = useState()

  function renderHeader(){
    return(
      <div className={styles.container_header}>
        <Button 
          label='Ajouter un utilisateur'
          onClick={() => onShowCreate(true)}
        />
      </div>
    )
  }

  function renderItem(item){
    return(
      <div className={`${styles.container_access} card`}>
        <div className={styles.image}>
          <img className='image_contain' src={ require('@icon/placeholder_user.svg').default}/>
        </div>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.email}>{item.email}</div>
        <div className={styles.last_connect}>{item.last_connect}</div>
        <div className={`${styles.delete} hover_background_red`} onClick={() => onShowDelete(item.id)}>
          <img className='image_contain' src={ require('@icon/delete.svg').default}/>
        </div>
      </div>
    )
  }

  function success({ response }){
    updateList({type:'create_item', item:response})
    onShowCreate(false)
  }

  function deleteAccess(){
    customFetch({ endpoint: 'admin/delete_access', onSuccess:onSuccessDelete, data:{id: showDelete}, type:'POST'})
  }

  function onSuccessDelete({ response }){
    updateList({type:'delete_item', item:response})
    onShowDelete(false)
  }

  return(
    <>
      <List 
        endpoint={"admin/access"}
        data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
        isLoading={listState.isLoading}
        data={listState.data}
        renderItem={renderItem}
        renderHeader={renderHeader}
        onError={() => {}}
        extraData={{id_cab}}
      />
      {showCreate && (
        <Modal
          closeModal={() => onShowCreate(false)}
          header='Créer un compte recruteur'
          modalShrink={true}
        >
          <Form
            fields={[
              {type:'input', title:'Prénom', key:'prenom'},
              {type:'input', title:'Nom', key:'nom'},
              {type:'input', title:'Email', key:'email'}
            ]}
            autoHeight={true}
            buttons={{
              action:{label:'Enregistrer', type:'full', field:[{key:"id_cab", value:id_cab}]},
              sub_action:{label:'Annuler', type:'cancel', onPress:() => onShowCreate(false)},
            }}
            endpoint={'admin/add_access'}
            success={success}
          />

        </Modal>
      )}
      {showDelete && (
        <ModalConfirm
          closeModal={() => onShowDelete(false)}
          header='Supprimer un compte recruteur'
          labelConfirm='Supprimer'
          typeConfirm='delete'
          onConfirm={deleteAccess}
          content={`Voulez-vous supprimer ce compte recruteur ? Il n'aura plus accès à son espace cabinet.`}
        />
      )}
    </>
  )
}

export default Access;
