import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import DateSelect from '../dateSelect/DateSelect.js';


import customFetch from '@utils/function/customFetch';

import styles from './overview.module.css';

const COLORS = {
  'non_inscrit_users':'rgb(12, 188, 139)',
  'non_inscrit_new':'rgba(12, 188, 139, 0.4)',
  'junior_users':'rgb(54,124,255)', 
  'junior_new':'rgb(54,124,255, 0.4)', 
  'senior_users':'rgb(255, 194, 57)', 
  'senior_new':'rgb(255, 194, 57, 0.4)',
}


const Overview = ({
  
}) => {

  const [filterDates, updateFilterDates] = useState({ id:2, label: `Ces 30 derniers jours` })
  const [data, updateData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    customFetch({ endpoint: 'admin/dashboard_overview', onSuccess, data: { filterDates }, type:'POST'})
  }, [filterDates])

  function onSuccess({ response }){
    updateData(response)
    setIsLoading(false)
  }

  if(!data) return null

  if(isLoading) return(
    <div className={styles.loading_image}>
      <img src={require('@icon/site/spinner.gif').default}/>
    </div>
  )

  return(
    <>
      <DateSelect
        fields={[
          { date: true },
          { id:0, label: `Aujourd'hui` },
          { id:1, label: `Ces 7 derniers jours` },
          { id:2, label: `Ces 30 derniers jours` },
          { id:3, label: `Ces 12 derniers mois` },
        ]}
        placeholder='Choisir une période'
        onSelect={updateFilterDates}
        defaultValue={filterDates}
      />
        
      <div className={`${styles.card_pie} card`}>
        <ResponsiveContainer>
          <PieChart>
            <Pie data={data.connections.pie} dataKey="value" nameKey="name" startAngle={90} endAngle={-270} cx="50%" cy="50%" outerRadius="60%" innerRadius="50%" fill="#8884d8">
              {
                data.connections.pie.map((entry, index) => (
                <Cell fill={COLORS[entry.name]} key={entry.name}/>
                ))
                
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className={styles.container_details_pie}>
          {data.connections.data.map((item) => {
            return(
              <div className={styles.details_pie}>
                <div className={styles.item_details_pie}>
                  <div className={styles.color_pie} style={{background:COLORS[item.key]}}></div>
                  <div className={styles.label_pie}>{item.label}</div>
                </div>
                <div className={styles.item_sub_details_pie}>
                  <div className={styles.first_connexion}>
                    <div className={styles.color_pie} style={{background:COLORS[item.key]}}></div>
                    <div className={styles.label_pie}>Première co.</div>
                  </div>
                  <div className={styles.reconnexion}>
                    <div className={styles.color_pie} style={{background:COLORS[item.key]}}></div>
                    <div className={styles.label_pie}>Reco.</div>
                  </div>
                </div>
                <div className={styles.visitors_pie}>{item.users + item.new} visiteur{item.users + item.new > 1 ? 's' : ''}</div>
                <div className={styles.connections_pie}>{item.connections} connexion{item.connections > 1 ? 's' : ''}</div>
              </div>
            )
          })}
        </div>
      </div>

      <div className={styles.row_details}>

        <div className={`${styles.card_detail} card`}>
          <div className={styles.card_title}>Conversion Buddy Scan</div>
          <div className={styles.item_bs}>
            {data.buddyScan.map((item) => (
              <div className={styles.container_item_bs}>
                <div className={styles.container_bar_bs}>
                  <div className={styles.container_value_bs}>{item.value}</div>
                  <div className={styles.bar_bs} style={item.style}></div>
                </div>
                <div className={styles.label_bs}>{item.label}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={`${styles.card_detail} card`}>
          <div className={styles.card_title}>Inscriptions</div>
          
          <div className={styles.container_stat}>
            <div className={styles.line_stat}>
              <div className={styles.item_stat}>
                <div className={styles.label_stat}>Juniors</div>
                <div className={styles.figure_stat}>{data.inscriptions.junior.relative}</div>
                <div className={styles.figure_stat}>{data.inscriptions.junior.absolute} pers.</div>
              </div>

              <div className={styles.item_stat}>
                <div className={styles.label_stat}>Seniors</div>
                <div className={styles.figure_stat}>{data.inscriptions.senior.relative}</div>
                <div className={styles.figure_stat}>{data.inscriptions.senior.absolute} pers.</div>
              </div>
            </div>

            <div className={styles.separator}></div>

            <div className={styles.line_stat}>
              <div className={styles.item_stat}>
                <div className={styles.label_stat}>BuddyScan</div>
                <div className={styles.figure_stat}>{data.inscriptions.buddyScan.relative}</div>
                <div className={styles.figure_stat}>{data.inscriptions.buddyScan.absolute} pers.</div>
              </div>

              <div className={styles.item_stat}>
                <div className={styles.label_stat}>Offres</div>
                <div className={styles.figure_stat}>{data.inscriptions.offres.relative}</div>
                <div className={styles.figure_stat}>{data.inscriptions.offres.absolute} pers.</div>
              </div>
            </div>

          </div>

        </div>

        <div className={`${styles.card_detail} card`}>
        <div className={styles.card_title}>Connexions</div>
          <div className={styles.item_stat}>
            <div className={styles.label_stat}>Juniors</div>
            <div className={styles.figure_stat}>{data.connections.data[1].connections} logs</div>
            <div className={styles.figure_stat}>{data.connections.data[1].users + data.connections.data[1].new > 0 ? Math.round(data.connections.data[1].connections/(data.connections.data[1].users + data.connections.data[1].new)*100) / 100 : '-'} logs/user</div>
          </div>

          <div className={styles.item_stat}>
            <div className={styles.label_stat}>Seniors</div>
            <div className={styles.figure_stat}>{data.connections.data[2].connections} logs</div>
            <div className={styles.figure_stat}>{data.connections.data[2].users + data.connections.data[2].new > 0 ? Math.round(data.connections.data[2].connections/(data.connections.data[2].users + data.connections.data[2].new)*100) / 100 : '-'} logs/user</div>
          </div>
        </div>

      </div>
     
    </>
  )
    
}

export default Overview;
