import React from 'react';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import './mission.css';

var update = require('immutability-helper');


class Mission extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      show_mission:false,
      overflow_mission:false  
    };

    this.toggleShow = this.toggleShow.bind(this)

  }

  componentDidMount() {
    const height = this.divElement.clientHeight;
    this.height = height + 0.06*window.innerHeight
  }

  shouldComponentUpdate(nextProps, nextState){
    return nextState.show_mission !== this.state.show_mission || nextState.overflow_mission !== this.state.overflow_mission
  }

  toggleShow(){
    this.setState({
      show_mission:!this.state.show_mission,
      overflow_mission:false
    })
    var _this = this 
    setTimeout(function(){
      _this.setState({
        overflow_mission:_this.state.show_mission ? true : false
      })
    }, 1000)
  }

  render(){
    let classe = "mission"
    classe += this.state.overflow_mission ? " show_mission" : ""
    return(
      <div className={classe} style={this.state.show_mission ? {height:this.height} : {}} onClick={this.toggleShow}>
        <div className="header_mission">
          <img className="img_mission" src={this.props.mission.image}/>
          <div className="name_mission">{this.props.mission.name}</div>
          <div className="arrow_mission">
            <img style={this.state.show_mission ? {transform:'rotate(180deg)'} : {}} src={require('@icon/down_arrow.svg').default}/>
          </div>
        </div>
          
        <div className="text_mission" style={this.state.show_mission ? {opacity:1} : {}} ref={ (divElement) => { this.divElement = divElement } }>
          <div className="container_text_article">
            {ReactHtmlParser(this.props.mission.text)}
          </div>
        </div>

      </div>
    )
  }
}

export default Mission;
