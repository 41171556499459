import React from 'react';

import styles from './banner_cab.module.css';

var update = require('immutability-helper');

const BannerCab = ({
  cab,
  user,
  closeModal,
  title,
  type,
  data,
}) => {
  function renderInfoOffre({image, label}){
    return(
      <div className={styles.item_infos_offre}>
        <div className={styles.image_infos_offre}>
          <img src={image} className="image_contain"/>
        </div>
        <div className={styles.label_infos_offre}>{label}</div>
      </div>
    )
  }

  return(
    <div className={styles.container_banner_cab}>
      <div className={styles.container_background}>
        <img className={styles.image_banner_cab} src={cab.banniere} alt=""/>
        <div className={styles.overlay_background}></div>
      </div>
      <div className={styles.close} onClick={closeModal}>
        <img className='image_contain' src={require('@icon/close_popup.svg').default}/>
      </div>
      <div className={styles.content_banner_cab}>
        <div className={styles.container_infos_cab}>
          {type === 'progress' ? (
            <div className={styles.container_progress_images}>
              <div className={styles.progress_image}>
                <img src={cab.logo} className="image_contain"/>
              </div>
              <div className={styles.progress_image}>
                <img src={user.image || require('@icon/profile/empty_image_profile.svg').default} className="image_cover"/>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className={styles.container_logo}>
                <img className={styles.image_logo_cab} src={cab.logo} alt=""/>
              </div>
            </div>
          )}
          <div className={styles.name_cab}>{cab.name}</div>
        </div>
        <div className={styles.title_banner_cab}>{title}</div>
        {type === 'apply' && data && (
          <div className={styles.container_infos_offre}>
            {data.profile && renderInfoOffre({image:require('@recruteur/icon/bannercab/profile.svg').default, label:data.profile})}
            {data.contract && renderInfoOffre({image:require('@recruteur/icon/bannercab/contract.svg').default, label:data.contract})}
            {renderInfoOffre({image:require('@recruteur/icon/bannercab/place.svg').default, label:data.place})}
            {renderInfoOffre({image:require('@recruteur/icon/bannercab/date.svg').default, label:data.date})}
          </div>
        )}
      </div>
    </div>
  )
}

export default BannerCab

 