import React, { Component } from 'react';

import styles from './buddy_score.module.css';

var update = require('immutability-helper');

class BuddyScore extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      
      
    };

  }

  renderScore(score) {
    if(score >= 70){
      return(
        <div className={`${styles.score_details} ${styles.top_score}`}>Top score</div>
      )
    }else if(score >= 50){
      return(
        <div className={`${styles.score_details} ${styles.middle_score}`}>Score moyen</div>
      )
    }else if(score >= 30){
      return(
        <div className={`${styles.score_details} ${styles.middle_score}`}>Score moyen</div>
      )
    }else{
      return(
        <div className={`${styles.score_details} ${styles.low_score}`}>Score faible</div>
      )
    }
  }

  render(){
    return(
      <div className={styles.buddy_score} style={this.props.style}>
        <div className={styles.container_logo_buddy_score}>
          <img src={require('@icon/logo_min.svg').default}/>
        </div>
        <div className={styles.score_buddy}><div>{this.props.score || this.props.score === 0 ? this.props.score : '?'}%</div></div>

        {this.props.details && (
          <div className={`${styles.container_details} card`}>
            <div className={styles.item_details}>
              <div className={styles.image_details}><img className='image_contain' src={require('@icon/heart.svg').default}/></div>
              <div className={styles.label_details}><div>Correspondance des <span>aspirations</span> avec les spécificités du cabinet</div></div>
              {this.renderScore(this.props.details.buddy_scan)}
            </div>
  
            <div className={styles.item_details}>
              <div className={styles.image_details}><img className='image_contain' src={require('@icon/folder.svg').default}/></div>
              <div className={styles.label_details}><div>Correspondance du <span>parcours</span> avec le CV des candidats usuellement recrutés</div></div>
              {this.renderScore(this.props.details.screening_test)}
            </div>
          </div>
        )}


      </div>
    )
  }
}

export default BuddyScore;