import React, { useRef, useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { Link, useParams, NavLink , useLocation} from "react-router-dom";
import {ReactComponent as IconLogo} from '@icon/cb.svg';

import useOnClickOutside from "@utils/function/useOnClickOutside";

import styles from './header.module.css';

var update = require('immutability-helper');


const Header = ({
  user,
  logOut,
  showSubscribe,
  showConnect,
  color,
  toggleSettings
}) => {

  const [showMenu, toggleMenu] = useState(false);

  const { pathname } = useLocation();

  const ref = useRef();
  useOnClickOutside(ref, () => toggleMenu(false));

  function renderNav(){
      return(
        <div className={styles.nav_header}>
          <NavLink 
            to={"/recruteur"} 
          >
            <IconLogo 
              className={styles.logo_header} 
            />
            <div className={styles.label_recruteur}>recrutement</div>
          </NavLink>
        </div>
      )
  }

  return(
    <nav className={`${styles.header} ${color && styles.color_header}`}>

      <div className={styles.background_header}></div>

      <div className={styles.container_header}>
        <div className={styles.nav_header}>
          <NavLink 
            to={"/recruteur"} 
          >
            <IconLogo 
              className={styles.logo_header} 
            />
            <div className={styles.label_recruteur}>recrutement</div>
          </NavLink>
        </div>

        {!isMobile && (
          <div className={styles.right_header}>
            {user ? (
              <div className={styles.header_profile}>
                <div className={styles.container_profile} onClick={() => toggleMenu(true)}>
                  <div className={`${styles.image_profile} ${styles.logo_cab}`}>
                    <img src={user.cab?.logo} className='image_contain'/>
                  </div>
                  <div className={styles.name_profile}>
                    {user.name || "Recruteur"}
                  </div>
                </div>
                {showMenu && (
                  <div className={styles.menu_profile} ref={ref}>
                    <div className={styles.nav_profile} onClick={() => toggleSettings(true)}>
                      <img src={require('@icon/header_settings.svg').default}/>
                      <div>Préférences</div>
                    </div>
                    <div className={styles.nav_profile} onClick={logOut}>
                      <img src={require('@icon/header_deco.svg').default}/>
                      <div>Déconnexion</div>
                    </div>
                  </div>
                )}
              </div>
            ):(
              <div className={styles.container_button}>
                <div className={styles.button} onClick={showConnect}>
                  Se connecter
                </div>
                <div className={`${styles.button} ${styles.button_full}`} onClick={showSubscribe}>
                  S'inscrire
                </div>
              </div>
            )}
          </div>
        )}
      </div>

    </nav>
  )
    
}

export default Header;
