import React, { useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import Field from '@utils/form/field/Field';
import ReactHtmlParser from 'react-html-parser';

import styles_field from '../field.module.css';
import styles from './options.module.css';

function Options(props) {

  const [value, updateValue] = useState(props.field.value);

  function selectOption(val){
    props.onChange({
      value:val === value ? null : val, 
      key:props.field.key, 
    })
    updateValue(val === value ? null : val)
  }

  return(
    <div className={`${styles_field.container_field} ${styles.container_field}`} style={props.field.style}>
      {props.renderLabelField && props.renderLabelField()}
      <div className={styles.containerOptions}>
        {props.field.options.map((item) => (
          <div className={`${styles.option} ${value === item.value ? `${styles.selectedOption}` : 'hover_color_font'}`} onClick={() => selectOption(item.value)}>{ReactHtmlParser(item.label)}</div>
        ))}
      </div>  
    </div>
  )
}

export default Options;