import React, { useState, useEffect, Stylesheet } from "react";
import ReactHtmlParser from "react-html-parser";

import Modal from "@utils/modal/Modal";
import Button from "@utils/button/Button";
import Scrollbars from "@utils/scrollbars/Scrollbars";

import BannerCab from "@recruteur/recrutement/bannercab/BannerCab";

import customFetch from "@utils/function/customFetch";

import Apply from "./apply/Apply";

import Progress from "./progress/Progress";

import styles from "./modaloffre.module.css";

var update = require("immutability-helper");

const ModalOffre = ({
  id_offre,
  id_match,
  id_cab,
  onClose,
  cv,
  onSuccessStepList,
  onSuccessApply,
  onMoveOffer,
  user,
  admin,
  extraData,
  endpoint,
  fetchUser,
  initialOffre,
  onModalOffreBlockOpen,
  showConnect,
  preview,
}) => {
  const [offre, updateOffre] = useState();
  const [showIntro, updateShowIntro] = useState(preview);

  useEffect(() => {
    customFetch({
      endpoint,
      onSuccess,
      type: "POST",
      data: { id_offre, id_cab, id_match, extraData, initialOffre },
    });
  }, []);

  function onSuccess({ response }) {
    updateOffre(response);
  }

  function onSuccessStep({ response }) {
    if (response.id_offre === offre.id) {
      let modifiedOffre = { ...offre };
      modifiedOffre.application = { ...modifiedOffre.application };
      modifiedOffre.application.step = response.step;
      updateOffre(modifiedOffre);
    }
    onSuccessStepList({ response });
  }

  if (!offre) {
    return null;
  }

  if (offre.application && !showIntro) {
    return (
      <Progress
        onClose={onClose}
        offre={offre}
        onSuccessStep={onSuccessStep}
        onMoveOffer={onMoveOffer}
        user={user}
        admin={admin}
        senior={!!id_match}
        updateShowIntro={updateShowIntro}
      />
    );
  }

  return (
    <Apply
      onClose={onClose}
      offre={offre}
      cv={cv}
      onSuccessApply={onSuccessApply}
      id_offre={id_offre}
      id_cab={id_cab}
      user={user}
      fetchUser={fetchUser}
      initialOffre={initialOffre}
      onModalOffreBlockOpen={onModalOffreBlockOpen}
      showIntro={showIntro}
      showConnect={showConnect}
      preview={preview}
    />
  );
};

export default ModalOffre;
