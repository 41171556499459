import React, { useState, useEffect } from 'react';

import JuniorsRecruitment from '@recruteur/recrutement/juniors/JuniorsRecruitment';

//import styles from './offres.module.css';


const Offres = ({
  cab
}) => {

  return(
      <JuniorsRecruitment
        cab={cab}
        type={'admin/cab'}
        extraData={{id_cab:cab.id}}
      />
  )
}

export default Offres;
