import React, { useEffect, useState, useRef } from "react";

import ReactHtmlParser from "react-html-parser";

import styles from "./itemmission.module.css";

const ItemMission = ({ mission }) => {
  const [showMission, updateShowMission] = useState(false);
  const [overflowMission, updateOverflowMission] = useState(false);
  const missionContainer = useRef(null);
  const missionHeaderContainer = useRef(null);
  const [height, setHeight] = useState();
  const [headerHeight, setHeaderHeight] = useState();

  useEffect(() => {
    if (missionContainer.current) {
      setHeight(missionContainer.current.scrollHeight);
    }
  }, [missionContainer]);

  useEffect(() => {
    if (missionHeaderContainer.current) {
      setHeaderHeight(missionHeaderContainer.current.scrollHeight);
    }
  }, [missionHeaderContainer]);

  function toggleShow() {
    updateShowMission(!showMission);
    updateOverflowMission(false);
    setTimeout(function () {
      updateOverflowMission(showMission);
    }, 1000);
  }

  return (
    <div
      ref={missionContainer}
      className={`${styles.item_mission} ${
        showMission && styles.show_mission
      } ${overflowMission && styles.overflow_mission}`}
      onClick={toggleShow}
      style={
        showMission
          ? { height: `${height}px` }
          : { height: `${headerHeight + 15}px` }
      }
    >
      <div
        className={styles.header_mission}
        ref={missionHeaderContainer}
        //style={{height:`${headerHeight}px`}}
      >
        <div className={styles.icon_mission}>
          <img src={mission.image} className="image_contain" />
        </div>
        <div className={styles.label_mission}>{mission.name}</div>
        <div className={styles.arrow_mission}>
          <img
            src={require("@icon/down_arrow_mission.svg").default}
            className="image_contain"
          />
        </div>
      </div>
      <div className={styles.text_mission}>{ReactHtmlParser(mission.text)}</div>
    </div>
  );
};

export default ItemMission;
