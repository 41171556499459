import React, { useEffect, useState } from 'react';

import Content from '../content/Content';

import ReactHtmlParser from 'react-html-parser';

import styles from './orga.module.css'

const Orga = ({
  cab
}) => {
  return(
    <div className={styles.container}>
      <div className={styles.text}>
        {ReactHtmlParser(cab.organisation)}
      </div>
      {cab.content.find(x => x.type === 4) && (
        <div className={styles.content}>
          <Content 
            type={4}
            content={cab.content.find(x => x.type === 4)}
            style={{ marginBottom:'4vh'}}
          />
          {cab.orga_profiles.map((item) => (
            <a 
              className={styles.linkedin}
              key={item.addlist.toString()}
              href={item.link}
              target="_blank"
            >
              <div className={styles.image}>
                <img src= {item.image} className='image_contain'/>
              </div>
              <div className={styles.details}>
                <div className={styles.icon}>
                  <img src={require('@icon/social_network/linkedin_logo.svg').default} className='image_contain'/>
                </div>
                <div className={styles.name}>{item.first_name} {item.last_name}</div>
                <div className={styles.label}>Contact clé</div>
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  )

};



export default Orga;
