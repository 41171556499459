import React, { useState, useEffect, Stylesheet } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import CardSenior from './CardSenior';
import List from '@utils/list/List';
import useListReducer from '@utils/function/useListReducer';
import Searchbar from '@utils/searchbar/Searchbar';

import {ReactComponent as IconInbox} from '@recruteur_icon/matching/inbox.svg';
import {ReactComponent as IconTrash} from '@recruteur_icon/matching/trash.svg';

import styles from './list_recruitment_seniors.module.css';

var update = require('immutability-helper');

const ListRecruitmentSenior = ({
  type, 
  updateMatch,
  list,
  updateList,
  navigateCV,
  updateShowCV,
  title
}) => {
  
  const [option, selectOption] = useState(0)
  const [search, onSearch] = useState()

  function getData(){
    return list.data.filter((item) => option && item.bin || !option && !item.bin)
  }

  function renderItem(data, index){
    return(
      <CardSenior
        type={type}
        item={data}
        option={option}
        showCV={(item) => updateShowCV({index, list:getData(), type, option})}
        index={index}
        updateMatch={updateMatch}
      />
    )
  }

  function onError(err){
  }

  function ListHeaderComponent(){
    return(
      <div className={styles.list_header_matching}>
        <div className={styles.header}>
          <div className={styles.index}>{type+1}</div>
          <div className={styles.title}>{title} ({getData().length})</div>
        </div>
        <div className={styles.searchbar}>
          <Searchbar 
            onSearch={onSearch}
            style={{margin:'1vh 0', flex:1}}
            search={search}
          />
          <div className={styles.container_options}>
            <div className={ `${styles.item_option} ${option === 0 && "active"} hover_color`} onClick={() => selectOption(0)}>
              <IconInbox width='3vh' height='3vh'/>
            </div>
            <div className={ `${styles.item_option} ${option === 1 && "active"} hover_color`} onClick={() => selectOption(1)}>
              <IconTrash width='3vh' height='3vh'/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={`${option && styles.list_trash} ${styles.container_list_matching}`}>
      <List 
        endpoint={"recruteur/matchs"}
        data={getData()}
        renderItem={renderItem}
        renderHeader={ListHeaderComponent}
        data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
        isLoading={list.isLoading}
        //renderEmpty={renderEmpty}
        onError={onError}
        extraData={{type}}
        socketKeys={"get_matchs_" + type}
        search={search}
      />
    </div>
  )
}

export default ListRecruitmentSenior

 