import React, { useEffect, useState } from "react";
import { isMobile, isMobileOnly } from "react-device-detect";

import customFetch from "@utils/function/customFetch";
import ListOfferJunior from "@user/listofferjunior/ListOfferJunior";
import BannerScreening from "@site/home/bannerscreening/BannerScreening";
import PreviewOffer from "@recruteur/recrutement/preview/PreviewOffer";

import styles from "./offers.module.css";

var date = require("@utils/function/date");

const SwitchOffers = ({ active, toggleSwitch }) => {
  function renderItemSwitch({ image, title, description, style, index }) {
    return (
      <div
        className={`hover_color_font ${styles.container_item_switch} ${
          active === index ? styles.active_switch : ""
        }`}
        style={style}
        onClick={() => toggleSwitch(index)}
      >
        <div className={styles.container_image_switch}>
          <img src={image} className="image_contain" />
        </div>
        <div className={styles.container_description_switch}>
          <div className={styles.title_switch}>{title}</div>
          {!isMobile && (
            <div className={styles.description_switch}>{description}</div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`card ${styles.tab_offers}`}>
      {renderItemSwitch({
        image: require("@icon/offer_junior.svg").default,
        title: "Toutes les offres",
        description:
          "CDI, Stages, Alternances... Consultez toutes les offres d’emploi du conseil",
        index: 0,
      })}
      {renderItemSwitch({
        image: require("@icon/offer_senior.svg").default,
        title: "Matching",
        description:
          "Nous vous aidons à trouver le cabinet qui vous correspond le mieux",
        index: 1,
        style: { flexDirection: "row-reverse" },
      })}
    </div>
  );
};

const Offers = ({
  openBuddyScan,
  user,
  fetchUser,
  location,
  showConnect,
  scrollToTop,
}) => {
  const [active, updateActive] = useState(0);
  const [cv, updateCV] = useState();
  const [preview, setPreviewOffer] = useState();
  const [showOffre, setShowOffre] = useState();

  useEffect(() => {
    customFetch({
      endpoint: "activity/activity",
      data: { token: localStorage.getItem("token"), id_activity: 3, type: 3 },
      type: "POST",
    });
  }, []);

  useEffect(() => {
    if (location.search) {
      const params = location.search.replace("?", "").split("&");
      for (let i = 0; i < params.length; i++) {
        const data = params[i].split("=");
        if (data[0] === "preview-offer") {
          customFetch({
            endpoint: "get_preview_offer",
            onSuccess: (data) => {
              if (data.response) {
                setPreviewOffer(data.response.id);
              }
            },
            type: "POST",
            data: { preview: data[1] },
          });
        } else if (data[0] === "offre") {
          setShowOffre(data[1]);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      customFetch({ endpoint: "user/profile", onSuccess, type: "GET" });
    }
  }, [user]);

  function onSuccess({ response }) {
    updateCV(response?.cv);
  }

  function renderContext() {
    if (user) return null;

    return null;
    /* return(
      <BannerContext
        image={require('@icon/bannercontext/score.svg').default}
        subtitle={isMobile ? [
          'Vous souhaitez obtenir les offres les plus adaptées à votre profil ? '
        ] : [
          'Vous trouverez ici toutes les offres de stages et de CDI (pour consultants juniors) sans personnalisation spécifique.',
          'Si vous souhaitez identifier les offres les plus adaptées à votre profil, nous vous invitons à passer le Buddy Scan ci-dessous ! Un score de match sera caluclé pour chaque offre : il reflètera l’adéquation du poste avec votre parcours et vos aspirations.'
        ]}
        renderFooter={() => (
          <div className={styles.banner_button}>
            <Button 
              type='full'
              label={isMobile ? 'Passez le Buddy Scan' : 'Trouvez les offres qui vous correspondent'}
              onClick={openBuddyScan}
            />
          </div>
        )}
      />
    ) */
  }

  return (
    <div className={styles.container_offres}>
      <SwitchOffers active={active} toggleSwitch={updateActive} />

      {active === 0 ? (
        <ListOfferJunior
          noScroll={true}
          endpoint={"user/get_offres"}
          endpointDetails={"user/offre"}
          user={user}
          fetchUser={fetchUser}
          renderContext={renderContext}
          profileUser={{ cv }}
          style={{ marginTop: isMobile ? 0 : "4vh" }}
          initialSearch={location?.state?.cab?.name}
          showConnect={showConnect}
          hasPagination={true}
          scrollToTop={scrollToTop}
          defaultShowFilters={!isMobileOnly}
          showOffre={showOffre}
        />
      ) : (
        <div className={styles.container_infos}>
          <div className="title">
            Vous avez plus de 18 mois d’expérience en CDI en conseil ?
          </div>
          <div className="description">
            En tant que consultant expérimenté, de très nombreuses opportunités
            vous sont ouvertes. Nous vous aidons à trouver celles qui vous
            correspondent le mieux parmi plus d’une centaine de cabinets
            partenaires !
          </div>
          <BannerScreening
            onClick={openBuddyScan}
            style={{ marginTop: "5vh" }}
            user={user}
          />
        </div>
      )}

      {preview && <PreviewOffer id_offer={preview} />}
    </div>
  );
};

export default Offers;
