import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import QuestionBuddyScan from './questionBuddyScan/QuestionBuddyScan';
import EditBuddyScan from './editBuddyScan/EditBuddyScan';
import Modal from '@utils/modal/Modal';
import Button from '@utils/button/Button';

import customFetch from '@utils/function/customFetch';

import styles from './buddyscan.module.css';

const UserBuddyScan = ({
  onClose,
  onSuccessSubscribe,
  senior
}) => {

  const history = useHistory();

  const [questionsBuddyScan, updateQuestionsBuddyScan] = useState()
  const [currentQuestion, updateCurrentQuestion] = useState(0)
  const [newAnswersBuddyScan, updateNewAnswersBuddyScan] = useState([])
  const [answersBuddyScan, updateAnswersBuddyScan] = useState([])
  const [isLoadingValidate, updateLoadingValidate] = useState()
  const [isChangingStatus, updateChangingStatus] = useState()

  useEffect(() => {
    customFetch({ endpoint: 'user/get_user_buddy_scan', onSuccess, type:'GET'}) 
  }, [])

  function onSuccess({ response }){
    updateAnswersBuddyScan(response.answers)
    updateQuestionsBuddyScan(response.questions)
  }

  function onValidate(answers, validate){
    var updatedAnswers = [...answersBuddyScan]
    let index = updatedAnswers.findIndex(x => x.id === questionsBuddyScan[currentQuestion].id)
    if(index !== -1){
      updatedAnswers[index] = {id:questionsBuddyScan[currentQuestion].id, answers}
    }else{
      updatedAnswers.push({id:questionsBuddyScan[currentQuestion].id, answers})
    }
    updateAnswersBuddyScan(updatedAnswers)
    if(validate){
      const seniorAnswer = updatedAnswers.find(x => x.id === 1)?.answers[0]
      if(senior && (seniorAnswer === 1 || seniorAnswer === 4) || !senior && (seniorAnswer === 2 || seniorAnswer === 3)){
        updateChangingStatus(true)
      }else{
        confirmUpdate(updatedAnswers)
      }
    }
  }

  function confirmUpdate(updatedAnswers){
    updateLoadingValidate(true)
    customFetch({ endpoint: 'subscribe_buddy_scan', onSuccess:onSuccessUpdate, data:updatedAnswers || answersBuddyScan}) 
    customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 1 } , type:'POST'}) 
  }

  function onSuccessUpdate({ response }){
    window.location.reload();
  }

  function renderContent({ userAnswers, updateUserAnswers, isLoading, updateIsLoading }){

    return(
      <QuestionBuddyScan 
        question={questionsBuddyScan[currentQuestion]}
        answers={answersBuddyScan.find(x => x.id === questionsBuddyScan[currentQuestion].id)?.answers}
        updateUserAnswers={updateUserAnswers}
        userAnswers={userAnswers}
        isLoading={isLoading}
        updateIsLoading={updateIsLoading}
        key={questionsBuddyScan[currentQuestion].id.toString()}
      />
    )
  }


  if(!questionsBuddyScan || !answersBuddyScan) return null

  return(
    <>
      <EditBuddyScan
        onClose={onClose}
        onValidate={onValidate}
        renderContent={renderContent}
        currentQuestion={currentQuestion}
        questions={questionsBuddyScan}
        answers={answersBuddyScan}
        updateCurrentQuestion={updateCurrentQuestion}
        isFirst={currentQuestion === 0}
        isLast={currentQuestion === questionsBuddyScan.length - 1}
      />
      {isLoadingValidate && (
        <Modal>
          <div className={`container ${styles.container_intro}`}>
            <div className={styles.intro_title}>
              Veuillez patienter quelques instants, votre profil est en cours de mise à jour...
            </div>
            <div className={styles.loading_image}>
              <img src={require('@icon/site/spinner.gif').default}/>
            </div>
          </div>
        </Modal>
      )}
      {isChangingStatus && (
        <Modal
          header='Changement de statut sur Consulting Buddies'
          closeModal={() => updateChangingStatus()}
          modalShrink={true}
        >
          <div className={`${styles.container_change}`}>
            <div className={styles.changing_text}>
              <div className={styles.changing_text_item}><span>Attention :</span> vous avez modifié dans le questionnaire votre niveau d’expérience dans le milieu du conseil.</div>
              <div className={styles.changing_text_item}><b>En confirmant votre changement de statut, vous abandonnez tout processus de recrutement en cours sur votre espace personnel.</b></div>
              <div className={styles.changing_text_item}>Si vous souhaitez poursuivre vos processus de recrutement en cours, cliquez sur annuler </div>
            </div>
            <div className={styles.changing_image}>
              <img src={require('@icon/illustration_warning.svg').default}/>
            </div>
          </div>
          <div className={styles.banner_button}>
            <Button
              label='Annuler'
              type='cancel'
              onClick={() => updateChangingStatus()}
              style={{marginRight:'1vw'}}
            />
            <Button
              label='Confirmer'
              type='full'
              onClick={() => confirmUpdate()}
            />
          </div>
        </Modal>
      )}
    </>
  )
};

export default UserBuddyScan

 
