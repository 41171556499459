import React from 'react';

import './lexique.css';

import { Scrollbars } from 'react-custom-scrollbars';

var update = require('immutability-helper');

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;


class Lexique extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      data:[],
      isLoading:true
    };


  }

  componentDidMount(){
    fetch(process.env.REACT_APP_ENDPOINT + '/lexique', {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    })
    .then((response) => response.json())
    .then((responseData) => {
      this.setState({
        data:responseData.data,
        isLoading:false
      })
      if(this.props.location && this.props.location.hash){
        scroller.scrollTo( this.props.location.hash, {
          duration: 150,
          smooth: true,
          containerId: 'container_recruteurs',
        })
      }
    })
    .catch((error) => {

         
     });

  }


  render(){
    return(
      <div id="outer_article">
        <div id="background_image"></div>

          <div id="body_recruteurs">
           <div className="header_lexique">
              <img className="background_recruteurs" src={require('@icon/lexique.png').default}/>
              <div className="overlay_recruteurs"></div>
              <div className="content_header_recruteurs">
                <img src={require('@icon/logo_min.svg').default}/>
                <div>Lexique du conseil</div>
              </div>
            </div>

            <div id="content_lexique">
              {this.state.data.map((item)=>{
                return(
                  <div className="category_lexique" key={item.letter}>
                    <div className="letter_lexique">{item.letter}</div>
                    {item.data.map((data)=>{
                      return(
                        <div className="item_lexique" id={"#" + data.name} key={data.id.toString()}>
                          <div className="word_lexique">{data.name}</div>
                          <div className="definition_lexique">{data.definition}</div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>

            
          </div>
      </div>
    )
    
  }
}

export default Lexique;
