import React, { useState, useEffect } from 'react';

import List from '@utils/list/List';
import useListReducer from '@utils/function/useListReducer';

import styles from './activity.module.css';

const Activity = ({
  id_user
}) => {

  const [state, updateData] = useListReducer()

  function renderItem(item){
    return(
      <div className={`${styles.container_activity} card`}>
        <div className={styles.date}>
          {item.date}
        </div>
        <div className={styles.label}>
          {item.label}
        </div>
      </div>
    )
  }

  return(
    <List 
      endpoint='activity/user_activity'
      data={state.data}
      renderItem={renderItem}
      data_received={(_data, refresh) => updateData(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
      isLoading={state.isLoading}
      socketKeys='admin/activity'
      extraData={{ id_user }}
    />
  )
}

export default Activity;
