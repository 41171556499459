import React, { useState } from 'react';

import List from '@utils/list/List';
import Searchbar from '@utils/searchbar/Searchbar';
import ActionsUser from '@admin/matching/users/actionsUser/ActionsUser';

import {ReactComponent as IconRefresh} from '@icon/admin/refresh.svg';
import {ReactComponent as IconScheduled} from '@icon/admin/scheduled.svg';
import {ReactComponent as IconCalled} from '@icon/admin/called.svg';
import {ReactComponent as IconInactive} from '@icon/admin/inactive.svg';

import customFetch from '@utils/function/customFetch';

import styles from './itemcab.module.css';

const ItemCab = ({
  cab,
  onUpdateCab,
  onClick
}) => {

  return(
    <div className={`${styles.container_cab} card hover_card`} onClick={onClick}>
      
      <div className={styles.infos_cab}>
          <div className={styles.image_cab}>
            <img className='image_contain' src={cab.logo}/>
          </div>
        <div className={styles.name_cab}>
          <div className={styles.main_info}>{cab.name}</div>
        </div>
      </div>

      <div className={styles.info}>
        {`${cab.suggestions} suggestion${cab.suggestions>1 ? 's':''}`}
      </div>

      <div className={styles.info}>
        {`${cab.waiting} en attente`}
      </div>

      <div className={styles.info}>
        {`${cab.matchs} match${cab.matchs>1 ? 's':''}`}
      </div>

    </div>
  )
}

export default ItemCab;
