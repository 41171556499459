import React, { useState, useEffect, Stylesheet, useReducer } from 'react';

import {Link} from "react-router-dom";

import List from '@utils/list/List';
import useListReducer from '@utils/function/useListReducer';
import Searchbar from '@utils/searchbar/Searchbar';

import Button from '@utils/button/Button';

import BuddyScore from '@utils/buddyscore/BuddyScore';
import BannerContext from '@utils/bannerContext/BannerContext';

import {ReactComponent as IconStar} from '@icon/star.svg';

import customFetch from '@utils/function/customFetch';

import styles from './listbuddyscore.module.css';

const Cab = ({
  cab
}) => {

  const [favori, updateFavori] = useState(cab.favori)

  function toggleFavori(e){
    e.stopPropagation()
    e.preventDefault()
    customFetch({ endpoint: 'user/cab_favori', onSuccess, data:{id_cab:cab.id}, type:'POST' })
  }

  function onSuccess(){
    updateFavori(1 - cab.favori)
  }

  return(
    <Link to={{pathname:'cabinet/' + cab.pathname, state:{id:cab.id}}} target="_blank"  className={`${styles.container_cab} card`} key={cab.id.toString()}>
      <div className={styles.container_icon}>
        <img src={cab.logo}/>
      </div>

      <div className={styles.details_cab}>
        <div className={styles.container_name}>
          {cab.name}
        </div>
        <div className={`${styles.container_name} ${styles.container_tag}`}>
          {(cab.tags || []).map((item, index) => {
            if(!item.id) return
            return(
              <span key={item.id.toString()}>{index>0 && ','} {item.label}</span>
            )})}
        </div>
      </div>

      <BuddyScore 
        score={cab.score}
        details={{buddy_scan:cab.buddy_scan, screening_test:cab.screening_test}}
        style={{alignSelf:'center'}}
      />
      <div className={`${styles.container_favori}`} onClick={toggleFavori}>
        <img src={!favori ? require('@icon/star.svg').default : require('@icon/star_full.svg').default}/>
      </div>

    </Link>
  )

}

const ListBuddyScore = ({
  user,
  onSuccessPreferences,
  showTest
}) => {
  const [state, updateData] = useListReducer()
  const [search, onSearch] = useState()

  function onError(err){
  }

  function renderContext(){
    if(user.senior){
      if(user.scheduled) return null 
      return(
        <BannerContext
          image={require('@icon/bannercontext/score.svg').default}
          subtitle={[
            'Bienvenue sur votre espace personnel !',
            'Bienvenue sur votre espace personnel ! Vous trouverez ci-dessous vos scores de matching avec l’ensemble des cabinets de conseil référencés sur Consulting Buddies.',
            'Si vous êtes à l’écoute d’opportunités, prenez rendez vous avec un de nos coach afin  de valider vos critères de recherche et démarrer la mise en relation avec les cabinets les plus intéressants pour vous.'
          ]}
          id_context={2}
          onSuccess={onSuccessPreferences}
          preferences={user.preferences}
          renderFooter={() => (
            <div className={styles.banner_button}>
              <Button 
                type='full'
                label='Prendre rendez-vous'
                onClick={() => window.location.href = '/candidat/coaching'}
              />
            </div>
          )}
          endpoint='user/update_preferences'
          style={{margin:'1vh'}}
        />
      )
    }else{
      if(!user.buddy_scan){
        return(
          <BannerContext
            image={require('@icon/bannercontext/score.svg').default}
            subtitle={[
              'Félicitations ! Vous avez créé avec succès votre espace candidat.',
              'Vous souhaitez découvrir les cabinets qui correspondent le mieux à votre profil et vos aspirations ?  Répondez à quelques questions et obtenez vos scores de match pour tous les cabinets de conseil référencés sur Consulting Buddies !'
            ]}
            renderFooter={() => (
              <div className={styles.banner_button}>
                <Button 
                  type='full'
                  label='Trouvez le cabinet qui vous correspond'
                  onClick={showTest}
                />
              </div>
            )}
            style={{margin:'1vh'}}
          />
        )
      }else{
        return(
          <BannerContext
            image={require('@icon/bannercontext/score.svg').default}
            subtitle={['Vous trouverez ci-dessous vos scores de matching avec l’ensemble des cabinets de conseil référencés sur Consulting Buddies : ']}
            id_context={0}
            onSuccess={onSuccessPreferences}
            preferences={user.preferences}
            endpoint='user/update_preferences'
            style={{margin:'1vh'}}
          />
        )
      }
    }
  }

  function ListHeaderComponent(){
    return(
      <>
        <Searchbar 
          onSearch={onSearch}
          search={search}
        />
        {renderContext()}
      </>
    )
  }

  function renderFooter(){
    return(
      <div className={styles.banner_button}>
        <Button 
          type='full'
          label='Prendre rendez-vous'
          onClick={() => window.location.href = '/candidat/coaching'}
        />
      </div>
    )
  }

  return(
    <List 
      endpoint={"user/get_buddy_score"}
      data={state.data}
      renderItem={(item => (<Cab cab={item} key={item.id.toString()} />))}
      renderHeader={ListHeaderComponent}
      data_received={(_data, refresh) => updateData(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
      isLoading={state.isLoading}
      onError={onError}
      socketKeys={"get_buddy_score"}
      search={search}
      noScroll={true}
      contentListStyle={{width:'100%'}}
    />
  )
}

export default ListBuddyScore

 