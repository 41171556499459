import React, { useState, useEffect } from 'react';

import customFetch from '@utils/function/customFetch';

import Form from '@utils/form/Form';
import Modal from '@utils/modal/Modal';

import styles from './modifymatch.module.css';

const ModifyMatch = ({
  onClose,
  match,
  id_user,
  onSuccess
}) => {

  return(
    <Modal
      closeModal={onClose}
      header='Associer un cabinet'
    >
      <Form 
        fields={[
          {type:'select', key:'id_cab', title:'Choisir un cabinet de conseil', placeholder:'Choisir un cabinet à associer', value:match.cab, disabled:!!match.cab, socket:"admin/cabs_match", extraData:{id_user} },
          {type:'texteditor', key:'comment', title:'Commentaire à propos du candidat', extraData:{min:true}, value:match.comment, disabled:!!match.cab}
        ]}
        buttons={!match?.id ? {
          action:{label:'Enregistrer', type:'full', field:[{key:'id_user', value:id_user}]},
          sub_action:{label:'Annuler', onPress:onClose}
        } : {
          action:{label:'Périmer', type:'warn', field:[{key:'id', value:match.id}, {key:'outdated', value:1}]},
          sub_action:{label:'Supprimer', type:'delete', field:[{key:'id', value:match.id}, {key:'deleted', value:1}]}
        }}
        endpoint={match.id ? 'admin/update_match' : 'admin/create_match'}
        success={onSuccess}
      />
    </Modal>
  )
}

export default ModifyMatch;
