import ReactPaginate from "react-paginate";
import { isMobileOnly } from "react-device-detect";

import styles from "./pagination.module.css";

const Pagination = ({ listState, itemOffset, setItemOffset }) => {
  const itemsPerPage = 20;
  const totalItems =
    listState.data[0].value.length +
    listState.data[1].value.length +
    listState.data[2].value.length;

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalItems;
    setItemOffset(newOffset);
  };
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={handlePageClick}
      pageRangeDisplayed={isMobileOnly ? 3 : 7}
      pageCount={pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
      className={styles.containerPagination}
      activeClassName={styles.active}
      marginPagesDisplayed={1}
    />
  );
};

export default Pagination;
