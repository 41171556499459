import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect, Switch, useHistory, withRouter } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import ListCabs from './listcabs/ListCabs';
import TypeCabs from './typecabs/TypeCabs';

import {ReactComponent as IconCab} from '@icon/site/discoverCabs/cab.svg';
import {ReactComponent as IconType} from '@icon/site/discoverCabs/type.svg';

import Tab from '@utils/tab/Tab';

import customFetch from '@utils/function/customFetch';

import styles from './discovercabs.module.css';

const DiscoverCabs = ({

}) => {

  useEffect(() => {
    customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 3, type : 1 } , type:'POST'}) 
  }, [])

  return(
    <div className={styles.container_list_cabs}>

      <Tab 
        tabs={[
          {'pathname':'/cabinets', icon:<IconCab/>, name:isMobile ? 'Les cabinets référencés' : 'Les cabinets de conseil référencés'},
          {'pathname':'/cabinets/type_cabinets', icon:<IconType/>, name:isMobile ? 'Les types de cabinets' : 'Les types de cabinets de conseil'}
        ]}
        isSticky={true}
        style={{marginLeft:0, marginRight:0}}
      />

      <Switch>
        <Route 
          path={['/cabinets', '/cabinets/']}
          exact
          render={() => <ListCabs />}
        />
        <Route 
          path={['/cabinets/type_cabinets']}
          exact
          render={() => <TypeCabs />}
        />
        </Switch>
    </div>
  )

}

export default DiscoverCabs;
