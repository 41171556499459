import React, { useState, useEffect, Stylesheet } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import {
    BrowserRouter as Router,
    useParams
  } from "react-router-dom";

import Field from '@utils/form/field/Field';
import Button from '@utils/button/Button';
import Delete from '@utils/delete/Delete';

import customFetch from '@utils/function/customFetch';

import styles from './addcv.module.css';

const AddCV = ({
    cv,
    getCV,
    deleteCV,
    disabled,
    label,
    isLoading,
    styleButton,
    style,
    fromHeader
}) => {

  function renderOption(){
    if(disabled || isLoading) return null 
    if(cv){
      return(
        <Delete 
          absolute={!isMobile || !fromHeader}
          onDelete={deleteCV}
        />
      )
    }else{
      return(
        <input 
          className={styles.input_cv}
          type="file" 
          onChange={getCV}
          accept={"application/pdf"}
        />
      )
    }
  }
  
  return(
    <div className={styles.container_button_cv} style={style}>
      <Button
        label={!isLoading && label}
        style={
          styleButton ? styleButton : 
            cv ? {flex:1} : 
            {flex:1, borderStyle:'dotted'}
        }
        icon={
          isLoading ? require('@icon/site/spinner.gif').default : 
            cv ? require('@icon/profile/pdf.svg').default : require('@icon/profile/add.svg').default}
        onClick={() => {
          if(!cv || isLoading) return
          window.open(cv, '_blank').focus();
        }}
      />
      {renderOption()}
    </div>
  )
}

export default AddCV

 