import React, { useState, useEffect, Stylesheet } from 'react';

import Button from '@utils/button/Button';
import Modal from '@utils/modal/Modal';

import customFetch from '@utils/function/customFetch';
import styles from './modalconfirm.module.css';

const ModalConfirm = ({
  header,
  closeModal,
  labelConfirm = 'Accepter',
  typeConfirm = 'full',
  onConfirm,
  content
}) => {
  return(
    <Modal
      header={header}
      closeModal={closeModal}
      modalShrink={true}
    >
      <div className={styles.content}>
        {content}
      </div>
      <div className={styles.banner_button}>
        <Button 
          label='Annuler'
          type='cancel'
          style={{marginRight:'1vw'}}
          onClick={closeModal}
        />
        <Button 
          label={labelConfirm}
          type={typeConfirm}
          onClick={onConfirm}
        />
      </div>

    </Modal>
  )
}

export default ModalConfirm