import React, { useState, useEffect } from 'react';

import Comments from '@utils/comments/Comments';

const CabComments = ({
  id_cab
}) => {

  return(
    <Comments
      id_cab={id_cab}
    />
  )
}

export default CabComments;
