import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import styles from '../apply.module.css';


function Intro({ 
  offre,
  presentation
}){
  return(
    <div className={styles.content_offre}>
      <div className={styles.container_section}>
        <div className={styles.container_title}>
          <div className={styles.container_icon}>
            <img src={require('@user/listofferjunior/modaloffre/icon/infos_clefs.svg').default}/>
          </div>
          <div className={styles.label_title}>Infos clefs</div>
        </div>
        <div className={styles.content}>
          {ReactHtmlParser(presentation || 'Aucune information disponible')}
        </div>
      </div>

      {offre && (
        <div className={styles.container_section}>
          <div className={styles.container_title}>
            <div className={styles.container_icon}>
              <img src={require('@user/listofferjunior/modaloffre/icon/description.svg').default}/>
            </div>
            <div className={styles.label_title}>Descriptif du poste</div>
          </div>
          <div className={styles.content}>
            {ReactHtmlParser(offre.description)}
          </div>
        </div>
      )}

      {offre && offre.description_profile && (
        <div className={styles.container_section}>
            <div className={styles.container_title}>
              <div className={styles.container_icon}>
                <img src={require('@user/listofferjunior/modaloffre/icon/profile.svg').default}/>
              </div>
              <div className={styles.label_title}>Profil recherché</div>
            </div>
            <div className={styles.content}>
              {ReactHtmlParser(offre.description_profile)}
            </div>
          </div>
      )}
    </div>
  )
}

export default Intro