import React, { useState, useEffect, Stylesheet } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Scrollbars from '@utils/scrollbars/Scrollbars';

import ActionCV from '../actioncv/ActionCV';

import Modal from '@utils/modal/Modal';

import styles from './seniorcv.module.css';

const SeniorCV = ({
  closeCV,
  item,
  showCV,
  type,
  cab,
  user,
  option,
  totalCV,
  navigateCV,
  indexCV,
  updateMatch
}) => {

  const [zoom, updateZoom] = useState(1.5)
  const [isLoadingPdf, updateLoadingPdf] = useState(true)
  const [pageCV, updatePageCV] = useState()

  function setZoom(value){
    if(value){
      updateZoom(zoom === 2.5 || zoom === 0.5 ? zoom : zoom + value)
    }else{
      updateZoom(1.5)
    }
  }

  function onDocumentLoadSuccess(data){
    updatePageCV(data._pdfInfo.numPages)
    updateLoadingPdf(false)
    /*if(this.props.type === 2 && !this.state.data[this.state.index_cv].cv_seen || this.props.type !== 2 && !this.state.data[this.state.index_cv].cv_anonymous_seen){
      global.socket.emit("view_cv", {type:this.props.type, id_match:this.state.data[this.state.index_cv].id})
    }*/
  }

  function renderLoadingPdf(){
    return(
        <div className={styles.container_pages_pdf} style={{height:window.innerHeight*0.85, width:window.innerHeight*0.85*21/29.7, alignItems:'center', justifyContent:'center'}}>
          <img src={require('@f_icon/select/loading.svg').default} style={{height:'8vh'}}/>
        </div>
      )
  }

  function renderPdf(){
    if(isLoadingPdf){
      return(
        <div className={styles.container_pages_pdf} style={{height:window.innerHeight*0.85, width:window.innerHeight*0.85*21/29.7, alignItems:'center', justifyContent:'center'}}>
          <img src={require('@f_icon/select/loading.svg').default} style={{height:'8vh'}}/>
        </div>
      )
    }else{
      let arr = []
      for(let i=0; i<pageCV;i++){
        arr.push(i)
      }
      return(
         <Scrollbars 
            autoHide
            renderView={props => <div {...props} className="view"/>}
            renderTrackHorizontal={props => <div {...props} className="horizontal_track"/>}
            renderThumbHorizontal={props => <div {...props} className="horizontal_thumb"/>}
            className={styles.container_pages_pdf}
          >
          {arr.map((item) =>{
            return(
              <Page
                pageIndex={item}
                height={window.innerHeight*0.85}
                scale={zoom}
              />
            )
          })}
        </Scrollbars>
      )
    }
  }
  
  function download(url, filename) {
    fetch(url).then(function(t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      });
    });
  }
 
  return(
    <Modal 
      closeModal={closeCV}
      simpleModal
      style={{height:'100%', borderRadius:0}}
    >

      <div className="container">
        <div className={styles.header_cv}>
          <div className={styles.intro_cv}>
            <img src={require('@recruteur_icon/matching/pdf.svg').default}/>
            <div className={styles.label_intro_cv}>{type === 2 ? "CV de " + item.user.name : "CV anonymisé #" + item.user.id}</div>
            <ActionCV
              type={type}
              user={user}
              option={option}
              id_match={item.id}
              updateMatch={updateMatch}
              eval_cab={item.eval_cab}
            />
          </div>
          <div className="options_cv">
            <div className={`${styles.item_option_cv} hover`}>
              <div onClick={() => download(type === 2 ? item.user.cv : item.user.cv_anonymous, type === 2 ? "cv.pdf" : "cv_anonyme.pdf")}>
                <img className='image_contain' src={require('@recruteur_icon/matching/download.svg').default}/>
              </div>
            </div>
            <div className={`${styles.item_option_cv} ${styles.close_cv} hover`} onClick={closeCV}>
              <img src={require('@recruteur_icon/matching/close.svg').default}/>
            </div>
          </div>
        </div>
        <div className={styles.container_cv}>
          {indexCV>0 ? (
            <div className={`${styles.navigate_cv} ${styles.navigate_cv_left} hover_background_color`} onClick={() => navigateCV(-1)}>
              <img src={require('@recruteur_icon/matching/prev.svg').default}/>
            </div>
          ):(null)}
          <Document
            file={type === 2 ? item.user.cv : item.user.cv_anonymous}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={renderLoadingPdf}
          >
            {renderPdf()}
          </Document>
          {indexCV<totalCV-1 ? (
            <div className={`${styles.navigate_cv} ${styles.navigate_cv_right} hover_background_color`} onClick={() => navigateCV(1)}>
              <img src={require('@recruteur_icon/matching/next.svg').default}/>
            </div>
          ):(null)}

          <div className={styles.container_zoom}>
            <div className={`${styles.container_item_zoom} hover`} onClick={() => setZoom(-0.25)}><img src={require('@recruteur_icon/matching/zoom_out.svg').default}/></div>
            <div className={`${styles.container_item_zoom} hover`} onClick={() => setZoom(null)}> <img src={require('@recruteur_icon/matching/zoom_default.svg').default}/></div>
            <div className={`${styles.container_item_zoom} hover`} onClick={() => setZoom(0.25)}><img src={require('@recruteur_icon/matching/zoom_in.svg').default}/></div>
          </div>
          
          {/*}
          <div className={styles.cv_buddy_score}>
            <img src={require('@icon/logo_min.svg').default}/>
            <div className={styles.label_cv_buddy_score}>notre score</div>
            <div className={styles.score_cv_buddy_score}>{item.buddy_score}%</div>
          </div>
          */}

        </div>

      </div>

    </Modal>
  )
}

export default SeniorCV

 