import React, { useEffect, useState } from 'react';

import Scrollbars from '@utils/scrollbars/Scrollbars'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import styles from './insights.module.css'

const ITEMS = [
  {type:1, logo:require('@icon/site/cab/insights/card.svg').default, label:'%data%€ chiffre d\'affaire France'},
  {type:2, logo:require('@icon/site/cab/insights/card.svg').default, label:'%data%€ chiffre d\'affaire monde'},
  {type:6, logo:require('@icon/site/cab/insights/people.svg').default, label:'%data% employés monde'},
  {type:5, logo:require('@icon/site/cab/insights/people.svg').default, label:'%data% employés France'},
  {type:3, logo:require('@icon/site/cab/insights/consultants.svg').default, label:'%data% consultants France'},
  {type:4, logo:require('@icon/site/cab/insights/consultants.svg').default, label:'%data% consultants monde'},
  {type:7, logo:require('@icon/site/cab/insights/group.svg').default, label:`%data% ans d'âge moyen`},
  {type:8, logo:require('@icon/site/cab/insights/gender.svg').default, label:'%data%% de femmes et %extraData%% d\'hommes'},
  {type:9, logo:require('@icon/site/cab/insights/location.svg').default, label:'%data% bureaux'},
  {type:10, logo:require('@icon/site/cab/insights/countries.svg').default, label:'%data% pays'},
  {type:11, logo:require('@icon/site/cab/insights/date.svg').default, label:'Créé en %data%'}
]

const ItemInsights = ({
  item
}) => {

  const itemDetails = ITEMS.find(x => x.type === item.type)

  return(
    <div className={styles.insights}>
      <div className={styles.logo_insights}>
        <img className='image_contain' src={itemDetails.logo}/>
      </div>
      <div className={styles.label_insights}>
        {itemDetails.label.replace('%data%', item.value).replace('%extraData%', 100 - item.value)}
      </div>
    </div>
  )

};

const Insights = ({
  cab
}) => {
  const [step, updateStep] = useState(0);
  return(
    <>
      <BrowserView style={{width:'100%'}}>
        <div className={styles.banner_insights}>
          
            {step === 0 ? (
              <div className={styles.annex}>
                <div className={styles.cb_insights}>
                  <div className={styles.logo_cb_insights}>
                    <img className='image_contain' src={require('@icon/cb_white.svg').default}/>
                  </div>
                  <div>insights</div>
                </div> 
              </div>
            ) : (
              <div className={styles.annex} onClick={() => updateStep(step-1)}>
                <img src={require('@icon/back.svg').default}/>
              </div>
            )
          }

          <div className={styles.container_insights}>
            {cab.cab_data.slice(4*step, 4*(step + 1)).map((item) => {
              return(
                <ItemInsights 
                  item={item}
                />
              )
            })}
          </div>
          
          {cab.cab_data.length>4*(step + 1) && (
            <div className={styles.annexNext}>
              <img src={require('@icon/arrow_color_right.svg').default} onClick={() => updateStep(step+1)}/>
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div className={styles.banner_insights}>
          <Scrollbars
            autoHeight
            style={{height:'12vh'}}
            innerStyle={{flexDirection:'row', alignItems:'center'}}
          >
            <div className={styles.cb_insights}>
              <div className={styles.logo_cb_insights}>
                <img className='image_contain' src={require('@icon/cb_white.svg').default}/>
              </div>
              <div>insights</div>
            </div>
            <div className={styles.container_insights}>
              {cab.cab_data.map((item) => {
                return(
                  <ItemInsights 
                    item={item}
                  />
                )
              })}
            </div>
          </Scrollbars>
        </div>
      </MobileView>
    </>
  )

};



export default Insights;
