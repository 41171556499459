import React, { useState, useEffect } from 'react';

import Field from '@utils/form/field/Field';
import List from '@utils/list/List';
import Modal from '@utils/modal/Modal';
import Form from '@utils/form/Form';
import useListReducer from '@utils/function/useListReducer';
import Searchbar from '@utils/searchbar/Searchbar';
import SelectForm from '@utils/form/selectForm/SelectForm';

import customFetch from '@utils/function/customFetch';

import {ReactComponent as IconEdit} from '@admin/icon/edit.svg';
import {ReactComponent as IconDelete} from '@admin/icon/delete.svg';
import {ReactComponent as IconClose} from '@icon/site/close.svg';

import styles from './listes.module.css';

const Listes = ({
    
}) => {

  const [deleteItem, setDeleteItem] = useState(false)
  const [updateItem, setUpdateItem] = useState(false)
  const [selectedList, updateSelectedList] = useState()
  const [formList, getFormListes] = useState(null)
  const [state, updateData] = useListReducer()
  const [search, onSearch] = useState(null)

  useEffect(() => {
    if(updateItem){
      customFetch({ endpoint: 'admin/get_form_listes', data:{list:selectedList.id, id:updateItem?.id}, onSuccess: (data) => getFormListes(data.response)}) 
    }else{
      getFormListes(null)
    }
  }, [updateItem?.id])

  function renderItem(item){
    return(
      <div className={`${styles.item}`} key={item.id.toString()}>
        {item.image && (
          <div className="round_image">
            <img src={item.image}/>
          </div>
        )}
        <div className={styles.label_item}>{item.label}</div>
        <div className={`${styles.container_action} hover_color`} onClick={() => setUpdateItem(item)}>
          <IconEdit/>
        </div>
        <div className={`${styles.container_action} hover_red`} onClick={() => setDeleteItem(item)}>
          <IconDelete/>
        </div>
      </div>
    )
  }

  function renderHeader(){
    return(
      <Searchbar 
        button={{label:'Ajouter un élément', onClick:() => setUpdateItem({id:0})}}
        onSearch={onSearch}
        search={search}
      />
    )
  }

  function onSuccess(data){
    updateData({type:updateItem.id ? 'update_item' : 'create_item', item:data.response})
    setUpdateItem(null)
  }

  function onDelete(id){
    updateData({type:'delete_item', item:{id}})
    setDeleteItem(null)
  }

  function renderContent(){
    if(!selectedList) return
    return(
      <div className="container">
        <List 
          endpoint="get_item"
          data={state.data}
          renderItem={renderItem}
          renderHeader={renderHeader}
          data_received={(_data, refresh) => updateData(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
          isLoading={state.isLoading}
          extraData={{table: selectedList.table}}
          socketKeys={"listes"}
          search={search}
        />
        {updateItem && formList && (
          <Modal 
            closeModal={() => setUpdateItem(null)}
            modalShrink
          >
            <div className={styles.container_form}>
              <div className={styles.container_header}>
                <div className={styles.container_label_header}>{!updateItem.id ? "Créer un élément" : "Modifier un élément"}</div>
                <div className={`${styles.container_action} hover_grey`} onClick={() =>  setUpdateItem(null)}>
                  <IconClose/>
                </div>
              </div>
              <Form
                fields={formList}
                buttons={{action:{label:'Enregistrer', type:'full', field:[{key:'list', value:selectedList.id}]}, sub_action:{label:'Annuler', type:'cancel', onPress:() => setUpdateItem(null)}}} 
                autoHeight
                endpoint="admin/update_item_listes"
                key={updateItem.id}
                success={onSuccess}
                id={updateItem.id}
              />
            </div>
          </Modal>
        )}
        {deleteItem && (
          <Modal 
            closeModal={() => setUpdateItem(null)}
            modalShrink
          >
            <div className={styles.container_form}>
              <div className={styles.container_header}>
                <div className={styles.container_label_header}>Supprimer un élément</div>
                <div className={styles.container_action} onClick={() =>  setDeleteItem(null)}>
                  <img src={require('@admin/icon/close.svg').default}/>
                </div>
              </div>
              <Form
                fields={[]}
                buttons={{action:{label:'Supprimer', type:'delete', field:[{key:'list', value:selectedList.id}, {key:'deleted', value:1}]}, sub_action:{label:'Annuler', type:'cancel', onPress:() => setDeleteItem(null)}}} 
                autoHeight
                endpoint="admin/update_item_listes"
                key={deleteItem.id}
                success={() => onDelete(deleteItem.id)}
                id={deleteItem.id}
              />
            </div>
          </Modal>
        )}
      </div>
    )
  }

  return(
    <SelectForm 
      endpointList="admin/get_listes"
      renderContent={renderContent}
      onSelect={updateSelectedList}
    />
  )
}

export default Listes;
