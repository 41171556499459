import React, { useState } from 'react';

import List from '@utils/list/List';
import Searchbar from '@utils/searchbar/Searchbar';
import Modal from '@utils/modal/Modal';
import Form from '@utils/form/Form';
import DrawerUser from './drawerUser/DrawerUser';
import customFetch from '@utils/function/customFetch';

import ItemUser from './itemUser/ItemUser';

import {ReactComponent as IconFilter} from '@icon/admin/filter.svg';

import useListReducer from '@utils/function/useListReducer';

import styles from './users.module.css';

const Users = ({
  
}) => {
  
  const [listState, updateList] = useListReducer()
  const [search, onSearch] = useState()
  const [showCreateUser, setShowCreateUser] = useState()
  const [filters, onChangeFilters] = useState({})
  const [showFilters, onShowFilters] = useState(false)
  const [sort, onChangeSort] = useState(0)
  const [selectedUser, selectUser] = useState()

  function renderItem(item, index){
    return(
      <ItemUser
        user={item}
        onUpdateUser={onUpdateUser}
        sort={sort}
        onClick={() => selectUser(item)}
        key={item.id.toString()}
      />
    )
  }

  function onUpdateUser({ response }){
    updateList({ type: 'update_item', item:response})
    if(selectedUser) selectUser(response)
  }


  function onDeleteUser({ response }){
    updateList({ type: 'delete_item', item:response})
    selectUser(null)
  }

  function renderHeader(){
    return(
      <Searchbar 
        onSearch={onSearch}
        placeholder="Rechercher un utilisateur"
        renderIconSearch={renderIconSearch}
        renderExtraBar={renderExtraBar}
        onSelectFilter={onSelectFilter}
        selectedFilters={filters}
        resetFilters={() => onChangeFilters({})}
        search={search}
      />
    )
  }
  
  function renderExtraBar(){
    return(
      <div className={styles.extraBar}>
        <div className={`hover_color ${styles.extraBarOption} ${sort === 0 && styles.active}`} onClick={() => onChangeSort(0)}>Inscription</div>
        <div className={`hover_color ${styles.extraBarOption} ${sort === 1 && styles.active}`} onClick={() => onChangeSort(1)}>Connexion</div>
        <div className={`hover_color ${styles.extraBarOption} ${sort === 2 && styles.active}`} onClick={() => onChangeSort(2)}>Mise à jour</div>
        <div className={`hover_color ${styles.extraBarOption} ${sort === 2 && styles.active}`} onClick={() => setShowCreateUser(true)}>Créer un utilisateur</div>
      </div>
    )
  }

  function renderIconSearch(){
    let bol
    for(var key in filters){
      if( (Array.isArray(filters[key]) && filters[key].length>0) || (!Array.isArray(filters[key]) && filters[key] !== null)){
        bol = true;
        break;
      }
    }
    return(
      <div className={`hover_background_color ${styles.icon_filter} ${bol && styles.active}`} onClick={() => onShowFilters(true)}>
        <IconFilter className="image_contain"/>
      </div>
    )
  }

  function onSelectFilter({ value, key }){
    let updatedFilters = {...filters}
    updatedFilters[key] = value 
    onChangeFilters(updatedFilters)
  }

  function clearFilters(){
    onShowFilters(false)
    onChangeFilters({})
  }

  function onValidateForm(data){
    let updatedFilters = {...filters}
    for(var key in data) updatedFilters[key] = data[key] 
    onShowFilters(false)
    onChangeFilters(updatedFilters)
  }

  function onValidateFormCreateUser(data){
    customFetch({ endpoint: 'admin/create_user', data, onSuccess:({ response }) => {
      updateList({ type: 'create_item', item:response})
      setShowCreateUser(false)
    }}) 
  }

  return(
    <div className={styles.container_list_users}>
      <List 
        endpoint={"admin/users"}
        data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
        isLoading={listState.isLoading}
        data={listState.data}
        renderItem={renderItem}
        renderHeader={renderHeader}
        search={search}
        onError={() => {}}
        extraData={{sort}}
        filters={filters}
      />
      {showFilters && (
        <Modal
          closeModal={() => onShowFilters(false)}
          header='Utilsateurs > Filtres'
        >
          <Form 
            fields={[
              {type:"select", key:"schools", title:'Ecoles', multiple:true, socket:"get_item", extraData:{table:'List_Schools'}, value:filters.schools},
              {type:"select", key:"profiles", title:'Métiers', multiple:true, socket:"get_item", extraData:{table:'List_Profiles'}, value:filters.profiles},
              {type:"select", key:"contract", title:'Contrat recherché', multiple:true, socket:"get_item", extraData:{table:'List_Contract'}, value:filters.contract},
              //{type:"options", key:"exp_cab", title:'Au moins une expérience de plus de 18 mois dans le conseil', options:[{label:'Non', value:0}, {label:'Oui', value:1}] , value:filters.exp_cab},
              {type:"options", key:"called", title:'Appel téléphonique', options:[{label:'Non', value:0}, {label:'Oui', value:1}], value:filters.called},
              {type:"options", key:"available", title:'Statut de recrutement', options:[{label:'Indisponible', value:0}, {label:'Disponible', value:1}], value:filters.available},
              {type:"options", key:"senior", title:'Statut consultant', options:[{label:'Junior', value:0}, {label:'Senior', value:1}], value:filters.senior},
              {type:"options", key:"bookmarked", title:'Bookmarked', options:[{label:'Non', value:0}, {label:'Oui', value:1}], value:filters.bookmarked},
            ]}
            buttons={{
              action:{label:'Enregistrer', type:'full'},
              sub_action:{label:'Réinitialiser', type:'cancel', onPress:clearFilters},
            }}
            onValidateForm={onValidateForm}
          />
        </Modal>
      )}
      {selectedUser && (
        <DrawerUser 
          closeDrawer={() => selectUser()}
          user={selectedUser}
          onUpdateUser={onUpdateUser}
          onDeleteUser={onDeleteUser}
        />
      )}
       {showCreateUser && (
        <Modal
          closeModal={() => setShowCreateUser(false)}
          header='Créer un utilisateur'
        >
          <Form 
            fields={[
              {type:"input", key:"prenom", title:'Prénom'},
              {type:"input", key:"nom", title:'Nom'},
              {type:"switch", key:"senior", title:'Senior'},
            ]}
            buttons={{
              action:{label:'Enregistrer', type:'full'},
            }}
            onValidateForm={onValidateFormCreateUser}
          />
        </Modal>
      )}
    </div>
  )
}

export default Users;
