import React, { Component } from 'react';

import BuddyScore from '@utils/buddyscore/BuddyScore';
import ActionCV from './actioncv/ActionCV';

import {ReactComponent as IconCheck} from '@recruteur/icon/matching/check.svg';

import ReactHtmlParser from 'react-html-parser';


import styles from './card_senior.module.css'

var moment = require('moment');
var update = require('immutability-helper');

const CardSenior = ({
  type,
  option,
  item,
  showCV,
  updateMatch
}) => {

  function renderList(img, data){
    if(!data || data.length === 0) return null
    return(
      <div className={styles.item_card_matching}>
        <div className={styles.icon_card_matching}>
          <img src={img}/>
        </div>
        <div className={styles.container_label_card_matching}>
          <span>
            {data.map((item, index) =>{
              return(
                <span key={item.id.toString()}>{`${index ? ' ; ' : ''}${item.label}`}</span>
              )
            })}
          </span>
        </div>
      </div>
    )
  }

  return(
    <div className={ `${styles.card_matching} card ${type === 1 && styles.card_waiting} hover_card`} onClick={showCV}>
      <div className={styles.header_card_matching}>
        <div className={styles.contrat_card_matching}>CDI Senior</div>
        <div className={styles.contrat_date_matching}>{item.date}</div>
      </div>
      
      <div className={styles.user_card_matching}>
        <div className={ `${styles.img_card_matching} ${type !== 2 && styles.img_card_matching_blur}` }>
          {item.user.image ? (
            <img src={item.user.image}/>
          ):(
            <img src={require('@icon/recruteurs/matching/avatar.svg').default}/>
          )}
        </div>

        <div className={styles.container_name}>
          <div className={styles.name_card_matching}><span>{type === 2 ? item.user.name : 'Candidat #' + item.user.id}</span></div>
          <BuddyScore
            score={item.buddy_score}
            style={{display:'none'}}
          />
        </div>
      </div>

      <div className={styles.details_card_matching}>
        {renderList(require('@icon/recruteurs/matching/profile.svg').default, item.user.profiles)}
        {renderList(require('@icon/recruteurs/matching/school.svg').default, item.user.schools)}
        {item.comment && (
          <div className={styles.item_card_matching}>
            <img src={require('@icon/recruteurs/matching/comment.svg').default}/>
            <div className={styles.container_label_card_matching}>
              <span>{ReactHtmlParser(item.comment)}</span>
            </div>
          </div>
        )}
      </div>
      
      <ActionCV
        type={type}
        option={option}
        id_match={item.id}
        updateMatch={updateMatch}
        eval_cab={item.eval_cab}
        answer_user={item.answer_user}
      />

    </div>
  )

}

export default CardSenior

/*

class CardSenior extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      check:false,
      pop_up:null
    };

    this.updateMatch = this.updateMatch.bind(this)
    this.closePopUp = this.closePopUp.bind(this)
    this.toggleCheck = this.toggleCheck.bind(this)

    this.pop_up = [
      {value:0, id_setting:1, title:"Rejeter candidature", label_title:"Souhaitez-vous rejeter cette candidature ?", text:["En rejetant ce profil, la candidature sera placée dans votre corbeille et n’apparaitra plus dans vos suggestions.", "Ce profil ne sera pas facturé."], className:"pop_up_red", label_button:"Rejeter"},
      {value:1, id_setting:0, title:"Valider candidat", label_title:"Souhaitez-vous valider ce candidat ?", text:["A la validation de ce profil, le candidat sera contacté et devra répondre à la demande de mise en relation.", "La mise en relation sera facturée selon les termes contractuels en cas de confirmation de la part du candidat."], className:"pop_up_green", label_button:'Valider'},
      {value:2, id_setting:2, title:"Rétablir le candidat dans les suggestions", label_title:"Souhaitez-vous rétablir ce candidat dans les suggestions ?", text:["En rétablissant ce candidat dans les suggestions, celui-ci sera retiré de la liste des candidatures rejetées.", "Vous aurez alors à nouveau la possibilité de valider ce profil."], className:"pop_up_orange", label_button:'Rétablir'},
    ]

  }

  renderAction(){
    if(this.props.type === 0){
      if(this.props.option === 0){
        if(!this.props.item.cv_anonymous_seen){
          return(
            <div className={styles.container_action}>
              <div className={ `${styles.icon_action} ${styles.icon_action_new}`}>
                <img src={require('@recruteur/icon/matching/new.svg').default}/>
              </div>
              <div className={styles.label_action}><span>Nouveau</span></div>
            </div>
          )
        }else{
          return(
            <div className={styles.container_action}>
              <div className={ `${styles.icon_action} ${styles.icon_red}`} onClick={(evt) => this.updateMatch(evt, 0)}>
                <img src={require('@recruteur/icon/matching/close.svg').default}/>
              </div>
              <div className={ `${styles.icon_action} ${styles.icon_green}`} onClick={(evt) => this.updateMatch(evt, 1)}>
                <img src={require('@recruteur/icon/matching/check.svg').default}/>
              </div>
            </div>
          )
        }
      }else{
        return(
          <div className={styles.container_action}>
            <div className={styles.icon_action} onClick={(evt) => this.updateMatch(evt, 2)}>
              <img src={require('@recruteur/icon/matching/reboot.svg').default}/>
            </div>
          </div>
        )
      }
    }else if(this.props.type === 1){
      if(this.props.option === 0){
        return(
          <div className={styles.container_action}>
            <div className={ `${styles.icon_action} ${styles.icon_action_new}`}>
              <img src={require('@recruteur/icon/matching/wait.svg').default}/>
            </div>
          </div>
        )
      }else{
        return(
          <div className={styles.container_action}>
            <div className={ `${styles.icon_action} ${styles.icon_action_new}`}>
              <img src={require('@recruteur/icon/matching/not_interested.svg').default}/>
            </div>
          </div>
        )
      }
    }else{
      if(!this.props.item.cv_seen){
        return(
          <div className={styles.container_action}>
            <div className={ `${styles.icon_action} ${styles.icon_action_new}`}>
              <img src={require('@recruteur/icon/matching/star.svg').default}/>
            </div>
            <div className="label_action"><span>Match</span></div>
          </div>
        )
      }else{
        let classe = "container_action"
        classe += this.props.item.eval_cab === 1 ? " eval eval_like" : ""
        classe += this.props.item.eval_cab === 0 ? " eval eval_dislike" : ""
        return(
          <div className={classe}>
            <div className="icon_action icon_red pointer" onClick={(evt) => this.updateMatch(evt, 0)}>
              <img src={require('@recruteur/icon/matching/dislike.svg').default}/>
            </div>
            <div className="icon_action icon_green pointer" onClick={(evt) => this.updateMatch(evt, 1)}>
              <img src={require('@recruteur/icon/matching/like.svg').default}/>
            </div>
          </div>
        )
      }
    }
  }

  updateMatch(evt, value, confirm){
    if(evt){
      evt.stopPropagation();
    }
    let id_setting = null
    if(this.props.type === 0){
      if(!confirm){
        let index = this.props.user.settings.findIndex(x => x.id_setting === this.pop_up[value].id_setting)
        if(index === -1 || this.props.user.settings[index].value){
          this.setState({
            pop_up:value
          })
          return
        }
      }else if(this.state.check){
        id_setting = this.pop_up[value].id_setting
      }
    }
    global.socket.emit("update_match", {type:this.props.type, value:value !== 2 ? value : null, reboot:value === 2 ? true : false, id_match:this.props.item.id, id_setting:id_setting})
  }

  renderName(){
    if(this.props.type === 2){
      return <span>{this.props.item.user.name}</span>
    }else{
      return <span>Candidat #{this.props.item.id}</span>
    }
  }

  renderProfiles(){
    return(
      <div className={styles.container_label_card_matching}>
        <span>
        {this.props.item.user.profiles.map((item, index) =>{
          return(
            <span key={item.id.toString()}>{index ? ' ; ' + item.label : item.label}</span>
          )
        })}
        </span>
      </div>
    )
  }

  renderSchools(){
    return(
      <div className={styles.container_label_card_matching}>
        <span>
        {this.props.item.user.schools.map((item, index) =>{
          return(
            <span key={item.id.toString()}>{index ? ' ; ' + item.name : item.name}</span>
          )
        })}
        </span>
      </div>
    )
  }

  closePopUp(){
    this.setState({
      pop_up:null
    })
  }

  toggleCheck(){
    this.setState({
      check:!this.state.check
    })
  }

  render(){
    return(
        <div className={ `${styles.card_matching} card`}  onClick={() => this.props.showCV(this.props.item)}>
          <div className={styles.header_card_matching}>
            <div className={styles.contrat_card_matching}>{this.props.item.user.contrat}</div>
            <div className={styles.contrat_date_matching}>date</div>
          </div>
          
          <div className={styles.user_card_matching}>
            <div className={ `${styles.img_card_matching} ${this.props.type !== 2 && styles.img_card_matching_blur}` }>
              {this.props.item.user.image ? (
                <img src={this.props.item.user.image}/>
              ):(
                <img src={require('@recruteur/icon/matching/avatar.svg').default}/>
              )}
            </div>

            <div className={styles.container_name}>
              <div className={styles.name_card_matching}>{this.renderName()}</div>
              <BuddyScore
                buddy_score={this.props.item.buddy_score}
              />
            </div>
          </div>

          <div className={styles.details_card_matching}>
            <div className={styles.item_card_matching}>
              <img src={require('@recruteur/icon/matching/profile.svg').default}/>
              {this.renderProfiles()}
            </div>
            <div className={styles.item_card_matching}>
              <img src={require('@recruteur/icon/matching/school.svg').default}/>
              {this.renderSchools()}
            </div>
            <div className={styles.item_card_matching}>
              <img src={require('@recruteur/icon/matching/comment.svg').default}/>
              <div className={styles.container_label_card_matching}>
                <span>{this.props.item.comment}</span>
              </div>
            </div>
          </div>
          
          {this.renderAction()}

        </div>




        {this.state.pop_up || this.state.pop_up === 0 ? (
          <PopUp
            closePopUp={this.closePopUp}
          >
            <div className={"pop_up pop_up_persona " + this.pop_up[this.state.pop_up].className}>
              <div className="header_pop_up header_color">
                <span>{this.pop_up[this.state.pop_up].title}</span>
                <div className="header_pop_up_action pointer" onClick={this.closePopUp}><img src={require('@recruteur/icon/header/settings_close.svg')}/></div>
              </div>

              <div className="content_pop_up">
                <div className="text_content_pop_up title_content_pop_up item_text_content_pop_up">{this.pop_up[this.state.pop_up].label_title}</div>
                <div className="text_content_pop_up">{this.pop_up[this.state.pop_up].text.map((item, index) => {return(<div className="item_text_content_pop_up" key={index.toString()}>{item}</div>)})}</div>
              </div>

              <div className="banner_button">
                <div className="container_option_check">
                  <div className="container_check pointer" onClick={this.toggleCheck}>
                    {this.state.check ? (
                      <IconCheck/>
                    ):(null)}
                  </div>
                  <div className="label_check">Ne plus me demander confirmation</div>
                </div>
                <div className="button button_cancel pointer" onClick={this.closePopUp}>Annuler</div>
                <Button 
                  label={this.pop_up[this.state.pop_up].label_button}
                  onClick={() => this.updateMatch(null, this.state.pop_up, true)}
                />
              </div>
              
            </div>

          </PopUp>
        ):(null)}

      </div>
    )
  }
}

export default CardSenior;*/