import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from '@utils/modal/Modal';
import Button from '@utils/button/Button';

import styles from './subscribebuddyscan.module.css';

const SubscribeBuddyScan = ({
  onClose,
  onValidate,
  currentQuestion,
  renderContent,
  progress,
  onGoBack,
  answers,
  isIllustration,
  question,
}) => {

  const [userAnswers, updateUserAnswers] = useState(answers)
  const [isLoading, updateIsLoading] = useState(false)

  return(
    <Modal
      closeModal={onClose}
    >
    <div className="container">
      <div className={styles.container_header}>
      {progress || progress === 0 ? (
          <div className={styles.container_progress}>
            <div className={styles.progress_bar}>
              <div className={styles.inner_progress_bar} style={{width:`${progress}%`}}></div>
            </div>
            <div className={styles.label_progress}>{progress}% du profil établi</div>
          </div>
        ):(
          <div></div>
        )}
        {onClose && (
          <div className={styles.container_close} onClick={onClose}>
            <img src={require('@icon/site/close.svg').default}/>
          </div>
        )}
      </div>
      {renderContent({ userAnswers, updateUserAnswers, isLoading, updateIsLoading })}
      {onValidate && (
          <div className={styles.banner_button}>
            {!isIllustration ? (
              <div className={`${styles.onGoBack} hover_grey`} onClick={() => {
                updateIsLoading(true)
                onGoBack()
              }}>
                Retour
              </div>
            ):(
              <div></div>
            )}
            <Button
              label="Suivant"
              type="full"
              disabled={question && (question.id === 7 || question.type === 1) && userAnswers?.length === 0}
              onClick={() => {
                updateIsLoading(true)
                updateUserAnswers([])
                onValidate(userAnswers)
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  )
};

export default SubscribeBuddyScan

 
