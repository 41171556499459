import React, { useState, useEffect } from 'react';

import Scrollbars from '@utils/scrollbars/Scrollbars';

import customFetch from '@utils/function/customFetch';

import styles from './profile.module.css';

const AnswersList = ({
  data,
  answers
}) => {

  const [listAnswers, updateListAnswers] = useState([])

  useEffect(() => {
    customFetch({ endpoint: 'get_item', data, onSuccess:onReceivedListAnswers}) 
  }, [])

  function onReceivedListAnswers({response}){
    updateListAnswers(response)
  }

  return(
    <div className={styles.container_answers}>
      {answers.map((item) => {
        let answer = listAnswers.find(x => x.id === item)
        if(!answer) return null 
        return(
          <div className={styles.container_answer}>
            {answer.label}
          </div>
        )
      })}
    </div>
  )
}

const Profile = ({
  id_user
}) => {

  const [buddyScan, updateBuddyScan] = useState()

  useEffect(() => {
    customFetch({ endpoint: 'admin/get_user_buddy_scan', onSuccess, data:{ id_user },  type:'POST'})
  }, [])

  function onSuccess({ response }){
    updateBuddyScan(response)
  }

  function renderAnswers(item){
    if(!buddyScan.answers) return null

    let answers = buddyScan.answers.find(x => x?.id === item.id)?.answers
    
    if(!answers || answers.length === 0) return null
    
    if(item.type === 0){
      return(
        <AnswersList
          data={item.data}
          answers={answers}
        />
      )
    }else if(item.type === 1){
      let answer = item.answers.find(x => x.id === answers[0])

      if(!answer) return null

      return(
        <div className={styles.container_answers}>
          <div className={styles.container_answer}>{answer.label}</div>
        </div>
      )
    }else{
      return(
        <div className={styles.container_answers}>
          {answers.map((item) => {
            let label = ''
            for(var key in item){
              if(key !== 'addlist' && key !== 'duration'){
                label += `${label.length > 0 ? ' - ' : ''}${item[key]?.label}`
              }
            }
            label += `${label.length > 0 ? ' - ' : ''}${item.duration?.label}`
            return(
              <div className={styles.container_answer}>
                {label}
              </div>
            )
          })}
        </div>
      )
    }
  }

  if(!buddyScan) return null

  return(
    <Scrollbars>
      {buddyScan.questions.map((item) => (
        <div className={styles.container_question}>
          <div className={styles.label_question}>{item.title}</div>
          {renderAnswers(item)}
        </div>
      ))}
    </Scrollbars>
  )
}

export default Profile;
