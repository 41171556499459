import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import QuestionBuddyScan from './questionBuddyScan/QuestionBuddyScan';
import SubscribeBuddyScan from './subscribeBuddyScan/SubscribeBuddyScan';

import customFetch from '@utils/function/customFetch';

import styles from './buddyscan.module.css';

const BuddyScan = ({
  onClose,
  onSuccessSubscribe,
  contentIntro,
}) => {

  const history = useHistory();

  const [questionsBuddyScan, updateQuestionsBuddyScan] = useState()
  const [currentQuestion, updateCurrentQuestion] = useState('intro')
  const [answersBuddyScan, updateAnswersBuddyScan] = useState([])

  useEffect(() => {
    customFetch({ endpoint: 'get_buddy_scan', onSuccess, type:'GET'})
    customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 5 } , type:'POST'})  
  }, [])

  function onSuccess(data){
    updateQuestionsBuddyScan(data.response)
  }

  function onValidate(answers){

    if(currentQuestion === 'intro'){
      updateCurrentQuestion(0);
      return
    }

    var updatedAnswers = [...answersBuddyScan]
    let index = updatedAnswers.findIndex(x => x.id === questionsBuddyScan[currentQuestion].id)
    if(index !== -1){
      updatedAnswers[index] = {id:questionsBuddyScan[currentQuestion].id, answers}
    }else{
      updatedAnswers.push({id:questionsBuddyScan[currentQuestion].id, answers})
    }
    updateAnswersBuddyScan(updatedAnswers)
    if(currentQuestion === questionsBuddyScan.length - 1){
      updateCurrentQuestion('loading')
      customFetch({ endpoint: 'subscribe_buddy_scan', onSuccess:onSuccessSubscribe, data:updatedAnswers}) 
      customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 6 } , type:'POST'}) 
    }else{
      updateCurrentQuestion(currentQuestion + 1);
    }
  }

  function renderContent({ userAnswers, updateUserAnswers, isLoading, updateIsLoading }){
    if(currentQuestion === 'intro'){
      return(
        <div className={`container ${styles.container_intro}`}>
          <div className={styles.intro_title}>
            Bienvenue !
          </div>
          <div className={styles.intro_image}>
            <img src={require('@icon/site/introBuddyScan.svg').default}/>
          </div>
          <div className={styles.intro_text}>
            Nous allons vous poser quelques questions sur votre parcours et vos aspirations professionnelles. Le but est de comprendre qui vous êtes et ce qui vous motive pour vous faire les meilleures propositions de cabinets par la suite !
          </div>
        </div>
      )
    }else if(currentQuestion === 'loading'){
      return(
        <div className={`container ${styles.container_intro}`}>
          <div className={styles.intro_title}>
            Veuillez patienter quelques instants, votre profil est en cours de création...
          </div>
          <div className={styles.loading_image}>
            <img src={require('@icon/site/spinner.gif').default}/>
          </div>
        </div>
      )
    }else{
      return(
        <QuestionBuddyScan 
          question={questionsBuddyScan[currentQuestion]}
          answers={answersBuddyScan.find(x => x.id === questionsBuddyScan[currentQuestion].id)?.answers}
          updateUserAnswers={updateUserAnswers}
          userAnswers={userAnswers}
          isLoading={isLoading}
          updateIsLoading={updateIsLoading}
        />
      )
    }
  }

  return(
    <SubscribeBuddyScan
      onClose={onClose}
      onValidate={onValidate}
      renderContent={renderContent}
      isIllustration={currentQuestion === 'intro' || currentQuestion === 'loading'}
      progress={questionsBuddyScan && Math.round( currentQuestion/questionsBuddyScan.length*100) }
      onGoBack={() => updateCurrentQuestion(currentQuestion ? currentQuestion - 1 : 'intro')}
      currentQuestion={currentQuestion}
      question={questionsBuddyScan && questionsBuddyScan[currentQuestion]}
    />
  )
};

export default BuddyScan

 
