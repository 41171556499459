import React, { useState, useEffect, Stylesheet } from 'react';

import Form from '@utils/form/Form';
import Field from '@utils/form/field/Field';

import customFetch from '@utils/function/customFetch';

const Administratif = ({
    
}) => {
  const [loading, updateLoading] = useState(false)
  const [sections, updateSections] = useState(null)

  useEffect(() => {
    customFetch({ endpoint: 'admin/get_form_administratif', onSuccess}) 
  }, [])
  
  function onSuccess(data){
    updateSections(data.response)
    updateLoading(false)
  }

  //TODO last modif
  if(loading){
    return null
  }else{
    return(
      <div className="container">
        {sections && (
          <Form
            endpoint={"admin/update_administratif"}
            sections={sections}
            type="formadmin"
            status="En ligne"
          />
        )}
      </div>
    )
  }
}

export default Administratif

 
