import React, { useEffect, useState } from 'react';

import ReactHtmlParser from 'react-html-parser';

import ItemMission from './itemMission/ItemMission';
import Content from '../content/Content';
import BannerContext from '@utils/bannerContext/BannerContext';

import styles from './missions.module.css'

const Missions = ({
  cab
}) => {

  return(
    <div className={styles.container}>
      <div className={styles.intro_mission}>
        <div className={styles.text}>
          {ReactHtmlParser(cab.mission)}
        </div>
        {cab.missions.length>0 && (
          <div className={styles.missions}>
            {cab.missions.map((item) => {
              return(
                <ItemMission
                  mission={item}
                />
              )
            })}
          </div>
        )}
      </div>
      <Content 
        type={1}
        content={cab.content.find(x => x.type === 1)}
      />

      {cab.content.find(x => x.type === 5) && (
        <>
          <BannerContext 
            image={require('@icon/site/cab/context/missions.svg').default}
            title={`Voici des articles illustrant les domaines d'expertise du cabinet ${cab.name}`}
            subtitle={[`Ces articles sont rédigés et mis à jour par ${cab.name}`]}
          />
          <Content 
            type={5}
            content={cab.content.find(x => x.type === 5)}
            style={{flex:'none'}}
          />
        </>
      )}
   
    </div>
  )

};



export default Missions;
