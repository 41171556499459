import React, { Component } from 'react';

import List from './List';
import './select.css';

import onClickOutside from "react-onclickoutside";

var update = require('immutability-helper');

class Select extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      value:this.props.field.value,
      show_search:false,
      data:[],
      search:'',
      selected:this.props.field ? this.props.field.value : null
    };

    this.showSearch = this.showSearch.bind(this)
    this.data_received = this.data_received.bind(this)
    this.select = this.select.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.onChange = this.onChange.bind(this)

  }

  data_received(data, refresh){

    let arr = this.state.data

    if(refresh){
      arr = data
    }else{
      for(let i=0;i<data.length;i++){
        let index = this.state.data.findIndex(x => x.id == data[i].id)
        if(index ==-1){
          arr = update(arr, {
            $splice: [[arr.length, 0, data[i]]]
          });
        }
      }
    }

    this.setState({
      data:arr,
      no_more:data.length < this.props.data_length,
      isLoading:false,
      forcedRefresh:false
    })
  }

  onChange(evt){
    let value = evt.target.value
    this.setState({
      search:value
    })
    if(this.props.onSearch){
      this.props.onSearch(value)
    }
  }


  select(item, event){
    event.stopPropagation()
    this.setState({
      show_search:this.props.multiple ? this.state.show_search : false,
      selected:this.props.multiple ? null : item,
      search:''
    })

    if(this.props.multiple){
      item.text = ""
    }

    if(this.props.onChange){
      this.props.onChange({type:this.props.type, value:item})
    }

  }

  editable(){
    return this.props.modify ? false : true
  }

  handleClickOutside(evt){
    if(this.state.show_search){
      this.setState({
        show_search:false
      })
    }
  }

  renderSearch(){
    if(this.props.endpoint){
      return(
        <div className="container_search_select">
          <List 
            endpoint={this.props.endpoint}
            renderItem={this.renderItem}
            search={this.state.search}
            data={this.state.data}
            data_received={this.data_received}
            no_more={this.state.no_more}
            hide_more={true}
            searchbar={true}
            autoHeight={true}
            //extraDataSocket={{selected:this.props.field.value, type:this.props.type, create:this.props.create}}
            extraDataSocket={this.props.extraDataSocket}
            renderEmpty={() =>{
              return(
                <div className="item_list_select empty_list_select">Aucun résultat ne correspond à votre sélection</div>
              )
            }}
          />
        </div>
      )
    }
  }

  renderItem(item){
    if(item.logo || item.image){
      return(
        <div className="item_list_select" onClick={(evt) => this.select(item, evt)} key={item.id.toString()}>
          <div className="container_cab_list">
            <div className="container_cab_logo">
              <img src={item.logo ? item.logo : item.image}/>
            </div>
            <div className="name_cab_list">{item.name}</div>
          </div>
        </div>
      )
    }else{
      return(
        <div
          onClick={(evt) => this.select(item, evt)} 
          className="item_list_select"
          key={item.id.toString()}
        >
          {this.props.renderItemSelect ? this.props.renderItemSelect(item) : (<div className="container_name_item_search">{item.name ? item.name : item.title}</div>)}
        </div>
      )
    }
    /*return(
      <div
        onClick={() => this.select(item)} 
        className="item_list_select"
      >
        {this.props.renderItemSelect ? this.props.renderItemSelect(item) : (<div className="container_name_item_search">{item.name ? item.name : item.title}</div>)}
      </div>
    )*/
  }

  showSearch(){
    this.setState({
      show_search:true
    })
  }

  renderListItem(){
    if(Array.isArray(this.props.value)){
      return(
        <div>
          {this.props.value.map((item)=>{
            return(
              <div>{item.name}</div>
            )
          })}
        </div>
      )
    }
  }

  renderPlaceholder(){
    if(this.props.field.value && (this.props.field.value.name || this.props.field.value.title)){
      return this.props.field.value.name ? this.props.field.value.name : this.props.field.value.title
    }else{
      return this.props.field.placeholder
    }
  }

  renderContent(){
    if(this.props.disabled){
      return(
        <div className="select_disabled">{this.renderPlaceholder()}</div>
      )
    }else if(this.state.selected){
      return(
        <div className="container_selected">
          {this.renderItem(this.state.selected)}
          <img
            src={require('../../icon/screening_test/delete.svg').default}
            className="icon_select"
            onClick={(evt) => this.select(null, evt)}
            alt="Supprimer"
          />
        </div>
      )
    }else{
      return(
        <input
          placeholder={this.renderPlaceholder()}
          onChange={this.onChange}
          size="1"
          //value={this.props.field.value && this.props.field.value.name ? this.props.field.value.name : null}
        />
      )
    }
  }

  render(){
    let classe = "container_select"
    classe += this.state.show_search ? " show_search" : ""
    return(
        <div className={classe} style={this.props.style} key={this.props.key}>
          <div className="container_input container_input_select" onClick={this.showSearch}>
           {this.renderContent()}
          </div>
          {this.renderSearch()}
          {this.renderListItem()}
        </div>
      )
  }
}

export default onClickOutside(Select);
