import React, { useEffect, useRef, useState } from 'react';

import List from '@utils/list/List';
import Input from '@utils/form/field/input/Input';
import Button from '@utils/button/Button';

import useListReducer from '@utils/function/useListReducer';
import customFetch from '@utils/function/customFetch';

import date from '@utils/function/date';

import styles from './comments.module.css';

const Comments = ({
  id_user,
  id_cab
}) => {

  const [listState, updateList] = useListReducer()
  const [comment, onChangeComment] = useState()

  const ref = useRef()

  function renderItem(item){
    return(
      <div className={styles.container_comment}>
        <div className={`${styles.comment} card`}>
          {item.comment}
        </div>
        <div className={styles.container_infos}>
          <div className={styles.sender}>{item.admin.name}</div>
          <div className={styles.date}>{date(item.date)}</div>
        </div>
      </div>
    )
  }

  function renderHeader(){
    return(
      <div className={styles.container_comment}>
        <Input 
          field={{key:'comment', extraData:{textarea:true}, style:{marginBottom:0}}}
          onChange={onChange}
          ref={ref}
        />
        <Button 
          label='Envoyer'
          type='full'
          onClick={() => sendComments({comment, id_user, id_cab})}
        />
      </div>
    )
  }

  function onChange(data){
    onChangeComment(data.value)
  }

  function sendComments({comment, id_user, id_cab}){
    customFetch({ endpoint: 'admin/post_comment', onSuccess, data:{comment, id_user, id_cab}, type:'POST'})
  }

  function onSuccess({ response }){
    updateList({type:'create_item', item:response})
    ref.current.reset()
  }

  return(
    <List 
      endpoint={"admin/comments"}
      data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
      isLoading={listState.isLoading}
      data={listState.data}
      renderItem={renderItem}
      renderHeader={renderHeader}
      onError={() => {}}
      extraData={{id_user, id_cab}}
    />
  )
}

export default Comments;
