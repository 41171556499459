import React from 'react';

import MinArticle from '@site/articles/minarticle/MinArticle';
import MinCab from '@site/cabs/mincab/MinCab';

import {Link} from "react-router-dom";

import './homepage.css';

var update = require('immutability-helper');


class HomePage extends React.Component {


  constructor(props) {
    super(props);
    this.state = {

    };

  }

  shouldComponentUpdate(nextProps, nextState){
    return nextProps.isLoading !== this.props.isLoading
  }

  renderListArticles(){
    if(this.props.isLoading){
      return(
        <div className="container_list_home">
          <MinArticle
            loading={true}
          />
          <MinArticle
            loading={true}
          />
          <MinArticle
            loading={true}
          />
          <MinArticle
            loading={true}
          />
          <MinArticle
            loading={true}
          />
          <MinArticle
            loading={true}
          />
         </div>
      )
    }else{
      return(
        <div className="container_list_home">
        {this.props.articles.map((item => {return(
            <MinArticle
              article={item}
              key={item.id.toString()}
            />
          )}))}
        </div>
      )
    }
  }

  renderListCabs(){
    if(this.props.isLoading){
      return(
        <div className="container_list_home">
          <MinCab
            loading={true}
          />
          <MinCab
            loading={true}
          />
          <MinCab
            loading={true}
          />
          <MinCab
            loading={true}
          />
          <MinCab
            loading={true}
          />
          <MinCab
            loading={true}
          />
         </div>
      )
    }else{
      return(
        <div className="container_list_home">
        {this.props.cabs.map((item => {return(
            <MinCab
              cab={item}
              key={item.id.toString()}
            />
          )}))}
        </div>
      )
    }
  }


  render(){
      return(
        <div className="container_homepage">
          <div className="container_section_homepage">
            <div className="container_title_section">
              <div className="title_section">Nous vous aidons à trouver le cabinet de conseil idéal</div>
            </div>
           
          </div>
          <div className="container_section_homepage">
            <div className="container_title_section">
              <div className="title_section">Les derniers articles de Consulting Buddies à découvrir</div>
              <Link to={"/article"} className="container_more">
                <span>Voir plus</span>
                <img src={require('@icon/view_more.svg').default}/>
              </Link>
            </div>
            {this.renderListArticles()}
          </div>
          <div className="container_section_homepage">
            <div className="container_title_section">
              <div className="title_section">Découvrez les cabinets de conseil référencés sur Consulting Buddies</div>
              <Link to={"/cabinet"} className="container_more">
                <span>Voir plus</span>
                <img src={require('@icon/view_more.svg').default}/>
              </Link>
            </div>
            {this.renderListCabs()}
          </div>
        </div>
      )
    
  }
}

export default HomePage;
