import React, { useState } from 'react';

import { Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Modal from '@utils/modal/Modal';
import Field from '@utils/form/field/Field';
import Button from '@utils/button/Button';
import Scrollbars from '@utils/scrollbars/Scrollbars';
import LinkedIn from '@utils/linkedin/LinkedIn';

import Connect from './Connect';

import customFetch from '@utils/function/customFetch';

import styles from './modalconnect.module.css'

const ModalConnect = ({
  onClose,
  user,
  showForgetPassword,
  onSuccess,
  recruteur,
  endpoint,
  apply
}) => { 

  const [email, updateEmail] = useState()
  const [password, updatePassword] = useState()
  const [error, updateError] = useState()

  function renderFooter(){
    return(
      <div className={styles.buttons}> 
        {error && (
          <div className={styles.error}>{error}</div>
        )}
        <Button 
          type="full"
          label="Se connecter"
          onClick={() =>  customFetch({ endpoint, data:{username:email, password:password}, onSuccess, onError, type:'POST'})}
          disabled={!email || !password || email.length === 0 || password.length === 0}
        />
      </div>
    )
  }

  function onError(data){
    updateError(data?.error || 'Une erreur inattendue est survenue, merci de réessayer plus tard.')
  }

  if(apply){
    return(
      <div className={styles.loginApply}>
        <Connect 
          email={email}
          updateEmail={updateEmail}
          password={password}
          updatePassword={updatePassword}
          recruteur={recruteur}
          user={user}
          showForgetPassword={showForgetPassword}
        />
        {renderFooter()}
      </div>
    )
  }

  return(
    <Modal
      closeModal={onClose}
      header={
        isMobile ? 'Connexion' : 
          recruteur ? `Connexion à l'espace recruteur Consulting Buddies` : `Connexion à l'espace personnel Consulting Buddies`}
      footer={{style:{justifyContent:'flex-end'}, content:renderFooter()}}
    >

      <Connect 
        email={email}
        updateEmail={updateEmail}
        password={password}
        updatePassword={updatePassword}
        recruteur={recruteur}
        user={user}
        showForgetPassword={showForgetPassword}
      />
        
      {!recruteur && (
        <div className={styles.banner_recruit}>
          Vous êtes recruteur ? <a className={styles.link_recruteur} href={"/recruteur/"}>Connectez-vous sur votre espace cabinet</a>
        </div>
      )}

    </Modal>
  )
}


export default ModalConnect;