import { useReducer } from 'react';

var update = require('immutability-helper');

function useListReducer() {

  const [state, updateData] = useReducer(reducer, {data:[], isLoading:true})
  
  return [state, updateData]

}

function reducer(state, action){
  let arr = state.data
  let index 

  switch (action.type) {
    case 'refresh':
      return { data : action.data, isLoading : false  };
    case 'next_batch':
      for(let i=0;i<action.data.length;i++){
        index = state.data.findIndex(x => x.id === action.data[i].id)
        if(index === -1){
          arr = update(arr, {
              $splice: [[arr.length, 0, action.data[i]]]
          });
        }
      }
      return { data : arr, isLoading : false };
    case 'update_item':
      index = arr.findIndex( x => x.id === action.item.id)
      if(index !== -1){
        arr = update(arr, {
          $splice: [[index, 1, action.item]]
        });
      }
      return { data : arr, isLoading : false };
    case 'delete_item':
      index = arr.findIndex( x => x.id === action.item.id)
      if(index !== -1){
        arr = update(arr, {
          $splice: [[index, 1]]
        });
      }
      return { data : arr, isLoading : false };
    case 'create_item':
      index = arr.findIndex( x => x.id === action.item.id)
      if(index === -1){
        arr = update(arr, {
          $splice: [[action.index || 0, 0, action.item]]
        });
      }
      return { data : arr, isLoading : false };
    case 'move_item': {
        const updatedArr = [...arr]
        index = updatedArr.findIndex( x => x.id === action.item.id)
        if(index !== -1){
          updatedArr.splice(index, 1);
          updatedArr.splice(action.toIndex, 0, action.item);
        }
        return { data : updatedArr, isLoading : false };
    } 
    case 'replace_item':
        const updatedArr = [...arr]
        index = updatedArr.findIndex( x => x.id === action.item.id)
        if(index !== -1){
          updatedArr.splice(index, 1);
        }
        updatedArr.splice(action.toIndex, 0, action.item);
        return { data : updatedArr, isLoading : false };
    default:
      throw new Error();
  }
}

export default useListReducer;
