import React, { useState } from 'react';

import List from '@utils/list/List';
import DateSelect from '../dateSelect/DateSelect.js';

import useListReducer from '@utils/function/useListReducer';

import styles from './pages.module.css';

const Pages = ({
  
}) => {
  
  const [filterDates, updateFilterDates] = useState()
  const [filterPages, updateFilterPages] = useState()
  const [filterUser, updateFilterUsers] = useState()

  const [listState, updateList] = useListReducer()

  function ListHeaderComponent(){
    return(
      <div>
        <DateSelect
          fields={[
            { id:0, label: `Non inscrits` },
            { id:1, label: `Juniors` },
            { id:2, label: `Seniors` },
          ]}
          placeholder='Choisir un type de candidat'
          onSelect={updateFilterUsers}
        />
        <DateSelect
          fields={[
            { id:0, label: `Page structure` },
            { id:1, label: `Page cabinet` },
            { id:2, label: `Articles` },
          ]}
          placeholder='Choisir un type de pages'
          onSelect={updateFilterPages}
        />
        <DateSelect
          fields={[
            { date: true },
            { id:0, label: `Aujourd'hui` },
            { id:1, label: `Ces 7 derniers jours` },
            { id:2, label: `Ces 30 derniers jours` },
            { id:3, label: `Ces 12 derniers mois` },
          ]}
          placeholder='Choisir une période'
          onSelect={updateFilterDates}
          defaultValue={{ id:2, label: `Ces 30 derniers jours` }}
        />
      </div>
    )
  }

  function renderItem(item){
    return(
      <div className={`${styles.page} card`} key={item.key}>
        <div className={styles.label}>{item.label}</div>
        <div className={styles.views}>{item.views} vue{item.views > 1  ? 's' : ''}</div>
      </div>
    )
  }

  return(
    <>
      <List 
        endpoint={"admin/dashboard_pages"}
        data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
        isLoading={listState.isLoading}
        data={listState.data}
        renderItem={renderItem}
        ListHeaderComponent={ListHeaderComponent}
        onError={() => {}}
        filters={{filterDates, filterPages, filterUser}}
      />
    </>
  )
    
}

export default Pages;
