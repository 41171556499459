import React, { useState } from 'react';
import './log_in.css';

import Button from '@utils/button/Button';
import customFetch from '@utils/function/customFetch';

var update = require('immutability-helper');


const LogIn = ({
  onSuccess
}) => {

  const [username, setUsername] = useState()
  const [password, setPassword] = useState()

  return(
    <div id="log_in">
      <div className="input_field">
          <input id="admin_username" placeholder="Username" onChange={(evt) => setUsername(evt.target.value)}/>
        </div>

        <div className="input_field">
          <input id="admin_password" placeholder="Password" type="password" onChange={(evt) => setPassword(evt.target.value)}/>
        </div>

        <div className="input_field">
          <Button 
            disabled={!username || !password}
            onClick={() => customFetch({ endpoint: 'login_admin', data:{username, password}, onSuccess, type:'POST'})}
            label="Connect"
            style={{backgroundColor:'white'}}
          />
        </div>
    </div>
  )

}

export default LogIn;
