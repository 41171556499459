import React, { useEffect, useState } from "react";

import HeaderCab from "./headerCab/HeaderCab";
import CabTab from "./cabTab/CabTab";

import InfosClefs from "./infosClefs/InfosClefs";
import Profiles from "./profiles/Profiles";
import Missions from "./missions/Missions";
import Recruit from "./recruit/Recruit";
import Orga from "./orga/Orga";
import Insights from "./insights/Insights";
import Actuality from "./actuality/Actuality";

import Mission from "./mission/Mission";
import Content from "./content/Content";
import SocialNetwork from "./socialnetwork/SocialNetwork";

import Page404 from "@utils/404/Page404";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import customFetch from "@utils/function/customFetch";

import { Link as LinkDom } from "react-router-dom";

import styles from "./cab.module.css";

var update = require("immutability-helper");

const IMAGES = [
  require("@icon/school_0.svg").default,
  require("@icon/school_1.svg").default,
  require("@icon/school_2.svg").default,
  require("@icon/school_3.svg").default,
];

const COLORS = ["#4E68E8", "#4FE9D5", "#E12582", "#FFB608"];

const CabSection = ({ title, children, name }) => {
  return (
    <div name={name} className={styles.section}>
      <div className={styles.title}>
        <div className={styles.square_title} />
        <div className={styles.label_title}>{title}</div>
      </div>
      {children}
    </div>
  );
};

const Cab = ({ location, match, user, openBuddyScan }) => {
  const [cab, updateCab] = useState();

  useEffect(() => {
    let id = location.state?.id;
    let preview;

    if (location.search) {
      let search = location.search.replace("?", "").split("=");
      if (search.length > 1 && search[0] === "preview") preview = search[1];
    }

    const data = {
      id: id,
      pathname: match.params.id,
      id_user: user?.id,
      preview: preview,
    };
    customFetch({ endpoint: "cab", onSuccess, data, type: "POST" });
  }, []);

  function onSuccess({ response }) {
    updateCab(response);
    customFetch({
      endpoint: "activity/activity",
      data: {
        token: localStorage.getItem("token"),
        id_activity: 3,
        type: 4,
        id_cab: response.id,
      },
      type: "POST",
    });
  }

  if (!cab) return null;

  return (
    <div id="container_cab" className={styles.container_cab}>
      <HeaderCab cab={cab} user={user} />

      <CabTab cab={cab} />

      <Insights cab={cab} />

      <CabSection name="infos_clefs" title="Infos clés">
        <InfosClefs cab={cab} />
      </CabSection>

      {!cab.hide_profiles && (
        <CabSection name="profiles" title="Profil des équipes">
          <Profiles cab={cab} />
        </CabSection>
      )}

      {!cab.hide_missions && (
        <CabSection name="missions" title="Missions">
          <Missions cab={cab} />
        </CabSection>
      )}

      {!cab.hide_recrutement && (
        <CabSection name="recrutement" title="Processus de recrutement">
          <Recruit cab={cab} user={user} openBuddyScan={openBuddyScan} />
        </CabSection>
      )}

      {!cab.hide_orga && (
        <CabSection name="organisation" title="Organisation">
          <Orga cab={cab} />
        </CabSection>
      )}

      {!cab.hide_actualite && (
        <CabSection name="actualites" title="Actualités">
          <Actuality cab={cab} />
        </CabSection>
      )}
    </div>
  );
};

export default Cab;
