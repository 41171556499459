import React, { useEffect, useState } from 'react';

import {ReactComponent as IconLogo} from '@icon/cb.svg';

import styles from './buttonbuddyscan.module.css';

const ButtonBuddyScan = ({
  onClick,
  label,
  white,
  style = {},
  styleLabel = {},
  styleIcon = {}
}) => (
  <div 
    className={`${styles.button_buddyscan} ${white && styles.white}`} 
    onClick={onClick}
    style={style}
  >
    <div className={styles.image_buddyscan} style={styleIcon}>
      <IconLogo/>
    </div>
    <div className={styles.label_buddyscan} style={styleLabel}>{label}</div>
  </div>
)

export default ButtonBuddyScan;
