import React, { useState, useEffect, Stylesheet } from "react";

import Modal from "@utils/modal/Modal";
import Offer from "@utils/offer/Offer";
import List from "@utils/list/List";
import Searchbar from "@utils/searchbar/Searchbar";
import useListReducer from "@utils/function/useListReducer";

import Letter from "./letter/Letter";
import Contact from "./contact/Contact";
import CV from "./cv/CV";
import FooterAction from "./footerAction/FooterAction";

import { ReactComponent as IconClose } from "@icon/site/close.svg";
import { ReactComponent as IconEdit } from "@icon/edit.svg";
import { ReactComponent as IconFilter } from "@icon/admin/filter.svg";
import { ReactComponent as IconDelete } from "@icon/trash_white.svg";

import styles from "./modaloffre.module.css";

import clsx from "clsx";

import customFetch from "@utils/function/customFetch";

var update = require("immutability-helper");

const ModalOffre = ({
  offer,
  closeModal,
  onSuccess,
  toggleModifyOffer,
  onChangeEmailContact,
  cab,
  type,
}) => {
  const [loading, updateLoading] = useState(true);
  //const [candidates, updateCandidates] = useState()
  const [selectedCandidate, updateSelectedCandidate] = useState();
  const [candidates, updateCandidates] = useListReducer();
  const [search, onSearch] = useState();
  const [activeTab, onChangeActiveTab] = useState(0);
  const [showResend, onShowResend] = useState();
  const [showFilters, toggleFilters] = useState(false);
  const [selectedFilters, updateSelectedFilters] = useState();
  const [showRefusedUsers, setShowRefusedUsers] = useState();

  useEffect(() => {
    if (!selectedCandidate && candidates?.data.length > 0) {
      updateSelectedCandidate(candidates.data[0]);
    }
  }, [candidates]);

  useEffect(() => {
    toggleFilters(false);
  }, [selectedFilters]);

  function onError() {}

  function renderCandidate(item) {
    if (selectedFilters && item.status.style !== selectedFilters) return null;

    return (
      <div
        className={`${styles.candidate} ${
          item.id === selectedCandidate?.id && styles.selected_candidate
        } hover_card`}
        onClick={() => selectCandidate(item)}
      >
        <div className={styles.image_candidate}>
          <img
            src={item.image || require("@icon/placeholder_user.svg").default}
            className="image_cover"
          />
        </div>
        <div className={styles.name_candidate}>{item.name}</div>
        <div
          className={`${styles.status_candidate} ${styles[item.status.style]}`}
        >
          {item.status.label}
        </div>
      </div>
    );
  }

  function selectCandidate(item) {
    onChangeActiveTab(0);
    onShowResend(false);
    updateSelectedCandidate(item);
  }

  function renderHeader() {
    return (
      <div className={styles.search}>
        <Searchbar
          onSearch={onSearch}
          style={{ flex: 1 }}
          search={search}
          styleSearchBar={{ padding: "5px", margin: "10px" }}
        />
        <div className={styles.filter}>
          {!showRefusedUsers && (
            <div
              className={`${styles.icon_filter} ${
                selectedFilters && styles.active
              }`}
              onClick={() => toggleFilters(!showFilters)}
            >
              <IconFilter className="image_contain" />
            </div>
          )}
          <div
            className={clsx(styles.icon_filter, styles.icon_trash, {
              [styles.active]: showRefusedUsers,
            })}
            onClick={() => setShowRefusedUsers(!showRefusedUsers)}
          >
            <IconDelete className="image_contain" />
          </div>
          <div
            className={`${styles.filter_status} card ${
              showFilters && styles.showFilters
            }`}
          >
            <div className={styles.status_intro}>
              Sélectionner le type de candiat à afficher
            </div>
            <div
              className={`${styles.status_candidate} ${styles.new}`}
              onClick={() => updateSelectedFilters("new")}
            >
              Nouveau
            </div>
            <div
              className={`${styles.status_candidate} ${styles.unhandled}`}
              onClick={() => updateSelectedFilters("unhandled")}
            >
              Non traité
            </div>
            <div
              className={`${styles.status_candidate} ${styles.tocontact}`}
              onClick={() => updateSelectedFilters("tocontact")}
            >
              A contacter
            </div>
            <div
              className={`${styles.status_candidate} ${styles.accepted}`}
              onClick={() => updateSelectedFilters("accepted")}
            >
              Accepté
            </div>
            <div
              className={`${styles.status_candidate} ${styles.default}`}
              onClick={() => updateSelectedFilters()}
            >
              Tous
            </div>
          </div>
        </div>
      </div>
    );
  }

  function onSuccessUpdate({ response }) {
    updateCandidates({ type: "update_item", item: response });
    updateSelectedCandidate(response);
    onShowResend(false);
  }

  function renderButtons(onValidate) {
    return (
      <FooterAction
        candidate={selectedCandidate}
        activeTab={activeTab}
        onSuccess={onSuccessUpdate}
        onValidate={onValidate}
        showResend={showResend}
        type={type}
      />
    );
  }

  function renderActions() {
    if (candidates.isLoading)
      return <div className={` ${styles.container_actions}`}></div>;
    if (candidates.data.length === 0)
      return (
        <div className={` ${styles.container_actions} ${styles.empty}`}>
          Aucune candidature pour l'instant
        </div>
      );
    return (
      <div className={styles.container_actions}>
        <div className={styles.container_tab}>
          <div
            className={`${styles.tab} ${
              activeTab === 0 && styles.active
            } hover_color`}
            onClick={() => onChangeActiveTab(0)}
          >
            <div className={styles.label_tab}>Voir le CV</div>
          </div>
          {offer.letter && (
            <div
              className={`${styles.tab} ${
                activeTab === 2 && styles.active
              } hover_color`}
              onClick={() => onChangeActiveTab(2)}
            >
              <div className={styles.label_tab}>Lettre de motivation</div>
            </div>
          )}
          <div
            className={`${styles.tab} ${activeTab === 1 && styles.active} ${
              !(
                selectedCandidate?.statusCab === 0 ||
                selectedCandidate?.statusCab === 1 ||
                selectedCandidate?.status?.style === "refused"
              )
                ? "hover_color"
                : "disabled"
            }`}
            onClick={() =>
              !(
                selectedCandidate?.statusCab === 0 ||
                selectedCandidate?.statusCab === 1 ||
                selectedCandidate?.status?.style === "refused"
              ) && onChangeActiveTab(1)
            }
          >
            <div className={styles.label_tab}>Contacter le candidat</div>
            {(selectedCandidate?.statusCab === 0 ||
              selectedCandidate?.statusCab === 1 ||
              selectedCandidate?.status?.style === "refused") && (
              <div className={styles.info_contact}>
                {selectedCandidate?.status?.style === "refused"
                  ? "Pour contacter ce candidat que vous avez refusé, vous devez annuler son refus ci-dessous"
                  : "Pour contacter un candidat, vous devez d’abord valider son CV ci-dessous"}
                <img src={require("@icon/polygon.svg").default} />
              </div>
            )}
          </div>
        </div>
        {activeTab === 0 && (
          <CV
            key={selectedCandidate?.id.toString()}
            cv={selectedCandidate?.cv}
            candidate={selectedCandidate}
            onSuccess={onSuccessUpdate}
            renderButtons={renderButtons}
            type={type}
          />
        )}
        {activeTab === 1 && (
          <Contact
            key={selectedCandidate?.id.toString()}
            candidate={selectedCandidate}
            onChangeEmailContact={onChangeEmailContact}
            emailContact={cab.emailContact}
            renderButtons={renderButtons}
            onSuccessUpdate={onSuccessUpdate}
            onShowResend={onShowResend}
            showResend={showResend}
          />
        )}
        {activeTab === 2 && (
          <Letter
            key={selectedCandidate?.id.toString()}
            letter={selectedCandidate?.letter}
            renderButtons={renderButtons}
          />
        )}
      </div>
    );
  }

  return (
    <Modal
      closeModal={closeModal}
      style={{ width: "70%", overflow: "visible" }}
    >
      <div className="container" style={{ overflow: "visible" }}>
        <div className={styles.header}>
          <Offer offer={offer} previewCV={true} />
          <div
            className={`${styles.container_icon}  hover_grey`}
            onClick={closeModal}
          >
            <IconClose className="image_contain" />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.container_candidates}>
            <List
              endpoint={`${type}/get_candidates_offre`}
              data={
                showRefusedUsers
                  ? candidates.data.filter(
                      (candidate) => candidate?.status?.style === "refused"
                    )
                  : candidates.data.filter(
                      (candidate) => candidate?.status?.style !== "refused"
                    )
              }
              renderItem={renderCandidate}
              data_received={(_data, refresh) =>
                updateCandidates(
                  refresh
                    ? { type: "refresh", data: _data }
                    : { type: "next_batch", data: _data }
                )
              }
              isLoading={candidates.isLoading}
              onError={onError}
              socketKeys={"get_candidates_offre"}
              search={search}
              renderHeader={renderHeader}
              extraData={{ id: offer.id, selectedFilters }}
              // renderEmpty={renderEmpty}
            />
            <div className={styles.footer}>
              <div
                className={`${styles.container_edit} hover_color`}
                onClick={() => toggleModifyOffer(offer.id)}
              >
                <div className={styles.container_icon}>
                  <IconEdit className="image_contain" />
                </div>
                <span className={styles.label_edit}>Editer l'offre</span>
              </div>
            </div>
          </div>
          {renderActions()}
        </div>
      </div>
    </Modal>
  );
};

export default ModalOffre;
