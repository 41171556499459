import React, { useState } from 'react';

import { Link } from "react-router-dom";
import Modal from '@utils/modal/Modal';
import Field from '@utils/form/field/Field';
import Button from '@utils/button/Button';
import Scrollbars from '@utils/scrollbars/Scrollbars';

import customFetch from '@utils/function/customFetch';

import styles from './modalreset.module.css'

const ModalReset = ({
  onClose,
  resetKey,
  recruteur
}) => {   
  const [password, onChangePassword] = useState('')
  const [error, setError] = useState()

  function renderFooter(){
    return(
      <Button 
        type="full"
        label="Envoyer"
        disabled={!password || password.length === 0}
        onClick={() => {
          if(password && password.length<8){
            setError('8 caractères minimum')
          }else{
            customFetch({ endpoint: `${recruteur ? 'recruteur/' : 'user/'}reset_password`, data:{password, resetKey}, onSuccess, type:'POST'})
          }
        }}
      />
    )
  }

  function onSuccess(){
    window.location.href = recruteur ? '/recruteur' : '/'
  }

  return(
    <Modal
      closeModal={onClose}
      header={"Réinitialisation du mot de passe"}
      footer={{style:{justifyContent:'flex-end'}, content:renderFooter()}}
      modalShrink={true}
    >

        <div className={styles.content}>
          
          <div className={styles.container_image}>
            <img src={require('@icon/illustration_reset.svg').default}/>
          </div>

          <div className={styles.label_forget}>Entrez votre nouveau mot de passe ci-dessous.</div>
          
          <div className={styles.form_forget}>
            <Field 
              field={{title:'Votre mot de passe', type:'input', extraData:{input_type:'password'}}}
              onChange={(data) => {
                setError()
                onChangePassword(data.value)
              }}
            />
          </div>

          {error && (
            <div className='error'>{error}</div>
          )}

        </div>

    </Modal>
  )
}


export default ModalReset;