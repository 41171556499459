import React, { useState, useEffect, Stylesheet } from 'react';
import { Link, useParams, NavLink , useLocation} from "react-router-dom";

import styles from './tab.module.css';

var update = require('immutability-helper');

const Tab = React.forwardRef(({ 
  tabs, 
  style, 
  style_item_tab, 
  isSticky,
  onChangePage,
  page
}, ref) => {

  const { pathname } = useLocation();

  if(onChangePage){
    return(
      <div className={`card hover_color ${styles.container_tab} ${isSticky && styles.sticky}`} style={style}>
        {tabs.map((tab, index) => (
          <div 
            className={`${styles.item_tab} ${index === page && styles.active_tab}`}
            style={style_item_tab}
            onClick={() => onChangePage(index)}
          >
            {tab.icon}
            <span>{tab.name}</span>
          </div>
        ))}
      </div>
    )
  }

  return(
    <div className={`card ${styles.container_tab} ${isSticky && styles.sticky}`} style={style}>
      {tabs.map((tab, index) => (
        <NavLink 
          key={index} 
          to={tab.pathname} 
          isActive={() => (tab.pathname === pathname || tab.routes?.includes(pathname))} 
          activeClassName={styles.active_tab}
          className={styles.item_tab}
          style={style_item_tab}
        >
          <div className={`${styles.inner_tab} hover_color`}>
            {tab.icon}
            <span>{tab.name}</span>
          </div>
        </NavLink>
      ))}
    </div>
  )
})

export default Tab

 