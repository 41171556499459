import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Content from '../content/Content';
import BannerContext from '@utils/bannerContext/BannerContext';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

import styles from './actuality.module.css'

const Actuality = ({
  cab
}) => {
  return(
    <div className={styles.container_actuality}>
      <div className={styles.section}>
        {cab.twitter && (
          <div className={styles.twitter_feed}>
            <div className={styles.intro_twitter}>
              <div className={styles.icon_twitter}><img className='image_contain' src={require('@icon/social_network/twitter_logo.svg').default}/></div>
              <div className={styles.label_twitter}>Les tweets de {cab.name}</div>
            </div>
            <TwitterTimelineEmbed
              sourceType="profile"
              url={cab.twitter}
              options={{height: '450px', width:'100%'}}
              lang="fr"
            />
          </div>
        )}
        <Content 
          type={cab.twitter ? 3 : 7}
          content={cab.content.find(x => x.type === 3)}
          style={isMobile ? {width: '100%'} : {}}
        />
      </div>
      {cab.content.find(x => x.type === 6) && (
        <div className={styles.section_article}>
          <BannerContext 
            image={require('@icon/site/cab/context/actuality.svg').default}
            title={`Voici des articles illustrant les dernières actualités du cabinet ${cab.name}`}
            subtitle={[`Ces articles sont rédigés et mis à jour par ${cab.name}`]}
          />
          <Content 
            type={6}
            content={cab.content.find(x => x.type === 6)}
            style={{flex:'none'}}
          />
        </div>
      )}
    </div>
  )

};



export default Actuality;
