import React from 'react';
import './menu.css';

import { Link } from "react-router-dom";

var update = require('immutability-helper');


class Menu extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      list:null
    };

    this.openListMenu = this.openListMenu.bind(this)

  }

  openListMenu(value){
    this.setState({
      list:this.state.list === value ? null : value
    })
  }

  renderItem(item){
    if(item.sub_menu && item.sub_menu.length>0){
      return(
        <div className="container_list_menu" key={item.label}>
          <div className="title_list_menu">
            {item.label}
          </div>
          <div className="container_item_list_menu">
            {item.sub_menu.map((item) => this.renderItem(item))}
          </div>
        </div>
      )
    }else{
      return(
        <Link to={item.path} className="item_menu" key={item.label}>
          <span>
            {item.label}
          </span>
        </Link>
      )

    }
  }

  render(){
      return(
        <div id="menu">

          {this.props.routes.map((item) => this.renderItem(item))}

          {this.props.extraMenu ? (
            this.props.extraMenu()
          ):(null)}
          
          <div id="version">
            {global.version}
          </div>

        </div>
      )
    
  }
}

export default Menu;
