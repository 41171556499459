import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import styles from'./carrousel.module.css';

var update = require('immutability-helper');


const Carrousel = ({
  carrousel
}) => {

  const [toShow, updateToShow] = useState(0)
  const [isOnClick, setOnClick] = useState(false)

  let timer

  useEffect(() => {
    if(timer){
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(function(){
      updateToShow(toShow === carrousel.length -1 ? 0 : toShow + 1)
    }, 5000)
    return () => clearTimeout(timer );
  }, [toShow]) 

  return(
    <div className={`${styles.container_carrousel}`}>
      
      <div className={styles.outer_carrousel}>
        {carrousel.map((item, index) => {
          let style = {}
          if(index === toShow){
            style=isMobile ? {transform:`translateX(-100vw)`, zIndex:2} : {transform:`translateX(-50vw)`, zIndex:2}
          }else if(index === toShow - 1 || toShow === 0 && index === carrousel.length-1){
            style=isMobile ? {transform:`translateX(-200vw)`} : {transform:`translateX(-100vw)`}
          }else if(index === toShow + 1 || toShow === carrousel.length-1 && index === 0){
            style={transform:`translateX(0)`, zIndex:2}
          }else{
            style={opacity:0}
          }
          return(
            <div className={styles.container_item_carrousel} style={style}>
              <img src={item.banniere} className='image_cover'/>
              <Link className={styles.outer_cab} style={style} to={`/cabinet/${item.pathname}`}>
                <div className={styles.container_cab}>
                  <div className={styles.container_logo}>
                    <img src={item.logo}/>
                  </div>
                  <div className={styles.container_name}>
                    <div>{item.name}</div>
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
      </div>

      <div className={styles.container_cabs}>
        <div className={styles.back}>
          <img src={require('@icon/back_white.svg').default} onClick={() => {
            if(isOnClick) return
            setOnClick(true)
            setTimeout(() => setOnClick(false), 1000)
            updateToShow(toShow == 0 ? carrousel.length - 1 : toShow - 1)}
          }/>
        </div>
        <div className={styles.forward} onClick={() => {
          if(isOnClick) return
          setOnClick(true)
          setTimeout(() => setOnClick(false), 1000)
          updateToShow(toShow == carrousel.length - 1 ? 0 : toShow + 1)
        }}>
          <img src={require('@icon/forward_white.svg').default}/>
        </div>

      </div> 

    </div>
  )

}

export default Carrousel;
