import React from 'react';

import './page404.css';

var update = require('immutability-helper');


class Page404 extends React.Component {


  constructor(props) {
    super(props);
    this.state = {

    };



  }

  componentDidMount(){

  }


  render(){
      return(
        <div id="container_home" >

          <div id="container_404">
            <div id="content_404">
              <img id="image_404" src={require('@icon/404_logo.svg').default}/>
              <img id="image_error_404" src={require('@icon/404_error.svg').default}/>
              <div id="text_404">
                <div>Origine de l’erreur 404 :</div>
                <div>Pourquoi n’a t-on pas décrété que ce type d’erreur s’appelle Erreur 764529 ou Erreur 438 ? Il semblerait que 3 tentatives d’explication s’affrontent :</div>
                <div>- La première serait résolument technique. Le 4 ferait référence à un état d’erreur, le 0 un problème de syntaxe, et le dernier 4 au type précis d’erreur.</div>
                <div>- La deuxième explication est plus légère. L’erreur 404 serait en réalité un hommage à Tim Berners-Lee qui occupait le bureau 404 au CERN</div>
                <div>- La dernière explication ferait aussi référence à ce même bureau 404. Mais cette fois, des ingénieurs agacés d’avoir à retourner dans ce fameux bureau pour relancer un serveur constamment défaillant auraient décidé de baptiser ainsi cette erreur courante.</div>
              </div>
            </div>
          </div>

        </div>
      )
    
  }
}

export default Page404;
