import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import styles from './cabtab.module.css'

const TABS = [
  { label : 'Infos clés', hideKey: null, anchor:'infos_clefs'},
  { label : 'Profil des équipes', hideKey: 'hide_profiles', anchor:'profiles'},
  { label : 'Missions', hideKey: 'hide_missions', anchor:'missions'},
  { label : 'Recrutement', hideKey: 'hide_recrutement', anchor:'recrutement'},
  { label : 'Organisation', hideKey: 'hide_orga', anchor:'organisation'},
  { label : 'Actualités', hideKey: 'hide_actualite', anchor:'actualites'},
]

const CabTab = ({
  cab
}) => {

  const [activeTab, setActiveTab] = useState()

  function handleSetActive(e){
    setActiveTab(e)
  }

  return(
    <div className={styles.container_tab}>
      {TABS.map((item) => {
        if(item.hideKey && cab[item.hideKey]) return null 
        return(
          <Link 
            to={item.anchor} 
            className={`${styles.item_tab} ${activeTab === item.anchor && styles.active} hover_color`} 
            containerId="main_scrollbar" 
            smooth={true} 
            offset={isMobile ? -50 : -100} 
            spy={true} 
            onSetActive={handleSetActive}
          >
            {item.label}
          </Link>
        )
      })}
    </div>
  )

};



export default CabTab;
