import React, { useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import List from '@utils/list/List';
import Searchbar from '@utils/searchbar/Searchbar';
import SearchbarMobile from '@site/function/SearchbarMobile';
import MinCab from '@site/cabs/mincab/MinCab';

import useListReducer from '@utils/function/useListReducer';

import styles from './listcabs.module.css';

var update = require('immutability-helper');

const ListCabs = ({

}) => {

  const [listState, updateList] = useListReducer()
  const [search, onSearch] = useState()
  const [filters, onChangeFilters] = useState({})

  function renderItem(item, index){
    return(
      <MinCab
        cab={item}
        key={item.id}
        style={
          isMobile ? {width:'90vw', marginLeft:'5vw'} : 
            index%2 === 0 ? {width:'47.5%', marginRight: '2.5%'} : {width:'47.5%', marginLeft: '2.5%'}}
        key={item.id.toString()}
      />
    )
  }

  function renderHeader(){
    return(
      <Searchbar 
        onSearch={onSearch}
        placeholder="Rechercher un cabinet de conseil"
        filters={[
          {field: { key:'type', placeholder:'Type conseil', socket:"get_item", extraData:{table:'List_Tags'}, multiple:'true', value:[]} },
          {field: { key:'secteur', placeholder:'Compétences', socket:"get_item", extraData:{table:'List_Secteur'}, multiple:'true', value:[]} },
          {field: { key:'size', placeholder:'Taille', socket:"get_sizes", multiple:'true', value:[]} },
          {field: { key:'profiles', placeholder:'Métiers', socket:"get_item", extraData:{table:'List_Profiles'}, multiple:'true', value:[]} },
          {field: { key:'lieu', placeholder:'Lieu', socket:"get_item", extraData:{table:'List_Lieux'}, multiple:'true', value:[]} }
        ]}
        //button={button}
        onSelectFilter={onSelectFilter}
        selectedFilters={filters}
        resetFilters={() => onChangeFilters({})}
        style={{marginLeft:0, marginRight:0}}
        style={{paddingTop:'2vh', paddingBottom:'2vh'}}
        search={search}
      />
    )
  }

  function onSelectFilter({ value, key }){
    let updatedFilters = {...filters}
    updatedFilters[key] = value 
    onChangeFilters(updatedFilters)
  }


  return(
    <List 
      endpoint={"search_cabs"}
      data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
      isLoading={listState.isLoading}
      //renderLoading={this.renderLoading}
      data={listState.data}
      renderItem={renderItem}
      renderHeader={renderHeader}
      //extraDataSocket={{filter:this.state.filter}}
      //prevData={this.state.data}
      search={search}
      //id_content_list="list_cabs"
      //renderEmpty={this.renderEmpty}
      noScroll={true}
      contentListStyle={{flexDirection:'row', flexWrap:'wrap'}}
      onError={() => {}}
      filters={filters}
    />
  )

}

export default ListCabs;

/*
class ListCabs extends React.Component {


  constructor(props) {
    super(props);

    let filter = [[], [], [], [], []]
    if(this.props.location && this.props.location.state){
      filter[this.props.location.state.type].push(this.props.location.state.selected)
    }

    this.state = {
      data:[],
      isLoading:true,
      filter:filter,
      search:'',
      refresh:false,
      show_filter:false
    };

    this.data_received = this.data_received.bind(this)
    this.ListHeaderComponent = this.ListHeaderComponent.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.onChange = this.onChange.bind(this)
    this.refresh = this.refresh.bind(this)
    this.toggleFilter = this.toggleFilter.bind(this)
  }


  data_received(data, refresh){

    let arr = this.state.data

    if(refresh){
      arr = data
    }else{
      for(let i=0;i<data.length;i++){
        let index = this.state.data.findIndex(x => x.id == data[i].id)
        if(index ==-1){
          arr = update(arr, {
              $splice: [[arr.length, 0, data[i]]]
          });
        }
      }
    }

    this.setState({
      data:arr,
      no_more:data.length !== 9,
      isLoading:false,
      forcedRefresh:false
    })
  }


  renderItem(item, index){
    return(
      <MinCab
        cab={item}
        key={item.id}
      />
    )
  }

  ListHeaderComponent(){
    if(window.innerWidth<600){
      return(
        <div id="container_header_search">
          <SearchbarMobile
            onSearch={this.onSearch}
            search={this.state.search}
            refresh={this.state.refresh}
            filter={this.state.filter}
            onChange={this.onChange}
            toggleFilter={this.toggleFilter}
            show_filter={this.state.show_filter}
          />
        </div>
      )
    }else{
      return(
        <div id="container_header_search">
          <Searchbar
            onSearch={this.onSearch}
            onChange={this.onChange}
            filter={this.state.filter}
            search={this.state.search}
            refresh={this.state.refresh}
          />
          <div className={this.state.search || this.state.filter.findIndex(x => x.length>0) !== -1 ? "refresh" : "refresh disabled"} onClick={this.refresh}>
            <img src={require('@icon/refresh.svg').default}/>
            <div>Réinitialiser</div>
          </div>
        </div>
      )
    }
  }

  toggleFilter(){
    this.setState({
      show_filter:!this.state.show_filter
    })
  }

  onSearch(data){
    this.setState({
      search:data,
      refresh:false
    })
  }

  onChange(data){
    let _update
    let arr = this.state.filter
    let index = arr[data.type-1].findIndex(x => x.id == data.value.id)
    if(index != -1){
      _update  = update(arr[data.type-1], {
        $splice: [[index, 1]]
      })      
    }else{
      _update = update(arr[data.type-1], {
        $splice: [[0, 0, data.value]]
      })
    }
    arr = update(arr, {
      $splice: [[data.type-1, 1, _update]]
    })

    this.setState({
      filter:arr,
      refresh:false
    })
  }

  refresh(){
    this.setState({
      filter:[[], [], [], [], []],
      search:'',
      refresh:true
    })
  }

  renderEmpty(){
    return(
      <div className="empty_discover">
        <div>Aucun cabinet à découvrir</div>
      </div>
    )
  }

  renderLoading(){
    return(
      <div className="container_list_home container_list_home_loading">
        <MinCab
          loading={true}
        />
        <MinCab
          loading={true}
        />
        <MinCab
          loading={true}
        />
        <MinCab
          loading={true}
        />
        <MinCab
          loading={true}
        />
        <MinCab
          loading={true}
        />
      </div>
    )
  }


  render(){
      return(
        <div id="container_list_cabs" className={this.state.show_filter ? "show_filter" : ""}>
          <List 
            endpoint={"search_cabs"}
            data_received={this.data_received}
            isLoading={this.state.isLoading}
            renderLoading={this.renderLoading}
            data={this.state.data}
            renderItem={this.renderItem}
            ListHeaderComponent={this.ListHeaderComponent}
            onScroll={this.props.onScroll}
            extraDataSocket={{filter:this.state.filter}}
            prevData={this.state.data}
            search={this.state.search}
            id_content_list="list_cabs"
            renderEmpty={this.renderEmpty}
            noScroll={true}
            contentListStyle={{flexDirection:'row', flexWrap:'wrap'}}
            onError={() => {}}
          />
        </div>
      )
    
  }
}

export default ListCabs;*/
