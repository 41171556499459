import React, { useState, useEffect, Stylesheet } from 'react';

import List from '@utils/list/List';
import useListReducer from '@utils/function/useListReducer';
import Searchbar from '@utils/searchbar/Searchbar';
import Button from '@utils/button/Button';
import ListOfferJunior from '@user/listofferjunior/ListOfferJunior';
import BannerContext from '@utils/bannerContext/BannerContext';

import styles from './list_matching.module.css';

import {ReactComponent as IconInbox} from '@recruteur/icon/matching/inbox.svg';
import {ReactComponent as IconTrash} from '@recruteur/icon/matching/trash.svg';

const ListMatching = ({
  user,
  profileUser,
  initialMatch,
  initialOffre,
  initialSpontaneous,
  onSuccessPreferences,
  showTest,
  scrollToTop,
  onModalOffreBlockOpen
}) => { 

  function renderContext(){
    if(!profileUser.buddy_scan){
      return(
        <BannerContext
          image={require('@icon/bannercontext/match.svg').default}
          subtitle={[
            'Bienvenue sur Consulting Buddies ! Pour le moment vous voyez toutes les offres d’emplois disponibles sur notre plateforme sans personnalisation.',
            'Si vous souhaitez optimiser votre recherche d’offres d’emplois, nous vous invitons à passer notre Buddy Scan afin de sélectionner les propositions les plus adaptées à votre profil !'
          ]}
          renderFooter={() => (
            <div className={styles.banner_button}>
              <Button 
                type='full'
                label='Trouvez le cabinet qui vous correspond'
                onClick={showTest}
              />
            </div>
          )}
          style={{margin:'1vh'}}
        />
      )
    }else{
      return(
        <BannerContext
          image={require('@icon/bannercontext/match.svg').default}
          subtitle={['Vous trouverez ci-dessous les propositions d’entretien des cabinets de conseil intéressés par votre profil. En cas de validation, vous serez mis en contact avec l’équipe RH afin de planifier un premier échange.']}
          id_context={1}
          onSuccess={onSuccessPreferences}
          preferences={profileUser.preferences}
          endpoint='user/update_preferences'
          style={{margin:'1vh'}}
        />
      )
    }
  }

  function renderEmpty(){
    return(
      <BannerContext
        image={require('@icon/bannercontext/match.svg').default}
        subtitle={[
          'Vous trouverez ici les propositions d’entretien des cabinets une fois qu’ils auront validé votre profil.',
          '<span class="color">Plus de 100 cabinets recrutent activement sur Consulting Buddies</span>'
        ]}
        style={{margin:'1vh'}}
      />
    )
  }

  return(
    <ListOfferJunior
      endpoint={user.senior ? 'user/get_matchs' : 'user/get_offres'}
      endpointDetails={user.senior ? 'user/get_user_match' : 'user/offre'}
      senior={user.senior}
      user={user}
      extraData={{id_user:user.id}}
      initialMatch={initialMatch}
      initialOffre={initialOffre}
      initialSpontaneous={initialSpontaneous}
      noScroll={true}
      style={{flex:'none'}}
      renderContext={renderContext}
      renderEmpty={renderEmpty}
      profileUser={profileUser}
      scrollToTop={scrollToTop}
      onModalOffreBlockOpen={onModalOffreBlockOpen}
    />
  )
}

export default ListMatching

 