import React from 'react';

import {ReactComponent as IconClose} from '@icon/recruteurs/matching/close.svg';
import {ReactComponent as IconCheck} from '@icon/recruteurs/matching/check.svg';
import {ReactComponent as IconReboot} from '@icon/recruteurs/matching/reboot.svg';
import {ReactComponent as IconLike} from '@icon/recruteurs/matching/like.svg';
import {ReactComponent as IconDislike} from '@icon/recruteurs/matching/dislike.svg';



import styles from './actioncv.module.css';

const ActionCV = ({
  type,
  option,
  updateMatch,
  id_match,
  eval_cab,
  answer_user
}) => {

  function renderActions(){
    if(type === 0){
      if(option === 0){
        return(
          <>
            <div 
              className={`${styles.button} ${styles.button_delete} hover_background_red`} 
              onClick={(evt) => updateMatch({evt, value:0, type, id_match})}
            >
              <IconClose/>
            </div>
            <div 
              className={`${styles.button} ${styles.button_validate} hover_background_green`}
              onClick={(evt) => updateMatch({evt, value:1, type, id_match})}
            >
              <IconCheck/>
            </div>
          </>
        )
      }else{
        return(
          <>
            <div 
              className={`${styles.button} ${styles.button_reboot} hover_background_orange`} 
              onClick={(evt) => updateMatch({evt, value:2, type, id_match})}
            >
              <IconReboot/>
            </div>
          </>
        )
      }
    }else if(type === 1){
      if(option === 0){
        return(
          <>
            <div className={`${styles.button}`}><img src={require('@icon/recruteurs/matching/close.svg').default}/></div>
            <div className={`${styles.button}`}><img src={require('@icon/recruteurs/matching/check.svg').default}/></div>
          </>
        )
      }else{
        return(
          <div className={styles.container}>
            <div className={`${styles.button}`}><img src={require('@icon/recruteurs/matching/not_interested.svg').default}/></div>
            <div className={`${styles.overlay} card`}>
              {answer_user === 1 ? 'Ce candidat est déjà en process avec votre cabinet' : 
                answer_user === 3 ? "Ce candidat a mis trop de temps à répondre à votre sollicitation" : "Ce candidat n'a pas donné suite à votre sollicitation"}
            </div>
          </div>
        )
      }
    }else{
      if(eval_cab === 0 || eval_cab === 1){
        return(
          <>
            <div 
              className={`${styles.button} ${styles.button_eval_cab} ${eval_cab === 0 && styles.button_delete}`} 
            >
              <IconDislike/>
            </div>
            <div 
              className={`${styles.button} ${styles.button_eval_cab} ${eval_cab === 1 && styles.button_validate}`}
            >
              <IconLike/>
            </div>
          </>
        )
      }else{
        return(
          <>
            <div 
              className={`${styles.button} ${styles.button_delete} hover_background_red`} 
              onClick={(evt) => updateMatch({evt, value:4, type, id_match})}
            >
              <img src={require('@icon/recruteurs/matching/dislike.svg').default}/>
            </div>
            <div 
              className={`${styles.button} ${styles.button_validate} hover_background_green`}
              onClick={(evt) => updateMatch({evt, value:3, type, id_match})}
            >
              <img src={require('@icon/recruteurs/matching/like.svg').default}/>
            </div>
          </>
        )
      }
    } 
  }

  return(
    <div className={styles.banner_buttons}>
      {renderActions()}
    </div>
  )
}


export default ActionCV;