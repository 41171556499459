import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactHtmlParser from 'react-html-parser';

import Field from '@utils/form/field/Field';

import styles from '@utils/list/list.module.css';
import styles_form from './formhandler.module.css';
import styles_form_admin from '../formadmin/formadmin.module.css';

import _ from 'lodash'

function FormHandler({
  fields,
  sections,
  id,
  onChange,
  autoHeight,
  dataForm,
  dataFiles,
  noNumber,
  renderHeader,
  renderFooter,
  disabled,
  required,
  showMissing,
  activeSection,
  icon
}) {
  const [error, updateError] = useState(null)
  const scrollbar = useRef()

  useEffect(() => {
    scrollbar.current.scrollTop(0)
  }, [activeSection])

  return(
    <div className={styles_form.outer_form}>
      <Scrollbars 
        autoHide
        autoHeight={autoHeight}
        autoHeightMax={'100%'}
        autoHeightMin={'100%'}
        renderView={props => <div {...props} id={id} className={`${styles.view} ${styles_form.form_view}`}/>}
        renderTrackHorizontal={props => <div {...props} className={styles.horizontal_track}/>}
        renderThumbHorizontal={props => <div {...props} className={styles.horizontal_thumb}/>}
        className={`${styles.inner_list}`}
        ref={scrollbar}
      > 
        {renderHeader && renderHeader()}
        {fields && (
          <div className={styles_form.container_form}>
            {fields.map((item) => {
              return(
                <Field
                  field={item}
                  onChange={onChange}
                  key={item.key}
                  dataForm={dataForm}
                  dataFiles={dataFiles}
                  disabled={disabled}
                  complete={required?.find(x => x.key === item.key)?.complete}
                  showMissing={showMissing}
                />
              )
            })}
          </div>
        )}
        {sections && (
          <div className={styles_form.container_form}>
            {sections.map((section, index) => {
              return(
                <div id={"section_" + section.key} key={section.key} className={styles_form.section}>
                  <div className={styles_form.title_section}>
                    {!noNumber && (
                      <>
                        {icon ? (
                          <div className={`${styles_form_admin.index} ${styles_form_admin.active}`}><div className={styles_form_admin.icon}>{ReactHtmlParser(icon)}</div></div>
                        ):(
                          <div className={`${styles_form_admin.index} ${styles_form_admin.active}`}>{activeSection+1}</div>
                        )}
                      </>
                    )}
                    {ReactHtmlParser(section.name)}
                  </div>
                  {section.fields.map((item) => {

                    return(
                      <Field
                        field={item}
                        section={index}
                        onChange={onChange}
                        key={item.key.value || item.key.value === 0 ? `${item.key.value}_${item.key.type}`: item.key}
                        dataForm={dataForm}
                        dataFiles={dataFiles}
                        complete={required?.find(x => x.key === item.key) ? required?.find(x => x.key === item.key).complete : true}
                        showMissing={showMissing}
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
        )}
        {renderFooter && renderFooter()}
      </Scrollbars>

      {error ? (
        <div className="error_form">{error}</div>
      ):(null)}

    </div>
  )
}

export default FormHandler;