import React, { useState, useEffect, Stylesheet } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import {Link} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

import List from '@utils/list/List';
import useListReducer from '@utils/function/useListReducer';
import Searchbar from '@utils/searchbar/Searchbar';
import Calendly from './calendly/Calendly';

import Scrollbars from '@utils/scrollbars/Scrollbars';

import {ReactComponent as IconInbox} from '@recruteur/icon/matching/inbox.svg';
import {ReactComponent as IconTrash} from '@recruteur/icon/matching/trash.svg';

import styles from './coaching.module.css';

var update = require('immutability-helper');

const Coaching = ({ 
  user,
  profileUser,
  onSuccessCalledScheduled
}) => {

  const [scheduled] = useState(profileUser.scheduled)

  if(user.senior && !scheduled) return <Calendly onSuccessCalledScheduled={onSuccessCalledScheduled}/>

  const [state, updateData] = useListReducer()
  const [search, onSearch] = useState()

  function renderItem(data, index){
    return(
      <Link 
        className={`card ${styles.card_article_coaching}`} 
        key={data.id.toString()} 
        to={{pathname:"/candidat/coaching/" + data.pathname, state:{id:data.id}}}
         style={isMobile ? {width:'100%', marginBottom:'3vh'} : 
          index%3 === 2 ? {width:'32%', margin:'0 0 3vh 0'} : {width:'32%', margin:'0 2% 3vh 0'}
        } 
      >
        <div className={`${styles.image_article_coaching}`}><img src={data.image}/></div>
        <div className={`${styles.title_article_coaching}`}>{data.title}</div>
        <div className={`${styles.abstract_article_coaching}`}>{ReactHtmlParser(data.abstract)}</div>
      </Link>
    )
  }

  function onError(err){
  }

  function ListHeaderComponent(){
    return(
      <div className={styles.container_header_coaching}>
        <Searchbar 
          onSearch={onSearch}
          placeholder="Rechercher une question fréquente"
          style={{flex:1}}
          search={search}
        />
      </div>
    )
  }

  return(
    <div className="container" style={{flex:'none'}}>
      <List 
        endpoint={"user/get_articles_coaching"}
        data={state.data}
        renderItem={renderItem}
        renderHeader={ListHeaderComponent}
        data_received={(_data, refresh) => updateData(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
        isLoading={state.isLoading}
        onError={onError}
        socketKeys={"get_offres"}
        search={search}
        contentListStyle={{flexDirection:'row', flexWrap:'wrap', flex:'none', justifyContent:'flex-start', width:'100%', alignItems:'center', padding:'1vh', boxSizing:'border-box'}}
        noScroll={true}
      />
    </div>
  )
}

export default Coaching