import React, { useState, useEffect, Stylesheet } from 'react';

import CreateOffer from './CreateOffer';
import ListOffer from '@utils/offer/ListOffer';
import Offer from '@utils/offer/Offer';
import Button from '@utils/button/Button';
import useListReducer from '@utils/function/useListReducer';
import ModalOffre from './modalOffre/ModalOffre';
import BannerContext from '@utils/bannerContext/BannerContext';

const JuniorsRecruitment = ({
    user,
    onChangeEmailContact,
    cab,
    extraData,
    type = 'recruteur',
    onSuccessPreferences
}) => {
  const [modalCreateOffer, toggleCreateOffer] = useState(null)
  const [modalModifyOffer, toggleModifyOffer] = useState(null)
  const [candidatesOffer, toggleCandidatesOffer] = useState()
  const [listState, updateList] = useListReducer()

  function onSuccess({ response, endpoint }){
    if(endpoint.indexOf('/create_offer') !== -1){
      updateList({type:'create_item', item:response})
    }else if(endpoint.indexOf('/modify_offer') !== -1){
      updateList({type:'update_item', item:response})
    }else if(endpoint.indexOf('/delete_offer') !== -1){
      updateList({type:'delete_item', item:response})
    }
    closeModalOffer()
  }

  function closeModalOffer(){
    toggleCreateOffer(false)
    toggleModifyOffer(false)
  }

  function renderItem(data){
    return(
      <Offer 
        offer={data}
        clickOffer={(offer) => {
          if(data.published){
            toggleCandidatesOffer(offer)
          }else{
            toggleModifyOffer(offer.id)
          }
        }}
        onSuccess={onSuccess}
      />
    )
  }

  return(
    <div className="container">
      { (modalCreateOffer || modalModifyOffer) && (
        <CreateOffer 
          closeModal={closeModalOffer}
          cab={cab}
          id={modalModifyOffer}
          onSuccess={onSuccess}
        />
      )}
      {user && (
        <BannerContext 
          image={require('@icon/bannercontext/juniors.svg').default}
          subtitle={[`Vous trouverez ci-dessous la liste de vos offres d’emplois (stages & CDI junior). Cliquez sur une offre pour accéder aux candidatures.`]}
          id_context={12}
          onSuccess={onSuccessPreferences}
          preferences={user.preferences}
          endpoint='recruteur/update_preferences'
          style={{padding:'2vh 2vw', margin:'1vh', marginTop:'2vh'}}
        />
      )}
      <ListOffer
        button={{label:'Créer une offre', onClick:() => toggleCreateOffer(true)}}
        onSuccess={onSuccess}
        listState={listState}
        updateList={updateList}
        endpoint={`${type}/get_offres`}
        renderItem={renderItem}
        extraData={extraData}
      />
      {candidatesOffer && (
        <ModalOffre 
          offer={candidatesOffer}
          closeModal={() => toggleCandidatesOffer(null)}
          toggleModifyOffer={(id) => {
            toggleModifyOffer(id)
            toggleCandidatesOffer(null)
          }}
          onChangeEmailContact={onChangeEmailContact}
          cab={cab}
          type={type}
        />
      )}
    </div>
  )
}

export default JuniorsRecruitment

 