import React from 'react';

import Ecoles from './ecoles/Ecoles';
import ExpPro from './exp_pro/ExpPro';
import Ponderation from './ponderation/Ponderation';
import QuestionsBuddyScan from './questions/QuestionsBuddyScan';

import Tab from '@utils/tab/Tab';

import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";

var update = require('immutability-helper');


const BuddyScan= ({
  props
}) => {

    return(
        <Router>
            <Tab 
              style={{marginTop:'-1vh', borderRadius:'0 0 1vh 1vh', borderTop:'1px solid var(--border)'}}
              style_item_tab={{borderRight:'none'}}
              tabs = {[
                {"pathname":"/admin/cms/buddyscan/ecoles", name:"Ecoles", routes:["/admin/cms", "/admin/cms/site","/admin/cms/site/accueil"]},
                {"pathname":"/admin/cms/buddyscan/experiences", name:"Expériences"},
                {"pathname":"/admin/cms/buddyscan/ponderation", name:"Pondération"},
                {"pathname":"/admin/cms/buddyscan/questions", name:"Questions"},
                {"pathname":"/admin/cms/buddyscan/simulateur", name:"Simulateur"},
              ]}
            />
            <Switch>
              <Route 
                  path="/admin/cms/buddyscan/ecoles" 
                  render={(props) => <Ecoles {...props} />}
                  exact
                />
                <Route 
                  path="/admin/cms/buddyscan/experiences" 
                  render={(props) => <ExpPro {...props} />}
                  exact
                />
                 <Route 
                  path="/admin/cms/buddyscan/ponderation" 
                  render={(props) => <Ponderation {...props} />}
                  exact
                />
                <Route 
                  path="/admin/cms/buddyscan/questions" 
                  render={(props) => <QuestionsBuddyScan {...props} />}
                  exact
                />
            </Switch>
        </Router>
    )
    
}

export default BuddyScan;
