import React from "react";
import { isMobile } from "react-device-detect";

import Modal from "@utils/modal/Modal";
import Form from "@utils/form/Form";

import styles from "./editprofile.module.css";

const EditProfile = ({ user, closeModal, updateUser }) => {
  function onSuccess({ response }) {
    updateUser({ ...response, ...{ verif: user.verif } });
    closeModal();
  }

  return (
    <Modal
      closeModal={closeModal}
      header="Modifier le profil"
      modalShrink={true}
      style={isMobile ? { maxHeight: "none", height: "100%" } : {}}
    >
      <div className={styles.container_image}>
        <Form
          fields={[
            {
              type: "photo",
              key: "version",
              title: "Photo de profil",
              value: user.image,
              extraData: { type: "round", maxSize: 500000 },
            },
            {
              type: "input",
              key: "prenom",
              title: "Prénom",
              value: user.prenom,
            },
            { type: "input", key: "nom", title: "Nom", value: user.nom },
            { type: "input", key: "email", title: "Email", value: user.email },
          ]}
          autoHeight={true}
          buttons={{
            sub_action: {
              label: `Annuler`,
              type: "cancel",
              onPress: closeModal,
            },
            action: { label: `Modifier`, type: "full" },
          }}
          endpoint={"user/edit_profile"}
          success={onSuccess}
        />
      </div>
    </Modal>
  );
};

export default EditProfile;
