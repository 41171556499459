import React from 'react';
import Modal from 'react-modal';
import { isMobile } from 'react-device-detect';

import {ReactComponent as IconClose} from '@icon/site/close.svg';

import styles from './modal.module.css';

var update = require('immutability-helper');

const CustomModal = ({
    children,
    closeModal,
    modalShrink,
    simpleModal,
    style,
    header,
    footer,
    styleModal = {},
}) => (
  <Modal
    isOpen={true}
    //style={styleModal}
    style={isMobile ? {content:{inset:0, top:0, bottom:0, right:0, left:0, padding:0}} : {}}
  > 
    <div className={styles.backgroundModal} onClick={closeModal}></div>
    <div className={`${styles.contentModal} ${modalShrink && styles.modalShrink} ${simpleModal && styles.simpleModal}`} style={style}>
      {header && (
        <div className={styles.container_header}>
          <div className={styles.label_header}>{header}</div>
          {closeModal && (
            <div className={`${styles.container_close}  hover_grey`} onClick={closeModal}>
              <IconClose className='image_contain'/>
            </div>
          )}
        </div>
      )}
      {children}
      {footer && (
        <div className={styles.banner_button} style={footer.style}>
          {footer.content}
        </div>
      )}
    </div>
  </Modal>
)

export default CustomModal

 