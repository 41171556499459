import React, { useState, useEffect } from 'react';

import Field from '@utils/form/field/Field';
import List from '@utils/list/List';
import Modal from '@utils/modal/Modal';
import Form from '@utils/form/Form';
import useListReducer from '@utils/function/useListReducer';
import Searchbar from '@utils/searchbar/Searchbar';

import customFetch from '@utils/function/customFetch';

import styles from '@admin/cms/listes/listes.module.css';

var update = require('immutability-helper');

const QuestionBuddyScan = ({
    
}) => {

  const [deleteItem, setDeleteItem] = useState(false)
  const [updateItem, setUpdateItem] = useState(false)
  const [form, getForm] = useState(null)
  const [state, updateData] = useListReducer()
  const [search, onSearch] = useState(null)

  useEffect(() => {
    if(updateItem){
      customFetch({ endpoint: 'admin/get_form_questions', data:{id:updateItem?.id}, onSuccess: (data) => getForm(data.response)}) 
    }else{
      getForm(null)
    }
  }, [updateItem?.id])

  function renderItem(item){
    return(
      <div className={styles.item} key={item.id.toString()}>
        {item.image && (
          <div className="round_image">
            <img src={item.image}/>
          </div>
        )}
        <div className={styles.label_item}>{item.label}</div>
        <div className={styles.container_action} onClick={() => setUpdateItem(item)}>
          <img src={require('@admin/icon/edit.svg').default}/>
        </div>
        {!item.locked && (
          <div className={styles.container_action} onClick={() => setDeleteItem(item)}>
            <img src={require('@admin/icon/delete.svg').default}/>
          </div>
        )}
      </div>
    )
  }

  function renderHeader(){
    return(
      <Searchbar 
        button={{label:'Ajouter un élément', onClick:() => setUpdateItem({id:0})}}
        onSearch={onSearch}
        search={search}
      />
    )
  }

  function onSuccess(data){
    updateData({type:updateItem.id ? 'update_item' : 'create_item', item:data.response})
    setUpdateItem(null)
  }

  function onDelete(id){
    updateData({type:'delete_item', item:{id}})
    setDeleteItem(null)
  }

  return(
    <div className="container">
      <List 
        endpoint="admin/get_questions"
        data={state.data}
        renderItem={renderItem}
        renderHeader={renderHeader}
        data_received={(_data, refresh) => updateData(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
        isLoading={state.isLoading}
        socketKeys={"get_questions"}
        search={search}
      />
      {updateItem && form && (
        <Modal 
          closeModal={() => setUpdateItem(null)}
          modalShrink
        >
          <div className={styles.container_form}>
            <div className={styles.container_header}>
              <div className={styles.container_label_header}>{!updateItem.id ? "Créer un élément" : "Modifier un élément"}</div>
              <div className={styles.container_action} onClick={() =>  setUpdateItem(null)}>
                <img src={require('@admin/icon/close.svg').default}/>
              </div>
            </div>
            <Form
              fields={form}
              buttons={{action:{label:'Enregistrer', type:'full'}, sub_action:{label:'Annuler', type:'cancel', onPress:() => setUpdateItem(null)}}} 
              autoHeight
              endpoint="admin/update_question_buddyscan"
              key={updateItem.id}
              success={onSuccess}
              id={updateItem.id}
            />
          </div>
        </Modal>
      )}
      {deleteItem && (
        <Modal 
          closeModal={() => setUpdateItem(null)}
          modalShrink
        >
          <div className={styles.container_form}>
            <div className={styles.container_header}>
              <div className={styles.container_label_header}>Supprimer un élément</div>
              <div className={styles.container_action} onClick={() =>  setDeleteItem(null)}>
                <img src={require('@admin/icon/close.svg').default}/>
              </div>
            </div>
            <Form
              fields={[]}
              buttons={{action:{label:'Supprimer', type:'delete', field:[{key:'deleted', value:1}]}, sub_action:{label:'Annuler', type:'cancel', onPress:() => setDeleteItem(null)}}} 
              autoHeight
              endpoint="admin/update_question_buddyscan"
              key={deleteItem.id}
              success={() => onDelete(deleteItem.id)}
              id={deleteItem.id}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default QuestionBuddyScan;
