import React from 'react';

import Cabs from './cabs/Cabs';
import Users from './users/Users';
import Tab from '@utils/tab/Tab';

import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";

const Matching = ({
  
}) => {
  
  return(
    <>
      <Tab 
        style={{borderRadius:'1vh'}}
        tabs = {[
          {"pathname":"/admin/matching/candidats", name:"Candidats", routes:["/admin/matching/candidats", "/admin/matching"]},
          {"pathname":"/admin/matching/cabinets", name:"Cabinets"},
        ]}
      />
      <Switch>
        <Route 
          path={["/admin/matching", "/admin/matching/candidats"]}
          exact
          render={(props) => <Users {...props} />}
        />

        <Route 
          path="/admin/matching/cabinets" 
          render={(props) => <Cabs {...props} />}
        />
      </Switch>
    </>
  )
    
}

export default Matching;
