import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import Modal from '@utils/modal/Modal';
import Button from '@utils/button/Button';
import Field from '@utils/form/field/Field';

import customFetch from '@utils/function/customFetch';

import styles from './questionBuddyScan.module.css';
import styles_list from '@utils/list/list.module.css';

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const AnswerList = ({
  answer,
  onSelect,
  selected
}) => {
  return(
    <div className={`${styles.container_answer_list} ${selected && styles.answer_list_selected} hover_background_color`} onClick={() => onSelect(answer.id)}>
      {answer.label}
    </div>
  )
}

const AnswerBuddyScan = ({
  answer,
  index,
  onSelect,
  selected
}) => {

  return(
    <div className={`${styles.container_answer} ${selected && styles.answer_selected} hover_color`} onClick={() => onSelect(answer.id)}>
      <div className={`${styles.index_answer}`}>
        {letters[index]}
      </div>
      <div className={`${styles.label_answer}`}>
        {answer.label}
      </div>
    </div>
  )
}

const QuestionBuddyScan = ({
  question,
  answers = [],
  userAnswers = [],
  updateUserAnswers,
  isLoading,
  updateIsLoading
}) => {

  //const [userAnswers, updateUserAnswers] = useState(answers)
  const [listAnswers, updateListAnswers] = useState([])
  const [addlistInitialValues, updateAddListInitialValues] = useState(null)
  //const [isLoading, updateIsLoading] = useState(false)

  useEffect(() => {
    if(question?.type === 2 && answers?.length>0){
      updateUserAnswers(answers)
      updateIsLoading(false)
    }else if(question?.type === 0){
      customFetch({ endpoint: 'get_item', data:question.data, onSuccess:onReceivedListAnswers}) 
      updateUserAnswers(answers)
    }else{
      updateUserAnswers(answers)
      updateIsLoading(false)
    }
  }, [question])

  function onReceivedListAnswers(data){
    updateListAnswers(data.response.sort((a, b) => a.label.localeCompare(b.label)))
    updateIsLoading(false)
  }

  function onSelect(value){
    let updatedAnswers = [...userAnswers]
    if(question.multiple || question.type === 0 && question.id !== 7){
      let index = userAnswers.findIndex(x => x === value)
      if(index !== -1){
        updatedAnswers.splice(index, 1)
      }else{
        updatedAnswers.push(value)
      }
      updateUserAnswers(updatedAnswers)
    }else{
      updatedAnswers = [value]
      updateUserAnswers(updatedAnswers)
    }
  }

  function onChangeAddlist(data){
    let updatedAnswers = [...userAnswers]
    for(var key in data.value){
      let index = updatedAnswers.findIndex(x => x.addlist === parseInt(key))
      if(index !== -1){
        updatedAnswers[index] = {...updatedAnswers[index], ...data.value[key], ...{addlist:parseInt(key)}}
      }else{
        updatedAnswers.push({...data.value[key], ...{addlist:parseInt(key)}})
      }
    }
    updateUserAnswers(updatedAnswers)
  }

  function renderAnswers(){
    if(isLoading) return null

    //Special display for question 7
    if(question.id === 7){
      return(
        <div className={styles.container_list_answers}>
          {listAnswers.map((item, index) => {
            return(
              <AnswerBuddyScan
                answer={item}
                index={index}
                onSelect={onSelect}
                selected={userAnswers.find(x => x === item.id)}
              />
            )
          })}
        </div>
      )
    }else if(question.type === 0){
      return(
        <div className={styles.container_answers_list}>
          {listAnswers.map((item, index) => {
            return(
              <AnswerList
                answer={item}
                onSelect={onSelect}
                selected={userAnswers.find(x => x === item.id)}
              />
            )
          })}
        </div>
      )
    }else if(question.type === 1){
      return(
        <div className={styles.container_list_answers}>
          {question.answers.map((item, index) => {
            return(
              <AnswerBuddyScan
                answer={item}
                index={index}
                onSelect={onSelect}
                selected={userAnswers.find(x => x === item.id)}
              />
            )
          })}
        </div>
      )
    }else{
      return(
        <Field
          field={{...question.field[0], ...{value:userAnswers}}}
          onChange={onChangeAddlist}
          key={question.id.toString()}
        />
      )
    }
  }

  return(
    <Scrollbars 
      autoHide
      //autoHeight={props.autoHeight}
      renderView={props => <div {...props} className={styles_list.view}/>}
      renderTrackHorizontal={props => <div {...props} className={styles_list.horizontal_track}/>}
      renderThumbHorizontal={props => <div {...props} className={styles_list.horizontal_thumb}/>}
      className={styles_list.inner_list}
    >
      <div className={`${styles.container_question}`}>
        <div className={styles.title}>{question.title}</div>
        {question.subtitle && (
          <div className={styles.subtitle}>{question.subtitle}</div>
        )}
        {renderAnswers()}
      </div>
    </Scrollbars>
  )
};

export default QuestionBuddyScan

 
