import React from 'react';

import SelectForm from '@utils/form/selectForm/SelectForm';

const Article = ({

}) => {

  return(
    <SelectForm 
      endpointList="admin/get_list_articles"
      endpointForm="admin/get_form_article"
      endpointUpdate="admin/update_article"
      endpointPreview="preview_articles"
      labelCreate="Créer un nouvel article"
    />
  );
};
/*
const Article = ({
    
}) => {
  const [loading, updateLoading] = useState(false)
  const [sections, updateSections] = useState(null)
  const [selectedArticle, updateSelectedArticle] = useState(null)

  useEffect(() => {
    selectedArticle && customFetch({ endpoint: 'admin/get_form_article', data:{id:selectedArticle.id}, onSuccess}) 
    selectedArticle && updateLoading(true)
  }, [selectedArticle?.id])

  function onSuccess(data){
    updateSections(data.response)
    updateLoading(false)
  }

  if(loading){
    return null
  }else{
    return(
      <div className="container">
        <Field
          onChange={(data) => updateSelectedArticle(data.value)}
          field={{type:"select", socket:"admin/get_list_articles", value:selectedArticle, placeholder:"Sélectionner un article existant ou en créer un nouveau", extraData:{sort:'id DESC', create:'Créer un nouvel article'}}}
          listener={true}
        />
        {sections && (
          <Form
            endpoint={"admin/update_article"}
            preview_endpoint={"preview_article"}
            sections={sections}
            type="formadmin"
            id={selectedArticle?.id}
            lastModif={selectedArticle?.lastModif}
            status={selectedArticle?.status}
            key={selectedArticle?.id || '0'}
            success={(data) => updateSelectedArticle(data.response)}
            preview={true}
          />
        )}
      </div>
    )
  }
}*/

export default Article

 
