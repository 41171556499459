import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';

import styles_switch from './switch.module.css';
import styles_field from '../field.module.css';

var update = require('immutability-helper');

function FieldSwitch(props) {

 const [checked, updateChecked] = useState(props.field.value ? true : false)

  function handleChange(){
    updateChecked(!checked)
    props.onChange({
      value:!checked, 
      key:props.field.key,
      section:props.section
    })
  }

 /*  useEffect(() => {
    if(!props.field.value){
      props.onChange({
        value:0, 
        key:props.field.key,
        section:props.section
      })
    }
  }, []) */

    return(
      <div className={`${styles_field.container_field} ${styles_switch.container_switch} ${props.field.extraData?.row && styles_switch.row}`} style={props.style}>
        {props.renderLabelField && props.renderLabelField()}
        <div className={`${styles_switch.switch} ${checked && styles_switch.checked}`} onClick={handleChange}>
          {props.options ? (
            <div className={styles_switch.container}>{checked ? props.options[0] : props.options[1]}</div>
          ):(
            <div className={styles_switch.container}>{checked ? 'Oui' : 'Non'}</div>
          )}
          <div className={styles_switch.button}></div>
        </div>
      </div>
    )
}

export default FieldSwitch;
