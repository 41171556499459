import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import Modal from '@utils/modal/Modal';
import Field from '@utils/form/field/Field';
import Button from '@utils/button/Button';
import Scrollbars from '@utils/scrollbars/Scrollbars';
import LinkedIn from '@utils/linkedin/LinkedIn';

import Header from '../header/Header';
import Footer from '@site/footer/Footer';
import BannerScreening from '@site/home/bannerscreening/BannerScreening';
import ButtonBuddyScan from '@site/home/buttonbuddyscan/ButtonBuddyScan';

import ModalConnect from '@site/connect/ModalConnect';
import ModalForget from '@site/forget/ModalForget';
import ModalReset from '@site/reset/ModalReset';
import ModalSubscribeRecruteur from './modalsubscriberecruteur/ModalSubscribeRecruteur';

import customFetch from '@utils/function/customFetch';

import styles from './landing.module.css'

const Landing = ({
  onSuccessLogin,
  location
}) => { 

  const [modal, showModal] = useState()

  const searchParams = []

  useEffect(() => {
    if(location.search){
      const params = location.search.replace('?', '').split('&')
      for(let i=0; i<params.length; i++){
        const data = params[i].split('=')
        searchParams.push({key:data[0], value:data[1]})
      }
    }
    if(searchParams.length>0 && searchParams[0].key === 'forgot'){
      customFetch({ endpoint: 'recruteur/check_forgot', onSuccess:onResetPassword, type:'POST', data:{key:searchParams[0].value}})
    }
  }, [])

  function onResetPassword({response}){
    if(response) showModal({type:'reset', key:response})
  }

  return(
    <div className={styles.container_landing}>

      <Header 
        showConnect={() => showModal('connect')}
        showSubscribe={() => showModal('subscribe')}
      />

      <Scrollbars>

        <div className={styles.intro_landing}>
          <div className={styles.image_landing}>
            <img src={require('@recruteur/icon/intro.svg').default} className="image_contain"/>
          </div>
          <div className={styles.text_landing}>
            <div className={styles.title_landing}>Recruter sur</div>
            <div className={styles.logo_landing}>
            <img src={require('@recruteur/icon/logo.svg').default} className="image_contain"/>
            </div>
            <div className={styles.description_landing}>Stagiaires, juniors et profils expérimentés : recrutez vos futurs consultants sur la première plateforme dédiée au secteur du conseil.</div>
            <div className={styles.description_landing}>Renforcez votre marque employeur, postez des offres et recevez directement des sélections de consultants à l'écoute du marché.</div>
            <div className={styles.button}>
              <ButtonBuddyScan 
                label='Trouvez le candidat idéal'
                white={true}
                onClick={() => showModal('subscribe')}
              />
            </div>
          </div>
        </div>

        <BannerScreening 
          recruteur={true}
          style={{margin:'20vh 5vw'}}
          onClick={() => showModal('subscribe')}
        />
        
        <Footer/>
      </Scrollbars>

      {modal === 'connect' && (
        <ModalConnect
          recruteur={true}
          onClose={() => showModal()}
          endpoint='login_recruteur'
          onSuccess={onSuccessLogin}
          showForgetPassword={() => showModal("forget")} 
        />
      )}

      {modal === 'subscribe' && (
        <ModalSubscribeRecruteur
          onClose={() => showModal()}
        />
      )}

      {modal === "forget" && (
        <ModalForget
          onClose={() => showModal()} 
          recruteur={true}
        />
      )}

      {modal?.type === "reset" && (
        <ModalReset
          onClose={() => showModal()} 
          recruteur={true}
          resetKey={modal.key}
        />
      )}


    </div>
  )
}


export default Landing;