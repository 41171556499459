import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";

import FormAdmin from "./formadmin/FormAdmin";
import FormView from "./formview/FormView";

import Field from "./field/Field";
import Button from "@utils/button/Button";

import styles from "@utils/list/list.module.css";
import styles_form from "./form.module.css";

import _ from "lodash";
import { forEachLimit } from "async";

import customFetch from "@utils/function/customFetch";

var update = require("immutability-helper");

function Form({
  id,
  fields,
  sections,
  endpoint,
  buttons,
  type,
  lastModif,
  status,
  success,
  autoHeight,
  preview,
  isFormData,
  noNumber,
  renderHeader,
  renderFooter,
  sendForm,
  disabled,
  onValidateForm,
  label_button,
  keepData = false,
}) {
  const [data_form, updateDataForm] = useState({});
  const [dataFiles, updateDataFiles] = useState({});
  const [error, updateError] = useState(null);
  const [required, updateRequired] = useState([]);
  const [showMissing, updateShowMissing] = useState();
  const [showSuccess, updateShowSuccess] = useState(false);

  useEffect(() => {
    if (sendForm) onValidate(sendForm);
  }, [sendForm]);

  useEffect(() => {
    setTimeout(() => updateShowSuccess(false), 5000);
  }, [showSuccess]);

  useEffect(() => {
    if (fields) {
      let requiredFields = fields.filter((item) => item.required);
      requiredFields = requiredFields.map((item) => ({
        key: item.key,
        complete: isComplete(item),
      }));
      updateRequired(requiredFields);
    } else if (sections) {
      let requiredFields = [];
      for (let i = 0; i < sections.length; i++) {
        let fields = sections[i].fields.filter((item) => item.required);
        fields = fields.map((item) => ({
          key: item.key,
          section: i,
          complete: isComplete(item),
        }));
        requiredFields = [...requiredFields, ...fields];
      }
      updateRequired(requiredFields);
    }
  }, []);

  function isComplete(item) {
    if (Array.isArray(item.value)) return item.value.length > 0;
    return item.value !== undefined && item.value !== null;
  }

  function onChange(data) {
    let arr = { ...data_form };
    let files = { ...dataFiles };

    if (!data.key) {
      return;
    }

    if (data.image || data.image === null) {
      if (data.image?.value) {
        files[data.image.value] = data.image.image;
      } else {
        files[data.value] = data.image;
      }
      if (data.image === null) {
        delete files[data.value];
        data.value = null;
      }
    }

    if (data.key.value || data.key.value === 0) {
      if (!arr[data.key.value]) arr[data.key.value] = {};
      arr[data.key.value][data.key.type] =
        data.value?.id || data.value?.id === 0 ? data.value.id : data.value;
    } else {
      arr[data.key] =
        data.value?.id || data.value?.id === 0 ? data.value.id : data.value;
    }
    updateDataForm(arr);
    updateDataFiles(files);
    updateError(false);

    const indexRequired = required.findIndex((x) => x.key === data.key);
    if (indexRequired !== -1) {
      const newRequired = [...required];
      newRequired[indexRequired] = {
        ...newRequired[indexRequired],
        ...{ complete: isComplete(data) },
      };
      updateRequired(newRequired);
    }
  }

  function onValidate(extraField) {
    let dataToSend = { ...data_form };
    if (extraField && Array.isArray(extraField)) {
      for (let i = 0; i < extraField.length; i++) {
        dataToSend[extraField[i].key] = extraField[i].value;
      }
    }

    if (id) dataToSend.id = id;
    if (onValidateForm) {
      onValidateForm(dataToSend);
    } else {
      customFetch({
        endpoint,
        files: dataFiles,
        data: _.omit(dataToSend, "complete"),
        onSuccess,
        onError,
      });
    }
    updateShowMissing(true);
  }

  function onSuccess(data) {
    if (data?.response?.preview) {
      window.open(data.response.preview, "_blank");
    } else {
      if (type === "formadmin") updateShowSuccess(true);
      if (!keepData) updateDataForm({});
      if (success) {
        success(data);
      }
    }
  }

  function onError(data) {
    updateError(data);
  }

  function onDelete() {
    if (buttons && buttons.delete) {
      customFetch({
        endpoint: buttons.delete.endpoint,
        data: { [buttons.delete.field.key]: buttons.delete.field.value },
        onSuccess,
      });
    }
  }

  if (type === "formadmin") {
    return (
      <>
        <FormAdmin
          onValidate={onValidate}
          onChange={onChange}
          fields={fields}
          sections={sections}
          lastModif={lastModif}
          status={status}
          preview={preview}
          dataForm={data_form}
          dataFiles={dataFiles}
          error={error}
          required={required}
          showMissing={showMissing}
          label_button={label_button}
        />
        {showSuccess && (
          <div className={styles_form.containerSuccess}>
            <div className={styles_form.labelSuccess}>
              Les modifications ont été publiées
            </div>
            <div
              className={`${styles_form.containerCloseSuccess} hover_color`}
              onClick={() => updateShowSuccess(false)}
            >
              <img
                src={require("@icon/close.svg").default}
                className="image_contain"
              />
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <FormView
        onValidate={onValidate}
        onChange={onChange}
        onDelete={onDelete}
        fields={fields}
        sections={sections}
        buttons={buttons}
        autoHeight={autoHeight}
        dataForm={data_form}
        dataFiles={dataFiles}
        noNumber={noNumber}
        renderHeader={renderHeader}
        renderFooter={renderFooter}
        disabled={disabled}
        error={error}
        isComplete={required && !required.find((x) => !x.complete)}
      />
    );
  }
}

export default Form;
