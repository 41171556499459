import React, { useState, useEffect, Stylesheet } from 'react';

import LinkedIn from '@utils/linkedin/LinkedIn'
import Form from '@utils/form/Form'

import styles from '@site/buddyScan/buddyscan.module.css';
import styles_s from './subscribe.module.css';

const Subscribe = ({
  user,
  onSuccessSubscribe,
  text,
  style,
  hideTitle,
  id_offre
}) => {

  const [showOtherSubscribe, updateShowOtherSubscribe] = useState(false)

  return(
    <div className={`container ${styles.container_intro}`} style={style}>
      {!hideTitle && (
        <>
          <div className={styles.intro_title}>
            Félicitations, votre profil est établi !
          </div>
        </>
      )}
      {!showOtherSubscribe && (
        <>
          <div className={`${styles.intro_image} ${styles_s.intro_image}`}>
            <img src={require('@icon/site/subscribeBuddyScan.svg').default}/>
          </div>
          <div className={styles_s.text_subscribe}>
            {text}
          </div>
        </>
      )}
      <div className={`${styles.container_subscribe} ${styles_s.container_subscribe}`}>
        <LinkedIn
          user={user}
          style={{margin:'2vh 0'}}
          id_offre={id_offre}
          connect={false}
        />
        {!showOtherSubscribe && (
          <div className={styles_s.container_info_linkedin} onClick={() => updateShowOtherSubscribe(true)}>
            <img src={require('@icon/cookie.svg').default}/>
            <div className={styles_s.label_info_linkedin}>Pourquoi utiliser mon profil LinkedIn ?</div>
            <div className={styles_s.popup_info_linkedin}>
              <div>Le connecteur LinkedIn vous permet d’éviter de renseigner manuellement vos nom, prénom et adresse mail nécessaires à la création de votre espace utilisateur.</div>
              <div><span><span style={{fontWeight:'500'}}>Ce sont les seules données transmises par linkedin</span> (cf. politique de confidentialité)</span></div> 
            </div>
          </div>
        )}
        {!showOtherSubscribe && (
          <div className={`${styles.container_other} hover_grey`} onClick={() => updateShowOtherSubscribe(true)}>
            Je n'ai pas de compte LinkedIn
          </div>
        )}
        {showOtherSubscribe && (
          <Form 
            fields={[
              {type:"input", key:"prenom", title:"Prénom", required:true, style:{width:'50%'}},
              {type:"input", key:"nom", title:"Nom", required:true, style:{width:'50%'}},
              {type:"input", key:"email", title:"Email", required:true},
              {type:"input", key:"password", title:"Mot de passe", required:true, extraData:{input_type:'password'}},
              {type:"checkbox", key:"cgu", required:true, options:[
                {label:`J'ai lu et j'accepte les <a href='cgu' target="_blank">CGU</a> de Consulting Buddies`, value:1},
              ]},
            ]}
            buttons={{action:{label:`Je m'inscris`, type:'full', field:[{key:'id_offre', value:id_offre}]}}} 
            autoHeight={true}
            endpoint='user/subscribe'
            success={onSuccessSubscribe}
          />
        )}
      </div>
    </div>
  )
}

export default Subscribe

 