import React, { useEffect, useState } from 'react';

import customFetch from '@utils/function/customFetch';
import TextEditor from '@utils/form/field/texteditor/TextEditor';

import styles from './administratif.module.css';


const Administratif = ({
  title,
  data
}) => {
  const [content, updateContent] = useState()

  useEffect(() => {
    customFetch({ endpoint: 'get_administratif', onSuccess, type:'POST', data})
  }, [])

  function onSuccess({ response }){
    updateContent(response)
  }

  if(!content) return null

  return(
    <div className={styles.container_administratif}>
      <div className={styles.title_administratif}>
        {title}
      </div>
      <div className={styles.content_administratif}>
        <TextEditor 
          field={{value:content, extraData:{min:true}}}
          display={true}
        />
      </div>
    </div>
  )
}

export default Administratif;
