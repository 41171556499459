import React, { useEffect, useState, useImperativeHandle } from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import styles_field from '../field.module.css';
import styles_input from './input.module.css';

const Input = React.forwardRef((props, refParent) => {

  const [value, updateValue] = useState(props.field.value || props.field.value === 0 ? props.field.value : '');
  const [error, updateError] = useState(null)

  var timeoutId

  useImperativeHandle(refParent, () => ({ reset }));

  function reset(){
    updateValue('')
  }

  function onChange(evt){

    if(timeoutId){
      clearTimeout(timeoutId);
    }

    let value = evt.target.value
    let _value_onchange = value

    if(value && props.field.extraData && props.field.extraData.regex){
      var pattern
      if(props.field.extraData.regex === "email"){
        pattern = /^\S+@\S+\.\S+$/
      }else if(props.field.extraData.regex === "phone"){
        _value_onchange = value.replaceAll(" ", "")
        pattern = /^0[1-9][0-9]{8}$/
      }else if(props.field.extraData.regex === "digit"){
        pattern = /^[0-9]*$/
      }else if(props.field.extraData.regex === "post_code"){
        pattern = /^(([0-8][0-9])|(9[0-8]))[0-9]{3}$/
      }else if(props.field.extraData.regex === "website"){
        pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
      }else if(props.field.extraData.regex === "decimal"){
        _value_onchange = value.replace(",", ".")
        pattern = /^[0-9]+((\.|\,)[0-9]+)?$/
      }

      if(pattern && !pattern.test(_value_onchange)){
        updateValue(value)
        updateError(true)
        return
      }
    }

    timeoutId = setTimeout(function() { 
      props.onChange({
        value:_value_onchange === '' ? null : _value_onchange, 
        key:props.field.key, 
        complete:value.length>0,
        addlist:props.field.addlist
      })
    }, 500);
    updateValue(value)
    updateError(null)
  }

  let classe = `${styles_field.container_field_input}`
  classe += error ? ` ${styles_input.error_input}` : ''
  classe += props.showMissing && !props.complete ? ` ${styles_input.item_missing}` : ``

  return(
    <div className={styles_field.container_field} style={props.field.style} ref={refParent}>
      {props.renderLabelField && props.renderLabelField(null, props.field.renderExtraLabelField)}
      <div className={classe}>
        {props.field.extraData && props.field.extraData.textarea ? (
          <TextareaAutosize
            placeholder={"Ecrire ici"}
            onChange={onChange}
            value={value}
          />
        ):(
          <input
            placeholder={props.field.placeholder || "Ecrire ici"}
            onChange={onChange}
            value={value}
            type={props.field.extraData ? props.field.extraData.input_type : null}
            disabled={props.field.disabled || props.disabled}
          />
        )}
        {props.field.extraData && props.field.extraData.unit && (
          <div className={styles_input.unit}>{props.field.extraData.unit}</div>
        )}
      </div>
    </div>
  )
});

export default Input;