import React, { useEffect, useState } from 'react';
import { isMobile, BrowserView } from 'react-device-detect';

import Form from '@utils/form/Form';
import ButtonBuddyScan from '@site/home/buttonbuddyscan/ButtonBuddyScan';

import customFetch from '@utils/function/customFetch';

import styles from './styles.module.css';

const Simulator = ({
  openBuddyScan
}) => {

  const [salaries, updateSalaries] = useState(null)

  useEffect(() => {
    customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 3, type: 6 } , type:'POST'})  
  }, [])

  const onSuccess = ({ response }) => {
    updateSalaries(response)
  }

  const ItemSalary = ({title, amount, styleLabel = {}, extension}) => {
    return(
      <div className={styles.item_salary}>
        <div className={styles.title_item_salary}>{title}</div>
        <div className={styles.amount_item_salary} style={styleLabel}>{amount ? `${currencyFormatter(amount)} ${extension}`: '-'}</div>
      </div>
    )
  }

  const currencyFormatter = (number) => {
    return new Intl.NumberFormat('fr-FR', { 
      style: 'currency', 
      currency: 'EUR', 
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number)
  }

  return(
    <div className={styles.container} >
      <h1 style={{display:'none'}}>Simulateur de salaire</h1>
      <div className={styles.intro}>
        <BrowserView  className={styles.logo}>
          <img src={require('@icon/consultingbuddies.svg').default} alt="Consulting Buddies"/>
        </BrowserView>
        <div className={styles.label_intro}>Quel salaire demander ou proposer en conseil ? Notre simulateur de salaire est basé sur les données de milliers de profils dans le conseil et mis à jour régulièrement afin de vous fournir une estimation la plus précise possible.</div>
      </div>
      <div className={styles.container_simulator}>
        <div className={styles.form}>
          <Form 
            fields={[
              {type:'select', key:'school', title:'Votre établissement d’études supérieures', placeholder:'Choisir établissement', socket:"get_item", extraData:{table:'List_Schools', sort:'name'}, required:true },
              {type:'select', key:'school_extra', title:'Votre formation complémentaire (facultatif)', placeholder:'Choisir établissement', socket:"get_item", extraData:{table:'List_Schools', sort:'name'}},
              {type:'select', key:'duration', title:'Durée de votre expérience', placeholder:'Choisir durée', socket:"get_duration_simulator", required:true},
              {type:'select', key:'cab_tags', title:'Catégorie de conseil', placeholder:'Choisir catégorie', socket:"get_item", extraData:{table:'List_Tags', sort:'name'}, required:true}
            ]}
            buttons={{
              action:{label:'Calculer mon salaire', type:'full'},
            }}
            endpoint={'simulator'}
            success={onSuccess}
            autoHeight={true}
            keepData={true}
          />
        </div>
        <div className={styles.results}>

          <div className={`${styles.section} ${styles.section_salaries}`}>
            <div className={styles.section_results}>
              <div className={styles.title_section}>Moyenne du salaire brut annuel</div>
              <div className={styles.items_salary}>
                <ItemSalary
                  title='Fourchette basse'
                  amount={salaries?.annual?.min}
                  extension='/ an'
                />
                <ItemSalary
                  title='Salaire moyen'
                  amount={salaries?.annual?.average}
                  extension='/ an'
                  styleLabel={{ fontSize:'23px'}}
                />
                <ItemSalary
                  title='Fourchette haute'
                  amount={salaries?.annual?.max}
                  extension='/ an'
                />
              </div>
            </div>

            <div className={styles.section_results}>
              <div className={styles.title_section}>Moyenne du salaire mensuel net d’impôt*</div>
              <div className={styles.items_salary}>
                <ItemSalary
                  title='Fourchette basse'
                  amount={salaries?.monthly?.min}
                  extension='/ mois'
                />
                <ItemSalary
                  title='Salaire moyen'
                  amount={salaries?.monthly?.average}
                  extension='/ mois'
                  styleLabel={{ fontSize:'23px'}}
                />
                <ItemSalary
                  title='Fourchette haute'
                  amount={salaries?.monthly?.max}
                  extension='/ mois'
                />
              </div>
            </div>
            
            <div className={styles.footer_results}>
              <div className={styles.label_details}><span>Vous souhaitez en savoir plus sur ces résultats ? <a href='/article/Simulateur_de_salaire_du_conseil_:_explications_et_méthodologie' target='_blank'>Cet article</a> présente la méthodologie et les principaux enseignements apportés par le simulateur.</span></div>
              <div className={styles.image_results}><img src={require('@icon/simulator_results.svg').default}/></div>
            </div>
          </div>


          <div className={styles.mention}>*Estimation pour une personne célibataire sans enfant</div>
          
          <div className={styles.section} style={{flexDirection:'row', alignItems:'center'}}>
            <div className={styles.illustration}>
              <img className="image_contain" src={require('@icon/illustration_recruit.svg').default}/>
            </div>
            <div className={styles.content}>
              <div className={styles.title_content}>Quel cabinet de conseil est fait pour vous ?</div>
              <ButtonBuddyScan
                label="Découvrir mon score"
                style={{alignSelf:isMobile ? 'center' : 'flex-start', marginTop:'2vh'}}
                onClick={openBuddyScan}
              />
            </div>
          </div>


          <div className={`${styles.mention} ${styles.label_details}`}><span>N’hésitez pas à <a href="mailto:thomas@consulting-buddies.com">nous contacter</a> pour nous aider à améliorer le simulateur.</span></div>


        </div>
      </div>

    </div>
  )
}

export default Simulator;
