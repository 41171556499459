import React, { useState, useEffect, Stylesheet } from "react";
import ReactHtmlParser from "react-html-parser";

import Modal from "@utils/modal/Modal";
import Button from "@utils/button/Button";
import Scrollbars from "@utils/scrollbars/Scrollbars";
import Field from "@utils/form/field/Field";
import Options from "@utils/form/field/options/Options";
import Subscribe from "@user/subscribe/Subscribe";
import ModalConnect from "@site/connect/ModalConnect";

import BannerCab from "@recruteur/recrutement/bannercab/BannerCab";

import Intro from "./intro/Intro.js";
import ApplyCV from "./applyCV/ApplyCV.js";

import customFetch from "@utils/function/customFetch";

import styles from "./apply.module.css";
import styles_home from "@user/homeuser.module.css";

function FormApply({ offre, onChange }) {
  if (!offre.spontaneous) {
    return (
      <div className={`${styles.content_offre} ${styles.content_letter}`}>
        <div>
          <img src={require("../icon/apply_letter.svg").default} />
        </div>
        <div className={styles.label_apply}>
          C’est la dernière étape ! Expliquez en quelques lignes au cabinet
          pourquoi vous souhaitez postuler chez eux !
        </div>
        <Field
          field={{
            type: "texteditor",
            title: "Votre lettre de motivation",
            extraData: { min: true },
            key: "letter",
          }}
          onChange={onChange}
        />
      </div>
    );
  } else {
    return (
      <div className={`${styles.content_offre} ${styles.content_letter}`}>
        <Field
          field={{
            type: "select",
            title: "Type de contrat souhaité",
            socket: "get_item",
            extraData: { table: "List_Contract" },
            key: "contract",
          }}
          onChange={onChange}
        />
        <Field
          field={{ type: "input", title: "Email de candidature", key: "email" }}
          onChange={onChange}
        />
        <Field
          field={{ type: "input", title: "Objet", key: "title" }}
          onChange={onChange}
        />
        <Field
          field={{
            type: "texteditor",
            title: "Message",
            extraData: { min: true },
            key: "letter",
          }}
          onChange={onChange}
        />
      </div>
    );
  }
}

function Apply({
  offre,
  onClose,
  cv,
  onSuccessApply,
  spontaneous,
  id_offre,
  id_cab,
  user,
  fetchUser,
  initialOffre,
  onModalOffreBlockOpen,
  showIntro,
  showConnect,
}) {
  const [step, updateStep] = useState(initialOffre ? 1 : 0);
  const [applyData, updateApplyData] = useState({ cv: cv });
  const [applyFiles, updateApplyFiles] = useState();
  const [showSubscribe, updateShowSubscribe] = useState(false);
  const [isComplete, updateComplete] = useState(true);

  useEffect(() => {
    customFetch({
      endpoint: "activity/activity",
      data: {
        token: localStorage.getItem("token"),
        id_activity: 8,
        id_offre,
        id_cab,
      },
      type: "POST",
    });
  }, []);

  useEffect(() => {
    if (step === 0) updateComplete(true);
    if (step === 1) updateComplete(applyData.cv || applyFiles?.cv);
    if (step === 2)
      updateComplete(
        applyData.letter &&
          applyData.letter.replace("<p><br></p>", "").length > 0
      );
    if (step === 1 && user.verif === 1) onModalOffreBlockOpen(true);
  }, [step, applyData, applyFiles]);

  function renderFooter() {
    if (offre.detailsOffre?.ats && user && !showIntro)
      return (
        <div
          className={styles.footer_apply}
          style={{ justifyContent: "flex-end" }}
        >
          <div className={styles.buttons_action}>
            <Button
              label={step === 0 ? "Suivant" : "Finaliser ma candidature"}
              type="full"
              onClick={validateStep}
            />
          </div>
        </div>
      );

    return (
      <div className={styles.footer_apply}>
        {step > 0 ? (
          <div
            className={styles.button_back}
            onClick={() => updateStep(step - 1)}
          >
            Retour
          </div>
        ) : (
          <div></div>
        )}
        <div className={styles.buttons_action}>
          <Button
            label="Voir la fiche cabinet"
            onClick={() =>
              window.open(
                process.env.REACT_APP_ENDPOINT +
                  "/cabinet/" +
                  offre.cab.pathname,
                "_blank"
              )
            }
          />
          {!showIntro && (
            <Button
              label={
                step === 1 &&
                (!offre.detailsOffre || offre.detailsOffre?.letter)
                  ? "Suivant"
                  : "Postuler"
              }
              type="full"
              onClick={validateStep}
              style={{ marginLeft: "1vw" }}
              disabled={!isComplete}
            />
          )}
        </div>
      </div>
    );
  }

  function onChange(data) {
    let updatedData = { ...applyData };
    updatedData[data.key] = data.value?.id ? data.value.id : data.value;
    updateApplyData(updatedData);
  }

  function getCV(e) {
    if (e.target.files && e.target.files[0]) {
      updateApplyFiles({ cv: e.target.files[0] });
    }
  }
  function deleteCV() {
    onChange({ key: "cv", cv: null });
    updateApplyFiles(null);
  }

  function validateStep() {
    if (offre.detailsOffre?.ats && user) {
      if (step === 0) {
        updateStep(1);
      } else {
        window.open(offre.detailsOffre.ats, "_blank");
        validateApply();
      }
      return;
    }

    if (step === 0 && (!user || !user.verif)) {
      updateShowSubscribe(0);
      return;
    }

    if (
      (step === 1 && offre.detailsOffre && !offre.detailsOffre?.letter) ||
      step === 2
    ) {
      validateApply();
    } else {
      updateStep(step + 1);
    }
  }

  function validateApply(data) {
    let inputData = { ...applyData };
    if (id_offre) inputData = { ...inputData, ...{ id_offre } };
    if (id_cab) inputData = { ...inputData, ...{ id_cab } };
    customFetch({
      endpoint: "user/applyOffre",
      onSuccess: onSuccessApply,
      type: "POST",
      files: applyFiles,
      data: inputData,
    });
  }

  function renderContent() {
    if (showSubscribe !== false) {
      return (
        <>
          <Options
            field={{
              value: 0,
              options: [
                { label: `S'incrire`, value: 0 },
                { label: `Se connecter`, value: 1 },
              ],
              style: { alignItems: "center", marginTop: "2vh" },
            }}
            onChange={(item) => updateShowSubscribe(item.value)}
          />
          {showSubscribe === 0 ? (
            <Subscribe
              onSuccessSubscribe={({ response }) => {
                window.location.href = response;
                /* fetchUser()
                updateStep(1)
                updateShowSubscribe(false) */
              }}
              text="C’est simple : vous vous inscrivez, vous ajoutez votre CV et vous postulez !"
              style={{ flex: "none" }}
              hideTitle={true}
              id_offre={
                offre.spontaneous ? `s${offre.cab.id}` : offre.detailsOffre?.id
              }
            />
          ) : (
            <ModalConnect
              //onClose={() => updatePopUp()}
              onSuccess={({ response }) => {
                window.location.href = `/candidat/candidatures`;
                /* fetchUser()
                updateStep(1)
                updateShowSubscribe(false) */
              }}
              endpoint="login_user"
              showForgetPassword={() => updatePopUp("forget")}
              apply
            />
          )}
        </>
      );
    }

    if (step === 0 || showIntro)
      return (
        <Intro
          offre={offre.detailsOffre}
          presentation={offre.cab.presentation}
        />
      );

    if (offre.detailsOffre?.ats && user) {
      return (
        <div className={`${styles.content_offre} ${styles.content_cv}`}>
          <div>
            <img
              src={
                require("@user/listofferjunior/modaloffre/icon/apply_cv.svg")
                  .default
              }
            />
          </div>
          <div className={styles.label_apply}>
            Vous allez être redirigé vers l’espace carrière de {offre.cab.name}{" "}
            pour finaliser votre candidature.
          </div>
        </div>
      );
    }

    if (step === 1)
      return (
        <ApplyCV
          offre={offre}
          cv={applyData.cv || applyFiles?.cv}
          getCV={getCV}
          deleteCV={deleteCV}
        />
      );
    if (step === 2) return <FormApply offre={offre} onChange={onChange} />;
  }

  return (
    <Modal
      closeModal={onClose}
      footer={showSubscribe === false && { content: renderFooter() }}
      style={{ width: "60%" }}
    >
      <BannerCab
        type="apply"
        cab={offre.cab}
        title={
          offre.spontaneous ? "Candidature spontanée" : offre.detailsOffre.title
        }
        data={!offre.spontaneous && offre.detailsOffre}
        closeModal={onClose}
      />
      <Scrollbars innerStyle={{ padding: "0 2vw" }}>
        {renderContent()}
      </Scrollbars>
    </Modal>
  );
}

export default Apply;
