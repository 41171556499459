import React from 'react';

import styles from './drawer.module.css';

const Drawer = ({
  children,
  closeDrawer,
  renderHeader,
  tabs,
  page,
  changePage
}) => {

  return(
    <div className={styles.outer_drawer}>
      <div className={styles.background} onClick={closeDrawer}></div>
      <div className={styles.container_drawer}>
        {renderHeader()}
        <div className={styles.tab_drawer}>
          {tabs.map((item) => {
            return(
              <div className={`hover_color ${styles.item_tab_drawer} ${item.index === page && styles.active}`} onClick={() => changePage(item.index)}>
                <span>{item.label}</span>
              </div>
            )
          })}
        </div>
        {children}
      </div>
    </div>
  )
  
}

export default Drawer;
