import React, { useState, useEffect } from 'react';

import ReactHtmlParser from 'react-html-parser';


import Input from './input/Input';
import Select from './select/Select';
import Photo from './photo/Photo';
import TextEditor from './texteditor/TextEditor';
import Content from './content/Content';
import Switch from './switch/Switch';
import Addlist from './addlist/Addlist';
import Number from './number/Number';
import Options from './options/Options';
import Checkbox from './checkbox/Checkbox';
import Context from './context/Context';
import Linkedin from './linkedin/Linkedin';
import Recruit from './recruit/Recruit';

import styles from './field.module.css';

function Field(props) {

  const [display, setDisplay] = useState(props.field.display === false ? false : true);

  useEffect(() => {
    if(props.dataForm && props.field.listenerDisplay){
      setDisplay(props.dataForm[props.field.listenerDisplay.key] === props.field.listenerDisplay.value)
    }
  }, [props.dataForm && props.dataForm[props.field.listenerDisplay?.key]])

  function renderLabelField(label, renderExtraLabelField){
    if(!label && !props.field.title) return
    return(
      <div className={styles.container_label_field}>
        <div className={styles.label_field}>
          {props.field.icon && (
            <div className={styles.icon}>
              <img src={props.field.icon} className='image_contain'/>
            </div>
          )}
          <span className={`${props.showMissing && !props.complete ? styles.label_missing : ''}`}>{ReactHtmlParser(label || props.field.title)}</span>
        </div>
        {renderExtraLabelField && renderExtraLabelField(props.field)}
      </div>
    )
  }

  if(display === false){
    return null
  }


  if(props.field.type === "input"){
    return(
      <Input
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "select"){
    return(
      <Select
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "photo"){
    return(
      <Photo
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "texteditor"){
    return(
      <TextEditor
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "content"){
    return(
      <Content
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "switch"){
    return(
      <Switch
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "addlist"){
    return(
      <Addlist
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "number"){
    return(
      <Number
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "options"){
    return(
      <Options
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "checkbox"){
    return(
      <Checkbox
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "context"){
    return(
      <Context
        {...props}
      />
    )
  }else if(props.field.type === "linkedin"){
    return(
      <Linkedin
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else if(props.field.type === "recruit"){
    return(
      <Recruit
        {...props}
        renderLabelField={renderLabelField}
      />
    )
  }else{
    return null
  }
}

export default Field;