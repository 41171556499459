import React, { Component } from 'react';

import styles from './button.module.css';

class Button extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      
    };

  }
  
  render(){
    return(
      <div 
        className={`
          ${styles.button} 
          ${styles.hover_bg_color} 
          ${this.props.disabled && styles.disabled}
          ${this.props.type && styles[this.props.type]} 
        `} 
        onClick={this.props.onClick}
        style={this.props.style}
      >
        {this.props.icon && <img src={this.props.icon}/>}
        {this.props.label}
      </div>
    )
  }
}

export default Button;
