import React, { useState, useEffect, Stylesheet } from 'react';

import customFetch from '@utils/function/customFetch';

import styles from './cv.module.css';

const CV = ({
    cv,
    candidate = {},
    onSuccess,
    renderButtons,
    type
}) => {

  useEffect(() => {
    if(type !== 'recruteur') return
    if(candidate.statusCab === 0){
      customFetch({ endpoint: 'recruteur/view_candidate', data:{id:candidate.id_candidate}, onSuccess})
    }
  }, [candidate])

  return(
    <>
      <div className={`${styles.content_action} container`}>
        {cv && (
          <object data={`${cv}#zoom=70`} type="application/pdf">
            <embed src={`${cv}#zoom=70`} type="application/pdf" />
          </object>
        )}
      </div>
      {renderButtons()}
    </>
  )
}

export default CV

 