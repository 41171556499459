import React, { useState, useEffect } from 'react';

import ModifyMatch from '../modifyMatch/ModifyMatch';

import customFetch from '@utils/function/customFetch';

import styles from './senioroffers.module.css';

const SeniorOffers = ({
  id_user
}) => {

  const [seniorOffers, updateSeniorOffers] = useState()
  const [modifyMatch, updateModifyMatch] = useState()

  useEffect(() => {
    customFetch({ endpoint: 'admin/pending_senior', onSuccess:onSeniorOffers, data:{id_user},  type:'POST'})
  }, [])

  function onSeniorOffers({ response }){
    updateSeniorOffers(response)
  }

  function onSuccess({ response }){
    let newSeniorOffers = [...seniorOffers]
    if(modifyMatch?.id){
      let index = seniorOffers.findIndex(x => x.id === response.id)
      if(index !== -1){
        if(response.deleted){
          newSeniorOffers.splice(index, 1)
        }else{
          newSeniorOffers.splice(index, 1, response)
        }
      }
    }else{
      newSeniorOffers.splice(0, 0, response)
    }
    updateSeniorOffers(newSeniorOffers)
    updateModifyMatch()
  }

  if(!seniorOffers) return null

  return(
    <div className={styles.senior_offers}>
      {seniorOffers.map((item) => {
        return(
          <div className={`${styles.button} ${styles.button_cab} ${item.outdated && styles.outdated}  ${item.outdated ? 'hover_background_orange' : 'hover_background_color'}`} onClick={() => updateModifyMatch(item)}>{item.cab.name}</div>
        )
      })}
      <div className={`${styles.button} hover_color`} onClick={() => updateModifyMatch({})}>Associer un cabinet</div>

      {modifyMatch && (
        <ModifyMatch 
          onClose={() => updateModifyMatch()}
          match={modifyMatch}
          id_user={id_user}
          onSuccess={onSuccess}
        />
      )}

    </div>
  )
}

export default SeniorOffers;
