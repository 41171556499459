import React, { Component } from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

import { Redirect } from "react-router-dom";

import './forgot.css';

class Forgot extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      password:''
    };

    this.onChange = this.onChange.bind(this)
    this.change_password = this.change_password.bind(this)

  }

  componentDidMount(){

    this.token = this.props.location.search.replace("?", "")
    fetch(process.env.REACT_APP_ENDPOINT + '/check_forgot', {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        key: this.token
      })
    })
    .then((response) => response.json())
    .then((responseData) => {
      if(responseData.error){
        this.setState({
          redirect:true
        })
      }else{
        this.setState({
          confirm_forgot:true
        })
      }
    })
    .catch((error) => {

         
    });
    
  }


  onChange(value, type){
    let pattern
    if(type === "email"){
      pattern = /^\S+@\S+\.\S+$/
    }

    if(!pattern || pattern.test(value)){
      this.setState({
        [type]:value
      })
    }else{
      this.setState({
        [type]:null
      })
    }
  }

  change_password(){
    fetch(global.endpoint + '/change_password', {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        forgot: this.token,
        password:this.state.password
      })
    })
    .then((response) => response.json())
    .then((responseData) => {
      this.setState({
        confirm:true
      })
    })
    .catch((error) => {

         
    });
  }


  render(){
    if(this.state.redirect){
      return <Redirect to={"/"}/>
    }else{
      return(
        <div id="outer_article">
          <div id="background_image"></div>
          <Scrollbars 
            id="container_article" 
            autoHide
            renderView={props => <div {...props} className="view"/>}
            onScroll={this.props.onScroll}
          >
        

            {this.state.confirm ? (
              <div id="body_forgot">
                <div id="forgot_label">
                  <div>Votre mot de passe a bien été modifié.</div>
                </div>
              </div>
            ):(
              <div id="body_forgot">
                <div id="forgot_label">
                  <div>Entrez votre nouveau mot de passe ci-dessous</div>
                  <input type="password" onChange={(evt)=>this.onChange(evt.target.value, "password")}/>
                </div>
                 <div id="button_init_screening" className={this.state.password.length>0 ? "pointer" : "pointer disabled"} onClick={() => this.change_password()}>Valider</div>
              </div>
            )}

          </Scrollbars>
        </div>
      )
    }
  }
}

export default Forgot;
