import React, { useEffect, useState, useRef } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Select from '@utils/form/field/select/Select';
import Button from '@utils/button/Button';
import Modal from '@utils/modal/Modal';
import Scrollbars from '@utils/scrollbars/Scrollbars';

import {ReactComponent as IconReset} from '@icon/reset.svg';
import {ReactComponent as IconFilter} from '@icon/admin/filter.svg';

import styles from './searchbar.module.css';


function Searchbar({ 
  onSearch,
  onSelectFilter,
  placeholder = "Rechercher",
  filters,
  button,
  style,
  renderExtraBar,
  renderIconSearch,
  selectedFilters,
  resetFilters,
  styleSearchBar = {},
  search,
  defaultShowFilters = false
}){

  const [showFilters, toggleFilters] = useState(defaultShowFilters)
  let selectRef = [];

  if(filters){
    filters.map(() => selectRef.push(useRef()))
  }

  function reset(){
    for(let i=0; i<selectRef.length;i++){
      selectRef[i].current.reset()
    }
    resetFilters()
  }


  function renderIconFilter(){
    return(
      <div className={`${styles.icon_filter} ${Object.keys(selectedFilters).filter(x => Array.isArray(selectedFilters[x]) && selectedFilters[x].length > 0).length > 0 && styles.active}`} onClick={() => toggleFilters(!showFilters)}>
        <IconFilter className="image_contain"/>
      </div>
    )
  }

  return(
    <div className={styles.outer_search} style={style}>
      <div className={styles.container_search}>
        <div className={styles.searchbar} style={styleSearchBar}>
          <img src={require('@icon/search.svg').default}/>
          <input
            onChange={(evt) => onSearch(evt.target.value)}
            placeholder={placeholder}
            value={search}
          />
          {renderIconSearch && renderIconSearch()}
          {isMobile && filters && renderIconFilter()}
        </div>
        {button && (
          <Button 
            label={button.label}
            onClick={button.onClick}
            style={{marginLeft:'2vh'}}
          />
        )}
        {!isMobile && filters && (
          <div className={`${styles.button_filter} hover_color`} onClick={() => toggleFilters(!showFilters)}>
            Filtres
          </div>
        )}
        {renderExtraBar && renderExtraBar()}
      </div>
      {showFilters && (
        <>
          <BrowserView className={styles.filters}>
              <div className={styles.container_filters}>
                {filters.map((item, index) => {
                  const value = selectedFilters[item.field.key]
                  return(
                    <Select
                      field={{...item.field, ...{value}}}
                      renderLabelField={() => {}}
                      onChange={onSelectFilter}
                      filter={true}
                      ref={selectRef[index]}
                    />
                  )
                })}
              </div>
              { Object.keys(selectedFilters).filter(x => Array.isArray(selectedFilters[x]) && selectedFilters[x].length > 0).length > 0 ?
                (
                  <div className={styles.reset_filters}>
                    <div className={`${styles.outer_reset} hover_color`} onClick={reset}>
                      <div className={styles.image_reset}>
                        <IconReset className='image_contain'/>
                      </div>
                      <span className={styles.label_reset_filters}>Reinitialiser</span>
                    </div>
                  </div>

                ) : (
                  <div className={styles.reset_filters}></div>
                )
            }
          </BrowserView>

          <MobileView>
            <Modal
              header='Filtres'
              closeModal={() => toggleFilters(false)}
            >
              <Scrollbars>
                {filters.map((item, index) => {
                  const value = selectedFilters[item.field.key]
                  return(
                    <Select
                      field={{...item.field, ...{value}}}
                      renderLabelField={() => {}}
                      onChange={onSelectFilter}
                      filter={true}
                      ref={selectRef[index]}
                    />
                  )
                })}
              </Scrollbars>
              <div className={styles.bannerButtons}>
                <Button 
                  label="Réinitialiser"
                  type="cancel"
                  onClick={reset}
                  style={{marginRight:'4vw'}}
                />
                <Button 
                  label="Appliquer"
                  type="full"
                  onClick={() => toggleFilters(false)}
                />
              </div>
            </Modal>
          </MobileView>

        </>
      )}
    </div>
  )
}

export default Searchbar