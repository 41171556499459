import React, { useState, useEffect } from 'react';
import SocketIOClient from 'socket.io-client';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";

import Landing from '@recruteur/landing/Landing';
import HomeRecruteur from '@recruteur/recrutement/HomeRecruteur';
import AdminConnect from '@recruteur/admin/AdminConnect';

import customFetch from '@utils/function/customFetch';

import './recruteur.css';

global.version = "1.0.0"
global.build = "1"

var update = require('immutability-helper');


const MainRecruteur =  ({
  location
}) => {

  const [isLoading, setLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    customFetch({ endpoint: 'recruteur/login_recruteur', onSuccess, onError, type:'GET'})
  }, [])

  useEffect(() => {
    if(localStorage.getItem('id_token')) customFetch({ endpoint: 'recruteur/reconnect', onSuccess: onSuccessReconnect, onError, type:'POST', data: {token: localStorage.getItem('id_token')}})
  }, [])

  const onSuccessReconnect = ({response}) => {
    localStorage.removeItem('id_token')
    customFetch({ endpoint: 'recruteur/login_recruteur', onSuccess, onError, type:'GET'})()
  }

  function onSuccess(data){
    setUser(data.response)
    setLoading(false)
  }

  function onError(){
    setLoading(false)
  }



  function logIn(){

  }

  function forgot(){
    
  }

  function logOut(){
    customFetch({ endpoint: 'recruteur/logout', onSuccess:onLogOut, type:'GET'})
  }

  function onLogOut(){
    setUser(null)
  }

  if(isLoading){
    return null
  }else if(user){
    return(
      <HomeRecruteur
        user={user}
        logOut={logOut}
      />
    )
  }else{
    return(
      <Landing 
        forgot={forgot}
        onSuccessLogin={onSuccess}
        location={location}
      />
    )
  }

}

export default MainRecruteur;
