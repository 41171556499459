import React from 'react';


import styles from './linkedin.module.css';

const LinkedIn = ({
    user,
    connect,
    style,
    id_offre
}) => {

  var url = 'https://www.linkedin.com/oauth/v2/authorization?client_id=773ff5lbe9pcve&redirect_uri='
  url += `${encodeURIComponent(process.env.REACT_APP_ENDPOINT)}/linkedin/auth/callback?`
  url += `connect=${id_offre ? id_offre : connect}`
  url += '&scope=r_liteprofile%20r_emailaddress&response_type=code'
  return(
    <a className={styles.button_linkedin} href={url} style={style}>
      <img src={require('@icon/linkedin.svg').default}/>
      <div className={styles.label_linkedin}>{connect ? 'Connexion' : 'Inscription'} avec LinkedIn</div>
    </a>
  )
}

export default LinkedIn

 