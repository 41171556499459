import React, { useState, useEffect } from 'react';

import Drawer from '@utils/drawer/Drawer';

import Activity from './activity/Activity';
import UserComments from './userComments/UserComments';
import CV from './cv/CV';
import Matching from './matching/Matching';
import Profile from './profile/Profile';
import Header from './header/Header';

import styles from './draweruser.module.css';

var update = require('immutability-helper');


const DrawerUser = ({
  closeDrawer,
  user,
  onUpdateUser,
  onDeleteUser
}) => {

  const [page, changePage] = useState(0)

  function renderContent(){
    if(page === 0){
      return(
        <Activity
          id_user={user.id}
        />
      )
    }else if(page === 1){
      return(
        <Profile
          id_user={user.id}
        />
      )
    }else if(page === 2){
      return(
        <CV
          user={user}
          onUpdateUser={onUpdateUser}
        />
      )
    }else if(page === 3){
      return(
        <Matching
          user={user}
        />
      )
    }else if(page === 4){
      return(
        <UserComments
          id_user={user.id}
        />
      )
    }
  }

  return(
      <Drawer
        closeDrawer={closeDrawer}
        renderHeader={() => (<Header user={user} onUpdateUser={onUpdateUser} onDeleteUser={onDeleteUser}/>)}
        page={page}
        changePage={changePage}
        tabs={[
          {index:0, label:"Activité"},
          {index:1, label:"Profil"},
          {index:2, label:"CV"},
          {index:3, label:"Matching"},
          {index:4, label:"Commentaires"}
        ]}
      >
        
        {renderContent()}
      </Drawer>
  )
}

export default DrawerUser;
