import React, { useState, useEffect } from 'react';

import Select from '../select/Select';
import Input from '../input/Input';
import Photo from '../photo/Photo';
import Delete from '@utils/delete/Delete';

import ReactPlayer from 'react-player'
import styles_itemcontent from './itemcontent.module.css';
import styles_field from '../field.module.css';
import styles_photo from '../photo/photo.module.css';

var update = require('immutability-helper');

const ImageFile = ({
  src,
  files
}) => {
  const [source, updateSource] = useState();

  useEffect(() => {
    if(files?.[src]){
      let reader = new FileReader();
      reader.onload = function(ev){
        updateSource(ev.target.result)
      };
      reader.readAsDataURL(files?.[src]);
    }else{
      updateSource(src)
    }

  }, [])

  if(!source){
    return null
  }else{
    return <img src={source}/>
  }
}

function ItemContent(props) {

  function renderField(field){
    if(field === 'image'){
      return(
        <Photo
          onChange={(value) => props.onChange({value:value.value, field, image:value.image, index:props.item.key})}
          field={{extraData:{type:'cover'}}}
          renderLabelField={() => {return null}}
          min={true}
        />
      )
    }else if(field === 'video'){
      return(
        <Input
          onChange={(value) => props.onChange({value:value.value, field, index:props.item.key})}
          field={{placeholder:"URL video"}}
          renderLabelField={() => {return null}}
        />
      )
    }else if(field === 'cab_data'){
      return(
        <Select
          onChange={(value) => props.onChange({value:value.value, field, index:props.item.key})}
          field={{socket:"get_item", extraData:{table:'List_Group_Data'}, placeholder:"KPI"}}
          renderLabelField={() => {return null}}
        />
      )
    }else if(field === 'article'){
      return(
        <Select
          onChange={(value) => props.onChange({value:value.value, field, index:props.item.key})}
          field={{socket:"get_list_articles", placeholder:"Sélectionnez l'article à afficher", extraData:props.item.extraData}}
          id_cab={props.id_cab}
          //extraData={props.item.extraData}
          renderLabelField={() => {return null}}
          socketKeys={props.item.key}
        />
      )
    }
  }

  function renderSelected(){
    if(props.item.value.type === 'image'){
      return(
        <div className={`${styles_itemcontent.container_item_content} ${styles_photo.banner}`}>
          <ImageFile 
            src={props.item.value.value}
            files={props.dataFiles}
          />
        </div>
      )
    }else if(props.item.value.type === 'video'){
      return(
        <div className={styles_itemcontent.container_video}>
          <div className={styles_itemcontent.outer_video}>
            <ReactPlayer
              url={props.item.value.value}
              height={'100%'}
              width={'100%'}
              controls={true}
            />
          </div>
        </div>
      )
    }else if(props.item.value.type === 'cab_data'){
      return(
        <div className={styles_itemcontent.cab_data}>
          {props.item.value.value.label}
        </div>
      )
    }else if(props.item.value.type === 'article'){
      return(
        <div className={styles_itemcontent.article}>
          <div className={styles_itemcontent.image_article}>
            <img className='image_cover' src={props.item.value.value.image}/>
          </div>
          <div className={styles_itemcontent.name}>{props.item.value.value.title || props.item.value.value.label}</div>
        </div>
      )
    }else{
      return(
        null
      )
    }
  }
  
  if(props.item.value && props.item.value.type && props.item.value.value){
    return(
      <div className={styles_itemcontent.container_item_content} style={props.item.style}>
        <div className={styles_itemcontent.item_content}>
          {renderSelected()}
        </div>
        <Delete 
          onDelete={() => props.onChange({ value:null, field:null, index:props.item.key })}
          style={{top:'1vh', right:'1vh'}}
          absolute
        />
      </div>
    )
  }else{
    return(
      <div className={` ${styles_itemcontent.container_item_content} ${props.item.type.length>=1 && (props.item.type[0] === 'cab_data' || props.item.type[0] === 'article') && !props.item.value?.value && styles_itemcontent.container_item_content_show}`} style={props.item.style}>
        <div className={styles_itemcontent.item_content}>
          {props.item.type.map((field, index) => {
            return(
              <div className={styles_itemcontent.field_content} key={index}>
                {index > 0 ?(
                  <div className={styles_itemcontent.separator}>ou</div>
                ):(null)}
                {renderField(field)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default ItemContent;
