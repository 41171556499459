import Addlist from '../addlist/Addlist';
import styles from './linkedin.module.css';

const LinkedIn = (props) => {

  const renderExtraLabelField = (field) => {
    if(field.key === 'link'){
      return(
        <div className={styles.linkedin}>
          <div className={styles.icon}>
            <img src={require('@icon/informations.svg').default}/>
            <div className={styles.details}>
              <div className={styles.illustration}>
                <img src={require('@icon/linkedin_1.png').default} />
              </div>
              <div className={styles.label}>Pour trouver l’URL du profil LinkedIn, rendez-vous sur le profil LinkedIn de la personne ciblée puis copiez l’adresse présente dans la barre d’adresse avant de la coller dans le champ ici.</div>
            </div>
          </div>
        </div>
      )
    }else if(field.key === 'image'){
      return(
        <div className={styles.linkedin}>
          <div className={styles.icon}>
            <img src={require('@icon/informations.svg').default}/>
            <div className={styles.details}>
              <div className={styles.illustration}>
                <img src={require('@icon/linkedin_2.png').default} />
              </div>
              <div className={styles.label}>Pour trouver l’URL de la photo de profil LinkedIn, rendez-vous sur le profil LinkedIn de la personne ciblée puis effectuez un clic droit sur la photo de profil et sélectionnez Copier le lien de l’image. Collez ensuite ce lien dans le champ ici.</div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  return(
    <Addlist 
      {...props}
      field={{
        ...props.field, 
        addlist: props.field.addlist.map((item) => ({...item, ...{renderExtraLabelField}}))
      }}
    />
  )
}

export default LinkedIn;