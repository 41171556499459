import React from 'react';

import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";

import RecruitSenior from './recruitSenior/RecruitSenior';
import RecruitJunior from './recruitJunior/RecruitJunior';

import Tab from '@utils/tab/Tab';

const Recruit = ({
  
}) => {
  
  return(
    <>
      <Tab 
        style={{marginTop:'-2vh', borderRadius:'0 0 1vh 1vh', borderTop:'1px solid var(--border)'}}
        style_item_tab={{borderRight:'none'}}
        tabs = {[
          {"pathname":"/admin/dashboard/recruit/seniors", name:"Seniors", routes:["/admin/dashboard/recruit", "/admin/dashboard/recruit/seniors"]},
          {"pathname":"/admin/dashboard/recruit/juniors", name:"Juniors"},
        ]}
      />
      
      <Switch>
        <Route 
          path="/admin/dashboard/recruit/juniors" 
          render={(props) => <RecruitJunior {...props} />}
        />
        <Route 
          path={['/admin/dashboard/recruit', '/admin/dashboard/recruit/seniors']}
          render={(props) => <RecruitSenior {...props} />}
        />
      </Switch>

    </>
  )
    
}

export default Recruit;
