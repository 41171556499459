import React, { useState, useEffect } from 'react';
import AutosizeInput from 'react-input-autosize';

import ActionsUser from '@admin/matching/users/actionsUser/ActionsUser';

import SeniorOffers from './seniorOffers/SeniorOffers';
import ModalConfirm from '@utils/modalConfirm/ModalConfirm';
import Options from '@utils/form/field/options/Options';

import {ReactComponent as IconEdit} from '@icon/admin/edit.svg';

import customFetch from '@utils/function/customFetch';

import styles from './header.module.css';

const Header = ({
  user,
  onUpdateUser,
  onDeleteUser
}) => {

  const [edit, toggleEdit] = useState()
  const [showDelete, showDeleteUser] = useState()
  const [updatedUser, onChangeUpdatedUser] = useState({})

  function changeUser({key, value}){
    let newUser = {...updatedUser}
    newUser[key] = value 
    onChangeUpdatedUser(newUser)
  }

  function modifyUser(){
    toggleEdit(false)
    customFetch({ endpoint: 'admin/update_user', onSuccess:onUpdateUser, data:{...updatedUser, ...{id: user.id}},  type:'POST'})
  }

  function deleteUser(){
    toggleEdit(false)
    customFetch({ endpoint: 'admin/delete_user', onSuccess:onDeleteUser, data:{id: user.id},  type:'POST'})
  }

  return(
    <div className={styles.header_utilisateur}>
      <div className={styles.intro_header}>
        <div className={styles.contract}>
          {user.contract}
        </div>

        <ActionsUser 
          user={user}
          onUpdateUser={onUpdateUser}
        />
      </div>
      <div className={`${styles.profile} ${edit && styles.profile_edit}`}>
        <div className={styles.image_user}>
          <img className='image_cover' src={user.image || require('@icon/placeholder_user.svg').default}/>
        </div>
        <div className={styles.name_profile}>
          <AutosizeInput
            className={styles.input_name}
            value={updatedUser.hasOwnProperty('prenom') ? updatedUser.prenom : user.prenom}
            onChange={(evt) => changeUser({key:"prenom", value: evt.target.value})}
            disabled={!edit}
          />
          <AutosizeInput
            className={styles.input_name}
            value={updatedUser.hasOwnProperty('nom') ? updatedUser.nom : user.nom}
            onChange={(evt) => changeUser({key:"nom", value: evt.target.value})}
            disabled={!edit}
          />
          {edit && (
            <div className={styles.item_profile}>
              <Options 
                field={{value: user.senior, options:[
                  {value:0, label:'Junior'},
                  {value:1, label:'Senior'},
                ]}}
                onChange={(val) => changeUser({key:"senior", value: val.value})}
              />
            </div>
          )}
          <div className={styles.container_edit_profile} onClick={() => toggleEdit(true)}>
            <IconEdit className='image_contain hover_color'/>
          </div>
          <div className={`${styles.button} ${styles.button_save} hover_background_color`} onClick={modifyUser}>Enregistrer</div>
          <div className={`${styles.button} ${styles.button_delete} hover_background_red`} onClick={() => showDeleteUser(true)}>Supprimer</div>
        </div>
        <div>
          <div className={styles.item_profile}>
            <img src={require('@icon/admin/phone.svg').default}/>
            <AutosizeInput
              value={updatedUser.hasOwnProperty('phone') ? updatedUser.phone : user.phone}
              onChange={(evt) => changeUser({key:"phone", value: evt.target.value})}
              placeholder="Pas de numéro"
              disabled={!edit}
            />
          </div>
          <div className={styles.item_profile}>
            <img src={require('@icon/admin/email.svg').default}/>
            <AutosizeInput
              value={updatedUser.hasOwnProperty('email') ? updatedUser.email : user.email}
              onChange={(evt) => changeUser({key:"email", value: evt.target.value})}
              placeholder="Pas d'email"
              disabled={!edit}
            />
          </div>
          <div className={styles.item_profile}>
            <img src={require('@icon/admin/last_login.svg').default}/>
            <AutosizeInput
              value={user.last_connect}
              placeholder="N/A"
              disabled={true}
            />
          </div>
        </div>
      </div>

      {user.senior === 1 && (
        <SeniorOffers 
          id_user={user.id}
        />
      )}

      {showDelete && (
        <ModalConfirm
          header="Suppression d'un utilisateur"
          closeModal={() => showDeleteUser(false)}
          modalShrink={true}
          content={'Attention ! Vous allez supprimer un utilisateur. Cette action est irréversible.'}
          typeConfirm="delete"
          onConfirm={deleteUser}
        />
      )}


    </div>
  )
}

export default Header;
