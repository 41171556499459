import React from 'react';

import {Link} from "react-router-dom";

import List from './List';

import './listsearch.css';

import onClickOutside from "react-onclickoutside";

var update = require('immutability-helper');


class ListSearch extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      data:[]
    };

    this.data_received = this.data_received.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.select = this.select.bind(this)

  }

 data_received(data, refresh){
    let arr = this.state.data

    if(refresh){
      arr = data
    }else{
      for(let i=0;i<data.length;i++){
        let index = this.state.data.findIndex(x => x.id == data[i].id)
        if(index ==-1){
          arr = update(arr, {
            $splice: [[arr.length, 0, data[i]]]
          });
        }
      }
    }

    this.setState({
      data:arr,
      no_more:data.length < this.props.data_length,
      isLoading:false,
      forcedRefresh:false
    })
  }

  renderItem(item){
    return(
      <div
        onClick={() => this.select(item)} 
        className="item_list_select"
        key={item.id.toString()}
      >
        {this.props.renderItem ? this.props.renderItem(item) : (<div className="container_name_item_search">{item.name ? item.name : item.title}</div>)}
      </div>
    )
  }

  handleClickOutside(evt){
    if(this.props.list === this.props.type){
      this.props.show_list(null)
    }
  }

  select(item){
    if(this.props.onChange){
      this.props.onChange({type:this.props.type, value:item})
    }
  }


  render(){
      return(
        <div id={this.props.id} className="container_list_search container_search_select" style={this.props.style}>
          <List 
            endpoint={this.props.endpoint}
            renderItem={this.renderItem}
            search={this.props.search}
            data={this.state.data}
            data_received={this.data_received}
            no_more={this.state.no_more}
            hide_more={true}
            searchbar={true}
            autoHeight={true}
            extraDataSocket={{selected:this.props.field.value, type:this.props.type, extraData:this.props.extraData}}
            renderEmpty={() =>{
              return(
                <div className="item_list_select empty_list_select">Aucun résultat ne correspond à votre sélection</div>
              )
            }}
          />
        </div>
      )
  }
}

export default onClickOutside(ListSearch);
