import React, { useState, useEffect, Stylesheet } from 'react';

import List from '@utils/list/List';
import Searchbar from '@utils/searchbar/Searchbar';

import useListReducer from '@utils/function/useListReducer';

import {ReactComponent as IconInbox} from '@recruteur/icon/matching/inbox.svg';
import {ReactComponent as IconTrash} from '@recruteur/icon/matching/trash.svg';

import styles from './list_offer.module.css';

var update = require('immutability-helper');

function ListOffer({ 
  modifyOffer,
  button,
  listState,
  updateList,
  onSuccess,
  endpoint,
  renderItem,
  noScroll,
  showFilter,
  extraData,
  admin,
  hideHeader,
  renderContext,
  renderEmpty,
  initialSearch,
  defaultShowFilters
}){
  const [option, selectOption] = useState(0)
  const [search, onSearch] = useState(initialSearch)
  const [filters, onChangeFilters] = useState({})

  function onError(err){
  }

  function renderComponentHeader(){
    if(admin || hideHeader) return null

    return(
      <>
        <Searchbar 
          onSearch={onSearch}
          placeholder="Rechercher une offre"
          filters={showFilter && [
            {field: { key:'contract', placeholder:'Contrat', socket:"get_item", extraData:{table:'List_Contract'}, multiple:'true', value:[]} },
            {field: { key:'type', placeholder:'Type conseil', socket:"get_item", extraData:{table:'List_Tags'}, multiple:'true', value:[]} },
            {field: { key:'secteur', placeholder:'Secteur', socket:"get_item", extraData:{table:'List_Secteur', sort:'name'}, multiple:'true', value:[]} },
            {field: { key:'lieu', placeholder:'Lieu', socket:"get_item", extraData:{table:'List_Lieux'}, multiple:'true', value:[]} }
          ]}
          button={button}
          onSelectFilter={onSelectFilter}
          selectedFilters={filters}
          resetFilters={() => onChangeFilters({})}
          search={search}
          defaultShowFilters={defaultShowFilters}
        />
        {renderContext && (!renderEmpty || listState.data.length) ? renderContext() : null}
      </>
    )
  }

  function onSelectFilter({ value, key }){
    let updatedFilters = {...filters}
    updatedFilters[key] = value 
    onChangeFilters(updatedFilters)
  }

  return(
    <div className={styles.container_list_offer}>
      <List 
        endpoint={endpoint}
        data={listState.data}
        renderItem={renderItem}
        data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
        isLoading={listState.isLoading}
        onError={onError}
        socketKeys={"get_offres"}
        search={search}
        renderHeader={renderComponentHeader}
        filters={filters}
        noScroll={noScroll}
        extraData={extraData}
        renderEmpty={renderEmpty}
      />
    </div>
  )
}

export default ListOffer