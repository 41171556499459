import React, { useState, useRef, useEffect, useReducer, useImperativeHandle } from 'react';

import Delete from '@utils/delete/Delete';
import useOnClickOutside from "@utils/function/useOnClickOutside";

import Calendar from '@utils/form/field/calendar/Calendar.js'

import styles_field from '@utils/form/field/field.module.css';
import styles_select from '@utils/form/field/select/select.module.css';
import styles_date_select from './dateselect.module.css';

const DateSelect = React.forwardRef((props, refParent) => {

  const ref = useRef();

  const [showCalendar, updateShowCalendar] = useState(false)
  useOnClickOutside(ref, () => !showCalendar && setSelectState({showSearch:false}));
  const [selectState, setSelectState] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {search: '', selected: props.defaultValue ? props.defaultValue : null, showSearch: false}
  )

  function select(item, evt){
    setSelectState({ selected:item, showSearch: false })
    props.onSelect(item)
  }

  function renderSearch(){
    if(selectState.showSearch){
      return(
        <div className={`${styles_select.container_search_select} ${styles_date_select.container_search_select}`} style={{maxHeight:'none'}}>
          {props.fields.map((item) => {
            if(item.date){
              return(
                <Calendar 
                  showCalendar={showCalendar}
                  updateShowCalendar={updateShowCalendar}
                  onSelect={select}
                />
              )
            }else{
              return renderItem(item)
            }
          })}
        </div>
      )
    }
  }

  function renderItem(item, index, selected){
    let classe = `${styles_select.item_list_select}`
    classe += !selected && (item.id === selectState.selected?.id) ? ` ${styles_date_select.selected}` : ''
    classe += !selected ? ' hover_color_font' : ''
    return(
      <div className={classe} onClick={!selected ? (evt) => select(item, evt) : undefined} key={item.id && item.id.toString()}>
        <div className={styles_select.name_item_select}>{item.label}</div>
      </div>
    )
  }

  function renderContent(){
    if(selectState.selected){
      return(
        <div className={`${styles_field.container_field_input} ${styles_select.container_selected}`} onClick={() => setSelectState({showSearch:true})}>
          {renderItem(selectState.selected, null, true)}
          {!props.defaultValue && (
            <Delete 
              onDelete={() => select(props.defaultValue)}
            />
          )}
        </div>
      )
    }else{
      return(
        <div className={`${styles_field.container_field_input} ${styles_select.container_input_select}`} onClick={() => setSelectState({showSearch:true})}>
          <input
            placeholder={props.placeholder}
            onChange={(evt) => setSelectState({search:evt.target.value})}
            size="1"
          />
          {(!props.filter || selectState.selected?.length === 0) ? (
            <img 
              onClick={() => setSelectState({showSearch: !selectState.showSearch})}
              className={styles_select.down_arrow} 
              src={require('@utils/icon/down_arrow.svg').default}
            />
          ) : (
            <div className={styles_select.counter} >{selectState.selected?.length}</div>
          )}
        </div>
      )
    }
  }

  let classe = `${styles_field.container_input}`
  classe += selectState.showSearch ? ` ${styles_select.show_search}` : ''
  classe += selectState.selected ? ` ${styles_select.selected}` : ''
  classe += props.showMissing && !props.complete ? ` ${styles_select.item_missing}` : ``

  return(
    <div className={`${styles_field.container_field} ${props.filter && styles_select.filter}`} ref={refParent}>
      <div className={classe} ref={ref}>
        {renderContent()}
        {renderSearch()}
      </div>
    </div>
  )
})

export default DateSelect;