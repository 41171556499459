import React from 'react';

import SelectForm from '@utils/form/selectForm/SelectForm';

const Cab = ({

}) => {

  return(
    <SelectForm 
      endpointList="admin/get_list_cabs"
      endpointForm="admin/get_form_cab"
      endpointUpdate="admin/update_cab"
      endpointPreview="preview_cab"
      labelCreate="Créer un nouveau cabinet"
      keyLastModif='last_modif_cab'
    />
  );
};
/*const Cab = ({
    
}) => {

  const [loading, updateLoading] = useState(false)
  const [sections, updateSections] = useState(null)
  const [selectedCab, updateSelectedCab] = useState(null)
  
  useEffect(() => {
    selectedCab && customFetch({ endpoint: 'admin/get_form_cab', data:{id:selectedCab.id}, onSuccess}) 
    selectedCab && updateLoading(true)
  }, [selectedCab?.id])

  function onSuccess(data){
    updateSections(data.response)
    updateLoading(false)
  }

  function onPreview(data){

  }

  return(
    <div className="container">
      <Field
        onChange={(data) => updateSelectedCab(data.value)}
        field={{type:"select", socket:"admin/get_list_cabs", value:selectedCab, placeholder:"Sélectionner un cabinet existant ou en créer un nouveau", extraData:{sort:'id DESC', create:'Créer une nouvelle fiche cabinet'}}}
        listener={true}
      />
      {sections && !loading && (
        <Form
          endpoint={"admin/update_cab"}
          preview_endpoint={"preview_cab"}
          sections={sections}
          type="formadmin"
          id={selectedCab?.id}
          lastModif={selectedCab?.lastModif}
          status={selectedCab?.status}
          key={selectedCab?.id}
          success={(data) => updateSelectedCab(data.response)}
          preview={true}
        />
      )}
    </div>
  )
}*/

export default Cab

 
