import React, { Component } from 'react';

import Select from '@utils/form/field/select/Select';

import './adminconnect.css';

var moment = require('moment')


class AdminConnect extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      selected:null
    };

    this.admin_connect = this.admin_connect.bind(this)
    this.onChange = this.onChange.bind(this)

  }

  onChange(data){
    this.setState({
      selected:data.value
    })
  }

  admin_connect(){
    global.socket.emit("admin_connect", this.state.selected)
  }

  render(){
    return(
       <div id="container_admin_connect">
        <div className="content_admin_connect">
          <div className="title_admin_connect">Bonjour {this.props.user.prenom} !</div>
          <div className="intro_admin_connect">Merci de choisir un compte sur lequel se connecter.</div>
          <Select
            renderLabel={() => {return null}}
            field={{extraData:{admin_connect:true}}}
            onChange={this.onChange}
            socket={"list_cabs"}
          />
          <div className={this.state.selected ? "button button_white pointer" : "button button_white disabled"} onClick={this.admin_connect}>Se connecter</div>
          <div className="button button_cancel pointer" onClick={this.props.log_out}>Se déconnecter</div>
        </div>
      </div>
    )
  }
}

export default AdminConnect;