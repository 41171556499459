import React, { useState, useEffect, Stylesheet } from 'react';

import Form from '@utils/form/Form';
import Modal from '@utils/modal/Modal';
import moment from 'moment';

import styles from './contact.module.css';

const Contact = ({
    candidate,
    onChangeEmailContact,
    emailContact,
    renderButtons,
    onSuccessUpdate,
    onShowResend,
    showResend,
}) => {

  const [showModal, onShowModal] = useState()
  const [sendForm, updateSendForm] = useState(false)
  const [key, updateKey] = useState(Math.random())

  useEffect(() => {
    updateKey(Math.random())
  }, [emailContact])

  if(!candidate) return null

  function renderFooter(){
    if(!showResend){
      if(candidate.contact){ 
        return (
          <div className={`${styles.info_contact} hover_color`} onClick={() => onShowResend(true)}>Renvoyer un message</div>
        )
      } else if(!showResend) {
        return (
          <div className={`${styles.info_contact} hover_color`} onClick={() => onShowModal(true)}>Modifier le modèle du message</div>
        )
      }
    }
  }

  function renderHeader(){
    if(candidate.contact && !showResend){
      return(
        <div className={styles.info_contact}>Attention, ce candidat a déjà été contacté le {moment.utc(candidate.contact.date).local().format('DD/MM/YYYY [à] HH:mm')} </div>
      )
    }
  }

  return(
    <div className={`container`}>
      <Form 
        fields={[
          {type:'input', key:'email_user', title:'Email du candidat', disabled:true, value:candidate.email},
          {type:'input', key:'email_contact_dest', title:'Email de réponse', value:candidate.contact?.dest || emailContact.dest},
          {type:'input', key:'email_contact_object', title:'Objet', value:candidate.contact?.object || emailContact.object},
          {type:'texteditor', key:'email_contact_message', title:'Message', extraData:{min:true}, placeholder:'Ecrire ici', value:candidate.contact?.message || emailContact.message}
        ]}
        renderHeader={renderHeader}
        sendForm={sendForm}
        endpoint='recruteur/contact_candidate'
        renderFooter={renderFooter}
        renderHeader={renderHeader}
        success={onSuccessUpdate}
        disabled={candidate.contact && !showResend}
        key={key}
      />
      
      {renderButtons(() => updateSendForm([
        {key:'id_candidate', value:candidate.id_candidate}, 
        {key:'email_candidate', value:candidate.email},
        {key:'contact', value:candidate.contact?.id}
      ]))}
     
      {showModal && (
        <Modal
          closeModal={() => onShowModal()}
          header="Enregistrer le modèle d'email de contact"
        >
          <Form 
            fields={[
              {type:'input', key:'email_contact_dest', title:'Email de réponse', value:emailContact.dest},
              {type:'input', key:'email_contact_object', title:'Objet', value:emailContact.object},
              {type:'texteditor', key:'email_contact_message', title:'Message', extraData:{min:true}, placeholder:'Ecrire ici', value:emailContact.message}
            ]}
            buttons={{
              action:{label:'Enregistrer', type:'full'}
            }}
            endpoint='recruteur/email_contact'
            success={(data) => {
              onShowModal()
              onChangeEmailContact(data)
            }}
          />
        </Modal>
      )}
    </div>
  )
}

export default Contact

 