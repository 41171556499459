import styles from './context.module.css';

const Context = (props) => {

  return(
    <div className={styles.context} style={props.field.style || {}}>
      <div className={styles.image} style={props.field.styleIcon || {}}>
        <img src={props.field.icon} className='image_contain'/>
      </div>
      <div className={styles.text}>{props.field.text}</div>
    </div>
  )

}

export default Context