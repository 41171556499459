import React from 'react';

import {Link} from "react-router-dom";

import './mincab.css';

var update = require('immutability-helper');


class MinCab extends React.Component {


  constructor(props) {
    super(props);
    this.state = {

    };


  }


  render(){
    if(this.props.cab){
      return(
        <Link to={{pathname:`cabinet/${this.props.cab.pathname}`, state:{id:this.props.cab.id}}} className="min_cab" style={this.props.style}>
          <div className="illustration_min_cab">
            <img src={this.props.cab.banniere}/>
          </div>
          <div className="details_min_cab">
            <div className="logo_min_cab">
              <img src={this.props.cab.logo}/>
            </div>
            
            <div className="name_min_cab">
              <div>{this.props.cab.name}</div>
            </div>

            <div className="container_info_min_cab">
              <img src={require('@icon/min_cab/tags.svg').default}/>
                <div>
                  {this.props.cab.tags ? (
                    this.props.cab.tags.map((item, index) => {
                    return(index === 0 ? item.label : ', ' + item.label)
                  })
                  ):(null)}
                </div>
            </div>

            <div className="container_info_min_cab">
              <img src={require('@icon/min_cab/profiles.svg').default}/>
                <div>
                   {this.props.cab.profiles ? (
                    this.props.cab.profiles.map((item, index) => {
                    return(index === 0 ? item.label : ', ' + item.label)
                  })
                  ):(null)}
                </div>
            </div>

            <div className="container_info_min_cab">
              <img src={require('@icon/min_cab/staff.svg').default}/>
                <div>{this.props.cab.size} employés</div>
            </div>

            <div className="container_info_min_cab">
              <img src={require('@icon/min_cab/address.svg').default}/>
                <div>{this.props.cab.address}</div>
            </div>
          </div>
        </Link>
      )
    }else{
      return(
        <div className="min_cab">
          <div className="illustration_min_cab">
            <div className="loading_img_cab"></div>
          </div>
          <div className="details_min_cab">
            <div className="logo_min_cab">
            </div>
            
            <div className="name_min_cab">
              <div className="loading_name_cab"></div>
            </div>

            <div className="container_info_min_cab">
              <img src={require('@icon/min_cab/tags.svg').default}/>
              <div className="loading_info_min_cab"></div>
            </div>

            <div className="container_info_min_cab">
              <img src={require('@icon/min_cab/profiles.svg').default}/>
                <div className="loading_info_min_cab"></div>
            </div>

            <div className="container_info_min_cab">
              <img src={require('@icon/min_cab/staff.svg').default}/>
                <div className="loading_info_min_cab"></div>
            </div>

            <div className="container_info_min_cab">
              <img src={require('@icon/min_cab/address.svg').default}/>
                <div className="loading_info_min_cab"></div>
            </div>
          </div>
        </div>
      )
    }
    
  }
}

export default MinCab;
