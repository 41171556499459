import React, { useState } from 'react';

import { Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Modal from '@utils/modal/Modal';
import Field from '@utils/form/field/Field';
import Button from '@utils/button/Button';
import Scrollbars from '@utils/scrollbars/Scrollbars';
import LinkedIn from '@utils/linkedin/LinkedIn';

import customFetch from '@utils/function/customFetch';

import styles from './modalconnect.module.css'

const Connect = ({
  email,
  updateEmail,
  password,
  updatePassword,
  recruteur,
  user,
  showForgetPassword
}) => { 

  return(
    <div className={styles.content}>
      
      <div className={styles.container_image}>
        <img src={require('@icon/illustration_connect.svg').default}/>
      </div>

      {!recruteur && (
        <LinkedIn
          user={user}
          connect={true}
        />
      )}
      
      {!recruteur && (
        <div className={styles.separator}>ou</div>
      )}
      
      <div className={styles.form_login}>
        <Field 
          field={{title:'Votre email', type:'input'}}
          onChange={({ value }) => updateEmail(value)}
        />
        <Field
          field={{title:'Votre mot de passe', type:'input', extraData:{input_type:'password'}}}
          onChange={({ value }) => updatePassword(value)}
        />
        <div className={`${styles.forgot} hover_color`} onClick={showForgetPassword}>Mot de passe oublié ?</div>
      </div>

    </div>
  )
}


export default Connect;