import React, { useState } from 'react';
import Addlist from '../addlist/Addlist';

import styles from './recruit.module.css';


const Recruit = (props) => {

  const [selectedRound, updateSelectedRound] = useState(0)

  return(
    <div className={styles.container}>
      <div className={styles.switch}>
        <div className={`${styles.item_switch} ${selectedRound === 0 && styles.active}`} onClick={() => updateSelectedRound(0)}>
          <span>CDI</span>
        </div>
        <div className={`${styles.item_switch} ${selectedRound === 1 && styles.active}`} onClick={() => updateSelectedRound(1)}>
          <span>Stages</span>
        </div>
      </div>

      <Addlist 
        field={props.field.fields[selectedRound]}
        onChange={props.onChange}
        key={selectedRound}
        renderLabelField={props.renderLabelField}
      />

    </div>
  )

}

export default Recruit;
