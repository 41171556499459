import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import MainSite from '@site/MainSite';

import Page404 from '@utils/404/Page404';
import BannerCookies from './components/BannerCookies';

import MainRecruteur from '@recruteur/MainRecruteur';
import MainAdmin from '@admin/MainAdmin';
import MainUser from '@user/MainUser';


import customFetch from '@utils/function/customFetch';

import BuddyScan from '@site/buddyScan/BuddyScan';

import './App.css';
import './css/common.css';
import './css/fonts/fonts.css';

var update = require('immutability-helper');

const App = () => {

  const history = useHistory();

  const [isLoading, updateLoading] = useState(true)
  const [user, updateUser] = useState()
  const [cookies, updateCookies] = useState(localStorage.getItem("banner"))
  const [popUp, updatePopUp] = useState()

  function acceptCookies(){
    localStorage.setItem("banner", true)
    updateCookies(true)
  }
 

    return(
       <div id="main_container">
        <Router>
          <Switch>

            <Route 
              path={["/recruteur/*", "/recruteur"]}
              exact
              render={(props) => <MainRecruteur {...props}/>}
            />

            <Route 
              path={["/admin/*", "/admin"]}
              exact
              render={(props) => <MainAdmin {...props}/>}
            />

            <Route 
              path={["/candidat/*", "/candidat"]}
              exact
              render={(props) => <MainUser {...props}/>}
            />


            <Route 
              path={"/"}
              render={() => (
                <MainSite
                />
              )}
            />

            <Route 
              render={(props) => <Page404 {...props}/>}
            />

          </Switch>

        

         

        </Router>

        {!cookies && (
          <BannerCookies 
            acceptCookies={acceptCookies}
          />
        )}


      </div>
    )
    
}

export default App;
