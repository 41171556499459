import React, { useEffect } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect, Switch, useHistory, withRouter } from "react-router-dom";

import ListArticles from './listarticles/ListArticles';
//import TypeCabs from './typecabs/TypeCabs';

import {ReactComponent as IconNew} from '@icon/site/discoverArticles/new.svg';
import {ReactComponent as IconTrending} from '@icon/site/discoverArticles/trending.svg';
import {ReactComponent as IconInfography} from '@icon/site/discoverArticles/infography.svg';

import Tab from '@utils/tab/Tab';

import customFetch from '@utils/function/customFetch';

import styles from './discoverarticles.module.css';

const DiscoverArticles = ({

}) => {

  useEffect(() => {
    customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 3, type : 2 } , type:'POST'}) 
  }, [])

  return(
    <div className={styles.container_list_articles}>

      <Tab 
        tabs={[
          {'pathname':'/articles', icon:<IconNew/>, name:'Nouveaux'},
          {'pathname':'/articles/populaires', icon:<IconTrending/>, name:'Populaires'},
          {'pathname':'/articles/infographies', icon:<IconInfography/>, name:'Infographies'}
        ]}
        isSticky={true}
        style={{margin:0}}
      />

      <Switch>
        <Route 
          path={['/articles', '/articles/']}
          exact
          render={() => (
            <ListArticles 
              endpoint={'articles_new'}
            />
          )}
        />
        <Route 
          path={['/articles/populaires']}
          exact
          render={() => (
            <ListArticles 
              endpoint={'articles_trending'}
            />
          )}
        />
        <Route 
          path={['/articles/infographies']}
          exact
          render={() => (
            <ListArticles 
            endpoint={'articles_infography'}
            />
          )}
        />
        </Switch>
    </div>
  )

}

export default DiscoverArticles;
