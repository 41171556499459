import React, { useState, useEffect, Stylesheet } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Modal from '@utils/modal/Modal';
import Button from '@utils/button/Button';
import Scrollbars from '@utils/scrollbars/Scrollbars';
import TextEditor from '@utils/form/field/texteditor/TextEditor';

import BannerCab from '@recruteur/recrutement/bannercab/BannerCab';

import AddCV from '@user/addcv/AddCV.js';

import StopApplication from '../stopApplication/StopApplication.js';

import customFetch from '@utils/function/customFetch';

import styles from './progress.module.css';
import TableRecruit from '@site/cabs/cab/recruit/tablerecruit/TableRecruit.js';

var update = require('immutability-helper');

function BannerProgress({ 
  rounds,
  step,
  viewingStep,
  updateViewingStep,
  senior
}){

  function renderItem({ label, index, last }){
    return(
      <div className={ `${styles.container_item_progress} ${index<=step+1 && styles.active_progress} ${index === viewingStep+1 && styles.current_active_progress} ${index === step+1 && styles.last_active_progress}`}>
        {index !== 1 && (
          <div className={styles.before_bar}></div>
        )}
        <div className={styles.container_number_progress} onClick={() => updateViewingStep(index-1)}>
          <div className={`${styles.number_progress} hover_background_color`}>{index}</div>
        </div>
        <div className={styles.container_label_progress}>
          {ReactHtmlParser(label)}
        </div>
        {!last && (
          <div className={styles.after_bar}></div>
        )}
      </div>
    )
  }

  if(senior){
    return(
      <div className={styles.content_banner_progress}>
        {rounds.map((item, index) => renderItem({label:item.title, index:index+1}))}
        {renderItem({ label: 'Résultat', index:rounds.length+1, last:true})}
      </div>
    )
  }
  
  return(
    <div className={styles.content_banner_progress}>
      {renderItem({ label: 'Candidature envoyée', index:1})}
      {rounds.map((item, index) => renderItem({label:item.title, index:index+2}))}
      {renderItem({ label: 'Résultat', index:rounds.length+2, last:true})}
    </div>
  )
}

function IntroProgress({ 
  offre
}){
  return(
    <div className={styles.content_progress}>
      <div className={styles.container_section}>
        <div className={styles.container_illustration}>
          <img src={require('../icon/intro_progress.svg').default}/>
        </div>
        <div className={styles.content_intro}>
          <div>{offre.detailsOffre?.ats ? `Vous avez été redirigé vers le site carrière de ${offre.cab.name} où vous pourrez finaliser votre candidature.` : 'Votre candidature est envoyée ! Si le cabinet de conseil est intéressé par votre profil vous recevrez prochainement un e-mail de sa part.'}</div>
          <div>En cas de retour positif, vous avez la possibilité de mettre à jour votre processus de recrutement ici et bénéficier de nos conseils étape par étape.</div>
        </div>
      </div>

      {!offre.detailsOffre?.ats && (
        <div className={styles.container_section}>
          <div className={styles.container_title}>
            <div className={styles.container_icon}>
              <img src={require('../icon/cv.svg').default}/>
            </div>
            <div className={styles.label_title}>Votre CV envoyé</div>
          </div>
          <div className={styles.content}>
            <AddCV 
              cv={offre.application.cv}
              disabled
              label='Mon CV'
            />
          </div>
        </div>
      )}

      {offre.application.letter && (
        <div className={styles.container_section}>
          <div className={styles.container_title}>
            <div className={styles.container_icon}>
              <img src={require('../icon/letter.svg').default}/>
            </div>
            <div className={styles.label_title}>Votre lettre de motivation</div>
          </div>
          <div className={styles.content}>
            {ReactHtmlParser(offre.application.letter)}
          </div>
        </div>
      )}
    </div>
  )
}

function DetailsStep({ 
  round,
  step
}){
  return(
    <div className={styles.content_progress}>
      <div className={styles.container_section}>
        <div className={styles.container_title}>
          <div className={styles.container_icon}>
            <img src={require('@user/listofferjunior/modaloffre/icon/advice.svg').default}/>
          </div>
          <div className={styles.label_title}>Description du tour d’entretien</div>
        </div>
        <div className={styles.content} style={{border:'1px solid var(--border)'}}>
          <TableRecruit
            rounds={[{
              title_entretien:round.title,
              addlist:0,
              text_entretien:round.text,
              text_critere:round.critere
            }]}
            coaching={true}
            step={step}
          />
        </div>
      </div>
      <div className={styles.container_section}>
        <div className={styles.container_title}>
          <div className={styles.container_icon}>
            <img src={require('@user/listofferjunior/modaloffre/icon/advice.svg').default}/>
          </div>
          <div className={styles.label_title}>Les conseils de Consulting Buddies</div>
        </div>
        <div className={styles.content}>
          <TextEditor 
            field={{value:round.coaching || 'Aucune information disponible', disabled:true}}
            display={true}
            key={round.id.toString()}
          />
        </div>
      </div>
    </div>
  )
}

function EndProgress({ 
  
}){
  return(
    <div className={styles.content_progress}>
      <div className={styles.container_section}>
        <div className={styles.container_illustration}>
          <img src={require('@user/listofferjunior/modaloffre/icon/end_progress.svg').default}/>
        </div>
        <div className={styles.content_intro}>
          <div>Félicitations ! Vous avez franchi l’ensemble des étapes de recrutement du cabinet de conseil !</div>
          <div>Afin que nous puissons mettre à jour votre profil, nous vous invitons à cliquer sur Valider si vous avez été recruté, ou sur Arrêt processus si le cabinet a malheureusement mis fin à votre candidature.</div>
        </div>
      </div>
    </div>
  )
}

function Progress({ 
  offre,
  onClose,
  onSuccessStep,
  onMoveOffer,
  user,
  admin,
  senior,
  updateShowIntro
}){

  const [viewingStep, updateViewingStep] = useState(offre.application.step)
  const [step, updateStep] = useState(offre.application.step)
  const [showProblem, updateShowProblem] = useState(false)
  const [showStopApplication, onShowStopApplciation] = useState()

  useEffect(() => {
    updateStep(offre.application.step)
    updateViewingStep(offre.application.step)
  }, [offre.application.step])

  function renderFooter(){
    return(
      <div className={styles.container_buttons}>
        {viewingStep > 0 ? (
          <div 
            onClick={() => validateStep(step - 1)}
            className={`${styles.button_back} ${viewingStep !== step && styles.disabled} hover_grey`}
          >
            Etape précédente
          </div>
        ):(
          <div></div>
        )}

        {!isMobile && step === 0 && offre.application?.status_cab ? (
          <div className={styles.cv_seen}>
            CV vu par le cabinet
          </div>
        ) : (null)}

        <div className={styles.buttons_action}>
          {!senior && (
            <Button 
              label="Voir l'offre"
              onClick={() => updateShowIntro(true)}
            />
          )}
          <Button 
            label="Arrêt processus"
            type="delete"
            onClick={() => onShowStopApplciation(true)}
            disabled={viewingStep !== step}
            style={{marginLeft:'1vw'}}
          />
          {(!user.senior && viewingStep === offre.rounds.length + 1 || user.senior && viewingStep === offre.rounds.length) ? (
            <Button 
              label={"Valider"}
              type={"validate"}
              onClick={() => finishApplication()}
              disabled={viewingStep !== step}
              style={{marginLeft:'1vw'}}
            />
          ):(
            <Button 
              label={"Etape suivante"}
              type={"full"}
              onClick={() => validateStep(step+1)}
              disabled={viewingStep !== step}
              style={{marginLeft:'1vw'}}
            />
          )}
        </div>
      </div>
    )
  }

  function validateStep(value){
    customFetch({ endpoint: `${admin ? 'admin' : 'user'}/validateStep`, onSuccess: onSuccessStep, type:'POST', data:{id_apply:offre.application.id, id_offre:offre.id, step:value} })
  }

  function finishApplication(){
    customFetch({ endpoint: `${admin ? 'admin' : 'user'}/finishApplication`, onSuccess: onMoveOffer, type:'POST', data:{id_apply:offre.application.id} })
  }

  return (
    <Modal
      closeModal={onClose}
      footer={{content:renderFooter()}}
      style={{width:'60%'}}
    >

    <div className={`${styles.containerProblem} ${showProblem && styles.showProblem}`}>
      <div className={styles.iconProblem} onClick={() => updateShowProblem(!showProblem)}>
        <img src={require('../icon/problem.svg').default} className='contain'/>
      </div>
      <a className={styles.menuProblem} href="mailto:contact@consulting-buddies.com" onClick={() => updateShowProblem(false)}>Signaler un problème</a>
    </div>

    <BannerCab
      type='progress'
      user={user}
      cab={offre.cab}
      title={'Candidature en cours'}
      closeModal={onClose}
    />
    <BannerProgress 
      rounds={offre.rounds}
      step={offre.application.step}
      viewingStep={viewingStep}
      updateViewingStep={updateViewingStep}
      senior={senior}
    />
    <Scrollbars
      innerStyle={{padding:'0 2vw'}}
    >
      {viewingStep === 0 && !senior && (
        <IntroProgress 
          offre={offre}
        />
      )}
      {((senior && viewingStep !== offre.rounds.length || 
       !senior && viewingStep > 0 && viewingStep !== offre.rounds.length + 1)) && (
        <DetailsStep 
          round={offre.rounds[senior ? viewingStep : viewingStep - 1]}
          step={viewingStep}
        />
      )}
      {(viewingStep === offre.rounds.length && senior || viewingStep === offre.rounds.length + 1) && (
        <EndProgress 
          
        />
      )}
    </Scrollbars>
    
    {showStopApplication && (
      <StopApplication
        closeModal={() => onShowStopApplciation()}
        id_apply={offre.application.id}
        endpoint={`${admin ? 'admin' : 'user'}/stopApplication`}
        onMoveOffer={onMoveOffer}
        admin={admin}
      />
    )}

  </Modal>
  )

}

export default Progress