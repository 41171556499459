import React, { useEffect, useState } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import BuddyScore from "@utils/buddyscore/BuddyScore";

import styles from "./headercab.module.css";

const IMAGES = [
  {
    type: 0,
    img: require("@icon/social_network/linkedin_logo.svg").default,
    order: 0,
  },
  {
    type: 1,
    img: require("@icon/social_network/twitter_logo.svg").default,
    order: 1,
  },
  {
    type: 2,
    img: require("@icon/social_network/glassdoor_logo.svg").default,
    order: 3,
  },
  {
    type: 5,
    img: require("@icon/social_network/instagram_logo.svg").default,
    order: 2,
  },
  //{type:3, img:require('@icon/social_network/greatplacetowork.svg').default, order:4}
];

const HeaderCab = ({ cab, user }) => {
  return (
    <div className={styles.headerCab}>
      <div className={styles.card}>
        <div className={styles.containerInfos}>
          <div className={styles.logo}>
            <img src={cab.logo} className="image_contain" />
          </div>
          <div className={styles.infosHeader}>
            <div className={styles.name}>
              <div className={styles.labelName}>{cab.name}</div>
              {!!cab.certified && (
                <img
                  src={require("@icon/verified.svg").default}
                  className="image_contain"
                  style={{ marginLeft: "1vw" }}
                />
              )}
            </div>
            {user && (
              <BuddyScore
                score={cab.buddyScore}
                style={isMobile ? { alignSelf: "center" } : {}}
              />
            )}
          </div>
        </div>
        <BrowserView>
          <div className={styles.intro}>{cab.introduction}</div>
        </BrowserView>
        <MobileView className={styles.info_min_cab}>
          <div className={styles.container_info_min_cab}>
            <img src={require("@icon/min_cab/tags.svg").default} />
            <div className={styles.label_info}>
              {cab.tags
                ? cab.tags.map((item, index) => {
                    return index === 0 ? item.label : ", " + item.label;
                  })
                : null}
            </div>
          </div>

          <div className={styles.container_info_min_cab}>
            <img src={require("@icon/min_cab/profiles.svg").default} />
            <div className={styles.label_info}>
              {cab.profiles
                ? cab.profiles.map((item, index) => {
                    return index === 0 ? item.label : ", " + item.label;
                  })
                : null}
            </div>
          </div>
          <div className={styles.container_info_min_cab}>
            <img src={require("@icon/min_cab/siteweb_cab.svg").default} />
            <div className={styles.label_info}>
              {cab.website.replace("https://", "").replace("http://", "")}
            </div>
          </div>
        </MobileView>
        <div className={styles.backgroundCard}></div>
        {cab.cab_sn && cab.cab_sn.length > 0 && (
          <div className={styles.sn}>
            {cab.cab_sn.map((item) => {
              let image = IMAGES.find((x) => x.type === item.type);
              if (!image || !item.value) return;
              return (
                <a
                  className={styles.item_sn}
                  href={item.value}
                  target="_blank"
                  style={{ order: image.order }}
                >
                  <div className={styles.logo_sn}>
                    <img src={image.img} className="image_contain" />
                  </div>
                </a>
              );
            })}
          </div>
        )}
      </div>

      <div className={styles.cover}>
        <img src={cab.banniere} className="image_cover" />
      </div>
    </div>
  );
};

export default HeaderCab;
