import React from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import List from '@utils/list/List';
import MinArticle from '@site/articles/minarticle/MinArticle';

import {Link} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import useListReducer from '@utils/function/useListReducer';

import styles from './listarticles.module.css';

var update = require('immutability-helper');
var date = require('@utils/function/date');

const ListArticles = ({
  endpoint
}) => {

  const [listState, updateList] = useListReducer()

  function renderItem(item, index){
    if(index === 0){
      return(
        <Link className={styles.container_main_article} style={isMobile ? {width:'100vw', marginLeft:0} : {}} key={item.id.toString()} to={{pathname:"/article/" + item.pathname, state:{id:item.id}}}>
          <div className={styles.image_main_article}>
            <img className="image_cover" alt={item.alt} src={item.image}/>
          </div>
          <div className={`${styles.content_main_article} card`}>
            <div className={styles.background}></div>
            <div className={styles.title_main_article}>
              {item.title}
            </div>
            <div className={styles.abstract_main_article}>
              {ReactHtmlParser(item.abstract)}
            </div>
            <div className={styles.date_main_article}>
              {item.author ? (
                <span className={styles.author_main_article}>{item.author.name}</span>
              ):(null)}
              <span>Mis à jour le {date(item.last_modif)}</span>
            </div>
          </div>
        </Link>
      )
    }else{
      return(
        <MinArticle
          article={item}
          key={item.id.toString()}
          style={
            isMobile ? {width:'90vw', marginLeft:'5vw'} : 
              index%2 === 1 ? {width:'47.5%', margin: '0 2.5% 5vh 0'} : {width:'47.5%', margin: '0 0 5vh 2.5%'}}
        />
      )
    }
  }

  return(
    <List 
      endpoint={endpoint}
      data={listState.data}
      renderItem={renderItem}
      data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
      isLoading={listState.isLoading}
      onError={() => {}}
      noScroll={true}
      socketKeys={endpoint}
      contentListStyle={isMobile ? {paddingTop:0} : {paddingTop:'4vh', flexDirection:'row', flexWrap:'wrap', overflow:'hidden'}}
    />
  )

}

export default ListArticles;
