import React, { useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Modal from '@utils/modal/Modal';

import MinArticle from '@site/articles/minarticle/MinArticle';

import ReactPlayer from 'react-player'
import styles from './itemcontent.module.css';

const ItemContent = ({
  content,
  style
}) => {
  const [showImage, updateShowImage] = useState();
  if(!content) return null

  function renderItem(){
    if(content.type === 'image'){
      return(
        <>
          <div className={styles.image} onClick={() => updateShowImage(content.value)}>
            <img className='image_cover' src={content.value}/>
          </div>
          {showImage && (
            <Modal
              closeModal={() => updateShowImage(false)}
              header={true}
              style={{width:'80%'}}
            >
              <div className={styles.container_image}>
                <img className='image_contain' src={showImage}/>
              </div>
            </Modal>
          )}
        </>
      )
    }else if(content.type === 'video'){
      return(
        <div className={styles.video}>
          <ReactPlayer
            url={content.value}
            height={'100%'}
            width={'100%'}
            controls={true}
            className='react-player'
          />
        </div>
      )
    }else if(content.type === 'article'){
      return(
        <MinArticle 
          article={content.value}
          style={{width:isMobile ? '100%' : '80%'}}
        />
      )
    }
  }

  return(
    <div className={styles.item_content} style={style}>
      {renderItem()}
    </div>
  )
};



export default ItemContent;

/*
class ItemContent extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      selected:null,
      field:null
    };


  }

  shouldComponentUpdate(){
    return false
  }

  renderItem(){
    if(this.props.item.type === 'image'){
      return(
       <div className="outer_video">
          <img className='react-player' src={this.props.item.value}/>
        </div>
      )
    }else if(this.props.item.type === 'video'){
      return(
        <div className="outer_video">
          <ReactPlayer
            url={this.props.item.value}
            height={'100%'}
            width={'100%'}
            controls={true}
            className='react-player'
          />
        </div>
      )
    }else if(this.props.item.type === 'cab_data'){
      let parite
      return(
         <div className="item_cab_data">
          <div className="title_item_cab">{this.props.item.name ? this.props.item.name.toUpperCase() : null}</div>
          <div className="container_cab_data">
            {this.props.item.value.map((data)=>{
              let unit = ""
              if(data.type === 8){
                parite = data.value
                unit="%"
              }
              if(data.value){
                return(
                  <div className="stat_cab_data" key={data.id.toString()}>
                    {data.label ? (
                      <div className="label_cab_data">{data.label}</div>
                    ):(null)}
                    <div className="container_image">
                      <img src={IMAGES[data.type-1]}/>
                    </div>
                    <div className="cab_data">
                      <div className="data">{data.value}{unit}</div>
                    </div>
                  </div>
                )
              }
            })}
            {parite || parite === 0 ? (
              <div className="stat_cab_data">
                <div className="label_cab_data">Hommes</div>
              <div className="container_image">
                <img src={require('@icon/data_h.svg').default}/>
              </div>
              <div className="cab_data">
                <div className="data">{100-parite}%</div>
              </div>
            </div>
            ):(null)}
          </div>
        </div>
      )
    }else if(this.props.item.type === 'article'){
      return(
        <MinArticle 
          article={this.props.item.value}
        />
      )
    }
  }

  render(){
    return(
      <div id={"item_content_" + this.props.id_content + "_" + this.props.item.index} className="item_content" style={this.props.item.style}>
        {this.renderItem()}
      </div>
    )
  }
}

export default ItemContent;
*/