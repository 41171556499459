import React, { useState, useEffect } from 'react';

import customFetch from '@utils/function/customFetch';

import styles from './recruit.module.css'

const RecruitSenior = ({
  
}) => {
  
  const [data, updateData] = useState()
  const [filterType, updateFilterType] = useState(0)
  const [offset, updateOffset] = useState(0)
  const [max, updateMax] = useState(1)

  useEffect(() => {
    customFetch({ endpoint: 'admin/dashboard_seniors', onSuccess, data: { filterType, offset }, type:'POST'})
  }, [filterType, offset])

  function onSuccess({ response }){
    updateMax(Math.max(...response.map(o => Math.max(o.suggestions, o.matchs, o.recruit))) || 1)
    updateData(response)
  }

  if(!data) return null

  return(
    <div className={`${styles.container} card`}>
      
      <div className={styles.container_header}>
        <div className={styles.title_header}>Activité senior</div>
        <div className={styles.container_type}>
          <div className={`${styles.item_type} hover_color ${filterType === 0 && styles.active}`} onClick={() => updateFilterType(0)}>Mois</div>
          <div className={`${styles.item_type} hover_color ${filterType === 1 && styles.active}`} onClick={() => updateFilterType(1)}>Semaine</div>
          <div className={`${styles.item_type} hover_color ${filterType === 2 && styles.active}`} onClick={() => updateFilterType(2)}>Jour</div>
        </div>
      </div>

      <div className={styles.container_graph}>
        <div className={styles.container_grid}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        
        <div className={`${styles.container_arrow} ${styles.container_arrow_back}`}>
          <img src={require('@icon/arrow_color_right.svg').default} className='hover_color' onClick={() => updateOffset(offset + 1)}/>
        </div>

        <div className={`${styles.container_arrow} ${styles.container_arrow_forward}`}>
          {offset ? (
             <img src={require('@icon/arrow_color_right.svg').default} className='hover_color' onClick={() => updateOffset(offset - 1)}/>
          ) : (null)}
        </div>

        <div className={styles.container_data}>
          {data.map((item) => {
            return(
              <div className={styles.item_data}>
                <div className={styles.graphs_data}>
                  
                  <div className={styles.data}>
                    <div className={styles.label_data}>{item.suggestions}</div>
                    <div className={styles.bar} style={{height:`${item.suggestions/max*80}%`, opacity: 0.5}}></div>
                  </div>

                  <div className={styles.data}>
                    <div className={styles.label_data}>{item.matchs}</div>
                    <div className={styles.bar} style={{height:`${item.matchs/max*80}%`, opacity:0.75}}></div>
                  </div>

                  <div className={styles.data}>
                    <div className={styles.label_data}>{item.recruit}</div>
                    <div className={styles.bar} style={{height:`${item.recruit/max*80}%`, opacity:1}}></div>
                  </div>

                </div>
                <div className={styles.legend_data}>{item.label}</div>
              </div>
            )
          })}
        </div>

      </div>

    </div>
  )
    
}

export default RecruitSenior;
