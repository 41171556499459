import React, { useState, useEffect } from 'react';

import Drawer from '@utils/drawer/Drawer';

import Matching from './matching/Matching';
import CabComments from './cabComments/CabComments';
import Offres from './offres/Offres';
import Access from './access/Access';

import styles from './drawercab.module.css';


const DrawerCab = ({
  closeDrawer,
  cab,
  onUpdateCab
}) => {

  const [page, changePage] = useState(0)

  function renderHeader(){
    return(
      <div className={styles.header}>
        <div className={styles.image}><img className='image_contain' src={cab.logo}/></div>
        <div className={styles.name}>{cab.name}</div>
      </div>
    )
  }

  function renderContent(){
    if(page === 0){
      return(
        <Matching
          id_cab={cab.id}
        />
      )
    }else if(page === 1){
      return(
        <Offres
          cab={cab}
        />
      )
    }else if(page === 2){
      return(
        <CabComments
          id_cab={cab.id}
        />
      )
    }else if(page === 3){
      return(
        <Access
        id_cab={cab.id}
        />
      )
    }
  }

  return(
      <Drawer
        closeDrawer={closeDrawer}
        renderHeader={renderHeader}
        page={page}
        changePage={changePage}
        tabs={[
          {index:0, label:"Matching"},
          {index:1, label:"Offres"},
          {index:2, label:"Commentaires"},
          {index:3, label:"Accès"},
        ]}
      >
        
        {renderContent()}
      </Drawer>
  )
}

export default DrawerCab;
