import React from 'react';

import SelectForm from '@utils/form/selectForm/SelectForm';

const Ponderation = ({

}) => {

  return(
    <SelectForm 
      endpointList="admin/get_list_cabs"
      endpointForm="admin/get_ponderation"
      endpointUpdate="admin/update_screening_ponderation"
      keyLastModif='last_modif_coef'
    />
  );
};

/*const Ponderation = ({
    
}) => {

  const [loading, updateLoading] = useState(false)
  const [sections, updateSections] = useState(null)
  const [selectedCab, updateSelectedCab] = useState(null)
  
  useEffect(() => {
    selectedCab && customFetch({ endpoint: 'admin/get_ponderation', data:{id:selectedCab.id}, onSuccess}) 
    selectedCab && updateLoading(true)
  }, [selectedCab?.id])

  function onSuccess(data){
    updateSections(data.response)
    updateLoading(false)
  }

  function onPreview(data){

  }

  return(
    <div className="container">
      <Field
        onChange={(data) => updateSelectedCab(data.value)}
        field={{type:"select", socket:"admin/get_list_cabs", value:selectedCab, placeholder:"Sélectionner un cabinet", extraData:{sort:'id DESC'}}}
      />
      {sections && !loading && (
        <Form
          endpoint={"admin/update_screening_ponderation"}
          sections={sections}
          type="formadmin"
          id={selectedCab?.id}
          lastModif={selectedCab?.lastModif}
          status={selectedCab?.status}
          key={selectedCab?.id}
        />
      )}
    </div>
  )
}*/

export default Ponderation

 
