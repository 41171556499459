import React, { useEffect, useState } from 'react';

import customFetch from '@utils/function/customFetch';

import {ReactComponent as IconClose} from '@icon/bannercontext/close.svg';

import ReactHtmlParser from 'react-html-parser';



import styles from './bannercontext.module.css'

const BannerContext = ({
  image,
  title,
  subtitle,
  id_context,
  onSuccess,
  preferences,
  renderFooter,
  endpoint,
  style,
  styleContent = {},
  styleImage = {},
  styleTitle = {}
}) => {

  function onClose(){
    customFetch({ endpoint, onSuccess, data:{id_context}, type:'POST' })
  }

  if(preferences?.find(x => x === id_context) !== undefined) return null

  return(
    <div className={styles.banner} style={style}>
      
      <div className={styles.content_context} style={styleContent}>
        <div className={styles.image} style={styleImage}>
          <img className='image_contain' src={image}/>
        </div>
        <div className={styles.content}>
          <div className={styles.title} style={styleTitle}>{title}</div>
          {subtitle.map((item, index) => (<div className={styles.subtitle} key={index.toString()}>{ReactHtmlParser(item)}</div>))}
          {renderFooter && renderFooter()}
        </div>
      </div>

      {(id_context || id_context === 0) && (
        <div className={`${styles.close} hover_grey`} onClick={onClose}>
          <IconClose/>
        </div>
      )}

    </div>
  )

};



export default BannerContext;
