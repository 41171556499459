import React from 'react';

import Overview from './overview/Overview';
import Pages from './pages/Pages';
import Recruit from './recruit/Recruit';
import Tab from '@utils/tab/Tab';

import { Route, Switch } from "react-router-dom";

const Dashboard = ({
  
}) => {
  
  return(
    <>
      <Tab 
        style={{borderRadius:'1vh'}}
        tabs = {[
          {"pathname":'/admin/dashboard/overview', name:"Audience", routes:["/admin", "/admin/dashboard/overview", "/admin/dashboard"]},
          {"pathname":'/admin/dashboard/pages', name:"Classement des pages"},
          {"pathname":'/admin/dashboard/recruit', name:"Recrutement"},
        ]}
      />
      <Switch>

        <Route 
          path="/admin/dashboard/pages" 
          render={(props) => <Pages {...props} />}
        />
        <Route 
          path="/admin/dashboard/recruit" 
          render={(props) => <Recruit {...props} />}
        />
        <Route 
          path={['/admin/dashboard', 'admin/dashboard/overview', '/admin']}
          render={(props) => <Overview {...props} />}
        />
      </Switch>
    </>
  )
    
}

export default Dashboard;
