import React, { useState, useEffect } from 'react';

import AddCV from '@user/addcv/AddCV';

import customFetch from '@utils/function/customFetch';

import styles from './cv.module.css';

const CV = ({
  user,
  onUpdateUser
}) => {

  const [isLoadingCV, updateIsLoadingCV] = useState()
  const [isLoadingCVAnonymous, updateIsLoadingCVAnonymous] = useState()

  function getCV({e, type}){
    if(e.target.files && e.target.files[0]){
      if(type === 'cv') updateIsLoadingCV(true)
      if(type === 'cv_anonymous') updateIsLoadingCVAnonymous(true)
      customFetch({ 
        endpoint: 'admin/uploadCV', 
        onSuccess: (data) => {
          if(type === 'cv') updateIsLoadingCV(false)
          if(type === 'cv_anonymous') updateIsLoadingCVAnonymous(false)
          onUpdateUser(data)
        }, 
        type:'POST', 
        data:{id_user:user.id}, 
        files:{[type]:e.target.files[0]}
      })
    }
  }

  function deleteCV({type}){
    customFetch({ endpoint: 'admin/deleteCV', onSuccess: onUpdateUser, type:'POST', data:{id_user:user.id, type}})
  }

  return(
    <div className={styles.container_cv}>
      <div className={styles.item_cv}>
        <div className={styles.title_cv}>CV complet</div>
        <AddCV 
          cv={user.cv}
          label={user.cv ? `CV de ${user.name}` : `Ajouter le CV de ${user.name}`}
          getCV={(e) => getCV({e, type:'cv'})}
          deleteCV={() => deleteCV({type:'cv'})}
          isLoading={isLoadingCV}
        />
      </div>

      {user.senior ? (
         <div className={styles.item_cv}>
         <div className={styles.title_cv}>CV anonyme</div>
         <AddCV 
           cv={user.cv_anonymous}
           label={user.cv_anonymous ? `CV anonyme de ${user.name}` : `Ajouter le CV anonyme de ${user.name}`}
           getCV={(e) => getCV({e, type:'cv_anonymous'})}
           deleteCV={() => deleteCV({type:'cv_anonymous'})}
           isLoading={isLoadingCVAnonymous}
         />
       </div>
      ) : (null)}
     
    </div>
  )
}

export default CV;
