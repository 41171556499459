import React, { useState, useEffect, Stylesheet } from "react";

import useListReducer from "@utils/function/useListReducer";
import ListOffer from "@utils/offer/ListOffer";
import Offer from "@utils/offer/Offer";

import ModalOffre from "./modaloffre/ModalOffre";

import Pagination from "./pagination/Pagination";

import styles from "./listofferjunior.module.css";

var update = require("immutability-helper");

function ListOfferJunior({
  onSuccess,
  user,
  profileUser,
  noScroll,
  admin,
  extraData,
  endpoint,
  endpointDetails,
  senior,
  cabView,
  hideHeader,
  initialMatch,
  styleOffer,
  style,
  fetchUser,
  initialOffre,
  initialSpontaneous,
  home,
  renderContext,
  renderEmpty,
  scrollToTop,
  initialSearch,
  onModalOffreBlockOpen,
  showConnect,
  hideCabName = false,
  hasPagination = false,
  defaultShowFilters,
  showOffre,
}) {
  const [listState, updateList] = useListReducer();
  const [modalOffre, toggleModalOffre] = useState();
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    scrollToTop && scrollToTop();
  }, [itemOffset]);

  useEffect(() => {
    if (initialOffre)
      toggleModalOffre({ id_offre: initialOffre, initialOffre: true });
    if (initialSpontaneous)
      toggleModalOffre({ id_cab: initialSpontaneous, initialOffre: true });
  }, []);

  useEffect(() => {
    if (showOffre)
      toggleModalOffre({ id_offre: showOffre, initialOffre: false });
  }, [showOffre]);

  function renderComponentHeader(item) {
    if (home) return null;

    if (item.id === "ongoing") {
      return (
        <div className={styles.title_section}>
          Candidatures en cours ({item.value.length})
        </div>
      );
    } else if (item.id === "available") {
      return (
        <div className={styles.title_section}>
          Offres disponibles ({item.value.length})
        </div>
      );
    } else if (item.id === "finished") {
      return (
        <div className={styles.title_section}>
          Offres archivées ({item.value.length})
        </div>
      );
    }
  }

  function renderItem(item, index) {
    if (item.value.length === 0) return null;
    return (
      <div className={styles.outer_card}>
        {renderComponentHeader(item)}
        {item.value.map((offer, index) => {
          if (
            hasPagination &&
            item.id === "available" &&
            (index < itemOffset || index >= itemOffset + 20)
          )
            return null;
          return (
            <Offer
              offer={offer}
              type="user"
              toggleModalOffre={toggleModalOffre}
              over={item.id === "finished"}
              available={item.id === "available"}
              senior={senior}
              onSuccessApply={onSuccessApply}
              onDeclineOffer={onDeclineOffer}
              cabView={cabView}
              initialMatch={initialMatch}
              styleOffer={styleOffer}
              admin={admin}
              onDeleteOffer={onDeleteOffer}
              hideCabName={hideCabName}
            />
          );
        })}
      </div>
    );
  }

  function onSuccessStep({ response }) {
    let updatedData = { ...listState.data[0] };
    let index = updatedData.value.findIndex(
      (x) => x.id_apply === response.id_apply
    );
    if (index === -1) return;
    updatedData.value = [...updatedData.value];
    updatedData.value[index] = { ...updatedData.value[index] };
    updatedData.value[index].application = {
      ...updatedData.value[index].application,
      ...{ step: response.step + 1 },
    };
    updateList({ type: "update_item", item: updatedData });
  }

  function onMoveOffer({ response }) {
    let updatedData = { ...listState.data[0] };
    let finishedData = { ...listState.data[2] };
    let index = updatedData.value.findIndex(
      (x) => x.id_apply === response.id_apply
    );
    if (index === -1) return;
    let offerToMove = { ...updatedData.value[index] };
    offerToMove.application = {
      ...offerToMove.application,
      ...{ status: response.status },
    };

    updatedData.value.splice(index, 1);
    updateList({ type: "update_item", item: updatedData });

    finishedData.value.unshift(offerToMove);
    updateList({ type: "update_item", item: finishedData });

    toggleModalOffre();
  }

  function onDeclineOffer({ response }) {
    // when declining senior match
    let updatedData = { ...listState.data[1] };
    let index = updatedData.value.findIndex((x) => x.id === response.id_match);
    if (index === -1) return;
    updatedData.value = [...updatedData.value];
    updatedData.value.splice(index, 1);
    updateList({ type: "update_item", item: updatedData });

    updatedData = { ...listState.data[2] };
    updatedData.value = [...updatedData.value];
    updatedData.value.splice(0, 0, response);
    updateList({ type: "update_item", item: updatedData });

    toggleModalOffre();
  }

  function onSuccessApply({ response }) {
    let updatedData = { ...listState.data[0] };
    updatedData.value = [...updatedData.value];
    updatedData.value.splice(0, 0, response);
    updateList({ type: "update_item", item: updatedData });

    updatedData = { ...listState.data[1] };
    let index;
    if (senior) {
      index = updatedData.value.findIndex((x) => x.id === response.id_match);
    } else {
      index = updatedData.value.findIndex(
        (x) => x.id === response.id && x.spontaneous === response.spontaneous
      );
    }
    if (index === -1) return;
    updatedData.value = [...updatedData.value];
    updatedData.value.splice(index, 1);
    updateList({ type: "update_item", item: updatedData });

    if (!admin && window.location.pathname !== "/candidat/candidatures") {
      window.location.href = "/candidat/candidatures";
    } else {
      if (scrollToTop) scrollToTop();
      toggleModalOffre();
    }
  }

  function onDeleteOffer({ response }) {
    let updatedData = { ...listState.data[0] };
    let index = updatedData.value.findIndex((x) => x.id_match === response.id);
    if (index !== -1) {
      updatedData.value.splice(index, 1);
      updateList({ type: "update_item", item: updatedData });
    }

    updatedData = { ...listState.data[1] };
    index = updatedData.value.findIndex((x) => x.id_match === response.id);
    if (index !== -1) {
      updatedData.value.splice(index, 1);
      updateList({ type: "update_item", item: updatedData });
    }

    updatedData = { ...listState.data[2] };
    index = updatedData.value.findIndex((x) => x.id_match === response.id);
    if (index !== -1) {
      updatedData.value.splice(index, 1);
      updateList({ type: "update_item", item: updatedData });
    }
  }

  return (
    <div className="container" style={style}>
      <ListOffer
        //endpoint={`${admin ? 'admin' : 'user'}/get_offres`}
        endpoint={endpoint}
        listState={listState}
        updateList={updateList}
        renderItem={(item, index) => renderItem(item, index)}
        noScroll={noScroll}
        showFilter={!senior}
        extraData={extraData}
        admin={admin}
        renderHeader={renderComponentHeader}
        hideHeader={hideHeader}
        renderContext={renderContext}
        renderEmpty={renderEmpty}
        initialSearch={initialSearch}
        defaultShowFilters={defaultShowFilters}
      />

      {hasPagination && !listState.isLoading && (
        <Pagination
          listState={listState}
          itemOffset={itemOffset}
          setItemOffset={setItemOffset}
        />
      )}

      {modalOffre && (
        <ModalOffre
          endpoint={endpointDetails}
          id_offre={modalOffre.id_offre}
          id_cab={modalOffre.id_cab}
          id_match={modalOffre.id_match}
          onClose={() => toggleModalOffre()}
          cv={profileUser?.cv}
          onSuccessStepList={onSuccessStep}
          onMoveOffer={onMoveOffer}
          onSuccessApply={onSuccessApply}
          user={user}
          admin={admin}
          extraData={extraData}
          fetchUser={fetchUser}
          initialOffre={modalOffre.initialOffre}
          onModalOffreBlockOpen={onModalOffreBlockOpen}
          showConnect={showConnect}
        />
      )}
    </div>
  );
}

export default ListOfferJunior;
