import React, { useState, useEffect } from 'react';

import Comments from '@utils/comments/Comments';

import styles from './usercomments.module.css';

const UserComments = ({
  id_user
}) => {

  return(
    <Comments
      id_user={id_user}
    />
  )
}

export default UserComments;
