import React, { useState, useEffect, Stylesheet } from 'react';

import { InlineWidget, CalendlyEventListener } from "react-calendly";

import BannerContext from '@utils/bannerContext/BannerContext';
import Button from '@utils/button/Button';

import customFetch from '@utils/function/customFetch';

import styles from './calendly.module.css';

const Calendly = ({ 
  onSuccessCalledScheduled
}) => {

  const [preview, showPreview] = useState(true)

  function onEventScheduled(data){
    customFetch({ endpoint: 'user/schedule_call', onSuccess: onSuccessCalledScheduled, type:'POST' })
  }

  if(preview){
    return(
      <BannerContext 
        image={require('@icon/bannercontext/context_call.svg').default}
        title='Rendez-vous individuel et gratuit avec un coach Consulting Buddies'
        subtitle={[
          `Merci de vout être inscrit sur Consulting Buddies ! Si vous le souhaitez nous pouvons vous mettre en relation avec les cabinets qui recrutent parmi plus d’une centaine de partenaires.`,
          `Pour cela il ne nous reste plus qu’à échanger par téléphone afin que nous identifions ensemble les cabinets qui pourraient être pertinents pour vous.`,
          `Votre coach Consulting Buddies cherchera à comprendre les critères qui font que vous seriez intéressé par certains cabinets plutôt que d’autres : types de mission, taille, culture, salaire... tous ces éléments seront abordés.`,
          `Vous pouvez directement fixer un rendez vous au créneau qui vous arrange via le bouton ci-dessous :`
        ]}
        endpoint='recruteur/update_preferences'
        style={{padding:'5vh 5vw', margin:0, marginTop:'2vh', background:'white'}}
        styleContent={{ flexDirection:'row-reverse' }}
        styleImage={{ width:'20vw', margin:'2vw'}}
        styleTitle={{ marginBottom: '5vh', marginTop:'3vh' }}
        renderFooter={() => (
          <Button 
            type='full'
            onClick={() => showPreview(false)}
            label="Prendre rendez-vous"
            style={{alignSelf:'flex-start', marginTop:'3vh'}}
          />
        )}
        style={{margin:'1vh'}}
      />
    )
  }

  return(
    <div className="container">
      <InlineWidget 
        url={process.env.REACT_APP_CALENDLY} 
        onEventScheduled={onEventScheduled}
      />
      <CalendlyEventListener
        onEventScheduled={onEventScheduled}
      />
    </div>
  )
}

export default Calendly