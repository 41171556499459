import React, { useRef, useState } from 'react';

import { Link, useParams, NavLink , useLocation} from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {ReactComponent as IconLogo} from '@icon/cb.svg';
import HeaderUser from '@user/header/HeaderUser'

import useOnClickOutside from "@utils/function/useOnClickOutside";
import Modal from "@utils/modal/Modal";

import styles from './header.module.css';

const HeaderBrowser = ({
  user,
  logOut,
  showSubscribe,
  showConnect,
  color,
  recruteur,
  pathname
}) => {
  const [showMenu, toggleMenu] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => toggleMenu(false));

  function renderNav(){
    if(recruteur){
      return(
        <div className={styles.nav_header}>
          <NavLink 
            to={"/recruteur"} 
          >
            <IconLogo 
              className={styles.logo_header} 
            />
            <div className={styles.label_recruteur}>recrutement</div>
          </NavLink>
        </div>
      )
    }

    return(
      <div className={styles.nav_header}>
        <NavLink 
          to={"/"} 
        >
          <IconLogo 
            className={styles.logo_header} 
          />
        </NavLink>
        <NavLink 
          to={"/cabinets"} 
          isActive={() => pathname.split('/')[1] === "cabinets"} 
          className={`${styles.header_block} hover_color`}
          activeClassName={styles.selected}
        >
          <span>Découvrir les cabinets</span>
        </NavLink>
        <NavLink 
          to={"/articles"} 
          isActive={() => pathname.split('/')[1] === "articles"} 
          className={`${styles.header_block} hover_color`}
          activeClassName={styles.selected}
        >
          <span>S'informer sur le conseil</span>
        </NavLink>
        <NavLink 
          to={"/offres"} 
          isActive={() => pathname.split('/')[1] === "offres"} 
          className={`${styles.header_block} hover_color`}
          activeClassName={styles.selected}
        >
          <span>Les offres d'emploi</span>
        </NavLink>
        <NavLink 
          to={"/simulateur-salaire"} 
          isActive={() => pathname.split('/')[1] === "simulateur-salaire"} 
          className={`${styles.header_block} hover_color`}
          activeClassName={styles.selected}
        >
          <span>Simulateur de salaires</span>
        </NavLink>
      </div>
    )
  }

  return(
    <nav className={`${styles.header} ${color && styles.color_header}`}>

      <div className={styles.background_header}></div>

      <div className={styles.container_header}>
       {renderNav()}

        <div className={styles.right_header}>
          {!recruteur && (!user || user.cab) && (
            <a className={`${styles.recruteur} hover_color`} href={"/recruteur/"}>
              <span>Espace recruteurs</span>
            </a>
          )}
          {user ? (
            <div className={styles.header_profile}>
              <div className={`${styles.container_profile} hover_background_color`} onClick={() => toggleMenu(true)}>
                <div className={`${styles.image_profile} ${user.cab?.logo && styles.logo_cab}`}>
                  {user.cab?.logo ? (
                     <img src={user.cab?.logo} className='image_contain'/>
                  ) : (
                    <img src={user.image || require('@icon/profile/empty_image_profile_white.svg').default} className={'image_cover'}/>
                  )}
                </div>
                <div className={styles.name_profile}>
                  {user.name || "Nouveau buddy"}
                </div>
              </div>
              {showMenu && (
                <div className={styles.menu_profile} ref={ref}>
                  <Link to={"/candidat"} className={styles.nav_profile}>
                    <img src={require('@icon/header_profile.svg').default}/>
                    <div>Mon espace</div>
                  </Link>
                  <div className={styles.nav_profile} onClick={logOut}>
                    <img src={require('@icon/header_deco.svg').default}/>
                    <div>Déconnexion</div>
                  </div>
                </div>
              )}
            </div>
          ):(
            <div className={styles.container_button}>
              <div className={styles.button} onClick={showConnect}>
                Se connecter
              </div>
              <div className={`${styles.button} ${styles.button_full}`} onClick={showSubscribe}>
                S'inscrire
              </div>
            </div>
          )}
        </div>
      </div>

    </nav>
  )
}

const HeaderMobile = ({
  pathname,
  user,
  showConnect,
  showSubscribe,
  profileUser,
  logOut,
  updateModal,
  onSuccessCV,
  onSuccessAvailability,
  updateUser
}) => {

  const [showPage, updateShowPage] = useState()

  return(
    <>
      <div className={styles.mobile_header}>
        <div className='image_contain' className={styles.mobile_icon} onClick={() => updateShowPage(showPage === 1 ? null : 1)}>
          <img src={require('@icon/mobile_list.svg').default}/>
        </div>
        <NavLink
          to={"/"} 
          onClick={() => updateShowPage(null)}
        >
          <div className={styles.mobile_icon}>
            <img className='image_contain' src={require('@icon/cb.svg').default}/>
          </div>
        </NavLink>
        {profileUser ? (
          <div className={styles.mobile_icon} onClick={() => updateShowPage(showPage === 3 ? null : 3)}>
            <img className='image_cover' src={user?.image || require('@icon/mobile_user.svg').default}/>
          </div>
        ) : 
          user ? (
            <NavLink 
              to={"/candidat"} 
              onClick={() => updateShowPage(null)}
            >
              <div className={styles.mobile_icon}>
                <img className='image_cover' src={user?.image || require('@icon/mobile_user.svg').default}/>
              </div>
            </NavLink>
          ) : (
            <div className={styles.mobile_icon} onClick={() => updateShowPage(showPage === 2 ? null : 2)}>
              <img className='image_contain' src={require('@icon/mobile_user.svg').default}/>
            </div>
          )}
      </div>

      {showPage === 1 && (
        <div 
          className={styles.container_modal_header}
        >
           <NavLink 
            to={"/offres"} 
            isActive={() => pathname.split('/')[1] === "offres"} 
            className={`${styles.header_block} hover_color`}
            activeClassName={styles.selected}
            onClick={() => updateShowPage(null)}
          >
            <span>Les offres d'emploi</span>
          </NavLink>
          <NavLink 
            to={"/cabinets"} 
            isActive={() => pathname.split('/')[1] === "cabinets"} 
            className={`${styles.header_block} hover_color`}
            activeClassName={styles.selected}
            onClick={() => updateShowPage(null)}
          >
            <span>Découvrir les cabinets</span>
          </NavLink>
          <NavLink 
            to={"/articles"} 
            isActive={() => pathname.split('/')[1] === "articles"} 
            className={`${styles.header_block} hover_color`}
            activeClassName={styles.selected}
            onClick={() => updateShowPage(null)}
          >
            <span>Découvrir le conseil</span>
          </NavLink>
          <NavLink 
            to={"/simulateur-salaire"} 
            isActive={() => pathname.split('/')[1] === "simulateur-salaire"} 
            className={`${styles.header_block} hover_color`}
            activeClassName={styles.selected}
            onClick={() => updateShowPage(null)}
          >
            <span>Simulateur de salaire</span>
          </NavLink>
        </div>
      )}

      {showPage === 2 && (
        <div 
          className={styles.container_modal_header}
        >
           <div 
            className={styles.header_block}
            onClick={() => {
              showConnect()
              updateShowPage(null)
            }}
           >
            <span>Se connecter</span>
          </div>
          <div 
            className={styles.header_block}
            onClick={() => {
              showSubscribe()
              updateShowPage(null)
            }}
           >
            <span>S'inscrire</span>
          </div>
        </div>
      )}

      {showPage === 3 && (
        <div 
          className={styles.container_modal_header}
        >
          <HeaderUser 
            user={user}
            profileUser={profileUser}
            showTest={() => updateModal('buddyScan')}
            onSuccessCV={onSuccessCV}
            onSuccessAvailability={onSuccessAvailability}
            updateUser={updateUser}
          />
          <div className={styles.header_block} onClick={logOut}>
            <div>Déconnexion</div>
          </div>
        </div>
      )}
    </>
  )
}


const Header = ({
  user,
  logOut,
  showSubscribe,
  showConnect,
  color,
  recruteur,
  profileUser,
  updateModal,
  onSuccessCV,
  onSuccessAvailability,
  updateUser
}) => {
  const { pathname } = useLocation();
  return(
    <>
      <BrowserView>
        <HeaderBrowser
          user={user}
          logOut={logOut}
          showSubscribe={showSubscribe}
          showConnect={showConnect}
          color={color}
          recruteur={recruteur}
          pathname={pathname}
        />
      </BrowserView>
      <MobileView>
        <HeaderMobile
          pathname={pathname}
          showConnect={showConnect}
          showSubscribe={showSubscribe}
          user={user}
          profileUser={profileUser}
          updateModal={updateModal}
          onSuccessCV={onSuccessCV}
          onSuccessAvailability={onSuccessAvailability}
          updateUser={updateUser}
          logOut={logOut}
        />
      </MobileView>
    </>
  )
  
    
}

export default Header;
