import React from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import List from '@utils/list/List';
import MinArticle from '@site/articles/minarticle/MinArticle';

import {Link} from "react-router-dom";
import useListReducer from '@utils/function/useListReducer';

import styles from './typecabs.module.css';

var date = require('@utils/function/date');

const TypeCabs = ({

}) => {

  const [listState, updateList] = useListReducer()

  function renderItem(item, index){
    return(
      <MinArticle
        article={item}
        key={item.id.toString()}
        style={
            isMobile ? {width:'90vw', marginLeft:'5vw'} : 
              index%2 === 0 ? {width:'47.5%', margin: '0 2.5% 5vh 0'} : {width:'47.5%', margin: '0 0 5vh 2.5%'}}
      />
    )
  }

  return(
    <List 
      endpoint={"type_cabs"}
      data={listState.data}
      renderItem={renderItem}
      data_received={(_data, refresh) => updateList(refresh ? {type:'refresh', data:_data} : {type:'next_batch', data:_data})}
      isLoading={listState.isLoading}
      onError={() => {}}
      noScroll={true}
      contentListStyle={{flexDirection:'row', flexWrap:'wrap'}}
      socketKeys={"articles"}
      contentListStyle={isMobile ? {paddingTop:'5vh'} : {paddingTop:'4vh', flexDirection:'row', flexWrap:'wrap', overflow:'hidden'}}
    />
  )

}

export default TypeCabs;
