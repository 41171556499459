import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import Button from '@utils/button/Button';
import FormHandler from '@utils/form/formhandler/FormHandler';


import styles from '@utils/list/list.module.css';
import styles_form from './formview.module.css';

import _ from 'lodash'

var update = require('immutability-helper');

function FormView({
  fields,
  sections,
  onChange,
  onDelete,
  onValidate,
  buttons,
  autoHeight,
  dataForm,
  dataFiles,
  noNumber,
  renderHeader,
  renderFooter,
  disabled,
  error,
  isComplete
}) {
  return(
    <div className="container">

      <FormHandler 
        fields={fields}
        sections={sections}
        onChange={onChange}
        autoHeight={autoHeight}
        dataForm={dataForm}
        dataFiles={dataFiles}
        noNumber={noNumber}
        renderHeader={renderHeader}
        renderFooter={renderFooter}
        disabled={disabled}
      />

      {error && (
        <div className={styles_form.error}>{error}</div>
      )}
      
      {buttons && (
        <div className={styles_form.container_banner_button}>
          <div className={styles_form.delete_button}>
            {buttons.delete && (
              <div onClick={onDelete} className='hover_red_font'>{buttons.delete.label}</div>
            )}
          </div>
          <div className={styles_form.container_action_button}>
            {buttons.sub_action && (
              <Button
                label={buttons.sub_action.label}
                type={buttons.sub_action.type}
                onClick={() => {
                  if(buttons.sub_action.onPress){
                    buttons.sub_action.onPress()
                  }else{
                    onValidate(buttons.sub_action.field)
                  }
                }}
                style={{marginRight:'1vw'}}
              />
            )}
            <Button
              label={buttons.action.label}
              type={buttons.action.type}
              //disabled={!Object.values(data_form).every(x => !x.required || x.value)}
              onClick={() => onValidate(buttons.action.field)}
              disabled={!isComplete}
            />
          </div>
        </div>
      )}

    </div>
  )
}

export default FormView;