import React from 'react';

import '../css/bannercookies.css';


class BannerCookies extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      show_more:false
    };

    this.show_more = this.show_more.bind(this)

  }

  shouldComponentUpdate(nextProps, nextState){
    return nextState.show_more !== this.state.show_more
  }

  show_more(){
    this.setState({
      show_more:true
    })
  }


  render(){
    return(
      <div id="container_cookies" className={this.state.show_more ? "full_cookies" : ""}>
        
        <div id="intro_cookies">
          <div>🍪</div>
          <div className="label_intro_cookies">Nous ne vendons pas vos données</div>
          <div className="show_more_cookies" onClick={this.show_more}>En savoir plus</div>
          <div className="container_close_cookie" onClick={this.props.acceptCookies}><img src={require('../icon/close_popup.svg').default}/></div>
        </div>

        <div id="inner_banner_cookies">
          <div className="label_banner_cookies"><span>Chez Consulting Buddies, nous ne vendons aucune donnée à des tierces parties. Nous utilisons uniquement les cookies<span className="picto_cookie">🍪</span>pour vous authentifier sur votre espace utilisateur et mesurer notre audience !</span></div>
          <div className="button_banner_cookies pointer" onClick={this.props.acceptCookies}>OK</div>
        </div>

      </div>
    )
    
  }
}

export default BannerCookies;
