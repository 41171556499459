import React from 'react';

import SelectForm from '@utils/form/selectForm/SelectForm';

const Recruit = ({

}) => {

  return(
    <SelectForm 
      endpointList="admin/get_list_cabs"
      endpointForm="admin/get_form_recruit"
      endpointUpdate="admin/update_cab_recruit"
      keyLastModif='last_modif_recruit'
    />
  );
};

export default Recruit;
