import React, { useState, useEffect, Stylesheet } from 'react';

import ReactHtmlParser from 'react-html-parser';

import customFetch from '@utils/function/customFetch';

import styles from './tablerecruit.module.css';

var update = require('immutability-helper');

function TableRecruit({ 
  rounds,
  id_cab,
  coaching,
  step
}){

  const [showAll, updateShowAll] = useState(false)

  return (
    <div className={styles.container_table}>
      <div className={styles.container_header}>
        <div className={`${styles.item} ${styles.item_number}`}>N°</div>
        <div className={`${styles.item} ${styles.item_type}`}>Type d'entretien</div>
        <div className={`${styles.item} ${styles.item_critere}`}>Critères évalués</div>
      </div>
      <div className={styles.inner_table}>
        {rounds.map((item, index) => {
          return(
            <div key={item.addlist.toString()} className={`${styles.item_recruit} ${index && !showAll && styles.hide}`}>
              <div className={`${styles.item} ${styles.item_number}`}>{step || index+1}</div>
              <div className={`${styles.item} ${styles.item_type}`}>
                <div className={styles.title}>{ReactHtmlParser(item.title_entretien)}</div>
                <div className={styles.text}>{ReactHtmlParser(item.text_entretien)}</div>
              </div>
              <div className={`${styles.item} ${styles.item_critere}`}>{ReactHtmlParser(item.text_critere)}</div>
            </div>
          )
        })}
      </div>
      {!showAll && !coaching && (
        <div className={`${styles.banner} hover_background_color`} onClick={() => {
          customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 4, id_cab } , type:'POST'}) 
          updateShowAll(true)
        }}>
          Voir le reste du processus d'entretien
          <img src={require('@icon/down_arrow_white.svg').default}/>
        </div>
      )}
    </div>
  )

}

export default TableRecruit