import React, { useState, useEffect, Stylesheet } from 'react';
import Switch from 'react-switch';
import customFetch from '@utils/function/customFetch';

import OfferUser from './offerUser/OfferUser';
import OfferCab from './offerCab/OfferCab';
import OfferSenior from './offerSenior/OfferSenior';

import styles from './offer.module.css';

function Offer({ 
  offer,
  clickOffer,
  onSuccess,
  type,

  toggleModalOffre,
  over,
  available,
  previewCV,
  senior,
  onSuccessApply,
  onDeclineOffer,
  cabView,

  initialMatch,
  styleOffer,

  admin,

  onDeleteOffer,
  hideCabName
}){

  if(type === 'user' && senior && offer.pending){
    return(
      <OfferSenior
        offer={offer}
        onSuccessApply={onSuccessApply}
        onDeclineOffer={onDeclineOffer}
        cabView={cabView}
        initialMatch={initialMatch}
        admin={admin}
      />
    )
  }else if(type === 'user'){
    return(
      <OfferUser
        offer={offer}
        senior={senior}
        toggleModalOffre={toggleModalOffre}
        over={over}
        available={available}
        style={styleOffer}
        admin={admin}
        onDeleteOffer={onDeleteOffer}
        hideCabName={hideCabName}
      />
    )
  }else{
    return(
      <OfferCab 
        clickOffer={clickOffer}
        offer={offer}
        onSuccess={onSuccess}
        previewCV={previewCV}
      />
    )
  }
}

export default Offer