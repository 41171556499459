import React, { useState, useEffect, Stylesheet } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  useParams
} from "react-router-dom";

import ListRecruitmentSenior from './ListRecruitmentSenior';
import ModalConfirm from './modalConfirm/ModalConfirm';
import SeniorCV from './seniorcv/SeniorCV';
import BannerContext from '@utils/bannerContext/BannerContext';

import List from '@utils/list/List';

import useListReducer from '@utils/function/useListReducer';
import customFetch from '@utils/function/customFetch';

import styles from './seniors_recruitment.module.css';

var update = require('immutability-helper');

const CV = [
  {
    value:0, 
    id_setting:1, 
    title:"Rejeter candidature", 
    label_title:"Souhaitez-vous rejeter cette candidature ?", 
    text:["En rejetant ce profil, la candidature sera placée dans votre corbeille et n’apparaitra plus dans vos suggestions.", "Ce profil ne sera pas facturé."], 
    style:{backgroundColor:'var(--red)', borderColor:'var(--red)', color:'white'}, 
    label_button:"Confirmer"
  },
  {
    value:1, 
    id_setting:0, 
    title:"Valider candidat", 
    label_title:"Souhaitez-vous valider ce candidat ?", 
    text:["Après validation de votre part, ce candidat sera contacté et devra répondre à la demande de mise en relation."], 
    style:{backgroundColor:'var(--green)', borderColor:'var(--green)', color:'white'}, 
    label_button:'Valider'
  },
  {
    value:2, 
    id_setting:2, 
    title:"Rétablir le candidat dans les suggestions", 
    label_title:"Souhaitez-vous rétablir ce candidat dans les suggestions ?", 
    text:["En rétablissant ce candidat dans les suggestions, celui-ci sera retiré de la liste des candidatures rejetées.", "Vous aurez alors à nouveau la possibilité de valider ce profil."], 
    style:{backgroundColor:'var(--orange)', borderColor:'var(--orange)', color:'white'}, 
    label_button:'Rétablir'
  },
  {
    value:3, 
    id_setting:5, 
    title:"Recrutement du candidat", 
    label_title:"Avez-vous recruté ce candidat ?", 
    text:["Vous confirmez que ce candidat est allé au bout du  processus de recrutement et a accepté une offre au sein de votre cabinet"], 
    style:{backgroundColor:'var(--green)', borderColor:'var(--green)', color:'white'}, 
    label_button:'Valider'
  },
  {
    value:4, 
    id_setting:6, 
    title:"Refus du candidat", 
    label_title:"Avez-vous refusé ce candidat ?", 
    text:["Vous confirmez que le candidat n’est pas allé au bout de votre processus de recrutement ou n’a pas accepté d’offre au sein de votre cabinet"], 
    style:{backgroundColor:'var(--red)', borderColor:'var(--red)', color:'white'}, 
    label_button:'Confirmer'
  },
]

const SeniorsRecruitment = ({
  user,
  updateUser,
  onSuccessPreferences
}) => {
  const [showModal, updateShowModal] = useState(null)
  const [isChecked, toggleCheck] = useState()
  const [showCV, updateShowCV] = useState(false)

  const [seniorSelect, updateSeniorSelect] = useListReducer()
  const [seniorWaiting, updateSeniorWaiting] = useListReducer()
  const [seniorConfirm, updateSeniorConfirm] = useListReducer()

  function updateMatch({ evt, value, type, id_match }){
    if(evt){
      evt.stopPropagation();
    }
    let id_setting = null
    if(type === 0 || type === 2){
      if(!showModal){
        let index = user.settings.findIndex(x => x === CV[value].id_setting)
        if(index === -1){
          updateShowModal({ value, type, id_match })
          return
        }
      }else if(isChecked){
        id_setting = CV[showModal.value].id_setting
      }
    }

    let data = {
      type,
      id:id_match
    }

    if(type === 2){
      value = value === 3 ? 1: 0
    }

    if(id_setting !== null) data = {...data, ...{id_setting}}
    if(value !== 2){
      data = {...data, ...{value}}
    }else{
      data = {...data, ...{reboot:true}}
    }

    toggleCheck()
    customFetch({ endpoint: `recruteur/update_match`, data, onSuccess, type:'POST'})
    if(id_setting !== null){
      updateUser({settings:[...user.settings, ...[id_setting]]})
    }
    
  }

  function onSuccess({ response }){
    if(response.status === 0){
      updateSeniorSelect({type:'update_item', data:seniorSelect, item:response})
    }else if(response.status === 1){
      if(response.bin){
        updateSeniorSelect({type:'replace_item', data:seniorSelect, item:response, toIndex:0})
      }else{
        updateSeniorSelect({type:'delete_item', data:seniorSelect, item:response})
        updateSeniorWaiting({type:'replace_item', data:seniorWaiting, item:response, toIndex:0})
      }
    }else if(response.status === 2){
      if(response.answer_cab === 0 || response.answer_cab === 1){
        updateSeniorConfirm({type:'replace_item', data:seniorConfirm, item:response})
      }else{
        updateSeniorSelect({type:'delete_item', data:seniorWaiting, item:response})
        updateSeniorWaiting({type:'replace_item', data:seniorConfirm, item:response, toIndex:0})
      }
    }
    updateShowCV(null)
    updateShowModal(null)
  }

  function navigateCV(value){
    const newShowCV = {...showCV}
    newShowCV.index = newShowCV.index + value 
    updateShowCV(newShowCV)
  }

  return(
    <div className={styles.seniors_recruitment}>
      <BannerContext 
        image={require('@icon/bannercontext/recrutement.svg').default}
        subtitle={[`Vous trouverez ci-dessous notre sélection de profils expérimentés parmi le pool de candidats à l’écoute d’opportunités en conseil.`]}
        id_context={5}
        onSuccess={onSuccessPreferences}
        preferences={user.preferences}
        endpoint='recruteur/update_preferences'
        style={{padding:'2vh 2vw', margin:'1vh', marginTop:'2vh'}}
      />
      <div className={styles.container_seniors_recruitment}>
        <ListRecruitmentSenior
          type={0}
          title="Sélectionnez les profils"
          user={user}
          updateMatch={updateMatch}
          list={seniorSelect}
          updateList={updateSeniorSelect}
          updateShowCV={updateShowCV}
        />
        <ListRecruitmentSenior
          type={1}
          title="Attendez le retour des candidats"
          user={user}
          list={seniorWaiting}
          updateList={updateSeniorWaiting}
          updateShowCV={updateShowCV}
        />
        <ListRecruitmentSenior
          type={2}
          title="Contactez les candidats"
          user={user}
          updateMatch={updateMatch}
          list={seniorConfirm}
          updateList={updateSeniorConfirm}
          updateShowCV={updateShowCV}
        />
      </div>

      {showModal && (
        <ModalConfirm
          match={showModal}
          updateMatch={updateMatch}
          closeModal={() => {
            updateShowModal()
            toggleCheck()
          }}
          isChecked={isChecked}
          toggleCheck={toggleCheck}
          content={CV[showModal.value]}
        />
      )}

      {showCV && (
        <SeniorCV 
          closeCV={() => updateShowCV(false)}
          indexCV={showCV.index}
          type={showCV.type}
          item={showCV.list[showCV.index]}
          totalCV={showCV.list.length}
          navigateCV={navigateCV}
          option={showCV.option}
          updateMatch={updateMatch}
        />
      )}
      
    </div>
  )
}

export default SeniorsRecruitment

 