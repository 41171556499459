import React, { useState, useEffect, Stylesheet } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import customFetch from "@utils/function/customFetch";
import styles from "../offer.module.css";
import ModalConfirm from "../../modalConfirm/ModalConfirm";
import ReactHtmlParser from "react-html-parser";

const OfferUser = ({
  offer,
  senior,
  toggleModalOffre,
  over,
  available,
  style,
  admin,
  onDeleteOffer,
  hideCabName = false,
}) => {
  const [showDelete, setShowDelete] = useState();

  function renderStatus(
    application,
    { stop_explanation, details_stop_explanation }
  ) {
    let label;
    let className;
    let width;

    switch (application.status) {
      case 0:
        label = `${application.step}/${application.total_step} étapes`;
        width = `${(application.step / application.total_step) * 100}%`;
        break;
      case 1:
        label = `Recrutement`;
        className = "finished";
        width = `100%`;
        break;
      case 2:
        label = `Candidature arrêtée`;
        className = "stoped";
        width = `100%`;
        break;
      case 3:
        label = `Candidature déclinée`;
        className = "declined";
        width = `100%`;
        break;
      default:
        break;
    }
    let stopExplanationLabel;

    switch (stop_explanation) {
      case 0:
        stopExplanationLabel = `J'ai accepté une offre d'une autre entreprise`;
        break;
      case 1:
        stopExplanationLabel = `Le cabinet n'a pas donné suite à ma candidature`;
        break;
      case 2:
        stopExplanationLabel = "Autre";
        break;
      case 3:
        stopExplanationLabel = "Candidature périmée";
        break;
      default:
        break;
    }
    return (
      <>
        <div
          className={`${styles.extraContentApply} ${
            className && styles[className]
          }`}
        >
          <div className={styles.container_steps}>{label}</div>
          <div className={styles.progress_bar}>
            <div className={styles.inner_progress_bar} style={{ width }}></div>
          </div>
        </div>
        {stopExplanationLabel && (
          <div className={styles.stop_explanation}>
            <div className={styles.title_stop_explanation}>
              {stopExplanationLabel}
            </div>
            {details_stop_explanation && (
              <div className={styles.details_stop_explanation}>
                {ReactHtmlParser(details_stop_explanation)}
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  function getRelevantId() {
    if (senior) {
      return { id_match: offer.id };
    } else if (!offer.spontaneous) {
      return { id_offre: offer.id };
    } else {
      return { id_cab: offer.id };
    }
  }

  function onError(e) {
    console.log(e);
  }

  return (
    <>
      <div
        className={`${styles.container_offre} ${over && styles.offre_over} ${
          offer.spontaneous && styles.spontaneous
        } card hover_card`}
        style={style}
        key={`${offer.spontaneous ? "cab_" : ""}${offer.id.toString()}`}
        onClick={() =>
          (!offer.application ||
            (offer.application &&
              offer.application.status !== 2 &&
              offer.application.status !== 3)) &&
          toggleModalOffre(getRelevantId())
        }
      >
        {admin && (
          <div
            className={styles.delete}
            onClick={(e) => {
              e.stopPropagation();
              setShowDelete(true);
            }}
          >
            <img
              src={require("@icon/close.svg").default}
              className="image_cover"
            />
          </div>
        )}
        <div className={styles.container_icon}>
          <img src={offer.cab.logo} />
        </div>
        <div className={styles.infos_offre}>
          {!hideCabName && (
            <div className={styles.container_name}>{offer.cab.name}</div>
          )}
          <div className={styles.title_offre}>
            {senior
              ? "Offre d'entretien"
              : offer.title || "Candidature spontanée"}
          </div>
          {!offer.spontaneous && !senior && (
            <div className={styles.details}>
              {offer.contract && (
                <>
                  <div className={styles.item_details_offre}>
                    {offer.contract?.label}
                  </div>
                  <div className={styles.item_details_offre}>|</div>
                </>
              )}
              <div className={styles.item_details_offre}>
                {offer.place?.label}
              </div>
              <div className={styles.item_details_offre}>|</div>
              <div className={styles.item_details_offre}>{offer.date}</div>
            </div>
          )}
        </div>
        {offer.application &&
          !available &&
          renderStatus(offer.application, {
            stop_explanation: offer.stop_explanation,
            details_stop_explanation: offer.details_stop_explanation,
          })}
        {offer.pop_up && (
          <div className={styles.detailsRefused}>{offer.pop_up}</div>
        )}
      </div>
      {showDelete && (
        <ModalConfirm
          header="Suppression d'une mise en relation"
          closeModal={() => setShowDelete(false)}
          labelConfirm="Supprimer"
          onConfirm={() =>
            customFetch({
              endpoint: "admin/update_match",
              data: { id: offer.id_match, deleted: true },
              onSuccess: (response) => {
                setShowDelete(false);
                onDeleteOffer(response);
              },
              onError,
              type: "POST",
            })
          }
          content="Attention ! Vous allez supprimer une mise en relation. "
        />
      )}
    </>
  );
};

export default OfferUser;
