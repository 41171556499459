import React, { useState, useRef } from 'react';
import styles from './header.module.css';

import { Link, NavLink } from "react-router-dom";
import useOnClickOutside from "@utils/function/useOnClickOutside";

var update = require('immutability-helper');

const Menu = ({
  toggleMenu,
  logOut
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => toggleMenu(false));

  return(
    <div className={styles.container_hidden_menu} ref={ref}>
      <div className={styles.container_option} onClick={logOut}>Déconnexion</div>
    </div>
  )
}

const Header = ({
  user,
  logOut
}) => {

  const [showMenu, toggleMenu] = useState(false)

  function menu(){
    return(
      <div className={styles.container_hidden_menu}>
        <div className={styles.container_option}>Déconnexion</div>
      </div>
    )
  }

  return(
    <div className={styles.header}>
      <div className={styles.container_logo}>

      </div>
      <div className={styles.container_nav}>
        <NavLink 
          to='/admin/dashboard' 
          activeClassName={styles.active}
          className={styles.item_header}
        >
          Tableau de bord
        </NavLink>
        <NavLink 
          to='/admin/matching' 
          activeClassName={styles.active}
          className={styles.item_header}
        >
          Matching
        </NavLink>
        <NavLink 
          to='/admin/cms' 
          activeClassName={styles.active}
          className={styles.item_header}
        >
          CMS
        </NavLink>
      </div>
      <div className={styles.container_menu}>
        <div className={styles.container_name} onClick={toggleMenu}>
          <div className="round_image">
            <img src={user.image}/>
          </div>
          <div className={styles.container_label_name}>{user.name}</div>
        </div>
        {showMenu && (
          <Menu 
            toggleMenu={toggleMenu}
            logOut={logOut}
          />
        )}
      </div>
    </div>
  )
    
}

export default Header;
