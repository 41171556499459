import React, { useState, useEffect, Stylesheet } from 'react';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Link } from "react-router-dom";

import Field from '@utils/form/field/Field';
import Button from '@utils/button/Button';
import Delete from '@utils/delete/Delete';
import AddCV from '@user/addcv/AddCV';
import EditProfile from '@user/editProfile/EditProfile';

import customFetch from '@utils/function/customFetch';

import styles from './header_user.module.css';
const { v4: uuidv4 } = require('uuid');

const HeaderUser = ({
    user,
    profileUser,
    showTest,
    onSuccessCV,
    onSuccessAvailability,
    updateUser
}) => {

  const [editProfile, toggleEditProfile] = useState()
  const [isLoadingCV, updateIsLoadingCV] = useState()

  function getCV(e){
    if(e.target.files && e.target.files[0]){
      updateIsLoadingCV(true)
      customFetch({ endpoint: 'user/uploadCV', onSuccess: (data) => {
        updateIsLoadingCV(false)
        onSuccessCV(data)
      }, type:'POST', files:{cv:e.target.files[0]}})
      customFetch({ endpoint: 'activity/activity', data: { token : localStorage.getItem('token'), id_activity: 2 } , type:'POST'}) 
    }
  }

  function deleteCV(){
    customFetch({ endpoint: 'user/deleteCV', onSuccess: onSuccessCV, type:'POST', data:{id:user.id}})
  }

  function onChangeAvailability({ value }){
    customFetch({ endpoint: 'user/updateAvailability', onSuccess: onSuccessAvailability, type:'POST', data:{value}})
  }

  return(
    <div className={`${styles.header_user} card`}>
      <div className={styles.user}>
        <div className={styles.outer_image}>
          <div className={styles.container_image}>
            {user?.image ? (
              <img src={user.image} className="image_cover"/>
            ) : (
              <div className={styles.empty_user}>
                <img src={require('@icon/empty_user.svg').default}/>
              </div>
            )}
          </div>
        </div>
        <div className={styles.details_profile_user}>
          <div className={`${styles.item_header} ${styles.name}`}>
            <div>{user.name}</div>
            <div className={`${styles.container_edit} hover_background_color`} onClick={toggleEditProfile}>
              <img src={require('@icon/edit_white.svg').default} className='image_contain'/>
            </div>
          </div>
          <div className={`${styles.item_header} ${styles.infos}`}>{user.email || 'Pas d\'email renseigné'}</div>
          <div className={`${styles.item_header} ${styles.senior}`}>{user.senior ? 'Senior' : 'Junior'}</div>
        </div>
      </div>
      

      <div className={styles.item_bloc_header}>
        <div className={`${styles.intro_bloc}`}>{user.senior ? `A l'écoute`: `En recherche`}</div>
        <div className={`${styles.content_bloc}`}>
          <Field
            field={{type:'switch', value:profileUser.available}}
            style={{margin:0, padding:0, width:'auto', justifyContent: 'center'}}
            onChange={onChangeAvailability}
          />
        </div>
      </div>
      
      {!isMobile && (
        <Link className={styles.item_bloc_header} style={{ cursor: 'pointer' }} to={'/candidat/candidatures'}>
          <div className={styles.bloc_figure}>{user.senior ? profileUser.total_match : profileUser.total_apply}</div>
          <div className={styles.bloc_label}>{user.senior ? `${profileUser.total_apply>1 ? 'Propositions d\'entretien' : 'Proposition d\'entretien'}` : `${profileUser.total_apply>1 ? 'Candidatures démarées' : 'Candidature démarée'}`}</div>
        </Link>
      )}

      {!isMobile && (
        <Link className={styles.item_bloc_header} style={{ cursor: 'pointer' }} to={'/candidat/candidatures'}>
          <div className={styles.bloc_figure}>{user.senior ? profileUser.ongoing_match : profileUser.ongoing_apply}</div>
          <div className={styles.bloc_label}>Processus en cours</div>
        </Link>
      )}

      <div className={`${styles.details_profile_user} ${styles.container_buttons}`}>
        <AddCV 
          cv={profileUser.cv}
          deleteCV={deleteCV}
          getCV={getCV}
          label={profileUser.cv ? 'Mon CV' : 'Ajouter mon CV'}
          isLoading={isLoadingCV}
          styleButton={isMobile ? { fontSize:'var(--size_3)', borderColor:'transparent', flex:1, padding:'4vh 4vw', justifyContent:'flex-start'} : {flex:1}}
          style={isMobile ? { borderBottom: '1px solid var(--border)', paddingRight:'2vw' } : {}}
          fromHeader={true}
        />
        <Button
          label="Repasser le test"   
          style={{borderStyle:'dotted', marginTop:'1vh'}} 
          icon={require('@icon/profile/test.svg').default}
          onClick={showTest}
          style={isMobile ? { fontSize:'var(--size_3)', border:'none', borderBottom: '1px solid var(--border)', flex:1, padding:'4vh 4vw', justifyContent:'flex-start'} : {}}
        />
      </div>

      {editProfile && (
        <EditProfile 
          user={user}
          closeModal={() => toggleEditProfile()}
          updateUser={updateUser}
        />
      )}

  </div>
  )
}

export default HeaderUser

 