import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import TableRecruit from './tablerecruit/TableRecruit';
import ButtonBuddyScan from '@site/home/buttonbuddyscan/ButtonBuddyScan';
import ListOfferJunior from '@user/listofferjunior/ListOfferJunior';

import {Link} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

import useListReducer from '@utils/function/useListReducer';
import customFetch from '@utils/function/customFetch';

import styles from './recruit.module.css'

const BannerRecruit = ({
  cab,
  user,
  openBuddyScan
}) => {

  const [cv, updateCV] = useState();

  useEffect(() => {
    if(user){
      customFetch({ endpoint: 'user/profile', onSuccess: onSuccessProfileUser, type:'GET'})
    }
  }, [user])

  function onSuccessProfileUser({ response }){
    updateCV(response?.cv)
  }


  if(user && user.senior) return null

  return(
    <div className={styles.container_banner} style={!user && !isMobile ? {flex:4} : {}}>
      <div className={styles.intro_banner}>
        <div className={styles.illustration}>
          <img className="image_contain" src={require('@icon/illustration_recruit.svg').default}/>
        </div>
        <div className={styles.content}>
          {!user ? (
            <>
              <div className={styles.title_content}>Votre profil peut-il intéresser {cab.name} ?</div>
              <div className={styles.subtitle_content}>Découvrez vos chances de décrocher un premier entretien avec ce cabinet</div>
              <ButtonBuddyScan
                label="Découvrir mon score"
                style={{alignSelf:isMobile ? 'center' : 'flex-start', marginTop:'2vh'}}
                onClick={openBuddyScan}
              />
            </>
          ) : (
            <>
              <div className={styles.title_content}>Découvrez les offres d’emploi actuellement disponibles chez {cab.name}</div>
            </>
          )}
        </div>
     </div>
     {(!user || !user.senior) && (
      <div className={styles.container_offres}>
        <ListOfferJunior
          endpoint={'user/get_offres'}
          endpointDetails={'user/offre'}
          extraData={{id_cab:cab.id, home:true}}
          noScroll={true}
          hideHeader={true}
          user={user}
          styleOffer={{backgroundColor:'white'}}
          profileUser={{cv}}
          home={true}
          hideCabName={true}
        />
        <Link 
          to={{pathname:`/offres`, state:{cab}}} 
          className={`${styles.view_more} hover_color`}
        >
          Voir toutes les offres de {cab.name}
        </Link>
      </div>
     )}
    </div>
  )
};

const Recruit = ({
  cab,
  user,
  openBuddyScan
}) => {

  const [selectedRound, updateSelectedRound] = useState(cab.rounds && cab.rounds.length>0 ? cab.rounds[0].type : 0)

  return(
    <div className={styles.container}>
      <div className={styles.bloc_recruit}>
        <div className={styles.text}>
          {ReactHtmlParser(cab.recrutement)}
        </div>
        <BannerRecruit 
          user={user}
          cab={cab}
          openBuddyScan={openBuddyScan}
          style={{flex:1}}
        />
      </div>
      
      {cab.rounds?.length>0 && (
        <>
          {cab.rounds.length>1 && (
            <>
              <div className={styles.switch}>
                {cab.rounds.find(x => x.type === 0) && (
                  <div className={`${styles.item_switch} ${selectedRound === 0 && styles.active}`} onClick={() => updateSelectedRound(0)}>
                  <span>CDI</span>
                  </div>
                )}
                {cab.rounds.find(x => x.type === 1) && (
                  <div className={`${styles.item_switch} ${selectedRound === 1 && styles.active}`} onClick={() => updateSelectedRound(1)}>
                    <span>Stages</span>
                  </div>
                )}
              </div>
            </>
          )}
          
          <div className={styles.table}>
            <TableRecruit 
              rounds={cab.rounds.find(x => x.type === selectedRound).value}
              id_cab={cab.id}
            />
          </div>
        </>
      )}

    

     
    </div>
  )

};



export default Recruit;
