import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-autosize-textarea";
import Field from "../Field";

import styles_field from "../field.module.css";
import styles_addlist from "./addlist.module.css";

function Addlist(props) {
  const [items, updateItems] = useState(null);
  const [formValues, updateFormValues] = useState({});

  useEffect(() => {
    if (props.field.value?.length > 0) {
      let iniatialValues = [];
      for (let i = 0; i < props.field.value.length; i++) {
        let addlistValue = [];
        for (let j = 0; j < props.field.addlist.length; j++) {
          let field = { ...props.field.addlist[j] };
          field.value = props.field.value[i][field.key];
          field.addlist = props.field.value[i].addlist;
          addlistValue.push(field);
        }
        iniatialValues.push(addlistValue);
      }
      updateItems(iniatialValues);
    } else if (props.field.initiateEmpty) {
      addItem();
    } else {
      updateItems([]);
    }
  }, [props.key]);

  function addItem() {
    let newItems = items ? [...items] : [];
    let fields = [...props.field.addlist];
    let index =
      newItems.length > 0
        ? Math.max(...newItems.map((o) => o[0].addlist)) + 1
        : 0;
    fields = fields.map((v) => ({ ...v, addlist: index }));
    newItems.push(fields);
    updateItems(newItems);

    let newFormValues = { ...formValues };
    newFormValues[index] = { deleted: null };
    updateFormValues(newFormValues);
    props.onChange({
      value: newFormValues,
      key: props.field.key,
    });
  }

  function deleteItem(addlist) {
    let newItems = [...items];
    let index = newItems.findIndex((x) => x[0].addlist === addlist);
    if (index !== -1) {
      newItems.splice(index, 1);
      updateItems(newItems);
    }

    let newFormValues = { ...formValues };
    newFormValues[addlist] = { deleted: true };
    updateFormValues(newFormValues);
    props.onChange({
      value: newFormValues,
      key: props.field.key,
    });
  }

  function onChange({ value, addlist, key }) {
    let newFormValues = { ...formValues };
    if (!newFormValues[addlist]) newFormValues[addlist] = {};
    newFormValues[addlist][key] = value;
    updateFormValues(newFormValues);
    props.onChange({
      value: newFormValues,
      key: props.field.key,
    });
  }

  function renderDelete(item) {
    return (
      <div
        className={`${styles_addlist.delete} hover_red`}
        onClick={() => deleteItem(item[0].addlist)}
      >
        Supprimer
      </div>
    );
  }

  return (
    <div className={styles_field.container_field}>
      <div className={styles_addlist.container_addlist}>
        {items?.map((item, index) => {
          //TODO : check index for key
          return (
            <div
              className={styles_addlist.container_item_addlist}
              key={item[0].addlist}
            >
              {props.renderLabelField &&
                props.renderLabelField(
                  props.field.titleAddlist.replace("{index}", index + 1),
                  () => renderDelete(item)
                )}
              <div className={styles_addlist.container_fields_addlist}>
                {item.map((field) => {
                  return (
                    <Field
                      field={field}
                      onChange={onChange}
                      key={
                        field.key.value
                          ? `${field.key.value}_${field.key.type}`
                          : field.key
                      }
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`${styles_addlist.add_button} hover_color`}
        onClick={addItem}
      >
        <img src={require("@icon/add.svg").default} />
        <span>{props.field.button}</span>
      </div>
    </div>
  );
}

export default Addlist;
