import React, { useState, useEffect, Stylesheet } from "react";

import BannerCab from "@recruteur/recrutement/bannercab/BannerCab";
import Form from "@utils/form/Form";
import Modal from "@utils/modal/Modal";

import customFetch from "@utils/function/customFetch";

var update = require("immutability-helper");

const CreateOffer = ({ id, cab, closeModal, onSuccess }) => {
  const [loading, updateLoading] = useState(true);
  const [fields, updateFields] = useState(null);

  useEffect(() => {
    customFetch({
      endpoint: "recruteur/get_form_offre",
      data: { id },
      onSuccess: getFormOffre,
      onError,
    });
  }, []);

  function getFormOffre(data) {
    updateFields(data.response);
    updateLoading(false);
  }

  function onError() {}

  if (loading) {
    return null;
  }

  return (
    <Modal closeModal={closeModal}>
      <BannerCab
        cab={cab}
        title={
          id
            ? "Modification d'une offre d'emploi"
            : "Création d'une offre d'emploi"
        }
        closeModal={closeModal}
      />
      {fields && (
        <Form
          endpoint={!id ? "recruteur/create_offer" : "recruteur/modify_offer"}
          fields={fields}
          buttons={{
            sub_action: id
              ? {
                  label: "Prévisualiser l'offre",
                  field: [
                    { key: "id", value: id },
                    { key: "preview", value: 1 },
                    { key: "id_cab", value: cab.id },
                  ],
                  /*   onPress: () => {
                    window.open(`/preview-offer/${id}`, "_blank", "noreferrer");
                  }, */
                }
              : {
                  label: "Enregistrer",
                  field: [
                    { key: "published", value: 0 },
                    { key: "id_cab", value: cab.id },
                  ],
                },
            action: id
              ? {
                  label: "Enregistrer les modifications",
                  type: "full",
                  field: [
                    { key: "id", value: id },
                    { key: "id_cab", value: cab.id },
                  ],
                }
              : {
                  label: "Publier",
                  type: "full",
                  field: [
                    { key: "published", value: 1 },
                    { key: "id_cab", value: cab.id },
                  ],
                },
            delete: id && {
              endpoint: "recruteur/delete_offer",
              label: "Supprimer cette offre",
              field: { key: "id", value: id },
            },
          }}
          success={onSuccess}
          renderFooter={() => (
            <div
              style={{
                fontSize: "var(--size_3)",
                color: "var(--font)",
                marginBottom: "3vh",
                fontStyle: "italic",
              }}
            >
              Les champs obligatoires sont marqués d'un astérisque.
            </div>
          )}
        />
      )}
    </Modal>
  );
};

export default CreateOffer;
