import React, { useState } from 'react';

import { Link } from "react-router-dom";
import Modal from '@utils/modal/Modal';
import Field from '@utils/form/field/Field';
import Button from '@utils/button/Button';
import Scrollbars from '@utils/scrollbars/Scrollbars';

import customFetch from '@utils/function/customFetch';

import styles from './modalforget.module.css'

const ModalForget = ({
  onClose,
  recruteur
}) => { 

  const [emailSent, onEmailSent] = useState()
  const [email, onChangeEmail] = useState('')
  const [error, setError] = useState()

  function renderFooter(){
    if(emailSent){
      return(
        <Button 
          type="full"
          label="Bien compris"
          onClick={onClose}
        />
      )
    }else{
      return(
        <Button 
          type="full"
          label="Envoyer"
          disabled={!email || email.length === 0}
          onClick={() => customFetch({ endpoint: 'forgot_password', data:{recruteur, email}, onSuccess, onError, type:'POST'})}
        />
      )
    }
  }

  function onSuccess(){
    onEmailSent(true)
  }

  function onError(error){
    setError(error)
  }

  if(emailSent){
    return(
      <Modal
        closeModal={onClose}
        header={"Mot de passe oublié"}
        footer={{style:{justifyContent:'flex-end'}, content:renderFooter()}}
        modalShrink={true}
      >

        <div className={styles.content}>
          
          <div className={styles.container_image}>
            <img src={require('@icon/illustration_sent.svg').default}/>
          </div>

          <div className={styles.label_forget}>Un email vous permettant de réinitialiser votre mot de passe vous a été envoyé</div>
          
        </div>

      </Modal>
    )
  }

  return(
    <Modal
      closeModal={onClose}
      header={"Mot de passe oublié"}
      footer={{style:{justifyContent:'flex-end'}, content:renderFooter()}}
      modalShrink={true}
    >

        <div className={styles.content}>
          
          <div className={styles.container_image}>
            <img src={require('@icon/illustration_forget.svg').default}/>
          </div>

          <div className={styles.label_forget}>Entrez votre email ci-dessous, nous vous enverrons un lien pour réinitialiser votre mot de passe</div>
          
          <div className={styles.form_forget}>
            <Field 
              field={{title:'Votre email', type:'input'}}
              onChange={(data) => {
                setError()
                onChangeEmail(data.value)
              }}
            />
          </div>

          {error && (
            <div className='error'>{error}</div>
          )}

        </div>

    </Modal>
  )
}


export default ModalForget;