import React, { useState, useEffect, Stylesheet } from 'react';

import Form from '@utils/form/Form';

import styles from './letter.module.css';

const Letter = ({
    letter,
    renderButtons
}) => {
  return(
    <>
      <div className={`${styles.content_action} container`}>
        <Form
          sections={[
            {
              name:'Lettre de motivation', 
              key:'letter',
              fields:[{type:'texteditor', key:'letter', disabled:true, value:letter}]
            }
          ]}
          noNumber={true}
        />
      </div>
      {renderButtons()}
    </>
  )
}

export default Letter

 