import React, { useEffect, useState } from "react";

import ReactHtmlParser from "react-html-parser";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import styles from "./profiles.module.css";
import ListSchools from "./list-schools/ListSchools";

const COLORS = ["#4E68E8", "#4FE9D5", "#E12582", "#FFB608"];

const Profiles = ({ cab }) => {
  cab.type_schools = cab.type_schools.sort((a, b) => b.value - a.value);

  function renderSchools() {
    if (cab.schools.length === 0) {
      return null;
    } else if (cab.schools.length === 1) {
      return (
        <div className={styles.container_schools}>
          <div className={styles.container_list_school}>
            {renderItemSchool(cab.type_schools[0])}
          </div>
        </div>
      );
    } else if (cab.schools.length === 2) {
      return (
        <div className={styles.container_schools}>
          <div className={styles.container_list_school}>
            {renderItemSchool(cab.type_schools[1])}
          </div>
          <div className={styles.container_list_school}>
            {renderItemSchool(cab.type_schools[0])}
          </div>
        </div>
      );
    } else if (cab.schools.length === 3) {
      return (
        <div className={styles.container_schools}>
          <div className={styles.container_list_school}>
            {renderItemSchool(cab.type_schools[2])}
          </div>
          <div className={styles.container_list_school}>
            {renderItemSchool(cab.type_schools[0])}
            {renderItemSchool(cab.type_schools[1])}
          </div>
        </div>
      );
    } else if (cab.type_schools.length === 4) {
      return (
        <div className={styles.container_schools}>
          <div className={styles.container_list_school}>
            {renderItemSchool(cab.type_schools[3])}
            {renderItemSchool(cab.type_schools[2])}
          </div>
          <div className={styles.container_list_school}>
            {renderItemSchool(cab.type_schools[0])}
            {renderItemSchool(cab.type_schools[1])}
          </div>
        </div>
      );
    }
  }

  function renderItemSchool(data) {
    const schools = cab.schools.find((x) => x.type === data.type)?.value;

    if (!schools) return;

    return (
      <ListSchools
        schools={schools}
        color={COLORS[data.type - 1]}
        label={data.label}
        value={data.value}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>{ReactHtmlParser(cab.text_profiles)}</div>

      <div className={styles.container_pie}>
        <div className={styles.pie}>
          <div className={styles.logo_graph_school}>
            <img className="image_contain" src={cab.logo} />
          </div>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={cab.type_schools}
                dataKey="value"
                nameKey="name"
                startAngle={90}
                endAngle={-270}
                cx="50%"
                cy="50%"
                outerRadius="60%"
                innerRadius="50%"
                fill="#8884d8"
              >
                {cab.type_schools.map((entry, index) => (
                  <Cell
                    fill={COLORS[entry.type - 1]}
                    key={entry.type.toString()}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        {renderSchools()}
      </div>
    </div>
  );
};

export default Profiles;
