import React from 'react';

import {Link} from "react-router-dom";

import Select from './Select';
import ListSearch from './ListSearch';
import './searchbarmobile.css';

var update = require('immutability-helper');


class SearchbarMobile extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      search:["", ""],
      filters:[],
      list_filter:[]
    };

    this.onSearch = this.onSearch.bind(this)

  }

  componentDidMount(){
    fetch(global.endpoint + '/filters', {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    })
    .then((response) => response.json())
    .then((responseData) => {
      let list_filter = []
      for(let i=0;i<responseData.data.length;i++){
        list_filter.push(false)
      }
      this.setState({
        filters:responseData.data,
        list_filter:list_filter
      })
    })
    .catch((error) => {

         
    });
  }

  onSearch(value, list){

    let search = this.state.search
    if(list<2){
      search = update(search, {
        $splice: [[list, 1, value]]
      });
    }

    this.setState({
      search:search,
      list:list
    })

    if(this.props.onSearch && list === 0){
      this.props.onSearch(value)
    }
  }

  renderSelected(index, id){
    if(this.props.filter){
      let ind = this.props.filter[index].findIndex(x => x.id === id)
      return(
        <div className={ind === -1 ? 'item_selected' : 'item_selected selected'}></div>
      )
    }
  }

  toggleListFilter(index){
    let list_filter = this.state.list_filter
    let _update = update(list_filter, {
      $splice: [[index, 1, !this.state.list_filter[index]]]
    });

    this.setState({
      list_filter:_update
    })
  }

  renderNumberSelected(index){
    if(this.props.filter && this.props.filter[index].length>0){
      return(
        <div className="number_selected">
          <div>{this.props.filter[index].length}</div>
        </div>
      )
    }
  }

  renderFilterSelected(){
    let number = 0
    for(let i=0; i<this.props.filter.length;i++){
      number += this.props.filter[i].length>0 ? 1 : 0
    }
    if(number){
      return(
        <div className="number_selected">
          <div>{number}</div>
        </div>
      )
    }
  }

  render(){
    return(
      <div id="container_searchbar">
        <div id="searchbar">
          <div id="search_field" className="container_field_search">
            <img className="icon_search" src={require('../../icon/loupe.svg').default}/>
            <input
              placeholder="Rechercher un cabinet"
              onChange={(evt) => this.onSearch(evt.target.value, 0)}
              size="1"
              value={this.state.search[0]}
            />
          </div>
          <div className="container_icon_filter pointer" onClick={this.props.toggleFilter}>
            <img src={require('../../icon/filtres.svg').default}/>
            {this.renderFilterSelected()}
          </div>

        </div>

        <div id="container_filter">
          <div id="header_filter">
            <div id="close_filter" onClick={this.props.toggleFilter}>
              <img src={require('../../icon/close_filter.svg').default}/>
            </div>
            <div id="title_filter">
              Filtres
            </div>
          </div>

          <div id="container_filters">
            {this.state.filters.map((item, index)=>{
              return(
                <div className={this.state.list_filter[item.index] ? "filter show_list_filter" : "filter"} key={item.name}>
                  <div className="container_title_filter" onClick={() => this.toggleListFilter(item.index)}>
                    <div className="title_filter">{item.name}</div>
                    {this.renderNumberSelected(item.index)}
                    <img src={require('../../icon/open_filter.svg').default}/>
                  </div>
                  <div className="list_filter">
                    {item.data.map((data)=>{
                      return(
                        <div className="container_cab_list" key={data.id.toString()} onClick={() => this.props.onChange({type:item.index+1, value:data})}>
                          {this.renderSelected(item.index, data.id)}
                          <div className="name_cab_list">{data.name ? data.name : data.label}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>

        </div>

      </div>
    )
    
  }
}

export default SearchbarMobile;
