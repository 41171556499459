var moment = require('moment');
require("moment/min/locales.min");
moment.locale('fr')

const momentCalendar = {
                        /*sameDay: function (now) {
                          var diff_hour = moment().utc().diff(this, "hours")
                          var diff_min = moment().utc().diff(this, "minutes")
                          if(diff_hour == 0 && diff_min == 0){
                            return "[Maintenant]";
                          }else if(diff_hour == 0){
                            return "[Il y a ]" + diff_min + "[min]";
                          }else{
                            return "[Il y a ]" + diff_hour + "[h]";
                          }
                        },
                        lastDay: "[Hier]",
                        lastWeek: 'dddd',
                        sameElse: 'Do MMMM'*/
                        sameElse:'DD/MM'
                      }

 const momentCalendarRow = {
                        sameDay: function (now) {
                          let min = moment(this).minutes()>9 ? moment(this).minutes() : '0' + moment(this).minutes()
                          return moment(this).hour() + ':' + min
                        },
                        lastDay: function (now) {
                          let min = moment(this).minutes()>9 ? moment(this).minutes() : '0' + moment(this).minutes()
                          return "Hier" + ' ' + moment(this).hour() + ':' + min
                        },
                        lastWeek: function (now) {
                          let min = moment(this).minutes()>9 ? moment(this).minutes() : '0' + moment(this).minutes()
                          return 'dddd ' + moment(this).hour() + ':' + min
                        },
                        sameElse: function (now) {
                          let min = moment(this).minutes()>9 ? moment(this).minutes() : '0' + moment(this).minutes()
                          return 'Do MMM ' + moment(this).hour() + ':' + min
                        }
                      }                  

module.exports = function(data, row) {  

  return moment(data).format("DD/MM/YYYY")

//  return moment(data).add(moment().utcOffset(), 'm').calendar(null, momentCalendar)

  /*
  if(row){
    return moment(data).add(moment().utcOffset(), 'm').calendar(null, momentCalendarRow)
  }else{
    
  }*/
}