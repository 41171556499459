import React, { useEffect, useState } from 'react';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Content from '../content/Content';
import ButtonBuddyScan from '../../../home/buttonbuddyscan/ButtonBuddyScan';

import ReactHtmlParser from 'react-html-parser';

import styles from './infosclefs.module.css'

const InfosClefs = ({
  cab
}) => {
  return(
    <div className={styles.container}>
      <div className={styles.text}>
        {ReactHtmlParser(cab.presentation)}
      </div>
      <div className={styles.containerContext}>
        <BrowserView>
          <div className={styles.bannerContextInfosClefs}>
            <div className={styles.labelContext}>
              <div>{cab.name}</div>
              <div>vous intéresse ?</div>
            </div>
            <Link 
              to={`recrutement`}
              containerId="main_scrollbar" 
              smooth={true} 
              offset={isMobile ? -50 : -100} 
              spy={true} 
            >
              <ButtonBuddyScan
                label='Voir les offres'
                styleIcon={{ height:'2vh', width:'2vh' }}
                styleLabel={{fontSize:'var(--size_3)', fontWeight:'500', marginRight:'1vw'}}
              />
            </Link>
          </div>
        </BrowserView>
        <Content 
          type={0}
          content={cab.content.find(x => x.type === 0)}
          style={isMobile ? {} : {paddingBottom:cab.content.find(x => x.type === 0)?.value?.length % 2 == 1 ? '20vh' : 0, alignSelf: 'flex-start'}}
        />
      </div>
    </div>
  )

};



export default InfosClefs;
