import React, { useEffect, useState } from 'react';

import {ReactComponent as IconLogo} from '@icon/simulateur-preview.svg';
import {ReactComponent as IconClose} from '@icon/close_popup.svg';

import Button from '@utils/button/Button'
import styles from './styles.module.css'

const SimulateurPreview = ({

}) => {

  const [hide, setHide] = useState(localStorage.getItem('close-simulateur'))

  if(hide) return null

  return(
    <div 
      className={`${styles.container}`} 
    >
      <div className={styles.title}>Découvrez notre simulateur de salaire de consultant</div>
      <div className={styles.image}>
        <IconLogo height='80px'/>
      </div>
      <Button
        label='Calculer mon salaire'
        type='white'
        onClick={() => window.location = '/simulateur-salaire'}
      />
      <div className={styles.icon} onClick={() => {
        setHide(true)
        localStorage.setItem('close-simulateur', true)
      }}>
        <IconClose height='10px' color='white'/>
      </div>
    </div>
  )
}

export default SimulateurPreview;