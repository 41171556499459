import React, { useState } from 'react';

import List from '@utils/list/List';
import Searchbar from '@utils/searchbar/Searchbar';
import ActionsUser from '@admin/matching/users/actionsUser/ActionsUser';

import customFetch from '@utils/function/customFetch';

import styles from './itemuser.module.css';

const ItemUser = ({
  user,
  onUpdateUser,
  sort,
  onClick
}) => {

  function action(evt, type){
    evt.stopPropagation();
    customFetch({ endpoint: 'admin/update_user', onSuccess:onUpdateUser, data:{[type]:1-user[type], id: user.id},  type:'POST'})
  }

  function renderDate(){
    if(sort === 0) return user.subscription 
    if(sort === 1) return user.last_connect
    return user.last_modif
  }

  return(
    <div className={`${styles.container_user} ${!user.available && styles.unavailable} card hover_card`} onClick={onClick}>
      
      <div className={styles.infos_user}>
        <div className={styles.container_image_user}>
          <div className={styles.image_user}>
            <img className='image_cover' src={user.image || require('@icon/placeholder_user.svg').default}/>
          </div>
          <div className={`${styles.badge} ${styles.badge_senior} ${!user.senior && styles.badge_junior}`}>{user.senior ? 'S' : 'J'}</div>
          {!user.available && (
            <div className={`${styles.badge} ${styles.badge_unavailable}`}>
              <img className="image_contain" src={require('@icon/admin/badge_unavailable.svg').default}/>
            </div>
          )}
          {user.complete && (
            <div className={`${styles.badge} ${styles.badge_complete}`}>
               <img className="image_contain" src={require('@icon/admin/badge_complete.svg').default}/>
            </div>
          )}
        </div>
        <div className={styles.name_user}>
          <div className={styles.main_info}>{user.name}</div>
          <div className={styles.sub_info}>#{user.id}</div>
          <div className={styles.sub_info}>{renderDate()}</div>
        </div>
      </div>

      <div className={styles.schools_user}>
        {user.schools.map((item) => (
          <div className={styles.school}>
            <span className={styles.lign_info}>
              <span className={styles.main_info}>{item.label}</span>
              <span className={styles.sub_info}>{item.formation}</span>
            </span>
          </div>
        ))}
      </div>

      <div className={styles.cabs_user}>
        {user.exp_cab.map((item) => (
          <div className={styles.cab}>
             <span className={styles.lign_info}>
              <span className={styles.main_info}>{item.label}</span>
              <span className={styles.sub_info}>{item.duration}</span>
            </span>
          </div>
        ))}
      </div>

      <ActionsUser 
        user={user}
        onUpdateUser={onUpdateUser}
      />

    </div>
  )
}

export default ItemUser;
