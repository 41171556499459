import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

var update = require('immutability-helper');

class List extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      loading_more:false
    };  

    this.r_news = this.r_news.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.myRef = React.createRef()

    this.page = 0

  }

  componentDidMount(){
    if(this.props.socket && global.socket){
      global.socket.emit(this.props.socket,  {page:0, search:this.props.search, extraData:this.props.extraDataSocket, refresh:true})
      global.socket.on("r_" + this.props.socket, this.r_news)
    }else{
      this.fetchData(0, this.props.search, this.props.extraDataSocket, false)
    }
  }

  componentWillUnmount(){
    if(this.props.socket && global.socket){
      global.socket.off("r_" + this.props.socket, this.r_news)
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.search !== this.props.search || JSON.stringify(nextProps.extraDataSocket) !== JSON.stringify(this.props.extraDataSocket)){
      this.more_news = true
      this.page = 0
      if(this.props.socket && global.socket){
        global.socket.emit(this.props.socket,  {page:0, search:nextProps.search, extraData:nextProps.extraDataSocket, refresh:true})
      }else{
        this.fetchData(0, nextProps.search, nextProps.extraDataSocket, true)
      }
      return false
    }
    return true
  }

  fetchData(page, search, extraData, refresh, prevData){
    fetch(process.env.REACT_APP_ENDPOINT + '/' + this.props.endpoint, {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        page: page,
        refresh:refresh,
        extraData:extraData, 
        search:search,
        prevData:prevData
      })
    })
    .then((response) => response.json())
    .then((responseData) => {
      this.r_news(responseData.data, responseData.refresh)
    })
    .catch((error) => {

         
    });
  }

  r_news(data, refresh){
    this.more_news = false
    this.props.data_received(data, refresh)
  }

  onScroll(event){
    if(!this.more_news && !this.props.no_more){
      if(event.target.scrollHeight - event.target.scrollTop < 1.5*event.target.clientHeight){
        this.more_news = true
        this.page++
        if(this.props.socket && global.socket){
          global.socket.emit(this.props.socket,  {page:this.page, search:this.props.search, extraData:this.props.extraDataSocket})
        }else{
          this.fetchData(this.page, this.props.search, this.props.extraDataSocket, false, this.props.prevData)
        }
        this.setState({
          loading_more:true
        })
      }
    }
    //let index = event.nativeEvent.path.findIndex(x => x.indexOf("#searchbar") > -1)
    if(this.props.onScroll){
      this.props.onScroll(event)
    }

  }

  renderContent(){
    if(this.props.isLoading && this.props.renderLoading){
      return(
        <div 
          id={this.props.id} 
          className={"content_list"} 
          style={this.props.style} 
        >
          {this.props.renderLoading()}
        </div>
      )
    }else{
      return(
        <div id={this.props.id_content_list} className="content_list">
          {this.props.data.length > 0 ? (
            this.props.data.map((item, index) => this.props.renderItem(item, index))
          ):(this.props.renderEmpty ? this.props.renderEmpty() : null)}
        </div>
      )
    }
  }

  render(){
    return (
        <div 
          id={this.props.id} 
          className={this.props.className ? this.props.className : "old_list"} 
          style={this.props.style} 
          ref={this.myRef}
          //onScroll={this.onScroll} 
        > 
          {this.props.renderHeader ? this.props.renderHeader() : null}
          <Scrollbars 
            id={this.props.id} 
            onScroll={this.onScroll} 
            autoHide
            autoHeight={this.props.autoHeight}
            renderView={props => <div {...props} className="view"/>}
            renderTrackHorizontal={props => <div {...props} className="horizontal_track"/>}
            renderThumbHorizontal={props => <div {...props} className="horizontal_thumb"/>}
            className="inner_list"
          >
            {this.props.ListHeaderComponent ? this.props.ListHeaderComponent() : null}
            {this.renderContent()}
            {this.props.ListFooterComponent ? this.props.ListFooterComponent() : null}
          </Scrollbars>
        </div>
      );
  }
}

export default List;
