import React, { useState } from 'react';

import Modal from '@utils/modal/Modal';
import Field from '@utils/form/field/Field';
import Button from '@utils/button/Button';

import customFetch from '@utils/function/customFetch';

import styles from '@site/connect/modalconnect.module.css'

const ModalSubscribeRecruteur = ({
  onClose,
}) => { 

  const [email, updateEmail] = useState()
  const [cab, updateCab] = useState()
  const [success, updateSuccess] = useState(false)

  function renderFooter(){
    return(
      <Button 
        type="full"
        label="Envoyer"
        onClick={() =>  customFetch({ endpoint: 'recruteur/become_partner', data:{username:email, cab}, onSuccess, onError, type:'POST'})}
      />
    )
  }

  function onError(data){

  }

  function onSuccess(data){
    updateSuccess(true)
  }

  return(
    <Modal
      closeModal={onClose}
      header={`Devenir un partenaire Consulting Buddies`}
      footer={!success && {style:{justifyContent:'flex-end'}, content:renderFooter()}}
    >

        <div className={styles.content}>
          
          <div className={styles.container_image}>
            <img src={require('@icon/illustration_become_partner.svg').default}/>
          </div>

          {success ? (
            <>
              <div className={styles.text_login}>Votre demande de contact a bien été prise en compte ! Nos équipes vont vous contacter dans les plus brefs délais.</div>
              <Button 
                type="full"
                label="Terminer"
                onClick={onClose}
              />
            </>
          ) : (
            <>
              <div className={styles.text_login}>Vous représentez un cabinet de conseil ? Transmettez-nous vos coordonnées et nous vous recontacterons rapidement afin de vous présenter comment Consulting Buddies peut vous aider à améliorer les performance de votre recrutement !</div>

              <div className={styles.form_login}>
                <Field
                  field={{title:'Votre cabinet de conseil', type:'input'}}
                  onChange={({ value }) => updateCab(value)}
                />
                <Field 
                  field={{title:'Votre email', type:'input'}}
                  onChange={({ value }) => updateEmail(value)}
                />
              </div>
            </>
          )}

        </div>

    </Modal>
  )
}


export default ModalSubscribeRecruteur;