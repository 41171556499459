import React, { useState, useEffect } from 'react';

import styles from'./typetranslate.module.css';

var update = require('immutability-helper');


class ItemTranslate extends React.Component {


  constructor(props) {
    super(props);
    this.state = {

    };


  }

  shouldComponentUpdate(nextProps, nextState){
    return nextProps.center !== this.props.center
  }

  translate(){
    if(this.props.index === this.props.center){
      return {translate:0, opacity:1}
    }else if(this.props.index === this.props.center + 1 || this.props.index === 0 && this.props.center === this.props.length-1){
      return {translate:'-100%', opacity:0}
    }else{
      return {translate:'100%', opacity:0}
    }
  }

  render(){
    this.style = this.translate()
    return(
      <div className={styles.item_translate} style={{transform:'translateY(' + this.style.translate + ')', opacity:this.style.opacity}}>
        {this.props.label}
      </div>

    )
    
  }
}

const TypeTranslate = ({

}) => {

  const values = [
    'Stratégie',
    'Management',
    'RSE',
    'Data'
  ]

  const [index, updateIndex] = useState(0)

  useEffect(() => {
    setTimeout(function(){
      updateIndex( index + 1 === values.length ? 0 : index + 1)
    }, 3000)
  }, [index])

  return(
    <div className={styles.container_translate}>
      {values.map((item, ind) => (
        <ItemTranslate
          center={index}
          index={ind}
          label={item}
          length={values.length}
          key={ind.toString()}
        />
      ))}
    </div>
  )

}

export default TypeTranslate;
